import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import CommonApiCalls from '../CommonApiCalls/CommonApiCalls'
import MasterComonent from '../../../Backend/MasterComonent'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getPurchaseOrderColumns from './PurchaseOrderColumns'
import { transformObjectPuchaseOrderExternal } from '../../../../inc/TransformerFunctions'
import { exportToXlsx, handleFetchDataForListing } from '../../../../inc/Validation'
import { generateBaseUrl } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'

let gridObj = null

const PurchaseOrderList = (props) => {
  const purchaseOrderCommonApiCalls = new CommonApiCalls()
  const poHeaderTypeCalls = new WorkConfirmationApiCalls()
  const navigate = useNavigate()
  const [documentType, setDocumentType] = useState('')
  const [exportLoading, setExportLoading] = useState(false) 
  const totalRecords = useRef(0)
  const [loading, setLoading] = useState(true)
  const [allSites, setAllSites] = useState([])
  const [purchaseStatuses, setPurchaseStatuses] = useState([])
  const [documentStatuses,setDocumentStatuses]=useState([])
  
  const location = useLocation()

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  
  const status = getSearchParam('status')
  

  const getAsyncData = async () => {
    setLoading(true)
    const sites = await purchaseOrderCommonApiCalls.getAllSites()
    const purchase_statuses = await purchaseOrderCommonApiCalls.getAllPurchaseOrderStatus()
    const document_statuses = await purchaseOrderCommonApiCalls.getAllDocumentStatus()
    if (sites && purchase_statuses && document_statuses) setLoading(false)
    setAllSites(sites)
    setDocumentStatuses(document_statuses)
    setPurchaseStatuses(purchase_statuses)
  }

  const get_purchase_order_details = async () => {
    const poAllHeaderTypes = await poHeaderTypeCalls.getAllPOHeaderType()
    const poAllHeaderType = poAllHeaderTypes?.find(
      (item) => item.name === 'Purchase Order',
    )
    if (poAllHeaderType) {
      setDocumentType(poAllHeaderType?.id)
    }
  }

  const handleGridReady= (params) => {
    gridObj = params
  }

  //function for first column hyper link click
  const cellHandler = (event) => { 
    if (event.colDef.field === 'document_number') {
      navigate(
        `/purchase-order-details?purchase_order_id=${event.data.purchase_order_id}`,
      )
    }
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}`
      )
    }
  }

  const typeTitle = () => {
    if(parseInt(status)===0){
      return "Draft"
    }else if(parseInt(status)===1){
      return "Confirmed"
    }
    else if(parseInt(status)===2){
      return "Under Review"
    } else {
      return "All"
    }
  }

  
  const Columns_Headings = getPurchaseOrderColumns({
    language:props.language,
    allSites:allSites,
    purchaseStatuses:purchaseStatuses,
    documentStatuses:documentStatuses
  })

  //useEffect for fetching all sites and vendors and purchase order statuses
  useEffect(() => {
    getAsyncData()
  }, [])

  useEffect(()=>{
    get_purchase_order_details()
  },[])

  const getTransformedObjForExport = (data) => transformObjectPuchaseOrderExternal(data, purchaseStatuses, documentStatuses, allSites);

  const exportData = async () => {
    setExportLoading(true)

    const apiUrl = Settings.apiPurchaseOrderUrl + `/PO_header`;
    const params = `&type=${documentType?.id || 0}${status?`&document_status=${status}`:''}`;
    
    let pagination = true
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current
    
    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
    await exportToXlsx({ url: baseUrl, columns: Columns_Headings, fileName: 'po_details', additionalFunction: getTransformedObjForExport })
    
    setExportLoading(false)
  }

  return (
    <div className="purchase_orders_listing_archive external-theme">
      <MasterComonent>
        <div className="container-fluid">
          <NavigationHeder
            backUrl="/dashboard"
            title={`Purchase Orders - ${typeTitle()}`}
            hideMoreBtn={true}
          ></NavigationHeder>
          <ExtHeaderLine
            title={Helper.getLabel(
              props.language,
              'purchase_orders',
              `Purchase Orders - ${typeTitle()}`,
            )}
          />
          {exportLoading ? <OverlayLoader /> : null}
          {loading ? (
            <SimpleLoading />
          ) : (
            <AgGridNew
              apiUrl={ Settings.apiPurchaseOrderUrl + `/PO_header`}
              additionalParams={`&type=${documentType?.id || 0}${status?`&document_status=${status}`:''}`}
              filterAdditionalParams={`&type=${documentType?.id || 0}${status?`&document_status=${status}`:''}`}
              pagination={true}
              columnDefs={Columns_Headings}
              onGridReady={handleGridReady}
              isDisabledCheckbox={true}
              onCellClicked={cellHandler}
              uniqueField={'purchase_order_id'}
              hideAddBtn={true}
              height={500}
              hideDeleteBtn={true}
              gridId={`po-external-listing-page`}
              btnsWrapperStyle="w-100"
              afterDeleteBtns={
                <div className="import_sample_template ms-auto px-3">
                  <button onClick={exportData}>
                    <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                  </button>
                </div>
              } 
              fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
            />
          )}
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(PurchaseOrderList)
