import React, { Component } from 'react';
import Helper from '../../../inc/Helper';
import Popup from '../../Popup/Popup';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import Checkbox from '../../Forms/Checkbox';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import FormValidator from '../../../inc/FormValidator';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import SimpleLoading from '../../Loading/SimpleLoading';
import AgGrid from '../../grid/ag/ag-grid';
import $ from 'jquery';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
class SegmentsParents extends Component {
    constructor(props){
        super(props);
        this.validationConfigure();
        this.state = {
            segment:this.props.segment,
            parents:[],
            parent:{
                isactive:true
            }
        }
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = [
            {name:'segment_id',displayName:Helper.getLabel(language,'parent','parent'),types:['Required'],max:10 },
            {name:'owner',displayName:Helper.getLabel(language,'owner','Owner'),types:['Required',"Number"],max:10 },
            {name:'code',displayName:Helper.getLabel(language,'code','code'),types:['Required'],max:10 },
            {name:'name',displayName:Helper.getLabel(language,'name','name'),types:['Required'],max:80 },
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.setState({
            segment:this.props.segment
        })
        this.loadParent();
    }
    loadParent(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment_parent').then(res=> {
            
            let segmentParents = [];
            let segment_id = that.state.segment.segment_id;
            if(res.data.status=='success'){
                res.data.data.forEach(parentItem => {
                    if(segment_id == parentItem.segment_id){
                        segmentParents.push(parentItem);
                    }
                });
            }
            that.setState({
                parents:segmentParents,
                isLoading:false
            })
        })
    }

    componentDidUpdate(prevProps){
        if(this.props.segment != prevProps.segment){
            let that = this;
            this.setState({
                segment:this.props.segment,
                parent:{
                    isactive:true
                }
            }, function (){
                that.loadParent()
            })
        }
    }
    saveBtnHanlder(items){
        
    }
    createSegmentParent(parent){
        let newData = {
            code: parent.code,
            name: parent.name,
            owner:  parent.owner,
            isactive: parent.isactive == "yes" || parent.isactive === true ? true : false,
            translationsource_id: null,
            segment_id:this.state.segment.segment_id
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        this.setState({
            isLoading:true
        })
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().post(Settings.apiUrls.segments + '/segment_parent',newData).then(res=>{
            console.log(res);
            if(res.data.status ==='success'){
                that.loadParent();
                Helper.alert(res.data.message)
            }else{
                that.setState({
                    isLoading:false
                })
            }
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })

    }
    
    updateParent (parent){
        let newData = {
            ...parent,
            isactive: parent.isactive == "yes" || parent.isactive === true ? true : false,
            segment_id:this.state.segment.segment_id
        }
        if(!this.validator.isValid(newData)){
            this.validator.displayMessage(this);
            return;
        }
        this.setState({
            isLoading:true
        })
        let that = this;
        let api = Api;
        api.setUserToken();
        api.axios().put(Settings.apiUrls.segments + '/segment_parent/'+parent.segmentvalueparent_id,newData).then(res=>{
            if(res.data.status ==='success'){
                that.loadParent();
                Helper.alert(res.data.message)
            }else{
                that.setState({
                    isLoading:false
                })
            }
        }).catch(error => {
            getPopupMessageBasedOnStatus(error)
        })

    }
    inputChangeHandler(event){
        let parent = this.state.parent;
        this.setState({
            parent:{
                ...parent,
                [event.target.name]:event.target.value
            }
        })
    }
    checkboxChnageHandler(name,value){
        let parent = this.state.parent;
        this.setState({
            parent: {
                ...parent,
                [name]: value =='on' ? true: false
            }
        })
    }
    onGridSave(items){
        items.forEach( item => {
            if(item.segmentvalueparent_id && item.segmentvalueparent_id >0 ){
                this.updateParent(item);
            }else{
                item.segmentvalueparent_id = null;
                this.createSegmentParent(item);
            }
        })
    }
    onPopupCloseHandler(e){
        if(this.props.onPopupClose && typeof this.props.onPopupClose ==='function'){
            this.props.onPopupClose();
        }
        $('html').removeClass('rs_popup_open');
    }
    render() {
        let language = this.props.language;
        let fromOptions = [{label:'0001', value:'0001'},{label:'0002', value:'0002'},{label:'0003', value:'0003'},{label:'0004', value:'0004'}];
        let toOptions = fromOptions;
        let segment = this.state.segment;
        if(!segment){
            return <>No Segment Selected</>
        }
        let blueSectionItems = [
            {title:Helper.getLabel(language,'segment_name','Segment Name'),subtitle:segment.display_name}
        ];
        let parent = this.state.parent;
        let gridHeader= [
            {field:'code', headerName: Helper.getLabel(language,"code",'Code')},
            {field:'name', headerName: Helper.getLabel(language,"name",'Name')},
            {field:'owner', headerName: Helper.getLabel(language,"owner",'Owner')},
            {field:'isactive', cellDataType:false, headerName: Helper.getLabel(language,"active",'Active'), inputType:'checkbox'}
        ];
        return (
            <Popup className="seg_popup" onClose={ this.onPopupCloseHandler.bind(this)} autoOpen={true} width="960px">
                <h2 className='seg_p_title'>{Helper.getLabel(language,'parent','Parent')}</h2>
                <div className='rfq-internal-blue'>
                 <BlueCallToAction items={blueSectionItems}/>
                </div>
                { this.state.isLoading ? <SimpleLoading /> : ''}
                <Collapse title={Helper.getLabel(language,'segments','Segments')} open={true} className="mb-4 mt-4">
                    <AgGrid  id="segment_parents" deleteConfig={{that:this,apiUrl: Settings.apiUrls.segments +'/segment_parent', idName:'segmentvalueparent_id',reloadFuntion:'loadParent'}}  onSave={ this.onGridSave.bind(this)}  header={gridHeader} data={this.state.parents}/>
                </Collapse>
                <div className='seg_p_footer'>
                    <Button className="btn_md blue" onClick = { this.saveBtnHanlder.bind(this) } title= { Helper.getLabel(language,"save",'Save')} />
                </div>
            </Popup>
        );
    }
}


export default SegmentsParents;