import React from 'react'
import Popup from '../../Popup/Popup'
import $ from 'jquery'
import AwardingLinesGrid from './AwardingLinesGrid'
import Button from '../../inc/Button'
import Helper from '../../../inc/Helper'

const RFQAwardingGrid = ({ setRFQAwardingPopup, language, additionalParams, rfqHeader, awardingLinesData, awardingGridObj, handleInitiateAwarding , getPRLinesForAwarding}) => {
  const onPopupClose = () => {
    $('html').removeClass('rs_popup_open')
    setRFQAwardingPopup(false)
  }
  return (
    <div>
      <Popup autoOpen={true} width="1100px" onClose={onPopupClose}>
        <div className='d-flex align-items-center justify-content-end mb-4'>
          <Button title={Helper.getLabel(language,"initiate_awarding","Initiate Awarding")} onClick={handleInitiateAwarding} />
        </div>
        <AwardingLinesGrid language={language} apiUrl={null} additionalParams={additionalParams} rfqHeader={rfqHeader} awardingLinesData={awardingLinesData} type="initiate awarding" awardingGridObj={awardingGridObj} getPRLinesForAwarding={getPRLinesForAwarding}/>
      </Popup>
    </div>
  )
}

export default RFQAwardingGrid
