import React, { useCallback, useState } from 'react';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';   
import Popup from '../../../Popup/Popup';
import $ from 'jquery';
import Helper from '../../../../inc/Helper';
import { exportToXlsx, getNextIncrementalNumber, getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { transformObjectScoringCriterias } from '../../../../inc/TransformerFunctions';
import { generateBaseUrl } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

/* Validations: Sequence is a integer and required ,name required & description to be 100 */

let gridApi = null;
let AddBtn = null;
let transaction = null;

const CriteriaDetails = ({
    scoringsectiondetail_id, 
    sectionName,
    rfqMode = false,
    setCriteriaDetails, 
    reloadGrid
}) => { 
    const [criteriasExportLoading,setCriteriasExportLoading] = useState(false);

    const onRowValueChanged = (params) => {
        const id = rfqMode ? params?.data?.scoringcriterialdetailrfq_id : params?.data?.scoringcriterialdetail_id; 
        if(id){
            updateDataSectionCriteria(params);
        } else {
            addNewDataSectionCriteria(params);
        }
    }
  
    const updateDataSectionCriteria = async (event) => { 
        try {
            let payload = {
                sequence: event?.data?.sequence,
                description: event?.data?.description,
                name: event?.data?.name,
                comments_mandatory: event?.data?.comments_mandatory ? true : false
            };

            const id = rfqMode ? event?.data?.scoringcriterialdetailrfq_id : event?.data?.scoringcriterialdetail_id;
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/scoringcriteria/${id}${rfqMode ? '?for_rfq=True' : ''}`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.api?.refreshServerSide({ purge: true });
                     
                    gridApi?.api?.deselectAll();
                }, 100);

                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    }

    const addNewDataSectionCriteria = async (event) => {
        try {
            let payload = {
                name: event?.data?.name,
                scoringsection_id: parseInt(scoringsectiondetail_id),
                sequence: parseInt(event?.data?.sequence),
                description: event?.data?.description,
                comments_mandatory: event?.data?.comments_mandatory ? true : false
            };
      
            let api = Api;
            api.setUserToken();
            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+`/scoringCriteria${rfqMode ? '?for_rfq=True' : ''}`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    gridApi?.api?.refreshServerSide({ purge: true });  
                    gridApi?.api?.deselectAll();
                }, 100);
        
                setTimeout(() => {
                    if (AddBtn) {
                        gridApi?.api?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    }

    const DeleteHandlerSectionCriteria = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        const id = rfqMode ? element?.data?.scoringcriterialdetailrfq_id : element?.data?.scoringcriterialdetail_id;
    
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/scoringcriteria/${id}${rfqMode ? '?for_rfq=True' : ''}`)
            const rowNode = gridApi?.api?.getRowNode(id)

            if (rowNode) {
                rowNode.setSelected(false)
            }
        
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridApi?.api?.applyServerSideTransaction(transaction)
            }, 200) 
        
            gridApi?.api?.refreshServerSide({ purge: true });
             
            gridApi?.api?.deselectAll();
            
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);
  
    const CloseHandler = () => {
        setCriteriaDetails((prevState) => {
            return {
                ...prevState,
                showPopup: false
            }
        })
        reloadGrid();
    }

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    }

    const SequenceValueChange = (params) => { 
        if(params.data.sequence == undefined) {
            let sequencesArray = []
            params.api.forEachNode((node) => {
                if(node.data.sequence === 0) return;
                sequencesArray.push({sequence: node.data.sequence !== undefined ? parseInt(node.data.sequence) : undefined})
            }) 
            let sequenceVal = getNextIncrementalNumber(sequencesArray) 
            params.node.setDataValue('sequence', sequenceVal) 
            params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
            return; 
        };
        if(params.data.sequence !== 0) return;

        let sequencesArray = []
        params.api.forEachNode((node) => {
            if(node.data.sequence === 0 || params.data.sequence == undefined) return;
            sequencesArray.push({sequence: parseInt(node.data.sequence)})
        }) 
        let sequenceVal = getNextIncrementalNumber(sequencesArray) 
        params.node.setDataValue('sequence', sequenceVal) 
        params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
    };
 
    const columnDefs = [
        {field: 'sequence', filter: 'agNumberColumnFilter', editable: true, headerName:'Sequence*'},
        {field: 'name', editable: (event) => rfqMode ? !event?.data?.scoringcriterialdetailrfq_id : !event?.data?.scoringcriterialdetail_id, filter: 'agTextColumnFilter', headerName:'Name*'},
        {field: 'description', filter: 'agTextColumnFilter', editable: true,  headerName:'Description'},
        { 
            field: 'comments_mandatory',
            filter: 'agSetColumnFilter',
            filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
            }, 
            cellRenderer: 'agCheckboxCellRenderer', 
            cellEditor: 'agCheckboxCellEditor', 
            cellRendererParams: { disabled: true },
            editable: true,
            minWidth: 230,
            valueGetter: (params) => {
                if(!params?.data?.comments_mandatory || !params?.data){
                    return false
                }
                else {
                    return params.data?.comments_mandatory
                }
            },
            headerName: 'Comments mandatory'
        }
    ];

    const getTransformedObjForExport = (data) => transformObjectScoringCriterias(data);

    const exportData = async () => {
        if(!scoringsectiondetail_id) return;

        setCriteriasExportLoading(true);
    
        const apiUrl = scoringsectiondetail_id?Settings.apiPurchaseRequisitionUrl+`/get_sections_criteria/${scoringsectiondetail_id}${rfqMode ? '?for_rfq=True' : ''}`:null;
        const params = '';
        
        let pagination = false;
        let defaultAdvanceFilterObj = false; 
        
        const baseUrl = generateBaseUrl({ gridObj: gridApi, apiUrl, params, pagination, defaultAdvanceFilterObj });
        await exportToXlsx({ url: baseUrl, columns: columnDefs, fileName: 'criterias_details', additionalFunction: getTransformedObjForExport })
        
        setCriteriasExportLoading(false);
    }

    return (
        <div className='common_responsive_class_popup_component'>
            {criteriasExportLoading ? <OverlayLoader isPopupInTab={true} /> : null}
            <Popup className="section_criteria_popup" autoOpen={true} onClose={CloseHandler}>
                <h1 className='rfq_head_line_txt'>Criteria - {sectionName}</h1>
                <div className='grid_sections_criteria grid_save_btn_hide'>
                    <AgGridNew
                        apiUrl={scoringsectiondetail_id?Settings.apiPurchaseRequisitionUrl+`/get_sections_criteria/${scoringsectiondetail_id}${rfqMode ? '?for_rfq=True' : ''}`:null}  
                        handleDeleteSelectedRows={DeleteHandlerSectionCriteria}
                        onRowEditingStopped={SequenceValueChange}
                        handleAddButton={handleBtn} 
                        addLabel={'Add criteria'}
                        columnDefs={columnDefs}  
                        onRowValueChanged={onRowValueChanged}
                        onGridReady={(params) => gridApi = params}
                        height={500}
                        gridId={'rfq_scoring_section_criteria_grid'}
                        uniqueField={rfqMode ? 'scoringcriterialdetailrfq_id' : 'scoringcriterialdetail_id'}
                        pagination={false}  
                        afterDeleteBtns={
                            <div className="import_sample_template ms-auto px-2">
                                <button onClick={exportData}>
                                    <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                                </button>
                            </div>
                        }
                        btnsWrapperStyle="w-100 px-2" 
                    />
                </div>
            </Popup>
        </div>
    )
}

export default CriteriaDetails;