import DatepickerFunctions from "../../../../inc/DatepickerHelper"
import Helper from "../../../../inc/Helper"
import {  getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator } from "../../../grid/ag/commonFunctions/GridCommonFunctions"

const getInvoiceColumns = ({language,location,invoiceStatuses,applyLinkToDocumentNumberCol}) => {
  return [
    {
      field: 'reference_number',
      headerName:
        location?.pathname === '/credit-notes'
          ? Helper.getLabel(language, 'document_no', 'Credit note no.')
          : Helper.getLabel(language, 'invoice_no', 'Invoice no.'),
      minWidth: 220,
      editable: false,
      cellRenderer: (params) => gridLinkValueFormat(params),
    },
    {
      field: 'description',
      minWidth: 180,
      headerName: Helper.getLabel(language, 'description', 'Description'),
      editable: false,
    },
    {
      field: 'document_number',
      headerName: Helper.getLabel(
        language,
        'source_document_no',
        'Source document number'
      ),
      minWidth: 200,
      editable: false,
      cellRenderer: applyLinkToDocumentNumberCol ? gridLinkValueFormat: null,
    },
    {
      field: 'vendor_code',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'),
      editable: false,
      cellRenderer:gridLinkValueFormat,
    },
    {
      field: 'vendor_name',
      minWidth: 200,
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
    },
    {
      field: 'vendorsite_name',
      headerName: Helper.getLabel(language, 'vendor_site', 'Vendor site'),
      editable: false,
      minWidth: 180,
    },
    {
      field: 'document_status',
      minWidth: 200,
      headerName: Helper.getLabel(
        language,
        'document_status',
        'Document status'
      ),
      editable: false,
      cellRenderer: (params) => getStatusNameById(params.value, invoiceStatuses),
      filter: 'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, invoiceStatuses),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: 'invoice_date',
      minWidth: 200,
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      headerName: Helper.getLabel(
        language,
        location?.pathname === '/credit-notes'
          ? 'credit_note_date'
          : 'invoice_date',
        location?.pathname === '/credit-notes'
          ? 'Credit note date'
          : 'Invoice date'
      ),
      editable: false,
    },
    {
      field: 'accounting_date',
      minWidth: 200,
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      headerName: Helper.getLabel(
        language,
        'accounting_date',
        'Accounting date'
      ),
      editable: false,
    },
    {
      field: 'document_date',
      minWidth: 200,
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      headerName: Helper.getLabel(
        language,
        'document_date',
        'Document date'
      ),
      editable: false,
    },
    {
      field: 'delivery_date',
      minWidth: 200,
      hide: true,
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        DatepickerFunctions.convertDateFromDataBase(params.value),
      headerName: Helper.getLabel(
        language,
        'delivery_date',
        'Delivery date'
      ),
      editable: false,
    },
    {
      field: 'delivery_name',
      minWidth: 200,
      hide: true,
      headerName: Helper.getLabel(
        language,
        'delivery_name',
        'Delivery name'
      ),
      editable: false,
    },
    {
      field: 'externalreference',
      minWidth: 200,
      hide: true,
      headerName: Helper.getLabel(
        language,
        'external_reference',
        'External reference'
      ),
      editable: false,
    },
    {
      field: 'PO_document_number',
      minWidth: 200,
      hide: true,
      headerName: Helper.getLabel(
        language,
        'po_document_number',
        'PO document number'
      ),
      editable: false,
    },
    {
      field: 'source_description',
      minWidth: 200,
      hide: true,
      headerName: Helper.getLabel(
        language,
        'source_description',
        'Source description'
      ),
      editable: false,
    },
  ]
}

export default getInvoiceColumns