import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import Button from "../../inc/Button";
import Collapse from "../../inc/Collapse";
import NavigationHeder from "../../Navigations/NavigationHeder";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import OrderSummary from "../common/OrderSummary";
import MasterComonent from "../../Backend/MasterComonent";
import NavCartWishlist from "../common/NavCartWishlist";
import RsWithRouter from "../../inc/RsWithRouter";
import ActionTypes from "../../../inc/ActionsTypes";
import SimpleLoading from "../../Loading/SimpleLoading";
import HeaderForm from "./HeaderForm";
import EditOldCartModal from "../modals/EditOldCartModal";
import { checkIfArrayIsEmpty, getBudgetColor, getPopupMessageBasedOnStatus, isValidDocumentNumber } from "../../../inc/Validation";
import Cart from "../Cart";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import WorkFlowCommonHeader from "../../Header/WorkFlowCommonHeader";
import WorkFlowCommonHeaderAPICalls from "../../Header/WorkflowCommonHeaderAPICalls";
import WorkFlowOffCanvas from "../../Header/WorkFlowOffCanvas";
import RFQBiddingRequirement from "../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQBiddingRequirement";
import OverlayLoader from "../common/OverlayLoader";
import { Alert } from "react-bootstrap";
import FixedNavigationHeader from "../../Navigations/FixedNavigationHeder";
import TabContentWraper from "../../Widgets/Tabs/TabContentWraper";
import Gui_id_list from "../../../inc/Gui_id_list";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isHeaderLoading: false,
      listViewTabsObj: {},
      height: 0,
      scrollDitection: 'down', 
      cartItems: [],
      selectedLineIds: [],
      purchaseRequisitionId: null,
      discount: 0,
      tax: 0,
      viewComponent: "Header View",
      editMode: false,
      documentNumber: "",
      showDocNumModal: false,
      requisitionStatusList: [],
      documentStatusList: [],
      description: null,
      delivery: null,
      attentionInformation: null,
      documentDate: null,
      accountingDate: null,
      deliveryDate: null,
      requesterList: [],
      employeeList: [],
      isPosting:false,
      requester: this.props.userData?.employee_id,
      requesterName: this.props.userData?.employee_name,
      submitterList: [],
      submitter:null,
      onBehalfRequest: false,
      email: null,
      externalReference: null,
      workflow_instance_exist: null,
      workflow_eligible: null,
      workflow_name: null,
      currencyList: [],
      currency: null,
      entitylist: [],
      non_compliance_request: null,
      is_pr_qualify_for_express_request: null, 
      entity: null,
      siteList: [],
      site: null,
      warehouseList: [],
      warehouse: null,
      requisitionStatus: null,
      site_name:'',
      entity_name:'',
      warehouse_name:'',
      documentStatus: null,
      viewOnly: false,
      showCanvas: false,
      showCurrentAssignees: false,
      sources: [],
      total:{},
      settings:{},
      sme:'',
      sme_team:'',
      sme_name:'',
      sme_team_name:'',
      teams:[],
      workflow_action_history: [],
      initialDataLoaded: false,
      distributionSourceId: null,
      currentDocumentStatus:null,
      prLinesCreated: false,
      material_service_not_included: null,
      scope_of_work: null,
      requirement_description: null,
      message:null, 
      isBudgetLoading:false,
      budgetResponse:{},
      is_rfi_pr:false,
      is_ro_pr:false,
      show:true,
      requisition_status:null,
      source_id:null,
    };

    this.section_name_navigation = 'purchase-requisition-form-navigation';
    this.handleHeaderSubmission = this.handleHeaderSubmission.bind(this);
    this.handlePRSubmit = this.handlePRSubmit.bind(this);
    this.setViewComponent = this.setViewComponent.bind(this);
    this.setDocumentNumber = this.setDocumentNumber.bind(this);
    this.handleCloseDocNumModal = this.handleCloseDocNumModal.bind(this);
    this.setObjectClear = this.setObjectClear.bind(this);
    this.getBackUrl = this.getBackUrl.bind(this);
    this.handleStatusChangeOfDocument = this.handleStatusChangeOfDocument.bind(this)
    this.onCreateFirstDistribution = this.onCreateFirstDistribution.bind(this);
    this.checkFundHandler = this.checkFundHandler.bind(this)
    this.wrapperDivRef = React.createRef();
    // this.checkPolicyHandler = this.checkPolicyHandler.bind(this)
  }

  resizeWindowHandle = () => {
    Helper.resizeWindowHandle(this.wrapperDivRef, (newHeight) => {
      if (this.state.height !== newHeight) {
        this.setState({ height: newHeight });
      }
    }, window);
  };  
  
  componentDidMount() {
    if (this.getSearchParam("pr_view_id")) this.setState({ viewOnly: true }); 
    this.resizeWindowHandle();
    this.getAllEmployees();
    this.getSites();
    this.getCurrencies();
    this.getEntities();
    this.getWarehouses();
    // this.getCartCount();
    this.getWishlistCount();
    this.getRequisitionStatusList();
    this.getDocumentStatusList();
    this.getPRData();
    this.getAllSources(); 
    this.checkBudgetProgress(null,false)
    // } else {
    // redirect to shop if pr id is missing
    // setTimeout(() => {
    //   this.props.rs_router.navigate("/purchase-requisition/shop");
    // }, 1000);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // get cart items after sources is fetched
    // if (
    //   (JSON.stringify(prevState.sources) !== JSON.stringify(this.state.sources) || (prevState?.currentDocumentStatus !== this.state.currentDocumentStatus)) 
    // ) {
    //   this.getCartItems();
    // } 
    if (
      prevState.isLoading !== this.state.isLoading ||
      this.wrapperDivRef?.current?.clientHeight !== prevState.wrapperDivRef
    ) { 
      this.resizeWindowHandle();
    }
    // set requester submitter if not set on page load
    if ((JSON.stringify(prevProps.userData) !== JSON.stringify(this.props.userData) || JSON.stringify(prevState.employeeList) !== JSON.stringify(this.state.employeeList)) && this.state.employeeList?.length > 0) {
      if (Object.keys(this.state.requesterList).length === 0 && Object.keys(this.state.submitterList).length === 0) {
        const customEmployeeList = this.state.employeeList?.filter((employee) => employee.employee_id === this.props.userData?.employee_id)
        this.setState({
          requesterList: customEmployeeList,
          submitterList: customEmployeeList,
        });
      }
      if (!this.state.requester) {
        this.setState({
          requester: this.props.userData?.employee_id,
        });
      }
      // if (!this.state.submitter) {
      //   this.setState({
      //     submitter: this.props.userData?.employee_id,
      //   });
      // }
      if(!this.state.tenant_id){
        this.setState({
          tenant_id: this.props.userData?.tenant_id
        })
      }  
    }
    if(prevState.source_id !== this.state.source_id){
      this.checkBudgetProgress(null,false)
    }
  }

  getSearchParam(name) {
    const params = new URLSearchParams(this.props.rs_router.location.search);
    return params.get(name);
  }

  async getCartItems(source_id) {
    let api = Api;
    api.setUserToken();
    // this.setState({ isLoading: true });
    if(!source_id){
      return
    }

    const cartItemsRes = await api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + "/lines/" +  this.getSearchParam("pr_view_id") + "/" + source_id);

    if (cartItemsRes.data?.status === "success") {
      const cartItems = cartItemsRes.data?.data;
      const newCartItems = [];
      for (const item of cartItems) {
        newCartItems.push(item);
      }

      if (this.props?.checkoutLineIds) {
        const checkoutIds = [];
        newCartItems?.forEach((item) => {
          checkoutIds.push(item.line_id);
        });
        this.props.updateCheckoutIds(checkoutIds);
      }

      this.setState({
        // isLoading: false,
        cartItems: [...newCartItems],
      });
    }
  }

  getAllEmployees() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiOrgUrl + "/employee_dropdown?status=true")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, employeeList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  async getWorkFlowCalls(source_id) {
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();
   
    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(source_id, this.getSearchParam("pr_view_id"));

    if(workflowActionHistory) this.setState({ workflow_action_history: workflowActionHistory });
 
    if(!this.props?.userData?.tenant_id) return;

    const trigger = await workflowCalls.getWorkFlowTriggers();
    const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name == "Update")?.id

    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(this.props?.userData?.tenant_id, source_id, this.getSearchParam("pr_view_id"));
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(source_id, this.props?.userData?.tenant_id, mode);
 
    if(workflowExist) {
      this.setState({
        workflow_instance_exist: workflowExist,
        workflow_name: workflowExist?.workflow_name
      });
    }
    if(eligibleWorkflow) this.setState({ workflow_eligible: eligibleWorkflow });
  }

  reloadFunctionWorkflow(){
    this.setState({
      isLoading:true
    })
    setTimeout(()=>{
      this.setState({
        isLoading:false,
      })
    },300) 
    this.getExistingHeaderData()
  }

  getAllSources() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/source")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, sources: res.data?.data });
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getSites() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_site")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, siteList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getCurrencies() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/currency")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, currencyList: res.data?.data })
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getEntities() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_entities")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, entitylist: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getWarehouses() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_warehouse")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, warehouseList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getRequisitionStatusList() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/enum/pr_requisition_status")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isLoading: false,
            requisitionStatusList: res.data?.data,
          });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getDocumentStatusList() {
    let api = Api;
    this.setState({ isLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/enum/POHeaderDocumentStatus")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isLoading: false,
            documentStatusList: res.data?.data,
          });
        }
      })
      .catch((error) => {
        that.setState({ isLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  setStateHandlingCanvas(value){
    this.setState({ showCanvas: value });
  }

  setStateHandlingCurrentAssignee(value){
    this.setState({ showCurrentAssignees: value });
  }

  // getCartCount() {
  //   let api = Api;
  //   api.setUserToken();
  //   let that = this;
  //   api
  //     .axios()
  //     .get(Settings.apiPurchaseRequisitionUrl + "/pr_cartlines")
  //     .then(function (res) {
  //       that.props.changeCartCount(res.data.data?.length);
  //     });
  // }

  getWishlistCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        that.props.changeWishlistCount(res.data.data?.length);
        that.props.updateWishlistIds([...res.data.data]);
      });
  }


  

  async getPRData() {
    if (this.getSearchParam("pr_view_id")) {
      await this.getExistingHeaderData();
    } else {
      const prId = this.getSearchParam("pr_view_id")
      if(!prId){
        setTimeout(() => {
          this.props.rs_router.navigate("/purchase-requisition");
        }, 600);
        return
      }
      await this.getExistingHeaderData(prId);
    }
  }

  async getOrderTotal(integratorId,source_id) {
    let api = Api
    api.setUserToken()
    if(!integratorId || !source_id){
      return
    }
    try {
      let total = await api.axios().get(Settings.apiPurchaseOrderUrl + `/total/${source_id}/${integratorId}`)
      this.setState({
        total: total.data.data,
      })
    } catch (error) {}
  }

  async getProcurementSetting(entity_id){
    let api = Api
    api.setUserToken()
    try{
      let settingsData = await api.axios().get(Settings.apiProcurementSettingsUrl + `/procurementsetting_by_entity/${entity_id}`)
        if(settingsData && settingsData?.data?.data?.is_sme_done_by_team_in_pr){
          let teamsData =  await api.axios().get(Settings.apiUrl + `/teamheader`)
          this.setState({
            teams:teamsData.data.data,
          })
        }
        this.setState({
            settings:settingsData.data.data
        })
    }catch(error){
      this.setState({
        settings: {
          is_sme_done_by_team_in_pr: false
        }
      });
    }
  }
  
  async getExistingHeaderData(prId) {
    let api = Api;
    api.setUserToken();

    this.setState({ isHeaderLoading: true });

    const existingPRId =this.getSearchParam("pr_view_id") 

    // fetch existing header data of PR id exists
    let prData 
    if (existingPRId) {
      this.setState({ purchaseRequisitionId: existingPRId })
      try {
        const headerDataRes = await api.axios().get(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + existingPRId)
        if (headerDataRes) {
          const data = headerDataRes.data?.data;
          prData = headerDataRes?.data?.data
          await this.getOrderTotal(existingPRId,data?.source_id)
          try {
            await this.getProcurementSetting(data?.entity_id);
          } catch (error) {
          }
          // this.getProcurementSetting(data?.entity_id)
          this.getCartItems(data?.source_id)
          this.setState({
            description: data.description,
            documentNumber:data.document_number,
            delivery: data.delivery_name,
            attentionInformation: data.attention_information,
            documentDate: DatepickerFunctions.convertDateFromDataBase(data?.document_date),
            accountingDate: DatepickerFunctions.convertDateFromDataBase(data?.accounting_date),
            deliveryDate: DatepickerFunctions.convertDateFromDataBase(data?.delivery_date),
            requester: data.requester_id,
            requesterName: data.requester_name,
            submitter: data.orderer_name || "-",
            onBehalfRequest: data.onbehalfrequest,
            email: data.email_address,
            is_pr_qualify_for_express_request: data.is_pr_qualify_for_express_request,
            non_compliance_request: data.non_compliance_request,
            externalReference: data.external_reference,
            currency: data.currency_id,
            entity: data.entity_id,
            entity_name:data.entity_name,
            site: data.site_id,
            site_name:data.site_name,
            sme:data.sme,
            sme_team:data.sme_team,
            sme_name:data.sme_name,
            sme_team_name: data.sme_team_name,
            warehouse_name:data.warehouse_name,
            warehouse: data.warehouse_id,
            isHeaderLoading: false,
            currentDocumentStatus:data.document_status,
            distributionSourceId: data.distributionsource_id,
            material_service_not_included: data.material_service_not_included,
            scope_of_work: data.scope_of_work,
            requirement_description: data.requirement_description,
            is_rfi_pr: data.is_rfi_pr,
            is_ro_pr:data.is_ro_pr,
            requisition_status:data.requisition_status,
            source_id: data.source_id
          });
        }
      } catch (error) {
        this.setState({ isHeaderLoading: false });
        getPopupMessageBasedOnStatus(error)
      }
    }
    this.getWorkFlowCalls(prData.source_id);
  }

  async handleHeaderSubmission() {
    const { delivery, attentionInformation, documentDate, accountingDate, deliveryDate } = this.state

    let api = Api;
    api.setUserToken();
    let prHeaderData = {
      description: this.state.description,
      accounting_date: DatepickerFunctions.convertDateForDataBase(accountingDate),
      document_date: DatepickerFunctions.convertDateForDataBase(documentDate),
      delivery_date:DatepickerFunctions.convertDateForDataBase(deliveryDate),
      deliveryaddress_id: null,
      delivery_name: delivery,
      attention_information: attentionInformation,
      priceinclude_tax: null,
      requester_id: this.state.requester,
      currency_id: parseInt(this.state.currency),
      email_address: this.state.email,
      entity_id: parseInt(this.state.entity),
      site_id: parseInt(this.state.site),
      warehouse_id: parseInt(this.state.warehouse),
      distributionsource_id: null,
      externalreference: "",
      attachmentsource_id: null,
      notesource_id: null,
      contactsource_id: null,
      agreement_id: null,
      onbehalf_request: this.state.onBehalfRequest,
    };
    this.setState({ isCreatingHeader: true });
    try {
      const createHeaderResponse = await ( api.axios().put(Settings.apiPurchaseOrderUrl + "/pr_header/" + this.state.purchaseRequisitionId, prHeaderData))

      if (createHeaderResponse) {
        this.setState({ isCreatingHeader: false, editMode: false });
      }
    } catch (error) {
      this.setState({ isCreatingHeader: false });
      getPopupMessageBasedOnStatus(error)
    }
  }

  addComponentRight() { 
    return (
      <div className='parent-sidebar-cn'>
        <MasterSidebar disableSticky={true}>
          <div className="order_summary_wrapper_purchase_requisition"> 
            <OrderSummary
              title={Helper.getLabel(this.props.language, "requisition_summary", "Requisition summary")}
              itemCount={
                this.state.viewOnly
                  ? this.state.cartItems.length
                  : this.props.checkoutLineIds.length
              }
              subtotal={this.state?.total?.subtotal}
              discount={this.state?.total?.line_discount}
              tax={this.state?.total?.tax}
              total={this.state?.total?.total}
              hideCheckoutButton={true}
            />
          </div>
          
          <NoteAttachmentHistory
            openCanvas={this.setStateHandlingCanvas.bind(this)}
            openCurrentAssignees={this.setStateHandlingCurrentAssignee.bind(this)}
            source_id={this.state.source_id}
            height={!this.state.show ? (this.state.height - 300) : (this.state.height - 315)}
            hideHistory={!this.state.workflow_action_history?.history?.length}
            historyArray={this.state.workflow_action_history}
            notesource_integrator={this.state.purchaseRequisitionId}
            attachmentsource_integrator={this.state.purchaseRequisitionId}
            hideVendorPortal={true}
            condition={this.state.currentDocumentStatus===2}
          />
        </MasterSidebar>

        {this.state.showCanvas && 
          <WorkFlowOffCanvas
            isOpen={this.state.showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={this.setStateHandlingCanvas.bind(this)}
            source_id={this.state.source_id}
            source_item_id={this.state.purchaseRequisitionId}
          />
        }

        {this.state.showCurrentAssignees &&
          <WorkFlowOffCanvas
            isOpen={this.state.showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={this.setStateHandlingCurrentAssignee.bind(this)}
            source_id={this.state.source_id}
            source_item_id={this.state.purchaseRequisitionId}
          />
        }
      </div>
    );
  }

  async handlePRSubmit() {
    let api = Api;
    api.setUserToken();
    
    this.setState({ isPosting: true });
    try {
      // create document number
      const docNumBody = {
        purchase_requisition_id: this.state.purchaseRequisitionId,
        document_number: this.state.documentNumber ?? null,
      };
      let docNumberRes = null;
      try {
        docNumberRes = await api.axios().post(Settings.apiPurchaseRequisitionUrl + "/generate_document_number", docNumBody)
        if (docNumberRes)
          this.props.rs_router.navigate("/purchase-requisition/success",{state:{prId:this.state.purchaseRequisitionId, isCurrentUser: this.props.rs_router?.location?.state?.isCurrentUser}});
      } catch (error) {
        if (error.response.status === 409 || error.response.status === 500) {
          this.handleShowDocNumModal();
          getPopupMessageBasedOnStatus(error)
        } else {
          this.setState({ isPosting: false });
          getPopupMessageBasedOnStatus(error)
        }
      }
    } catch (error) {
      this.setState({ isPosting: false });
      getPopupMessageBasedOnStatus(error)
    }
  }

  // deleteallcartitems
  deleteAllCartItems() {
    let api = Api;
    let that = this;
    api.setUserToken();

    this.state.selectedCartItems.forEach((selectedLineId) => {
      api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl + "/line/" + selectedLineId + "/" + this.state.source_id)
        .then(function (res) {
          if (res.status === 202) {
            that.setState((prevState) => {
              const newCartItems = prevState.cartItems.filter((cartItem) => cartItem.line_id !== selectedLineId);
              return { cartItems: newCartItems };
            });
            that.decrementCartCount();
            Helper.alert("Cart item removed", "success");
          }
        })
        .catch(function (err) {
          Helper.alert("Failed to remove cart item", "error");
        });
    });
  }

  async onCreateFirstDistribution(source_id) {
    let api = Api;
    api.setUserToken();
    try {
      const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + this.state.purchaseRequisitionId, { distributionsource_id: source_id })
      if (res) {
        this.setState({ distributionSourceId: source_id });
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
    }
  }


  setViewComponent(data) {
    this.setState({ viewComponent: data });
  }

  setDocumentNumber(e) {
    if (isValidDocumentNumber(e.target.value)) 
      this.setState({ documentNumber: e.target.value });
  }

  handleShowDocNumModal() {
    this.setState({ showDocNumModal: true });
  }

  handleCloseDocNumModal() {
    this.setState({ showDocNumModal: false });
  }

  handleClose() {
    this.setState({ show: false });
  }

  setObjectClear(obj) {
    this.setState({ listViewTabsObj: obj });
  }

  checkErrorMessage(msg) {
    const lowercaseStr = msg.toLowerCase();
    const result = lowercaseStr.includes("already exists");
    return result ? "Document number entered already exists" : msg;
  }

  getBackUrl() {
    const origin = this.getSearchParam("return");
    if (origin === "rfq") {
      return "/request-for-quotation/allrequisitions";
    } else {
      return this.state.viewOnly 
        ? "/purchase-requisition"
        : "/purchase-requisition/cart";
    }
  }

  getSourceId(name) {
    for (let i = 0; i < this.state.sources?.length; i++) {
      if (this.state.sources[i]?.source_name === name) {
        return this.state.sources[i]?.source_id;
      }
    }
    return null;
  }

  checkFundHandler(){
    let api = Api;
    api.setUserToken();
    if(!this.state.source_id){
      return
    }
    this.setState({
      isBudgetLoading:true
    })
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/budget_check/${this.state.source_id}/${this.getSearchParam('pr_view_id')}`)
      .then((res) => {
        if (res.data.status === "success") {
          Helper.alert(res.data.message,'success')
  
          let attemptCount = 0; 
          const maxAttempts = 7; 

          const intervalId = setInterval(() => {
            this.checkBudgetProgress(intervalId,true, attemptCount, maxAttempts);
            attemptCount++;  

            if (attemptCount >= maxAttempts) {
              clearInterval(intervalId);
              this.setState({ isBudgetLoading: false });
            }
          }, 7000);
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
        this.setState({ isBudgetLoading: false });
        // this.checkBudgetProgress();
      });
  }

checkBudgetProgress(intervalId, isNeedAlert=true,attemptCount, maxAttempts) {
    if(!this.state.source_id || this.state.is_rfi_pr){
      return
    }
    let api = Api;
    api.setUserToken();
      api
      .axios()
      .get(Settings?.apiPurchaseOrderUrl + `/budget_progress/${this.state.source_id}/${this.getSearchParam('pr_view_id')}`)
      .then((res) => {
        const message = res.data.message?.toLowerCase();
        this.setState({
          budgetResponse:res.data
        })
        if(isNeedAlert){
          if (message === 'passed' || message === 'failed' || message === 'passed with warning' || message === 'budget not performed') {
            Helper.alert(message === 'budget not performed'?`Your budget check is not performed yet.`:`Your budget is ${res.data.message?.toLowerCase()}.${message==='failed'?'Please review the details at the line level.':''}`, message === 'passed' || message === 'passed with warning' ? 'success' : 'error');
            clearInterval(intervalId); 
            this.setState({ isBudgetLoading: false, message: message === 'budget not performed'?`Your budget check is not performed yet.`: `Your budget is ${res.data.message?.toLowerCase()}.${message==='failed'?'Please review the details at the line level.':''}` });
          } else if (message === 'not checked') {
          }
        }
      })
      .catch((error) => {
        if(attemptCount === 4 && error?.response?.data?.message?.toLowerCase() === 'not checked'){
          Helper.alert('Please check logs for more details or contact administrator.', 'error');
          this.setState({
            message:`Please check logs for more details or contact administrator.`,
            budgetResponse:error?.response?.data
          })
        }
      });
  }

  async handleStatusChangeOfDocument(event,isCustomPayload=false,customPayload={}){
    let document_status_id = this.state?.documentStatusList?.find(item => item.name == "Draft")?.id;
    let payload = isCustomPayload ? customPayload : { document_status: document_status_id }
    this.setState({ isLoading:true });
    let api = Api;
    api.setUserToken();
    try {
      const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + this.state.purchaseRequisitionId, payload)
      if (res?.status == 202) { 
        this.setState({ isLoading:false });
        if(isCustomPayload){
          let currentUser = this?.props?.rs_router?.location?.state?.isCurrentUser
          this.props.rs_router.navigate(currentUser ? `/purchase-requisition-created-by-me?user_id=${currentUser}` : '/purchase-requisition')
        }
        this.getExistingHeaderData();
      }
    } catch (error) {
      this.setState({ isLoading:false });
      getPopupMessageBasedOnStatus(error);
    }
  }

  onPurchaseRequisitionCancel(e){
    Helper.confirmMe(() => {
      this.handleStatusChangeOfDocument(e,true,{requisition_status:2})
    }, "Your Purchase Requisition will be canceled.", null, "Are you sure you want to cancel this purchase requisition?",580)
  }

  render() {
    let navs = [
      {
        title: Helper.getLabel(this.props.language, "general_information", "General Information"), 
        data_section: "one", active_status: 'active_tab' 
      },
      { title: Helper.getLabel(this.props.language, "segments", "Segments"),  data_section: "two", active_status: '' },
      { title: Helper.getLabel(this.props.language, "requirements", "Requirements"),  data_section: "three", active_status: '' },
    ];
    let ViewHeaderList = [
      { title: Helper.getLabel(this.props.language, 'header_view', 'Header View') },
      { title: Helper.getLabel(this.props.language, 'line_view', 'Line View') },
    ]

    const targetRoles = [
       "Lynkaz - Purchase Officer",
       "Lynkaz - Vendor Account Manager",
       "Lynkaz - Tenant Admin",
       "Lynkaz - Budget Officer"
    ];

    let hasTargetRole

    if(this.props.userData){
      hasTargetRole = Object.values(this?.props?.userData?.roles).some(role => targetRoles.includes(role));
    } 

    const { security } = this.props;
    const titleText = `<span style="color:#313638;">${ this.state.viewOnly ? 'Purchase Requisitions > Summary' : 'Shop > Add Requisition Details > Shopping Cart > Summary'}</span>`;
    const currentUser = this?.props?.rs_router?.location?.state?.isCurrentUser;
    const { documentNumber } = this.state;
    const documentNumberText = documentNumber ? `<span style="color:#FC5E24;">#${documentNumber}</span>` : "";
    const disableAllActionBtns = currentUser ? false : !security.canEdit(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_action_button); 
    return (
      <div style={{position:'relative'}} className="pr-summary-screen">
        <MasterComonent>
          <div className='pr-wrapper scrollBarBlinkControl'>
            <div ref={this.wrapperDivRef}> 
              <NavigationHeder
                backUrl={this?.props?.rs_router?.location?.state?.isPrevPath===true || !hasTargetRole?-1:this.getSearchParam("pr_view_id") && this.state.currentDocumentStatus===0?-1:(this?.props?.rs_router?.location?.state?.prevRoutePath === '/dashboard' ? "/dashboard" : "/purchase-requisition")}
                title={`${titleText} ${documentNumberText}`}
                hideMoreBtn={true}
              >
                {this.props.hideMoreBtn ? (
                  <NavCartWishlist isEditBtnVisible={this.state.currentDocumentStatus===0} prId={this.getSearchParam("pr_view_id")} />
                ) : ( 
                <>   
                  {
                   (this.state.currentDocumentStatus !== 2 && this.state.requisition_status !==2) && <Button isDisable={disableAllActionBtns} title={Helper.getLabel(this.props.language, 'cancel', 'Cancel')} className='red-btn-style' isActive={false} onClick={this.onPurchaseRequisitionCancel.bind(this)}/>
                  }         
                  {this.state.currentDocumentStatus === 0 && this.state.requisition_status !==2 && (
                  <>
                    {/* <Button className="black-btn-style ms-1" title={Helper.getLabel(this.props.language, 'check_policy', 'Check policy')} onClick={this.checkPolicyHandler} /> */}
                    {this.state.is_rfi_pr ? <></> :<Button isDisable={disableAllActionBtns} className="orange-btn-style ms-1" title={Helper.getLabel(this.props.language, 'check_fund', 'Check Fund')} onClick={this.checkFundHandler} />}
                    <Button isDisable={disableAllActionBtns} className="brown-btn-style ms-1" title={Helper.getLabel(this.props.language, 'continue_shopping', 'Continue Shopping')} onClick={()=>{this.props.rs_router.navigate(`/purchase-requisition/shop?pr_view_id=${this.getSearchParam('pr_view_id')}`)}} />
                    <Button 
                      isActive={false} 
                      title={Helper.getLabel(this.props.language, "back", "Back")} 
                      onClick={() => this.props.rs_router.navigate(-1)}
                      isDisable={this.state.isLoading} 
                      className="blue-btn-style" /> 
                    {this.props.rs_router.location.state?.isEditBtn==='true' && <Button isDisable={disableAllActionBtns} title={Helper.getLabel(this.props.language, "edit", "Edit")} className="blue-btn-style" isActive={false} onClick={()=>{this.props.rs_router.navigate(`/purchase-requisition/addrequisitiondetails?pr_view_id=${this.getSearchParam('pr_view_id')}`,{state:{isBackSummary:true}})}} />}
                    <Button
                      isActive={true}
                      title={Helper.getLabel(this.props.language, "submit", "Submit")}
                      onClick={() => this.handlePRSubmit()}
                      isDisable={
                        this.state.isLoading || 
                        this.props.checkoutLineIds?.length === 0 || disableAllActionBtns
                      }
                    />
                  </>
                )}
                </>
                )}
                { this.state?.workflow_instance_exist?.instance_details?.last_instance_status?.[4] === 'Rejected' && this.state.currentDocumentStatus === 3 && (  
                  <Button
                    onClick={this.handleStatusChangeOfDocument}
                    className="blue-btn-style"
                    isDisable={this.state.isLoading || disableAllActionBtns}
                    title={Helper.getLabel(this.props.language, 'edit', 'Edit')}
                  />
                )}
              </NavigationHeder>

              { this.state.viewOnly && this.state?.workflow_instance_exist?.instance_exist ? 
                !this.state.isLoading &&
                <WorkFlowCommonHeader
                  removeHeaderMargins={true}
                  submitWorkflowAction={
                    !this.state?.workflow_instance_exist?.instance_exist &&
                    this.state.workflow_eligible && 
                    this.submitWorkflow.bind(this)
                  }
                  workflowInstanceDetails={this.state?.workflow_instance_exist?.instance_details}
                  instance_task_id={this.getSearchParam("instance_id")}
                  module_id={!this.getSearchParam("instance_id") ? this.state.source_id : null}
                  source_id={this.state.source_id}
                  source_item_id={this.getSearchParam("pr_view_id")}
                  SubmitWorkflowObjDetails={this.state.workflow_name}
                  module_item_id={!this.getSearchParam("instance_id") ? this.getSearchParam("pr_view_id") : null}
                  workflowInstanceExist={this.state?.workflow_instance_exist?.instance_exist}
                  workflowEligible={this.state.workflow_eligible}
                  workflowName={this.state.is_rfi_pr ? "RFI Purchase Requisition Workflow" : "Purchase Requisition Workflow"}
                  reloadFunction={this.reloadFunctionWorkflow.bind(this)}
                /> : ''
              }
               {this.state.is_rfi_pr && this.state.show && <Alert variant='dark' className='bg-transparent mb-2 mt-2' style={{borderColor:'#3CBA54', color:'#3CBA54'}} onClose={()=>{this.handleClose()}} dismissible>
                   <p className='mb-0'>This Purchase Requisition is for informational (RFI) purposes only. It will not be converted into an RFQ, PO, or Contract, nor will it reserve any budget.</p>
               </Alert>}
              <FixedNavigationHeader  
                sectionName={this.section_name_navigation}  
                navs={navs} 
                scrollDitection={this.state.scrollDitection} 
                selectedSupNav={this.state.viewComponent} 
                setSelectedSupNav={(newComponent) => this.setState({ viewComponent: newComponent })} 
                superNav={ViewHeaderList} 
              />
            </div>
  
            {(this.state?.message !== null) && <Alert variant='dark' className="bg-transparent" style={{borderColor:getBudgetColor(this.state.budgetResponse?.message),color:getBudgetColor(this.state.budgetResponse?.message)}}>{this.state.message}</Alert>}
              {this.state.isLoading ? (
                <SimpleLoading />
              ) : (
                  <TabContentWraper
                    height={this.state.height}
                    removeMargin={true}
                    setScrollDitection={(newComponent) => this.setState({ scrollDitection: newComponent })}  
                    leftSideContent={
                      <> 
                        {this.state.viewComponent === 'Header View' && (
                          this.state.isHeaderLoading || 
                          this.state.isCreatingHeader ? <SimpleLoading /> : ( 
                            <>
                              <div className="lists_contant one" id={`${this.section_name_navigation}General Information`} style={{ position: "relative" }}> 
                                <Collapse title='General Information' className='mb-5' open={true}>
                                  <HeaderForm
                                    viewOnly={true}
                                    editMode={false}
                                    description={this.state.description}
                                    delivery={this.state.delivery}
                                    attentionInformation={
                                      this.state.attentionInformation
                                    }
                                    is_pr_qualify_for_express_request={this.state.is_pr_qualify_for_express_request}
                                    purchase_requisition_non_compliance={this.state.non_compliance_request}
                                    documentNumber={this.state.documentNumber}
                                    documentDate={this.state.documentDate}
                                    accountingDate={this.state.accountingDate}
                                    deliveryDate={this.state.deliveryDate}
                                    requester={this.state.requester}
                                    requesterName={this.state.requesterName}
                                    requesterList={this.state.requesterList}
                                    submitter={this.state.submitter}
                                    submitterList={this.state.submitterList}
                                    employeeList={this.state.employeeList}
                                    onBehalfRequest={this.state.onBehalfRequest}
                                    email={this.state.email}
                                    externalReference={this.state.externalReference}
                                    is_rfi_pr={this.state.is_rfi_pr}
                                    is_ro_pr={this.state.is_ro_pr}
                                    currency={this.state.currency}
                                    currencyList={this.state.currencyList}
                                    entity={this.state.entity}
                                    entitylist={this.state.entitylist}
                                    site={this.state.site}
                                    site_name={this.state.site_name || "-"}
                                    entity_name={this.state.entity_name || "-"}
                                    warehouse_name={this.state.warehouse_name || "-"}
                                    siteList={this.state.siteList}
                                    warehouse={this.state.warehouse}
                                    warehouseList={this.state.warehouseList}
                                    documentStatusList={this.state.documentStatusList}
                                    currentDocumentStatus={this.state.currentDocumentStatus}
                                    setEditMode={(val) => 
                                      this.setState({ editMode: val })
                                    }
                                    setDescription={(val) => 
                                      this.setState({ description: val })
                                    }
                                    setDelivery={(val) => 
                                      this.setState({ delivery: val })
                                    }
                                    setAttentionInfo={(val) => 
                                      this.setState({ attentionInformation: val })
                                    }
                                    setDocumentDate={(val) => {
                                      this.setState({
                                        documentDate: val,
                                      })
                                    }}
                                    setAccountingDate={(val) => {
                                      this.setState({
                                        accountingDate: val,
                                      })
                                    }}
                                    setDeliveryDate={(val) => {
                                      this.setState({
                                        deliveryDate: val,
                                      })
                                    }}
                                    setRequester={(val) => 
                                      this.setState({ requester: val })
                                    }
                                    setOnBehalfRequest={() => {
                                      this.setState(
                                        {
                                          onBehalfRequest: !this.state.onBehalfRequest,
                                        },
                                        () => {
                                          if (this.state.onBehalfRequest)
                                            this.setState({
                                              requester: null,
                                            })
                                          else
                                            this.setState({
                                              requester:
                                              this.state.userData?.employee_id,
                                            })
                                        }
                                      )
                                    }}
                                    setSubmitter={(val) => 
                                      this.setState({ submitter: val })
                                    }
                                    setEmail={(val) => this.setState({ email: val })}
                                    setExternalRef={(val) => 
                                      this.setState({ externalReference: val })
                                    }
                                    setCurrency={(val) => 
                                      this.setState({ currency: val })
                                    }
                                    setEntity={(val) => this.setState({ entity: val })}
                                    setSite={(val) => this.setState({ site: val })}
                                    setWarehouse={(val) =>
                                      this.setState({ warehouse: val })
                                    }
                                    handleHeaderSubmission={this.handleHeaderSubmission}
                                    isSMEExist={this.state?.settings?.is_sme_done_by_team_in_pr}
                                    teams={this.state.teams}
                                    sme={this.state.settings?.is_sme_done_by_team_in_pr?this.state?.sme_team:this?.state?.sme}
                                    sme_name={this.state.settings?.is_sme_done_by_team_in_pr?this.state?.sme_team_name:this.state.sme_name}
                                    setSMEExpert={(val) => this.state.settings?.is_sme_done_by_team_in_pr ? this.setState({ sme_team: val }):this.setState({ sme: val })}
                                  />
                                </Collapse>
                              </div>
                              <div className="lists_contant two" id={`${this.section_name_navigation}Segments`} style={{ position: "relative" }}>
                                <Collapse title='Segments' className='mb-5' open={true}>
                                  <SegmentsDistribution
                                    source_id={this.getSourceId('Purchase Requisition')}
                                    totalAmount={this?.state?.total?.total}
                                    integrator={this.state.purchaseRequisitionId}
                                    distribution_source_id={this.state.distributionSourceId}
                                    onCreateFirstDistribution={this.onCreateFirstDistribution}
                                    disableFinancial={true}
                                    isEditable={false}
                                  />
                                </Collapse>
                              </div> 

                              <div className="lists_contant three" id={`${this.section_name_navigation}Requirements`} style={{ position: "relative" }}>
                                <Collapse title='Requirements' className='mb-5' open={true}>
                                  <div className='rfq-internal-requirements bidding_requirement'>
                                    <RFQBiddingRequirement
                                      type='rfq'
                                      scopeOfWork={this.state?.scope_of_work}
                                      materialsAndServicesNotIncluded={this.state?.material_service_not_included}
                                      agreementTerms={this.state?.requirement_description}
                                      disable={true}
                                    />
                                  </div>
                                </Collapse>
                              </div>
                            </>  
                          )
                        )} 
                        
                        <div style={{ position: "relative" }}> 
                          {this.state.viewComponent === 'Line View' && (
                            <Cart hideNoteAttachmentComp={true} showNext={false} viewOnly={true} editMode={true} viewLineDistribution={true}  cartItems={[]} prId={this.getSearchParam("pr_view_id")} currentDocumentStatus={this.state.currentDocumentStatus} type="summary" is_rfi_pr={this.state.is_rfi_pr} source_id={this.state.source_id} />
                          )}
                        </div>
                      </>
                    }
                    rightSideContent={ this.addComponentRight.bind(this) }
                  /> 
              )} 
            {/* <AddDocNumberModal
              show={this.state.showDocNumModal}
              hide={this.handleCloseDocNumModal}
              documentNumber={this.state.documentNumber}
              setDocumentNumber={this.setDocumentNumber}
              handlePRSubmit={this.handlePRSubmit}
              setIsLoading={(val) => this.setState({ isLoading: val })}
            /> */}
            {this.props.showEditOldCartModal ? <EditOldCartModal viewOnly={true} type="summary" currentDocumentStatus={this.state.currentDocumentStatus} prId={this.getSearchParam("pr_view_id")} is_rfi_pr={this.state.is_rfi_pr} source_id={this.state.source_id}/> : null}
          </div>
        </MasterComonent>
        {this.state.isPosting || this.state.isBudgetLoading ? <OverlayLoader isLogoCenter={true} /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.language,
    userData: state.auth.user,
    checkoutLineIds: state.prShop.checkoutLineIds,
    totalPRAmount: state.prShop.totalPRAmount,
    showEditNewCartModal: state.prShop.showEditNewCartModal,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_CART_COUNT, payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_WISHLIST_COUNT, payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_WISHLIST_IDS, payload }),
    updateCheckoutIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_CHECKOUT_LINE_IDS, payload }),
    updateTotalPrAmount: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_TOTAL_PR_AMOUNT, payload }),
    setPRCart:(payload) => 
      dispatch({type:ActionTypes.SET_PR_ID,payload})
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWithRouter(Summary));