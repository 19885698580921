import React, { Fragment, useCallback } from 'react'
import { connect } from 'react-redux'; 
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings'; 
import Alert from '../../inc/Alert'; 
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation';
import AgGridNew from '../../grid/ag/ag-grid-new';

let gridApi = null;
let AddBtn;
let transaction;

const DeliveryArchive = (props) => { 

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.delivery_term_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }

  const addNewData = async (event) => {
    if((event?.data?.description?.trim()?.length) > 255){
      Helper.alert('Description field character limit exceeded', 'error');
      return;
    };

    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseOrderUrl+'/delivery_term', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridApi.applyServerSideTransaction(transaction);
          }
        }, 1000); 
        Helper.alert('Delivery term added successfully');
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }

  const updateData = async (event) => {
    if((event?.data?.description?.trim()?.length) > 255){
      Helper.alert('Description field character limit exceeded', 'error');
      return;
    };

    try {
      let payload = {
        name: event?.data?.name,
        description: event?.data?.description
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiPurchaseOrderUrl+`/delivery_term/${event?.data?.delivery_term_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi.refreshServerSide({ purge: true });
          gridApi?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };

  const DeleteDelivery = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_delivery_id = element?.data?.delivery_term_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl+`/delivery_term/${delete_delivery_id}`)
      const rowNode = gridApi?.getRowNode(delete_delivery_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi.refreshServerSide({ purge: true })
      gridApi?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
   
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.delivery_term.delivery_term)){
    return <Fragment> 
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  /* Grid Column Headers */

  const gridColHeader = [
    {field:'name', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(props.language,'name','Name')}, 
    {field:'description', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(props.language,'description','Description')}
  ];

  return ( 
    <div className='delivery_term_archive'>
      <div className='container-fluid'>
        <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language,'delivery_term','Delivery Terms')} />
 
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl+'/delivery_term'}
          pagination={false}
          columnDefs={gridColHeader}
          onRowValueChanged={onRowValueChanged}
          hideDeleteBtn={!security.canDelete(frontendIds.procure.purchase_orders.control_forms.delivery_term.delivery_term_delete_button)}
          hideAddBtn={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.delivery_term.delivery_term_create_button)}
          handleDeleteSelectedRows={DeleteDelivery}
          height={500}
          onGridReady={(params) => gridApi = params?.api}
          handleAddButton={handleBtn}
          uniqueField={`delivery_term_id`}
          gridId={'rs_delivery_term_list'}
        />
      </div>
    </div> 
  )
}
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.delivery_term.delivery_term
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(DeliveryArchive, SecurityOptions))))