import React, { useState,  useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import Button from '../../inc/Button'
import Helper from '../../../inc/Helper'
import InvoiceLineGrid from './InvoiceLineGrid'
import StepperSection from '../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/StepperSection/StepperSection'
import SimpleLoading from '../../Loading/SimpleLoading'
import { connect } from 'react-redux'
import WorkConfirmationApiCalls from '../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import { getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation'
import PriceDiscountTab from '../Purchase Order Screen/PurchaseOrderDetails/PriceDiscountTab'
import Collapse from '../../inc/Collapse'
import Documents from '../../Registrations/Vendor/Documents'
import $ from 'jquery'
import InvoiceListPopup from './InvoiceListPopup'
import InvoiceProductsDetails from './InvoiceProductsDetails'
import InvoiceSummary from './InvoiceSummary'
import InvoiceGeneralInformation from './InvoiceGeneralInformation'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let cancelTokenSource = axios.CancelToken.source();
const AddComponentRight = ({invoiceHeader,invoice_id}) => {
  return (
    <MasterSidebar>
      <div className="work-confirmation-sidebar">
        <NoteAttachmentHistory
          notesource_integrator={invoice_id}
          attachmentsource_integrator={invoice_id}
          hideHistory={true}
          source_id={invoiceHeader?.source_id || Settings?.source_id?.invoice}
          condition={invoiceHeader?.document_status===2}
        />
      </div>
    </MasterSidebar>
  )
}

const NewInvoice = (props) => {
  const currentDate = new Date()
  const navigate = useNavigate()
  const location = useLocation()
  const invoiceApiCalls = new WorkConfirmationApiCalls()
  let newInvoiceCall = false

  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [invoiceDescription, setInvoiceDescription] = useState('')
  const [invoiceDate, setInvoiceDate] = useState(
    currentDate,
  )
  const [documentDate,setDocumentDate]=useState(currentDate)
  const [currentStep, setCurrentStep] = useState(0)
  const [invoiceHeader, setInvoiceHeader] = useState(null)
  const [selectedDocument, setSelectedDocument] = useState({})
  const [linesData, setLinesData] = useState([])
  const [lineIds, setLineIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [sourceId, setSourceId] = useState(null)
  const [savedSteps, setSavedSteps] = useState([false, false, false])
  const [priceDiscountData,setPriceDiscountData]=useState({})
  const [deliveryData,setDeliveryData]=useState({})

  const [matchPopUp,setMatchPopUp]=useState(false)
  const [saveBtnApi, setSaveBtnApi] = useState(false);
  const [invoiceListPopupOpen,setInvoiceListPopup]=useState(false)
  const [invoicesList,setInvoicesList]=useState([])
  const [selectedValues, setSelectedValues] = useState([]);
  const [deleteBtnApi,setDeleteBtnApi]=useState(false)
  const [submitBtnApi,setSubmitBtnApi]=useState(false)

  //for fetch params from route
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }
  const {id} = useParams()

  const receiving_id = getSearchParam('receiving_id')
  const purchase_order_id = getSearchParam('purchase_order_id')

  let lastStep = 3 
 
  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep < lastStep ? prevStep + 1 : prevStep))
  }

  const prevStep = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep))
  }

  const handleSave = () => {
    setSavedSteps((prevSavedSteps) => {
      const updatedSavedSteps = [...prevSavedSteps]
      updatedSavedSteps[currentStep] = true
      localStorage.setItem(
        'savedInvoiceSteps',
        JSON.stringify(updatedSavedSteps),
      )
      return updatedSavedSteps
    })
  }
 
  const data = {
    source_id: selectedDocument?.source_id,
    invoice_date: DatepickerFunctions.convertDateForDataBase(invoiceDate),
    accounting_date:DatepickerFunctions.convertDateForDataBase(currentDate),
    document_date:DatepickerFunctions.convertDateForDataBase(documentDate),
    reference_number: invoiceNumber,
    description: invoiceDescription,
    invoice_type:0,
    document_id:selectedDocument?.purchaseorder_id || selectedDocument?.purchase_order_id,
    receiving_ids:receiving_id?[selectedDocument?.shipment_id]:selectedValues
  }

  const updateInvoiceHeaderData = {
    reference_number: invoiceNumber,
    contactsource_id: null,
    attachmentsource_id: null,
    notesource_id: null,
    addresssource_id: null,
    description: invoiceDescription,
    invoice_date:DatepickerFunctions.convertDateForDataBase(invoiceDate),
    delivery_term_id:parseInt(deliveryData?.delivery_term_id) || '',
    mode_of_delivery_id:parseInt(deliveryData?.mode_of_delivery_id) || '',
  }
  //data for price and discount tab
  const updatePriceDiscountTab = {
      priceinclude_tax:Boolean(priceDiscountData?.price_include_tax),
      paymentterm_id:priceDiscountData?.payment_term_id || null,
      discount_amount:priceDiscountData?.discount_amount || 0,
      discount_percentage:priceDiscountData?.discount_percentage || 0,
      rounding_amount:priceDiscountData?.rounding_amount || 0,
  }

  const handleStep1Next = () => {
    if (currentStep === 1) {
        nextStep()
        handleSave()    
    }
  }

  const handleStep2Next = () => {
    if(currentStep===2){
      if (
        invoiceHeader.price_include_tax !== priceDiscountData.price_include_tax ||
        invoiceHeader.payment_term_id !== priceDiscountData.payment_term_id ||
        invoiceHeader.discount_amount !== priceDiscountData.discount_amount ||
        invoiceHeader.discount_percentage !== priceDiscountData.discount_percentage ||
        invoiceHeader.rounding_amount !== priceDiscountData.rounding_amount
      ) {
      updatedInvoiceHeader({},updatePriceDiscountTab)
      handleSave()
      }else{
        nextStep()
        handleSave()
      }
    }
  }

  const handleStep3Next = () => {
    if(currentStep===3){
        nextStep()
        handleSave()
      }
  }

  const updatedInvoiceHeader = async (workflow_triggerer_object = {}, data, isNextNeeded) => {
    let invoice_header_api_payload = objectIsEmpty(workflow_triggerer_object)
      ? data
      : workflow_triggerer_object;
    let api = Api
    api.setUserToken()
    try {
      const res = await api
        .axios()
        .put(
          Settings.apiPurchaseOrderUrl + `/invoice_header/${id}`,
          invoice_header_api_payload,
        )
  
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success')
        
        if (objectIsEmpty(workflow_triggerer_object)) {
          getInvoiceHeaderById();
          if(isNextNeeded){
            nextStep();
          }
        } else {
          const timer = setTimeout(() => {
            props.user?.usertype=== 0
              ? navigate(`/invoice-details/${id}`, {
                  state: { prevPath: "/work-confirmation/edit" },
                })
              : navigate(`/invoice-details?invoice_id=${id}`, {
                  state: { prevPath: "/work-confirmation/edit" },
                });
          }, 500);
          
          return () => clearTimeout(timer);
        }
      }
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }  

  const postApiCallofInvoice = async (api, isNextNeeded) => {
    try {
      const res = await api.axios().post(Settings.apiPurchaseOrderUrl + `/invoice_header`, data, { cancelToken: cancelTokenSource.token });
  
      if (res.data.status === 'success') {
        Helper.alert(res.data.message, 'success');
        navigate('/invoice/edit/' + res.data.invoice_id);
        handleSave();
        if(isNextNeeded){
          nextStep();
        }
        setTimeout(() => {
          newInvoiceCall=false;
        }, 1000);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
  

  const handleInvoicePostApicall = (api,isNextNeeded) => {
    if(invoiceHeader?.invoice_id){
      handleSave()
      if(isNextNeeded){
        nextStep()
      }
    } else {   
      if(newInvoiceCall){
        return
      };

      newInvoiceCall = true

      Helper.createDebouncedAPIFunction([
        async () => await postApiCallofInvoice(api)
      ], setSaveBtnApi, cancelTokenSource)();  
    }
  }

  const handleLocationValidations = (isNextNeeded) => {
    if (
      id &&
      invoiceHeader?.document_status === 0 &&
      (invoiceHeader?.reference_number !== invoiceNumber ||
        invoiceHeader?.description !== invoiceDescription ||
        Helper.formateDate(invoiceHeader?.invoice_date) !==
          Helper.formateDate(invoiceDate) || 
        invoiceHeader?.delivery_term_id !== deliveryData?.delivery_term_id || 
        invoiceHeader?.mode_of_delivery_id !== deliveryData?.mode_of_delivery_id )
    ) {
      Helper.createDebouncedAPIFunction([
        async () => await updatedInvoiceHeader({},updateInvoiceHeaderData)
      ], setSaveBtnApi, cancelTokenSource,false,500000)();  
    } else {
       if(isNextNeeded){
         nextStep()
       }
    }
  }
  
  const handleStep0Next = (isNextNeeded=true) => {
    if (currentStep === 0) {
      let api = Api
      api.setUserToken()
      if (id) {
        handleLocationValidations(isNextNeeded)
      } else {
        if(objectIsEmpty(selectedDocument)){
          Helper.alert("Please select a document","error")
          return
        }
        handleInvoicePostApicall(api,isNextNeeded)  
        }
    }
  }

  const handleNextStep = () => {
    handleStep0Next()
    handleStep1Next()
    handleStep2Next()
    handleStep3Next()
  }

  const isNextButtonDisabled = () => {
    if (
      ((currentStep === 0) && (!invoiceNumber || (!invoiceDescription && props.user?.usertype===1))) ||
      (currentStep === 0 &&
        invoiceHeader &&
        (!invoiceHeader?.reference_number ||
        !invoiceHeader?.accounting_date ||
        !invoiceHeader?.document_date ||
        !invoiceHeader?.description) &&
        (currentStep === 0 &&
        (!invoiceNumber || (!invoiceDescription && props.user?.usertype===1))))
    ) {
      return true
    }
    if(currentStep===lastStep || invoiceHeader?.document_status===1){
      return true
    }
  }

  const disable = isNextButtonDisabled()

  const getInvoiceHeaderById = () => {
    let api = Api
    api.setUserToken()
    if (!id && !invoiceHeader) return
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/invoice_header/${id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success' && id) {
          setInvoiceHeader(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const getSourceId = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/source`)
      .then(function (res) {
        if (res.data.status === 'success') {
          const sources = res.data.data
          const filteredSource = sources.find(
            (source) => source.source_name === 'Invoice-LineDetails',
          )
          if (filteredSource) {
            setSourceId(filteredSource.source_id)
          }
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const getAllLines = () => {
    if (!invoiceHeader && !invoiceHeader?.invoice_id) {
      return
    }
    setLoading(true)
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/lines/${invoiceHeader?.invoice_id}/${invoiceHeader?.source_id}`,
        {},
      )
      .then(async function (res) {
        if (res.data.status === 'success') {
          let promises = res.data.data.map((obj) =>
            getLineItemDetails(obj.line_id)
              .then((data) => {
                obj.items = data
                return obj
              })
              .catch((error) => {
                obj.items = []
                return obj
              }),
          )
          let updatedData = await Promise.all(promises)
          setTimeout(() => {
            setLinesData(updatedData)
            setLoading(false)
            return updatedData
          }, 100)
        }
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const getLineItemDetails = (line_id) => {
    return new Promise((resolve, reject) => {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .get(Settings.apiPurchaseOrderUrl + `/lines/${line_id}/${sourceId}`, {})
        .then((res) => {
          if (res.data.status === 'success') {
            resolve(res.data.data)
          } else {
            reject(new Error('API call failed'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const getAllAsyncData = async () => {
    if (!invoiceHeader && !invoiceHeader?.invoice_id) {
      return
    }
    const lineId = await invoiceApiCalls.getAllHeaderLines(
      invoiceHeader?.purchaseorder_id,
      Settings.source_id.purchase_order,
    )
    if (lineId) setLineIds(lineId)   
  }

  const handleSubmitInvoice = () => { 
    if (!id && !invoiceHeader) return;

    Helper.createDebouncedAPIFunction([
      async () => await updatedInvoiceHeader({ workflow_initiated: true })
    ], setSubmitBtnApi, cancelTokenSource)();  
  }

  const getReceivings = async () => {
    if(!receiving_id) return
    const shipment_header = await invoiceApiCalls.getAllShipmentHeaderById(
     receiving_id,
    )
    setSelectedValues(shipment_header?.receiving_reference_number)
    if (shipment_header && receiving_id) setSelectedDocument(shipment_header)
  }

  const get_purchase_order_details = () => {
    if(!purchase_order_id) return
    let api = Api
    api.setUserToken()
    if (purchase_order_id === null || purchase_order_id === undefined) return
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/PO_header/${purchase_order_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setSelectedDocument(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
 
  const getInvoiceList = () => {
    if(!selectedDocument?.purchase_order_id) return
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiPurchaseOrderUrl + `/get_invoices_by_PO/${selectedDocument?.purchase_order_id}?status=0`)
      .then(function (res) {
        if (res.data.status === 'success') {
          const data = res.data?.data?.filter((item)=>item.type===0)
          setInvoicesList(data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  useEffect(() => {
    const savedStepsFromLocalStorage = localStorage.getItem('savedInvoiceSteps')
    if (savedStepsFromLocalStorage) {
      setSavedSteps(JSON.parse(savedStepsFromLocalStorage))
    }
    getSourceId()
  }, [])

  const invoiceBtnClick = () => {
    setInvoiceListPopup(true)
  }

  useEffect(() => {
    getAllLines()
    getAllAsyncData()
    setPriceDiscountData({
      price_include_tax:invoiceHeader?.price_include_tax===null?null:invoiceHeader?.price_include_tax,
      payment_term_id:invoiceHeader?.payment_term_id,
      discount_amount:invoiceHeader?.discount_amount,
      discount_percentage:invoiceHeader?.discount_percentage,
      rounding_amount:invoiceHeader?.rounding_amount,
    })
    setDeliveryData({
      delivery_term_id:invoiceHeader?.delivery_term_id,
      mode_of_delivery_id:invoiceHeader?.mode_of_delivery_id,
      delivery_date:invoiceHeader?.delivery_date

    })
  }, [invoiceHeader])

  useEffect(() => {
    getInvoiceHeaderById()
  }, [id])

  useEffect(()=>{
    getReceivings()
  },[receiving_id])

  useEffect(()=>{
    get_purchase_order_details()
  },[purchase_order_id])

  useEffect(()=>{
    if(selectedDocument){
      getInvoiceList()
    }
  },[selectedDocument?.document_number])


  const onDeleteInvoiceAPICall = async () => {
    if(newInvoiceCall) {
      return;
    }
    
    newInvoiceCall = true;
    let api = Api;
    api.setUserToken();
    
    try {
      const res = await api.axios().delete(
        `${Settings.apiPurchaseOrderUrl}/invoice_header/${invoiceHeader?.invoice_id}`
      );
  
      if (res.data.status === 'success') {
        Helper.alert(res.data?.message, 'success');
  
        const timer = setTimeout(() => {
          navigate(-2);
          newInvoiceCall = false;
        }, 500);
  
        return () => clearTimeout(timer);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  };
  

  const onDeleteInvoice = () => {
    Helper.confirmMe(() => {
      Helper.createDebouncedAPIFunction([
        async () => await onDeleteInvoiceAPICall()
      ], setDeleteBtnApi, cancelTokenSource,false,500000)();  
    }, "This action will delete your document.", null, "Are you sure you want to continue?", 350); 
  }
  
  const handleClose = () => {
    if(location.state?.prevPath==="/invoice-details" || (currentStep===0 && location?.pathname==="/invoice/new")){
      navigate(-1)
    }else{
      navigate(-2)
    }
  }

  //const onClick Match Product
  const onProductMatchBtnClick = () => {
    setMatchPopUp(true)
  }

  const onProductDetailPopupClose = () => {
    setMatchPopUp(false)
    $('html').removeClass('rs_popup_open');
  }

  const onInvoiceListPopUpClose = () => {
    setInvoiceListPopup(false)
    $('html').removeClass('rs_popup_open');
  }

  
  const addComponentTop = () => {
    return (
      <StepperSection
        currentStep={currentStep}
        savedSteps={savedSteps}
        shipmentHeader={invoiceHeader}
        type="invoice"
        userType={props?.user?.usertype}
    />
    )
  }

  return (
    <div
      className={
        props.user?.usertype === 0
          ? `request_for_quotation_archive external-screens responsive-screen-style work-confirmation-internal-screen ${currentStep===0?'scrollBarBlinkControlInvoice':''}`
          : `request_for_quotation_archive external-screens responsive-screen-style ${currentStep===0?'scrollBarBlinkControlInvoice':''}`
      }
    >
      <MasterComonent>
        <div className="container-fluid invoice-screen">
          <NavigationHeder
            title={id ? `Edit Invoice` : `Create Invoice`}
            backUrl={location.state?.prevPath==="/invoice-details" || !id ?-1:-2}
            hideMoreBtn={true}
          >
            <Button
                title={Helper.getLabel(props.language, 'close', 'Close')}
                onClick={handleClose}
                className="black-btn-style"
              />
             {
              invoiceHeader &&
              invoiceHeader.document_status === 0 && currentStep !== lastStep && (
                <Button
                  title={Helper.getLabel(props.language, 'delete', 'Delete')}
                  onClick={onDeleteInvoice}
                  isDisable={invoiceHeader?.document_status===1}
                  className={invoiceHeader?.document_status===1?'':'red-btn-style'}
                />
             )}
              {
                currentStep === 0 && (
                <Button
                  title={Helper.getLabel(props.language, 'save', 'Save')}
                  onClick={()=>{handleStep0Next(false)}}
                  isDisable={disable || saveBtnApi}
                  isActive={false}
                  className='rfq_save_btn'
                />
             )}
            {currentStep === 1 && (
              <Button
                title={Helper.getLabel(props.language, 'match_product_receipts', 'Match Product Receipts')}
                onClick={onProductMatchBtnClick}
                isDisable={invoiceHeader?.document_status===1}
                className={invoiceHeader?.document_status===1?'':'brown-btn-style'}
              />
            )}
            {currentStep === 0 && invoicesList.length >= 1 && (
              <Button
                title={Helper.getLabel(props.language, 'view_existing_invoices', 'View Existing Invoices')}
                onClick={invoiceBtnClick}
                isDisable={invoiceHeader?.document_status===1}
              />
            )} 
            {currentStep > 0 && currentStep < lastStep && (
              <TotalWidget source_id={invoiceHeader?.source_id} integrator={invoiceHeader?.invoice_id} />
            )}
            {currentStep !== 0 && (
              <Button
                title={Helper.getLabel(props.language, 'back', 'Back')}
                onClick={prevStep}
                isDisable={invoiceHeader?.document_status===1}
                className={invoiceHeader?.document_status===1?'':"blue-btn-style"}
              />
            )}
            {currentStep !== lastStep && <Button
              title={Helper.getLabel(props.language, "next", "Next")} 
              onClick={handleNextStep}
              isDisable={disable || saveBtnApi}
              className={disable?'':"blue-btn-style"}
            />}
            {currentStep === lastStep && (
              <Button
                title={Helper.getLabel(props.language, 'submit', 'Submit')}
                isActive={true}
                onClick={handleSubmitInvoice}
                isDisable={invoiceHeader?.document_status===1}
              />
            )}
          </NavigationHeder>
          <div>
            {currentStep > 0 && <StepperSection
              currentStep={currentStep}
              savedSteps={savedSteps}
              shipmentHeader={invoiceHeader}
              type="invoice"
              userType={props?.user?.usertype}
            />}
               {currentStep === 0 && (
                  <InvoiceGeneralInformation 
                    invoiceDate={invoiceDate}
                    setInvoiceDate={setInvoiceDate}
                    documentDate={documentDate}
                    setDocumentDate={setDocumentDate}
                    invoiceNumber={invoiceNumber}
                    setInvoiceNumber={setInvoiceNumber}
                    invoiceDescription={invoiceDescription}
                    setInvoiceDescription={setInvoiceDescription}
                    selectedDocument={selectedDocument}
                    setSelectedDocument={setSelectedDocument}
                    invoiceHeader={invoiceHeader}
                    setInvoiceHeader={setInvoiceHeader}
                    location={id}
                    invoicesList={invoicesList}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    addComponentTop={addComponentTop}
                    deliveryData={deliveryData}
                    setDeliveryData={setDeliveryData}
                    user={props.user}
                    id={id}
                  />
                )}
            <div className="work-confirmation-main-screen-grid">
                {currentStep === 1 &&
                  (loading ? (
                    <SimpleLoading />
                  ) : (
                    <InvoiceLineGrid
                      lineData={linesData}
                      fetchAllLines={getAllLines}
                      lineIdOptions={lineIds}
                      invoiceHeader={invoiceHeader}
                      userType={props.user?.usertype}
                      setLinesData={setLinesData}
                      currentStep={currentStep}
                    />
                  ))}
                {/* {currentStep === 2 && props?.user?.usertype===0 &&
                  (loading ? <SimpleLoading /> : <Collapse
                  title={Helper.getLabel(
                    props.language,
                    "price_and_discount",
                    "Price & Discount"
                  )}
                  open={true}
                ><PriceDiscountTab
                  isEditable={
                   invoiceHeader?.document_status !== 1
                  }
                  state={priceDiscountData}
                  setState={setPriceDiscountData}
                  name="price_include_tax"
                  paymentTermName="payment_term_id"
                /></Collapse>)} */}
                {currentStep === 2 &&
                  (loading ? <SimpleLoading /> : 
                  <Documents source_id={invoiceHeader?.source_id} attachmentsource_id={invoiceHeader?.attachement_source_id} attachmentsource_integrator={invoiceHeader?.invoice_id} document_type="invoice" document_status={invoiceHeader?.document_status} />)}
                {currentStep === 3 &&
                  (loading ? (
                    <SimpleLoading />
                  ) : (
                    <InvoiceSummary linesData={linesData} currentStep={currentStep}  invoiceHeader={invoiceHeader} userType={props.user?.usertype} user={props.user}  sourceId={sourceId} />
                  ))}
                  {invoiceListPopupOpen && <InvoiceListPopup language={props.language} selectedDocument={selectedDocument} documentNumber={selectedDocument?.document_number} onInvoiceListClose={onInvoiceListPopUpClose} setInvoiceHeader={setInvoiceHeader} />}
                  {matchPopUp && <InvoiceProductsDetails language={props.language} onProductDetailPopupClose={onProductDetailPopupClose} invoice_id={invoiceHeader?.invoice_id} invoice_header={invoiceHeader} userType={props?.user?.usertype}/>}
                {currentStep > 0 && <AddComponentRight invoiceHeader={invoiceHeader} invoice_id={id} />}
            </div>
          </div>
        </div>
      </MasterComonent>
      {saveBtnApi || deleteBtnApi || submitBtnApi ? <OverlayLoader isPopupInTab={true} isLogoCenter={true} /> : <></>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(NewInvoice)
