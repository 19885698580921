import React, { Component } from "react";

import MasterComonent from "../../Backend/MasterComonent";
import NavigationHeder from "../../Navigations/NavigationHeder";

import SuccessImage from "../../../assets/success-check.svg";
import Helper from "../../../inc/Helper";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import { connect } from "react-redux";
import RsWithRouter from "../../inc/RsWithRouter";
import ActionTypes from "../../../inc/ActionsTypes";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import { Link } from "react-router-dom";

class Success extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      documentNumber: null,
      description: null,
      deliveryDate: null,
      total: 0,
      currencyId: null,
      getCurrencies: [],
      documentStatus:null,
      defaultSettings:{},
    };
  }

  componentDidMount() {
    this.getHeaderData();
    this.getCurrencies();
    this.getDefaultProcurementSettings()
  }

  // componentDidUpdate(prevProps){
  //   if(prevProps?.prCart !== this.props.prCart){
  //     this.getHeaderData()
  //   }
  // }

  getHeaderData() {
    let api = Api;
    api.setUserToken();

    this.setState({ isLoading: true });

    const existingPRId = this.props.prCart?.purchaserequistion_id || this.props.rs_router?.location?.state?.prId;

    // fetch existing header data of PR id exists
    if (existingPRId) {
      this.setState({ purchaseRequisitionId: existingPRId });
      api
        .axios()
        .get(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + existingPRId)
        .then(headerDataRes => {
          api
            .axios()
            .get(Settings.apiPurchaseOrderUrl + `/total/${headerDataRes?.data?.data?.source_id}/` + existingPRId)
            .then(totalRes => {
              if (headerDataRes) {
                // this.props.setPRCart([])

                const data = headerDataRes.data?.data;
                this.setState({
                  documentNumber: data.document_number,
                  description: data.description,
                  deliveryDate: Helper.convertDateFromDb(data.delivery_date),
                  isHeaderLoading: false,
                  currencyId: data.currency_id,
                  total: totalRes?.data?.data?.total || 0,
                  documentStatus: data.document_status,
                });
              }
            })
            .catch(error => {
              this.setState({ isHeaderLoading: false });
              getPopupMessageBasedOnStatus(error)
            });
        })
        .catch(error => {
          this.setState({ isHeaderLoading: false });
          getPopupMessageBasedOnStatus(error)
        });
    } else {
      // redirect to shop
      setTimeout(() => {
        this.props.rs_router.navigate("/purchase-requisition");
      }, 1000);
    }
  }

  getCurrencies() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/currency")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isLoading: false, currencyList: res.data?.data });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      });
  }

  getDefaultProcurementSettings() {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.apiProcurementSettingsUrl + `/get_default_procurmentsetting`)
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({
            defaultSettings: res?.data?.data,
          });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error);
      });
  }
  
  getCurrencyCode(currencyId) {
    return this.state.currencyList?.find((curr) => curr.currency_id === currencyId)?.currency_code;
  }

  render() {
    let current_user = this.props.rs_router?.location?.state?.isCurrentUser
    return (
      <MasterComonent>
        <div className="pr-wrapper">
          <NavigationHeder 
            title={this.props.title} 
            backUrl={this.state?.documentStatus === 0?this.props.backUrl:current_user?`/purchase-requisition-created-by-me?user_id=${current_user}`:"/purchase-requisition"} 
            hideMoreBtn={this.props.hideMoreBtn}
          />
          <div className="container-fluid">
            <div className="success-screen-container">
              <div className="successscreen-content">
                <img src={SuccessImage} alt="success" />
                <h2>{Helper.getLabel(this.props.language, "requisition_submitted_successfully", "Requisition Submitted Successfully!")}</h2>
                <p>{Helper.getLabel(this.props.language, "details_of_transaction_are_included_below", "Details of transaction are included below")}</p>
                <table className="pr-details-table">
                  <tr>
                    <th>Requisition Number</th>
                    <td>{this.state.documentNumber ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>{this.state.description ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Need By Date</th>
                    <td>{this.state.deliveryDate ?? "-"}</td>
                  </tr>
                  <tr>
                    <th>Total Amount</th>
                    <td>
                      {this.getCurrencyCode(this.state.currencyId)} {Helper.formateCurrency(this.state.total) ?? "-"}
                    </td>
                  </tr>
                </table>

                <div className="next-steps">
                  <h2 className="pr-next-steps-heading">What's Next?</h2>
                  <p>Your requisition will be reviewed by the procurement team. You will receive notifications regarding the approval status and further steps.</p>
                </div>
                <div className="additional-info">
                  <h2 className="pr-next-steps-heading">Additional Information</h2>
                  <p>
                    You can track the status of your requisition in the <Link to="/dashboard">Requisition Tracker</Link>. If you have any questions or need further assistance, please contact{" "}
                    <a href={`mailto:${this.state.defaultSettings?.support_email}`} style={{textDecoration:"none"}}>{this.state.defaultSettings?.support_email}</a>.
                  </p>
                </div>

                <p className="pr-footer-text">Thank you for using our procurement system. We are committed to ensuring a smooth and efficient procurement process for you.</p>
              </div>
            </div>
          </div>
        </div>
      </MasterComonent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalPRAmount: state.prShop.totalPRAmount,
    prCart:state.prCart.prCartInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return{
    setPRCart: (payload) => dispatch({ type: ActionTypes.SET_PR_ID, payload }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RsWithRouter(Success))
