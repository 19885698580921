import React from "react";
import AddRequisitionDetails from "../../components/PurchaseRequisition/Checkout/AddRequisitionDetails";
import Gui_id_list from "../../inc/Gui_id_list";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";

const AddRequisitionDetailsScreen = (props) => { 
  return (
    <AddRequisitionDetails
      backUrl={"/purchase-requisition"}
      title="Shop > Add Requisition Details"
      hideMoreBtn={true}
      security={props?.security}
    />
  );
};

const SecurityOptions = {
  gui_id: Gui_id_list.request.purchase_requisitions.purchase_requisitions_main
};

export default (ApplySecurityRoles(AddRequisitionDetailsScreen, SecurityOptions)); 