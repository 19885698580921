import React from 'react';
import Gui_id_list from '../../inc/Gui_id_list';
import MasterComonent from '../../components/Backend/MasterComonent';
import ItemCatalogueEdit from "../../components/RateCard/RateCardEdit/RateCardEdit";
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Alert from '../../components/inc/Alert';
import { connect } from 'react-redux';

const ItemCatalogueEditScreen = (props) => {
    let security = props?.security; 

    if(!security.canView(Gui_id_list.procure.item_catalogue.item_catalogue_main_edit_screen) || props?.user?.is_buyer !== true){
        return <MasterComonent>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </MasterComonent>
    };
    return ( 
        <ItemCatalogueEdit /> 
    )
}

const mapStateToProps = (state) => {
    return { 
        user:state.auth.user
    }
}
 
const SecurityOptions = {
  gui_id: Gui_id_list.procure.item_catalogue.item_catalogue_main_edit_screen
};

export default connect(mapStateToProps) (((ApplySecurityRoles(ItemCatalogueEditScreen, SecurityOptions))));