/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect,useRef } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Dropdown from '../../Forms/Dropdown'


const GeneralSetup = (props) => {
  const useRefData = useRef({entity:false,site_loaded:false,warehouse_loaded:false,site:false,sitePageNumber:1,warehousePageNumber:1,parentStateChanegTimmer:null});
  //States For Dropdowns
  const [currency, setCurrency] = useState([])
  const [sitesList, setSitesList] = useState([])
  const [warehouseList, setWarehouseList] = useState([])
  //End

  const sitesData = useRef([])
  const warehouseData = useRef([])

  //API Calls Renderer
    useEffect(() => {
        CurrencyAPI() 
        if(!useRefData.current?.site_loaded){
          useRefData.current.site_loaded = true;
          loadSiteById(props.state?.site_id)
        }
        if(!useRefData.current?.warehouse_loaded){
          useRefData.current.warehouse_loaded = true;
          warehouseLoadById(props.state?.warehouse_id)
        }        
    }, [])
  // End

  //API Reduction 
   let CurrencyCalled = false 
  //End


  // API Calls
  const loadSiteById = (rsSiteId) => {
    if (!rsSiteId) {
      return;
    }
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/site/' + rsSiteId).then(function (res) {
      if (res.data.status === "success") {
        let siteResData = res.data.data;
        setSitesList((prevData) => [
          ...prevData,
          {
            label: siteResData?.code + ' - ' + siteResData?.name,
            value: siteResData?.site_id
          }
        ]);
      }
    });
  };
  
  const warehouseLoadById = (rsWarehouseId) => {
    if (!rsWarehouseId) {
      return;
    }
    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiUrl + '/warehouse/' + rsWarehouseId).then(function (res) {
      if (res.data.status === "success") {
        let warehouseResData = res.data.data;
        setWarehouseList((prevData) => [
          ...prevData,
          {
            label: warehouseResData?.code + ' - ' + warehouseResData?.name,
            value: warehouseResData?.warehouse_id
          }
        ]);
      }
    });
  };
  
  // API Calls
  const CurrencyAPI = () => {
    if(CurrencyCalled) {
      return;
    }
      let api = Api
      CurrencyCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
      if(res.data.status==="success"){
        let CurrencyARR = []
        res.data.data.forEach(item => {  
        CurrencyARR.push({
          value: item.currency_id,
          label: `${item.currency_code} - ${item.currency_name}`
        })
      })
      setCurrency(CurrencyARR)
      } 
    }).catch((res) => { 
      // Helper.alert(res.response.data.message, 'error')
    })
  }
 
  //End
  const onEntityChangeHandler = (event) => {
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
              site_id:'',
              warehouse_id:'',
          }
      })
  }
  const onSiteChangeHandler = (event) => {
    
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
              warehouse_id:'',
          }
    })
  }
  const onChangeHandler = (event) => {
    props.setState((prevState) => {
          return {
              ...prevState,
              [event.target.name]:event.target.value,
          }
      })
  }
  
  const mapSitesData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.site_id
    };
  }

  const mapWarehouseData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.warehouse_id
    };
  }

  return (
        <div className='row'>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown id="currency" label ={Helper.getLabel(props.language,'currency',"Currency")} required ={true} options = {currency} name='currency_id' value={props.state?.currency_id} onChange={onChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_currency',"Select currency")}  reRenderRequired={true}/>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown id="entity_po" disableClear={true} label ={Helper.getLabel(props.language,'entity',"Entity")} required ={true} options =  {props.entities} name='entity_id' value={props.state?.entity_id} onChange={onEntityChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_entity',"Select entity")}  reRenderRequired={true}/>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown pagination={true} apiUrl={Settings?.apiUrl + `/get_site`} additionalParams={{entity_id:props.state?.entity_id}} allDataRef={sitesData} mapFunction={mapSitesData} id="site_id_po" disableClear={true} label ={Helper.getLabel(props.language,'site',"Site")} required ={true} options =  {sitesList} name='site_id' value={props.state?.site_id} onChange={onSiteChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_site',"Select site")} reRenderRequired={true}/>
              </div>
              <div className='col-xs-12 col-sm-6 col-md-6'>
                <Dropdown pagination={true} apiUrl={Settings?.apiUrl + `/get_warehouse`} additionalParams={{site_id:props.state?.site_id}} allDataRef={warehouseData} mapFunction={mapWarehouseData} id="swarehouse_id_po" disableClear={true} label ={Helper.getLabel(props.language,'warehouse',"Warehouse")} required ={true} options =  {warehouseList} name='warehouse_id' value={props.state?.warehouse_id} onChange={onChangeHandler} disable = { !props.isEditable } placeHolder={Helper.getLabel(props.language,'select_warehouse',"Select warehouse")} reRenderRequired={true}/>
              </div>
        </div>
  )
}
 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (GeneralSetup) 