import React, { Component } from "react";
import Cart from "../../components/PurchaseRequisition/Cart";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import RsWithRouter from "../../components/inc/RsWithRouter";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import Helper from "../../inc/Helper";
import { getPopupMessageBasedOnStatus } from "../../inc/Validation";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../inc/Gui_id_list";
import OverlayLoader from "../../components/PurchaseRequisition/common/OverlayLoader";

// "/purchase-requisition/addrequisitiondetails"
class ShoppingCartScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prHeader:{},
      isLoading:false
    }
    this.handleNextBtn = this.handleNextBtn.bind(this)
    this.handleBackBtn = this.handleBackBtn.bind(this)
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  async getExistingHeaderData() {
    let api = Api
    api.setUserToken()
    const existingPRId = this.getSearchParam('pr_view_id')

    // fetch existing header data of PR id exists
    if (existingPRId) {
      this.setState({isLoading:true})
      try {
        const headerDataRes = await api.axios().get(Settings.apiPurchaseRequisitionUrl + '/pr_header/' + existingPRId)
        if (headerDataRes) {
          const data = headerDataRes.data?.data
          this.setState({ prHeader: data, isLoading:false })
        }
      } catch (error) {
        this.setState({isLoading:false})
        getPopupMessageBasedOnStatus(error)
      }
    }
  }

  componentDidMount() {
    this.getExistingHeaderData()
  }

  async checkPolicyHandler() {
    let api = Api;
    api.setUserToken();
 
    try {
      const res = await api
        .axios()
        .get(
          Settings.apiPurchaseRequisitionUrl +
            `/check_policies/${this.getSearchParam('pr_view_id')}`
        );
 
      if (res.data.status === 'success') {
        Helper.alert(res.data.message, 'success'); 
      }
    } catch (error) {
      // getPopupMessageBasedOnStatus(error);
    }
  }

  handleNextBtn(){
    this.checkPolicyHandler()
    let current_user = this.props.rs_router?.location?.state?.isCurrentUser
    if(current_user){
      this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${this.getSearchParam('pr_view_id')}`,{state:{isCurrentUser:current_user}});
    }else{
      this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${this.getSearchParam('pr_view_id')}`);
    }
  }

  handleBackBtn(){
    this.props.rs_router.navigate(-1)
  }

  render() {
    const titleText = `<span style="color:#313638;">Shop > Add Requisition Details > Cart</span>`
    const currentUser = this.props.rs_router?.location?.state?.isCurrentUser;
    const documentNumber  = this?.props?.rs_router?.location?.state?.documentNumber;
    const documentNumberText = documentNumber ? `<span style="color:#FC5E24;"> #${documentNumber}</span>` : "";
    
    const disableAllActionBtns = currentUser ? false : !this.props.security.canEdit(Gui_id_list.request.purchase_requisitions.purchase_requisitions_main_action_button);
    return (
      <ShopLayout
        backUrl={this?.props?.rs_router?.location?.state?.isCurrentUser !== null && this?.props?.rs_router?.location?.state?.isCurrentUser !== undefined ? `/purchase-requisition-created-by-me?user_id=${this?.props?.rs_router?.location?.state?.isCurrentUser}`:"/purchase-requisition"}
        title={`${titleText} ${documentNumberText}`}
        disableAllActionBtns={disableAllActionBtns}
        hideMoreBtn={true}
        nextBtnHandler={this.handleNextBtn}
        handleBackBtn={this.handleBackBtn}
        isContinueShopping={true}
        currentDocumentStatus={0}
        prId={this.getSearchParam('pr_view_id')}
        prHeader={this.state.prHeader}
      >
       {this.isLoading ? <OverlayLoader isPopupInTab={true} isLogoCenter={true} /> : <Cart showNext={true} currentDocumentStatus={0} viewLineDistribution={true} source_id={this.state?.prHeader?.source_id} prHeader={this.state.prHeader} is_rfi_pr={this.state?.prHeader?.is_rfi_pr} />}
      </ShopLayout>
    );
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.request.purchase_requisitions.purchase_requisitions_main
};

export default (ApplySecurityRoles(RsWithRouter(ShoppingCartScreen), SecurityOptions)); 