import React, { Component } from "react";
import ShopLayout from "../../components/PurchaseRequisition/ShopLayout";
import ShopResults from "../../components/PurchaseRequisition/ShopResults"
import Api from '../../inc/Api'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import Settings from '../../inc/Settings'
import RsWithRouter from "../../components/inc/RsWithRouter";
import OverlayLoader from "../../components/PurchaseRequisition/common/OverlayLoader";

// "/purchase-requisition"
class ShopScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      prHeader: {},
      isLoading:false
    }
    this.getSearchParam = this.getSearchParam.bind(this)
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  async getExistingHeaderData() {
    let api = Api
    api.setUserToken()
    const existingPRId = this.getSearchParam('pr_view_id')

    // fetch existing header data of PR id exists
    if (existingPRId) {
      this.setState({isLoading:true})
      try {
        const headerDataRes = await api.axios().get(Settings.apiPurchaseRequisitionUrl + '/pr_header/' + existingPRId)
        if (headerDataRes) {
          const data = headerDataRes.data?.data
          this.setState({ prHeader: data, isLoading:false })
        }
      } catch (error) {
        this.setState({isLoading:false})
        getPopupMessageBasedOnStatus(error)
      }
    }
  }

  componentDidMount() {
    this.getExistingHeaderData()
  }

  render() {
    return (
      <ShopLayout
        backUrl={-1}
        title="Shop"
        hideMoreBtn={true}
        hideNextBtn={true}
        isCartVisible={true}
        currentDocumentStatus={0}
        prHeader={this.state.prHeader}
      >
        {this.state.isLoading ? <OverlayLoader isPopupInTab={true} isLogoCenter={true}/> : <ShopResults prHeader={this.state.prHeader} />}
      </ShopLayout>
    );
  }
}

export default RsWithRouter(ShopScreen)