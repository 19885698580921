import React, { useState } from 'react'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import CurrencyRenderer from '../../grid/ag/cellRender/CurrencyRenderer'
import RateCardLineDetails from '../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RateCardLineDetails'
import { commonThousandSeparator, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getBudgetColor, getBudgetIcon, getNewValue, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import EditOldCartModal from '../../PurchaseRequisition/modals/EditOldCartModal'
import Settings from '../../../inc/Settings'
import Api from '../../../inc/Api'
import { connect } from 'react-redux'
import ActionTypes from '../../../inc/ActionsTypes'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { useNavigate } from 'react-router-dom'

const ActionButtons = ({language,onCancelBtnClick,onMarkZeroBtnClick}) => {
  return <div className='d-flex gap-3 align-items-center'>
     <button className='bg-transparent p-0 border-0' onClick={onCancelBtnClick}>{Helper.getLabel(language,'cancel','Cancel')}</button>
     <button className='bg-transparent p-0 border-0' onClick={onMarkZeroBtnClick}>{Helper.getLabel(language,'mark_as_zero','Mark as zero')}</button>
  </div>
}
let forceRender = 0
const AwardingLinesGrid = ({ language, apiUrl, additionalParams, rfqHeader , showOldCartModal, showEditOldCartModal, awardingGridObj, awardingTotalRecords, awardingLinesData, type, getPRLinesForAwarding}) => {
  const [rateCardPopup, setRateCardPopup] = useState(false)
  const [lineId, setLineId] = useState(0)
  const [prId,setPrId]=useState(0)
  const [isLoading,setIsLoading]=useState(false)

  const navigate = useNavigate()

  const getPrLinebyId = (line_id) => {
    let api = Api;
    api.setUserToken();
    setIsLoading(true)
    api
      .axios()
      .get(
        Settings.apiPurchaseOrderUrl +
          `/line/${line_id}/${Settings.source_id.purchase_requisition}`
      )
      .then(function (res) {
        if (res.data?.status === "success") {
          showOldCartModal(res.data.data)
          setIsLoading(false)
        }
      })
      .catch((res) => {
         setIsLoading(false)
      });
  };

  const updateLinesValue = (data,line_id,isRefresh=false) => {
    let api = Api
    api.setUserToken()
    if(line_id === null) return
    api
      .axios()
      .put(
        Settings.apiPurchaseOrderUrl +
          `/line/${line_id}/${Settings.source_id.purchase_requisition}`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          if(isRefresh){
            getPRLinesForAwarding()
            forceRender++
            setTimeout(()=>{
              awardingGridObj?.current?.api?.refreshServerSide({purge:false})
            },500)
          }
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const updateLineCall = (action) => {
    const selectedData = awardingGridObj?.current?.api?.getSelectedRows()
      let payload 
      selectedData.forEach((line,index) => {
        if(action==="cancel"){
          payload = {requisition_status:2,action_taken_on_non_awarded_line:true}
        }
        if(action==="markzero"){
          payload = {unit_price:0,line_amount:0,action_taken_on_non_awarded_line:true}
        }

        const isLast = index === selectedData.length - 1;
        updateLinesValue(payload, line.purchaserequisition_line_id,isLast)
      });
  }
  const updateLineOnAction = async (action) => {
    Helper.confirmMe(() => {
     updateLineCall(action)
    }, `This action will ${action==="cancel"?"cancel your selected lines.":"mark your selected lines as zero."} `, null, `Are you sure you want to ${action==="cancel"?"cancel selected lines":"mark selected lines as zero"}?`, 540);
  }

  const rateCardCell = (params) => {
    return <div className="grid_simple_link position-relative">Rate cards {params?.data?.rate_card_linked && <div className="orange-attachment-dot" style={{top:"18px", right:"48%"}}></div>}</div>
  }

  const viewDetailsCell = (params) => {
    return <div className="grid_simple_link">View pr line details</div>
  }

  const budgetCheckCellRenderer = (params) => {
    return <div className="d-flex justify-content-center align-items-center" style={{height:"41px"}}>{params.value === null || params.value === undefined ? "-" : <FontAwesomeIcon icon={getBudgetIcon(params)} color={getBudgetColor(params)} />}</div>
  }

  const valueFormatterForName = (params) => {
    if(type==='initiate awarding'){
      return params?.data?.lines_type===0 ? params.data.item_name : params?.data?.lines_type===1 ? params?.data?.category_name: params?.data?.comments
    }
    return params.value || '-'
  }

  let Columns_Headings = [
    {
      field:'purchaserequisition_document_number',
      headerName: Helper.getLabel(language, 'vendor_name', 'Purchase requisition number'),
      editable: false,
      minWidth: 240,
      cellRenderer:gridLinkValueFormat
    },
    {
      field: 'vendor_name',
      headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
      editable: false,
      minWidth: 180,
    },
    {
      field: 'name',
      headerName: Helper.getLabel(
        language,
        'name',
        'Name'
      ),
      editable: false,
      minWidth: 180,
      valueFormatter: valueFormatterForName,
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(language, 'quantity', 'Quantity'),
      editable: false,
      minWidth: 150,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: 'required_qty',
      headerName: Helper.getLabel(language, 'quantity', 'Quantity propose'),
      editable: false,
      minWidth: 180,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: 'unit_name',
      headerName: Helper.getLabel(language, 'unit', 'Unit'),
      editable: false,
      minWidth: 150,
      valueFormatter: (params) => params.value || '-',
    },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(language, 'unit_price', 'Unit price'),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 150,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: 'discount_amount',
      headerName: Helper.getLabel(
        language,
        'discount_amount',
        'Discount amount'
      ),
      cellRenderer:CurrencyRenderer,
      editable: false,
      minWidth: 180,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: 'discount_percentage',
      headerName: Helper.getLabel(
        language,
        'discount_percentage',
        'Discount percentage'
      ),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 200,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(language, 'line_amount', 'Line amount'),
      editable: false,
      cellRenderer:CurrencyRenderer,
      minWidth: 150,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
  ]

  if(rfqHeader?.rfq_type === 0 && type!=="initiate awarding" && rfqHeader?.converted_document){
    Columns_Headings.splice(0, 0,  
      {
        field: "budget_check",
        headerName: Helper.getLabel(language, "budget", "Budget"),
        name: "budget_check",
        cellRenderer: (params) => budgetCheckCellRenderer(params),
        editable: false,
      },
      {
        field: "view_details",
        headerName: Helper.getLabel(language, "view_details", "Details"),
        name: "view_details",
        cellRenderer: viewDetailsCell,
        editable: false,
      }
    );
  }

  if (rfqHeader?.rfq_type ===  1 && type !== "initiate awarding") {
    Columns_Headings.splice(4, 0, {  
      field: "rate_card",
      headerName: Helper.getLabel(language, "rate_card", "Rate card"),
      editable: false,
      minWidth: 180,
      cellRenderer: rateCardCell,
    });
  }
  
  const onCellClicked = (event) => {
     if(event.colDef.field === "rate_card"){
      setRateCardPopup(true)
      setLineId(event.data.line_id)
    }
    if(event.colDef.field==='view_details'){
      setPrId(event.data.purchaserequisition_id)
      getPrLinebyId(event?.data?.purchaserequisition_line_id)
    }
    if(event.colDef.field==='purchaserequisition_document_number' && (event.data.purchaserequisition_id)){
      navigate(`/purchase-requisition/summary?pr_view_id=${event.data.purchaserequisition_id}`,{state:{isPrevPath:true}})
    }
  }

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: awardingLinesData?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`,
        })),
        rowCount: awardingLinesData?.length,
      })
    },
  }

  const handleGridReady = (event) => {
    awardingGridObj.current = event
    if(type==="initiate awarding" && event){
      setTimeout(() => {
        event?.api?.setGridOption('serverSideDatasource', dataSource)
      }, 200)
    }
  }

  const handleFetchDataPR = (response) => {
    awardingTotalRecords.current = response.data.total_records
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response?.data?.total_records
    }
  }

  return (
    <>
    <Collapse
      className='mb-4'
      open={true}
      title={type==="initiate awarding"?Helper.getLabel(language, 'awarded_lines', 'Request for Quotation Lines'):Helper.getLabel(language, 'awarded_lines', 'Awarded Lines')}
    >
      <AgGridNew 
        gridId={'rfq_header_awarding_table_1'}
        key={forceRender}
        hideDeleteBtn={true}
        hideAddBtn={true}
        columnDefs={Columns_Headings}
        additionalParams={additionalParams}
        fetchData={handleFetchDataPR}
        apiUrl={apiUrl}
        onCellClicked={onCellClicked}
        pagination={true}
        afterDeleteBtns={type==="initiate awarding" && <ActionButtons language={language} onCancelBtnClick={()=>{updateLineOnAction('cancel')}} onMarkZeroBtnClick={()=>{updateLineOnAction('markzero')}} />}
        onGridReady={handleGridReady}
      />
    </Collapse>
    {
      rateCardPopup && <RateCardLineDetails setShowPopup={setRateCardPopup} lineId={lineId} rfqData={rfqHeader} isEditable={false}/>
    }
    {
      showEditOldCartModal && <EditOldCartModal source_id={Settings.source_id.purchase_requisition} viewOnly={true} is_rfi_pr={false} prId={prId} type='rfq' isRFQEditable={rfqHeader?.rfq_status===7} />
    }
    {
      isLoading && <OverlayLoader isLogoCenter={true} isPopupInTab={true} />
    }
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    showEditOldCartModal: state.prShop.showEditOldCartModal
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showOldCartModal: (payload) => {
      dispatch({ type: ActionTypes.SHOW_EDITOLDCART_MODAL, payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AwardingLinesGrid);

