import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Helper from '../../../../inc/Helper'
import Api from '../../../../inc/Api'
import Settings from '../../../../inc/Settings'
import ToggleSwitch, { TitlePosition } from '../../../Forms/ToggleSwitch'
import LineViewComponent from '../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import RFQAttachmentPopup from '../RFQList/RFQAttachmentPopup'
import TaxTransactionPopup from '../../../Purchase Orders Internal/Edit PO/ListView/TaxTransactionPopup'
import { commonThousandSeparator, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { getLabelFromDropdown, getNewValue } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import NumberCellEditor from '../../../grid/ag/cellEditor/NumberCellEditor'
import RateCardLineDetails from './RateCardLineDetails'
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown'
import * as XLSX from 'xlsx'
import $ from 'jquery'

let forceRender = 1
let lineAmount = 0
// let gridObj = null

const ImportExportExcel = ({onImportClick,onExportClick,isEditable}) => {
  return <Fragment>
    <div className="compliance_action_excel_btn" style={{opacity:!isEditable ? 0.5 : 1}}>
      <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" />
      <input type="file" onChange={onImportClick} disabled={!isEditable} />
    </div>

    <div className="import_sample_template">
      <button onClick={onExportClick}>
        <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export Excel File
      </button>
    </div>
  </Fragment>
}

const RFQBiddingProposal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 })
  const [rfqAttachments, setRFQAttachments] = useState([])
  const [vendorReplyStatus, setVendorReplyStatus] = useState([])
  const [allUnit,setAllUnit]=useState([])
  const [showTaxPopup, setShowTaxPopup]=useState(false)
  const [lineTaxId,setLineTaxId]=useState(null)
  const [lineId,setLineId]=useState(0)
  const [attachmentSourceId,setAttachmentSourceId]=useState(0)
  const [alternativeLines,setAlternativeLines]=useState(false)
  const [isLineProposed,setIsLineProposed]=useState(null)
  const [selectedRow,setSelectedRow]=useState({})
  const [rateCardPopup,setRateCardPopup]=useState(false)

  const navigate = useNavigate()
  const rfqLines = useRef([])
  const gridObj  = useRef(null)

  //value formatters
  const gridValueFormat = (params) => {
    const cellValue =
      params.value === null || params.value === undefined ? '-' : parseFloat(params.value).toFixed(6)
    return <div className="d-flex align-items-center" style={{height:"40px"}}><div className="rfq-cell">{Helper.formateCurrency(cellValue)}</div></div>
  }

  const gridValueColorFormat = (params) => {
    const cellValue =
      params.value === null || params.value === undefined ? ' ' : parseFloat(params.value).toFixed(6)
    const cellClass = cellValue === ' ' ? 'rfq-null-value-cell' : ''
    return <div className='d-flex align-items-center' style={{height:"40px"}}><div className={`rfq-cell ${cellClass}`}>{Helper.formateCurrency(cellValue)}</div></div>
  }

  const displayViewLogo = () => {
    return <div className="logo_inside_grid"><img src="/images/icons/view.svg" alt="view logo" style={{height:'24px'}} /></div>
  }

  const displayViewAttachedLogo = (params) => {
    return <div className="d-flex align-items-center justify-content-center" style={{marginLeft:"-17px"}}><div className="position-relative"><img src="/images/icons/attach.svg" alt="attach-icon" style={{height:"24px"}} />{params?.data?.RFQLine_attachment_exists && <div className="orange-attachment-dot" style={{top:"8.5px"}}></div>}</div></div>
  }

  const getLineStatusById = (params) => {
    const statusName = vendorReplyStatus?.find(
      (option) => option.id === params.value,
    )
    return statusName ? <div>{statusName.name}</div> : null
  }

  const taxTransactionViewCell = (params) => {
    if (params.data.line_id) {
      return <div className="rs_inactive_label">View tax</div>
    }
    return <></>
  }

  //On Cell Click Event
  const onProposalCellClicked = (event) => {
    if (event.colDef.field === 'view') {
      const lineId = event.data.line_id
      navigate(
        `/rfq-vendor-reply/rfq-bidding-general-details?line_id=${lineId}`,
        { state: props.rfqData },
      )
    }
    if (event.colDef.field === 'item_name') {
      if(event?.data?.line_type!==0){
        return
      }
      const itemId = event.data.item_id
      navigate(`/rfq-vendor-reply/rfq-bidding-details/${itemId}`, {
        state: props.rfqData,
      })
      if(props.type==='rfi'){
        $('html').removeClass('rs_popup_open')
        setRateCardPopup(false)
      }
    }
    if(event.colDef.field === 'rate_card'){
      setRateCardPopup(true)
      const line_id = event.data?.line_id
      setLineId(line_id)
      lineAmount = event?.data?.line_amount
    }
    onCellClicked(event)
  }

  const onCellClicked = (event) => {
    if (event.colDef.field === 'attached') {
      setShowModal(true)
      let scrollY = window.scrollY || document.documentElement.scrollTop
      let mouseY = event.event.clientY
      let absoluteMouseY = mouseY + scrollY
      let mouseX = event.event.clientX
      let absoluteMouseX = window.innerWidth - mouseX - 100
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX })
      const alternate_line = event.data?.alternate_line
      const attachedId = !alternate_line ? event.data.RFQLine_attachmentsource_id: event.data?.attachmentsource_id
      const line_id = event.data?.line_id
      setLineId(line_id)
      setAttachmentSourceId(attachedId)
      setAlternativeLines(alternate_line)
      setRFQAttachments([])
      get_rfq_attachments(alternate_line?line_id:event.data?.RFQLine_id
        ,alternate_line?80:61)
    }
    if(event.colDef.field === 'view_tax_transaction'){
      setLineTaxId(event.data?.line_id)
      setShowTaxPopup(true)
   }
  }

  //function to close attachment modal
  const closeModal = () => {
    setShowModal(false)
  }

  const onTaxTransactionPopupClose = () => {
    setLineTaxId(null)
    setShowTaxPopup(false)
  }

  //function to calculate line amount
  const getLineViewAmount = (params, lineData = null) => {
    let data = params.data ? params.data : params
    let qtyData = data.required_qty 
    let quantity = parseFloat(qtyData) ? parseFloat(qtyData) : 0;
    let unitPrice = parseFloat(data.unit_price) ? parseFloat(data.unit_price) : 0
    let discount_amount = parseFloat(data.discount_amount) ? parseFloat(data.discount_amount) : 0
    let discount_percentage = parseFloat(data.discount_percentage) ?? 0
    if (lineData) {
      qtyData =  lineData.required_qty 
      quantity =  parseFloat(qtyData) ? parseFloat(qtyData) : 0;
      unitPrice = parseFloat(lineData.unit_price) ? parseFloat(lineData.unit_price) : 0
      discount_amount = parseFloat(lineData.discount_amount) ? parseFloat(lineData.discount_amount) : 0
      discount_percentage = parseFloat(lineData.discount_percentage) ? parseFloat(lineData.discount_percentage) : 0
    }
    if (discount_amount > unitPrice) {
      discount_amount = unitPrice
    }
    discount_amount = discount_amount * quantity

    let totalLineAmountBeforeDiscount = quantity * unitPrice
    let lineAmount = totalLineAmountBeforeDiscount - discount_amount
    lineAmount = lineAmount - (discount_percentage / 100) * lineAmount
    let finalLineAmount = lineAmount
    if (finalLineAmount <= 0) {
      return 0
    }
    return finalLineAmount
  }

  const onUnitPriceChangeHandler = (event,params) => {
    let required_qty = document.getElementById('required_qty')
    let discount_amount = document.getElementById('discount_amount')
    let discount_percentage = document.getElementById('discount_percentage')
    let lineData = {
      required_qty:required_qty.value,
      unit_price:event?.target?.value,
      discount_amount:discount_amount.value,
      discount_percentage:discount_percentage.value
    }
    let totalLineAmount = getLineViewAmount(params,lineData);
    let editorInsAr = params.api.getCellEditorInstances();
    editorInsAr.forEach( cellEditorIns => {
        if(cellEditorIns.params && cellEditorIns.params.colDef.field === 'line_amount'){
            if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
                cellEditorIns.updateValue(totalLineAmount)
            }else{
                params.node.setDataValue('line_amount',totalLineAmount)
            }
        }
    })
    params.node.setDataValue('line_amount', totalLineAmount);
}

const valueFormateForLineView = (params) => {
    let lineData = null

    if(!params.data.line_id){
      lineData = {
        required_qty:document.getElementById('required_qty')?.value,
        unit_price:document.getElementById('unit_price')?.value,
        discount_amount:document.getElementById('discount_amount')?.value,
        discount_percentage:document.getElementById('discount_percentage')?.value
      }
    }

    let value = params.value === null || params.value === undefined ? getLineViewAmount(params,lineData) : params.value
   
    return  <div className="rs_ag_currency_renderer">{Helper.formateCurrency(value)}</div>
}

const handleAmountChange = (event,params) => {
  let lineData = {
    required_qty:document.getElementById('required_qty')?.value,
    unit_price:document.getElementById('unit_price')?.value,
    discount_amount:document.getElementById('discount_amount')?.value,
    discount_percentage:document.getElementById('discount_percentage')?.value
  }
  let totalLineAmount = getLineViewAmount(params,lineData)
  params.node.setDataValue('line_amount',totalLineAmount)
 }

  //Api Calls
  const get_rfq_attachments = (id,source_id) => {
    let api = Api
    api.setUserToken()
    if (lineId === null || lineId===undefined || id===null) return
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachments/${source_id}/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setRFQAttachments(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
  
  const get_all_line_status_external = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreplylinestatus_external`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setVendorReplyStatus(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const get_all_unit_names = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.loginUrl + `/unit`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setAllUnit(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }


  const updateLinesValue = (data,line_id,isRefresh=false) => {
    let api = Api
    api.setUserToken()
    if(line_id === null) return
    api
      .axios()
      .put(
        Settings.apiPurchaseOrderUrl +
          `/line/${line_id}/${props.source_id ? props.source_id :props.rfqData?.source_id}`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          if(isRefresh){
            gridObj?.current?.api?.refreshServerSide({purge:true})
          }
          // forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const onRowValueChanged = (event) => {
    const line_id = event.data.line_id
    const line_amount = Helper.formateCurrency(getLineViewAmount(event))
    const rowData = rfqLines?.current?.find((item)=>item.line_id===line_id)
    let data = {
      line_amount:parseFloat(Helper.getCurrencyForDb(line_amount))
    }
    if (event.data.required_qty !== rowData.required_qty) {
      data.required_qty = parseInt(event.data.required_qty) || 0;
    }
    
    if (event.data.unit_price !== rowData.unit_price) {
      data.unit_price = parseFloat(event.data.unit_price) || 0;
    }
    
    if (event.data.discount_amount !== rowData.discount_amount) {
      data.discount_amount = parseFloat(event.data.discount_amount) || 0;
    }
    
    if (event.data.discount_percentage !== rowData.discount_percentage) {
      data.discount_percentage = Helper.getOnlyNumber(event.data.discount_percentage) || 0;
    } 
     
    if (event.data.unit !== rowData.unit) {
      data.unit = Helper.getOnlyNumber(event.data.unit) || 0;
    }
    if((props?.type==="rfi") && (parseFloat(event?.data?.line_amount)>parseFloat(props.lineAmount))){
      Helper.alert(`Line amount of any item should not be greater than ${ commonThousandSeparator(props.lineAmount)}.`, "error")
      return
    }
    updateLinesValue(data,line_id) 
  }

  const onSwitchValueChanged = (value) => {
     setIsLineProposed(value)
  }

  const setAlternateLineExistsValue = (gridObj,value) => {
    gridObj?.api?.forEachNode((node)=>{
      if(node?.data?.line_id===selectedRow?.line_id){               
        node?.setDataValue('alternate_line_exists',value)
      }
    })
  }

  const onSelectionChanged = (event) => {
    let selectedRows = event.api.getSelectedRows()
    setSelectedRow(selectedRows[0])
    if(selectedRows[0]?.alternate_line_exists){
      setIsLineProposed(true)
    }else{
      setIsLineProposed(false)
    }
  }

  useEffect(()=>{
    get_all_line_status_external()
    get_all_unit_names()
  },[])
  const allUnitsList = allUnit?.map((item)=>{
    return{
      value:item.unit_id,
      label:item.description
    }
  })

  //data for columns
  let GridColumnsHeadingProposalLines = [
    {
      field: 'line_number',
      headerName: Helper.getLabel(props.language, 'line_number', 'Line number'),
      minWidth: 200,
    },
    {
      field: 'item_name',
      headerName: Helper.getLabel(props.language, 'item_name', 'Item name'),
      minWidth: 200,
      cellClass: 'rs_grid_simple_link rfq-link',
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'description',
      headerName: Helper.getLabel(props.language, 'description', 'Description'),
      minWidth: 200,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'category_name',
      headerName: Helper.getLabel(props.language, 'category', 'Category'),
      minWidth: 180,
      valueFormatter:(params)=>params.value?params.value:"-"
    }, 
    {
      field: 'comments',
      headerName: Helper.getLabel(props.language, 'comments', 'Comments'),
      minWidth: 240,
      valueFormatter:(params)=>params.value?params.value:"-"
    },
    {
      field: 'ordered_qty',
      headerName: Helper.getLabel(props.language, 'qty_require', 'Qty require'),
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},
      minWidth: 180,
    },
    {
      field: 'required_qty',
      headerName: Helper.getLabel(props.language, 'qty_propose', 'Qty propose'),
      minWidth: 150,
      onChange:handleAmountChange,
      cellEditor:NumberCellEditor, 
      cellRenderer: gridValueColorFormat,
    },
    {
      field: 'unit',
      headerName: Helper.getLabel(props.language, 'unit', 'Unit'),
      minWidth: 150,
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      cellEditorParams: { values: allUnitsList },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
    },
    //commented for future 
    // {
    //   field: 'lead_time',
    //   headerName: Helper.getLabel(props.language, 'lead_time', 'Lead time'),
    //   minWidth: 200,
    //   cellEditor:NumberCellEditor, 
    //   valueFormatter: (params) => {
    //     return Math.floor(Math.random() * 21); 
    //   }
    // },
    {
      field: 'unit_price',
      headerName: Helper.getLabel(props.language, 'unit_price', 'Unit price'),
      minWidth: 200,
      onChange:onUnitPriceChangeHandler,
      cellEditor:NumberCellEditor, 
      cellRenderer: gridValueColorFormat,
    },
    {
      field: 'discount_amount',
      headerName: Helper.getLabel(
        props.language,
        'discount_amount',
        'Discount amount',
      ),
      minWidth: 200,
      cellEditor:NumberCellEditor, 
      onChange:handleAmountChange,
      cellRenderer: gridValueFormat,
    },
    {
      field: 'discount_percentage',
      headerName: Helper.getLabel(
        props.language,
        'discount_percentage',
        'Discount percentage',
      ),
      minWidth: 200,
      onChange:handleAmountChange,
      cellEditor:NumberCellEditor,
      cellRenderer: gridValueFormat,
    },
    {
      field: 'line_amount',
      headerName: Helper.getLabel(props.language, 'line_amount', 'Line amount'),
      minWidth: 200,
      editable: false,
      cellRenderer:(params)=>{return valueFormateForLineView(params)}, 
      valueFormatter:(params)=>{return getLineViewAmount(params)}
    },
    {
      field:'vendorreplystatus_external',
      headerName: Helper.getLabel(props.language, 'status', 'Status'),
      minWidth:200,
      cellRenderer: getLineStatusById,
    },
    {
      field: 'attached',
      minWidth: 140,
      headerName: Helper.getLabel(props.language, 'attachment', 'Attachment'),
      cellClass: 'rfq-attached-cell',
      cellRenderer: displayViewAttachedLogo,
      sortable:false,
      filter:false
    },
    {
      field: 'view',
      minWidth: 120,
      headerName: Helper.getLabel(props.language, 'view', 'View'),
      cellRenderer: displayViewLogo,
      sortable:false,
      filter:false
    },
    {
      field: 'view_tax_transaction',
      minWidth: 150,
      headerName: Helper.getLabel(props.language, 'tax', 'Tax'),
      editable: false,
      cellRenderer:taxTransactionViewCell,
      sortable:false,
      filter:false
    },
    {
      field:'advance',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'advance_amount',
        'Advance Amount',
      ),
      editable:false
    },
    {
      field:'comments',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'comments',
        'Comments',
      ),
      editable:false
    },
    {
      field:'recoupment_rate',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'recoupment_rate',
        'Recoupment Rate',
      ),
      editable:false
    },
    {
      field:'retainage_rate',
      minWidth:200,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'retainage_rate',
        'Retainage Rate',
      ),
      editable:false
    },
    {
      field:'max_retainage_amount',
      minWidth:220,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'maximum_retainage_amount',
        'Maximum Retainage Amount',
      ),
      editable:false
    },
    {
      field:'alternate_line_exists',
      minWidth:220,
      hide:true,
      headerName: Helper.getLabel(
        props.language,
        'alternate_line_exists',
        'Alternate line exists',
      ),
      editable:false,
      cellRenderer: 'agCheckboxCellRenderer', 
      cellRendererParams: { disabled: true },
    },
  ]
  let isEditable
  GridColumnsHeadingProposalLines.forEach((column) => {
    const targetDate = new Date(props?.rfqData?.RFQ_type===2?props?.rfqData?.auction_end_date_time:props?.rfqData?.expiry_datetime);
    const currentDate = new Date();
    isEditable =  props?.rfqData?.onhold || props?.rfqData?.RFQ_status === 2 || props?.allFieldsAreZero || props?.isAuctionBidEditable || props?.rfqData?.RFQ_status === 9?false:props.rfqData?.interested_to_participate === true &&
    currentDate <= targetDate &&
    !props.bidStatus &&
    !(props.rfqData?.require_full_quantity === true && column.field === 'required_qty');
    if (
      column.field === 'unit_price' ||
      column.field === 'required_qty' ||
      column.field === 'discount_amount' ||
      column.field === 'discount_percentage' 
    ) {
      column.editable = (params) => {return (!params?.data?.allow_to_change_unit_price && props.type!=="rfi" && props.rfqData?.RFQ_type === 1 )  ? false : props.isEditable ?? isEditable}
        // !props.lineLevelStatus
    }else if (column.field === 'unit'){
      column.editable = props.type === 'rfi' ? props.isEditable : false
    }  else {
      column.editable = false
    }
  })

  

  const rateCardCell = (params) => {
    return <div className="grid_simple_link position-relative">Rate cards {params?.data?.rate_card_linked && <div className="orange-attachment-dot" style={{top:"18px", right:"58%"}}></div>}</div>
  }

  if(props.type===1){
    GridColumnsHeadingProposalLines?.push({ field:'rate_card', minWidth:220, headerName: Helper.getLabel(
      props.language,
      'rate_card',
      'Rate card',
    ), cellRenderer:rateCardCell})
  }

  if(props.type==="rfi"){
   GridColumnsHeadingProposalLines = GridColumnsHeadingProposalLines?.filter((item)=>item.field !== "line_number" && item.field !== "view" && item.field !== "vendorreplystatus_external" && item.field !== "attached")
  }

  const checkLineStatus = () => {
    if (!rfqLines?.current || rfqLines?.current?.length === 0) {
      return false;
    }
    return rfqLines?.current?.some((line) =>
      [2, 3, 4, 5, 8, 9].includes(line.vendorreplystatus_external)
    )
  }

  const lineStatus = checkLineStatus()

  const handleFetchData = (response) => {
     let data = response?.data?.data?.filter((item)=>item.alternate_line !== true)
     rfqLines.current = data
     if(props.type !== "rfi"){
      const hasRateCard = data.some((item) => item.rate_card_linked);
      const allRateCard = data.every((item) => item.rate_card_linked);
      
      let rateCardStatus = "no";
      if (allRateCard) {
        rateCardStatus = "all";
      } else if (hasRateCard) {
        rateCardStatus = "some";
      }
      props.setRfiLinesRateCard(rateCardStatus);  
     }   
     return {
      rowData: data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`,
        required_qty: parseInt(item.required_qty) === 0 && props.type==="rfi" ? 1 : item.required_qty
      })),
      rowCount: data?.length
     }
  }

   const handleAlternateFetchData = (response) => {
     let data = response?.data?.data 
     if(response?.data?.data?.length >= 1){
       setAlternateLineExistsValue(gridObj?.current,true)
     }else{
       setAlternateLineExistsValue(gridObj?.current,false)
     }
     return {
      rowData: data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: data?.length
     }
  }

  const handleGridReady = (params) => {
      gridObj.current = params
  }

  const exportToExcel = () => {
    const api = gridObj.current.api;
    const rowData = [];
    const visibleColumns = GridColumnsHeadingProposalLines.filter(col => !col.hide && col.field !== "view_tax_transaction");

    // Define selected columns, renaming "unit" to "unit_name"
    const selectedColumns = ["line_id", ...visibleColumns.map(col => col.field === "unit" ? "unit_name" : col.field).filter(col => col !== "line_id")];
    const columnHeaders = ["Line id", ...visibleColumns.map(col => col.headerName).filter((_, index) => visibleColumns[index].field !== "line_id")];

    const numberFields = ["required_qty", "ordered_qty", "unit_price", "discount_amount", "discount_percentage"];

    api.forEachNode((node, rowIndex) => {
        const rowNum = rowIndex + 2; // Excel rows are 1-based index, headers in row 1
        const filteredData = selectedColumns.map((col, colIndex) => {
            let value = node.data[col] ?? "";

            if (numberFields.includes(col) && !isNaN(parseFloat(value)) && value !== "") {
                value = parseFloat(value).toFixed(2);
            }

            // Apply formula dynamically to the 'line_amount' column
            if (col === "line_amount") {
                const lineAmountValue = node.data["line_amount"] ?? 0;
                return {  v: parseFloat(lineAmountValue)?.toFixed(2) , f: `=G${rowNum}*I${rowNum}-(((J${rowNum}*G${rowNum}))+(((G${rowNum}*I${rowNum})-(J${rowNum}*G${rowNum}))*K${rowNum}/100))` };
            }

            return value;
        });

        rowData.push(filteredData);
    });

    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([columnHeaders, ...rowData]);

    // Set column widths for better readability
    worksheet["!cols"] = columnHeaders.map(header => ({ wch: header.length + 5 }));

    // Create and save workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Rate Card Details");

    XLSX.writeFile(workbook, "Rate_Card_Details.xlsx");
};

  const handleFileImport = async (event) => {
    if (!event.target.files.length) return;
    const file = event.target.files[0];
    event.target.value = null;
    if (!file) return;
  
    const importedData = await readExcelFile(file);
    if (!importedData.length) {
      Helper.alert("The imported file is empty.", "warning");
      return;
    }
  
    const transformedData = transformExcelData(importedData);
    const existingLines = getExistingLines();
    const { linesToUpdate, hasMatchedLines } = getLinesToUpdate(transformedData, existingLines);

    if(!hasMatchedLines){
      Helper.alert("Please export data first.", "warning");
      return;
    }
  
    if (!linesToUpdate.length) {
      Helper.alert("No lines found to update.", "warning");
      return;
    }

    if (props?.type === "rfi" && linesToUpdate.some(line => parseFloat(line.line_amount) > parseFloat(props.lineAmount))) {
      Helper.alert(`Line amount of any item should not be greater than ${commonThousandSeparator(props.lineAmount)}.`,"error");
      return;
    }
  
    linesToUpdate.forEach((line) => updateLinesValue(line, line.line_id, true));
  };
  
  // Function to read and parse the Excel file
  const readExcelFile = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const buffer = e.target.result;
        const workbook = XLSX.read(buffer, { type: "buffer" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        resolve(XLSX.utils.sheet_to_json(sheet, { defval: "" }));
      };
    });
  };
  
  // Function to transform imported data
  const transformExcelData = (importedData) => {
    const visibleColumns = GridColumnsHeadingProposalLines.filter(
      (col) => !col.hide && col.field !== "view_tax_transaction"
    );
  
    const columnMap = mapExcelHeaders(visibleColumns, Object.keys(importedData[0]));
  
    return importedData.map((row) => {
      let newRow = {};
      Object.keys(row).forEach((header) => {
        if (columnMap[header]) newRow[columnMap[header]] = row[header];
      });
      return newRow;
    });
  };
  
  // Function to create column mapping
  const mapExcelHeaders = (visibleColumns, excelHeaders) => {
    let columnMap = {};
    visibleColumns.forEach((col) => {
      const excelHeader = col.headerName.trim();
      if (excelHeaders.includes(excelHeader)) {
        columnMap[excelHeader] = col.field === "unit" ? "unit_name" : col.field;
      }
    });
  
    if (!columnMap["Line id"] && excelHeaders.includes("Line id")) {
      columnMap["Line id"] = "line_id";
    }
  
    return columnMap;
  };
  
  // Function to fetch existing AG Grid data
  const getExistingLines = () => {
    let existingLines = [];
    gridObj?.current?.api.forEachNode((node) => {
      existingLines.push(node.data);
    });
    return existingLines;
  };
  
  // Function to compare and find updated lines
  const getLinesToUpdate = (transformedData, existingLines) => {
    let hasMatchedLines = false;
    const linesToUpdate = transformedData
      .map((importedRow) => {
        const existingRow = existingLines.find((line) => line.line_id === importedRow.line_id);
        if (!existingRow) return null;
        hasMatchedLines = true;
        let data = compareRowData(importedRow, existingRow);
        return Object.keys(data).length > 1 ? { ...data, line_id: importedRow.line_id } : null;
      })
      .filter(Boolean);
    return { linesToUpdate, hasMatchedLines };
  };
  
  // Function to compare row data and create the update payload
  const compareRowData = (importedRow, existingRow) => {
    let data = {};
    const fieldsToCheck = ["required_qty", "unit_price", "discount_amount", "discount_percentage"];
    fieldsToCheck.forEach((field) => {
      if (importedRow[field] !== undefined && parseFloat(importedRow[field]).toFixed(2) !== parseFloat(existingRow[field]).toFixed(2)) {
        data[field] =
          field === "discount_percentage"
            ? Helper.getOnlyNumber(importedRow[field])
            : parseFloat(importedRow[field]) || 0;
      }
    });
  
    data.line_amount = parseFloat(
      Helper.getCurrencyForDb(Helper.formateCurrency(getLineViewAmount(importedRow)))
    );
  
    return data;
  };
  
  return (
    <Fragment>
      <div>
        {/* <AgGridMemo
          id='proposal_line_grid'
          header={GridColumnsHeadingProposalLines}
          data={dataRfqLines}
          hideSave={true}
          addBtnHide={true}
          hideDelete={true}
          singleRowSelect={true}
          onCellClicked={onProposalCellClicked}
          onRowValueChanged={onRowValueChanged}
          forceRender={forceRender}
          onSelectionChanged={onSelectionChanged}
          dissableCheckBox={!props?.rfqData?.allow_alternate_lines_on_vendor_response}
        /> */}
        <AgGridNew
          gridId={props.gridId?props.gridId:'proposal_line_grid'}
          columnDefs={GridColumnsHeadingProposalLines}
          key={forceRender}
          apiUrl={props.apiUrl ? props?.apiUrl : Settings.apiPurchaseOrderUrl+`/lines/${props.rfq_vendor_id}/${props.rfqData?.source_id || Settings.source_id?.RfqVendorReply}`}
          hideDeleteBtn={true}
          hideAddBtn={true}
          fetchData={handleFetchData}
          onCellClicked={onProposalCellClicked}
          onRowValueChanged={onRowValueChanged}
          onSelectionChanged={onSelectionChanged}
          rowType={'single'}
          onGridReady={handleGridReady}
          isDisabledCheckbox={!props?.rfqData?.allow_alternate_lines_on_vendor_response}       
          afterDeleteBtns={props.type==="rfi"&&<ImportExportExcel onExportClick={exportToExcel} onImportClick={handleFileImport} isEditable={props.isEditable} />}     
        />
        {showModal && (
          <RFQAttachmentPopup
            closeModal={closeModal}
            data={rfqAttachments}
            setRfqAttachment={setRFQAttachments}
            onAttachmentUpload={()=>{get_rfq_attachments(lineId,80)}}
            language={props.language}
            type={alternativeLines?"line view":""}
            style={{
              top: popUpPosition.top,
              right: popUpPosition.right,
            }}
            source_id={alternativeLines?80:props.rfqData?.source_id}
            attachmentsource_integrator={lineId}
            attachmentsource_id={attachmentSourceId}
            allowEdit={
              props.rfqData?.interested_to_participate === false ||
              props.rfqData?.interested_to_participate === null ||
              props.allFieldsAreZero === true ||
              props.type === 'versions' ||
              props.bidStatus || !alternativeLines
            }
          />
        )}
      </div>

    {showTaxPopup && lineTaxId && <TaxTransactionPopup onTaxTransactionPopupClose = {onTaxTransactionPopupClose} line_id={lineTaxId} source_id={props.rfqData?.source_id} integrator={props.rfq_vendor_id}/> }

     {props?.type==="rfi"?<></>:props.rfqData?.allow_alternate_lines_on_vendor_response &&
        !props?.rfqData?.require_full_quantity && !objectIsEmpty(selectedRow) && <ToggleSwitch
        margin={'mb-4 mt-3'}
        titlePosition={TitlePosition.right}
        value={isLineProposed}
        onChange={onSwitchValueChanged}
        title='Do you want to propose any alternate item/service then requested in this proposal?'
      />}

      <div className='rs_bidding_proposal_grid'></div>
      {props?.type==="rfi"?<></>:isLineProposed && !objectIsEmpty(selectedRow) &&(
          <LineViewComponent
            defaultValues={{ requester_id: props.rfqData?.buyer_id }}
            source_id={props.rfqData?.source_id}
            line_source={props.rfqData?.source_id}
            isEditable={
             props.rfqData?.onhold || props.allFieldsAreZero || props?.rfqData?.RFQ_status === 2 || props?.rfqData?.RFQ_status === 9 ? false:( props.rfqData?.interested_to_participate === true ||
              props.rfqData?.vendorreply_status === 1 ||
              props.bidStatus ||
              lineStatus || alternativeLines) 
            }
            header_id={props.rfqData?.RFQ_vendorreply_id}
            purchase_status={null}
            document_status={props.rfqData?.vendor_replystatus}
            delivery_date={props.rfqData?.delivery_date}
            onbehalf_request={false}
            vendor_id={null}
            vendorsite_id={null}
            setOrderStatusProps={null}
            hideDistribution={true}
            hideGeneral={true}
            hideSetup={true}
            hideBillingRule={true}
            alternate_line={true}
            vendorReplyLineId={selectedRow?.line_id}
            onCellClicked={onCellClicked}
            formType="rfq"
            hideAdditionalInformationTab={true}
            lineGridTitle={Helper.getLabel(props.language, 'alternate_lines', 'Alternate Lines')}
            additionalLineColumns={[
              {
                field: 'attached',
                minWidth: 120,
                headerName: Helper.getLabel(props.language, 'attached', 'Attached'),
                cellClass: 'rfq-attached-cell',
                cellRenderer: displayViewAttachedLogo,
              },
            ]}
            hideDistributionInnerTabs={true}
            fetchData={handleAlternateFetchData}
          />
        )}
        {
          rateCardPopup && <RateCardLineDetails setShowPopup={setRateCardPopup} lineId={lineId} rfqData={props.rfqData} isEditable={isEditable} lineAmount={lineAmount} />
        }
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQBiddingProposal)
