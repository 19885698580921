import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../../inc/Api";
import Helper from "../../../inc/Helper";
import Settings from "../../../inc/Settings";
import SimpleLoading from "../../Loading/SimpleLoading";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import AgGridNew from "../../grid/ag/ag-grid-new";
import { getLabelFromDropdown, getNewValue, gridLinkValueFormat } from "../../grid/ag/commonFunctions/GridCommonFunctions";
import SimpleDropdown from "../../grid/ag/cellEditor/SimpleDropdown";
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker"
import AdvancedDropdown from "../../grid/ag/cellEditor/AdvancedDropdown";
import WarehouseCellRenderer from "../../grid/ag/cellRender/WarehouseCellRenderer";
import SiteCellRenderer from "../../grid/ag/cellRender/SiteCellRenderer";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faExclamationTriangle,faHourglassEnd,faTimes } from '@fortawesome/free-solid-svg-icons';
import VendorCommentsPopup from "../../RequestForQuotation/VendorCommentsPopup";

let forceRender = 1
let gridApi = null
class LineDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPRLineSiteDataLoading: false,
      isEntityLoading:false,
      isRequesterLoading:false,
      isSitesLoading: false,
      isDeleting: false,
      sitesList: [],
      entityList: [], 
      warehouseList:[],
      requesterList: [],
      budgetCheckRes: { field_comment: null, openPopup: false }
    };

    this.saveHandler = this.saveHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.budgetCheckCellRenderer = this.budgetCheckCellRenderer.bind(this)
  }

  componentDidMount() {
    this.getSites();
    this.getEntity();
    this.getWarehouse();
    this.getAllEmployees();
  }

  getAllEmployees() {
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isRequesterLoading: true });
    api
      .axios()
      .get(Settings.apiOrgUrl + "/employee_dropdown?status=true")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isRequesterLoading:false,requesterList: res.data?.data });
          forceRender++
        }
      })
      .catch((error) => {
        that.setState({ isRequesterLoading:false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  fetchData(response){
    return {
      rowData:response.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`
      })),
      rowCount: response.data?.data?.sites?.length,
    }
  }

  getSites() {
    let api = Api;
    this.setState({ isSitesLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_site")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isSitesLoading: false, sitesList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isSitesLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getWarehouse() {
    let api = Api;
    this.setState({ isSitesLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_warehouse")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isSitesLoading: false, warehouseList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isSitesLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getEntity() {
    let api = Api;
    this.setState({ isEntityLoading: true });
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/get_entities")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ isEntityLoading: false, entityList: res.data?.data });
        }
      })
      .catch((error) => {
        that.setState({ isEntityLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getSumOfExistingQty(linesite_id) {
    const sum =
      this.props.sites?.length > 0
        ? this.props.sites.reduce((sum, item) => {
            if (item.linesite_id !== linesite_id) {
              return sum + item.ordered_qty;
            } else {
              return sum;
            }
          }, 0)
        : 0;
    return sum;
  }

  updateSitesDetails(payload,lineId){
    let api = Api
    api
      .axios()
      .put(
        Settings.apiPurchaseOrderUrl +
        `/linesite/` +
        lineId + "/" + this.props.source_id,
        payload,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          setTimeout(()=>{
            gridApi?.api?.refreshServerSide({purge:true})
          },200)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
  addSitesDetails(payload){
    let api = Api
    api
      .axios()
      .post(
        Settings.apiPurchaseOrderUrl +
        `/linesite`,
        payload,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data?.message, 'success')
          setTimeout(()=>{
            gridApi?.api?.refreshServerSide({purge:true})
            gridApi?.api?.deselectAll();
          },200)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }
  saveHandler(rowItem) {
    const { site_id, requester_id, ordered_qty } = rowItem.data;

    if (!site_id) {
      Helper.alert("Site not selected", "error");
      return;
    }
    if (!requester_id) {
      Helper.alert("Requester not selected", "error");
      return;
    }
    if (!ordered_qty) {
      Helper.alert("Ordered quantity not entered", "error");
      return;
    }
    if (isNaN(ordered_qty)) {
      Helper.alert("Order Quantity Invalid", "error");
      return;
    }

    const qtyDistributed = this.getSumOfExistingQty(rowItem?.data?.linesite_id);

    if (
      Number.parseFloat(ordered_qty) + qtyDistributed >
      Number.parseFloat(this.props.cartItem?.ordered_qty)
    ) {
      Helper.alert(
        "Order Quantity cannot be greater from line order quantity!",
        "error"
      );
      return;
    }
    let updatedData = null;
    let newData = null;
    if (!rowItem.data?.linesite_id) {
      newData = rowItem.data;
    } else {
      updatedData = rowItem.data;
    }
    if (newData) {
      const formattedData = {
        linesite_id: newData.linesite_id ?? -1,
        source_id:this.props.source_id,
        linestatus: 0,
        line_id: this.props.cartItem?.line_id
          ? this.props.cartItem?.line_id
          : null,
        ordered_qty: parseInt(newData.ordered_qty) ?? null,
        delivery_date:
          DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newData.delivery_date)) !== "NaN-NaN-NaN"
            ?  DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newData.delivery_date))
            : null,
        start_date: null,
        end_date: null,
        distributionsource_id: this.props?.cartItem?.distributionsource_id
          ? this.props?.cartItem?.distributionsource_id
          : null,
        requester_id: parseInt(newData.requester_id) ?? null,
        entity_id: parseInt(newData.entity_id),
        site_id: parseInt(newData.site_id) ?? null,
        warehouse_id: parseInt(newData.warehouse_id),
      };

      // if same distribution already exists in to be saved data, return
      let alreadyExists = this.props.sites?.some((site) => {
        return JSON.stringify(formattedData) === JSON.stringify(site);
      });
      if (alreadyExists) return;

      this.addSitesDetails(formattedData)
    }
    if (updatedData) {
      const formattedData = {
        linesite_id: updatedData.linesite_id,
        linestatus: 0,
        line_id: this.props.cartItem?.line_id
          ? this.props.cartItem?.line_id
          : null,
        ordered_qty: parseInt(updatedData.ordered_qty) ?? null,
        delivery_date:
           DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(updatedData.delivery_date)) !== "NaN-NaN-NaN"
            ?  DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(updatedData.delivery_date))
            : null,
        start_date: null,
        end_date: null,
        distributionsource_id: this.props?.cartItem?.distributionsource_id
          ? this.props?.cartItem?.distributionsource_id
          : null,
        requester_id: parseInt(updatedData.requester_id) ?? null,
        entity_id: updatedData?.entity_id,
        site_id: parseInt(updatedData.site_id) ?? null,
        warehouse_id: updatedData?.warehouse_id,
      };
      this.updateSitesDetails(formattedData,rowItem?.data?.linesite_id)    
    }
  }

  async deleteHandler(element) { 
    let api = Api;
    api.setUserToken();
    
    let delete_line_site_id = element?.data?.linesite_id;

    try {
      const res = await api
        .axios()
        .delete( Settings.apiPurchaseOrderUrl + `/linesite/` + delete_line_site_id + "/" + this.props.source_id)
      const rowNode = gridApi?.api?.getRowNode(delete_line_site_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.api?.applyServerSideTransaction(transaction)
      }, 200) 
      gridApi?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  addClickCheckHandler = () => {
    if (
      this.getSumOfExistingQty() ===
      parseFloat(this.props.cartItem?.ordered_qty)
    ) {
      Helper.alert(
        "Either increase cart line's order quantity or decrease distribution site's quantity",
        "error"
      );
      return false;
    }
    return true;
  };

  getBudgetIcon(params){
    if(params?.value?.toLowerCase()==="passed"){
      return faCheck
    }else if(params?.value?.toLowerCase()==="passed_with_warning"){
      return faExclamationTriangle
    }else if(params?.value?.toLowerCase()==="not_performed"){
      return faHourglassEnd
    }else{
      return faTimes
    }
  }

  onCellClickDistributionGrid = (params) => {
    if (params.colDef.field === 'budget_check_response') {
      this.setState((prevState) => ({
        budgetCheckRes: {
          ...prevState.budgetCheckRes, 
          field_comment: params?.data?.budget_check_response === null || params?.data?.budget_check_response === undefined 
            ? "No error found" 
            : JSON.stringify(params?.data?.budget_check_response),
          openPopup: true
        }
      }));
    }
  };
   
  setBudgetCheckRes = (newState) => {
    this.setState({ budgetCheckRes: newState });
  };

  getBudgetColor(params){
    if(params?.value?.toLowerCase()==="passed"){
      return "#3CBA54"
    }else if(params?.value?.toLowerCase()==="passed_with_warning"){
      return "#d83b01"
    }else{
      return "#D73535"
    }
  }

  budgetCheckCellRenderer(params){
    return <div className="d-flex justify-content-center align-items-center">{params.value === null || params.value === undefined ? "-" : <FontAwesomeIcon icon={this.getBudgetIcon(params)} color={this.getBudgetColor(params)} />}</div>
  }

  render() {
    let totalDropdownPageEmployeeItem = 1;
    let select2SettingsEmployee = {
      ajax: {
        url: (params) =>{
          totalDropdownPageEmployeeItem = params.page || 1;
          return `${Settings.apiOrgUrl}/employee_dropdown?status=true`;
        },
        processResults: function (data) {
          let isMorePageExist = ( data.total_records - ( totalDropdownPageEmployeeItem * Settings.dropdownPageSize )) > 0 ? true : false ;
          let result = data?.data?.map( item => {
            return {
              id: item.employee_id,
              text: item.displayname
            }
          })
          return {
            results: result,
            pagination: {
              more: isMorePageExist
            }
          };
        }
      }
    };
    
    let Columns_Headings_Distribution = [
      {
        field: "budget_check",
        headerName: Helper.getLabel(
          this.props.language,
          "budget",
          "Budget"
        ),
        name: "budget_check",
        cellRenderer: this.budgetCheckCellRenderer, 
        editable:false,
      },
      {
        field: "budget_check_response",
        headerName: Helper.getLabel(
          this.props.language,
          "budget_check_error",
          "Budget check error"
        ),
        cellRenderer: (params) => {
          return gridLinkValueFormat(params, 'View budget error')
        }, 
        minWidth: 200,
        name: "budget_check_response", 
        editable:false,
      },
      {
        field: "linesite_id",
        headerName: Helper.getLabel(
          this.props.language,
          "external_reference",
          "External reference"
        ),
        name: "linesite_id",
        editable:false,
      },
      {field:'entity_id',inputId:'entity_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(this.props.language,'entity',"Entity"),cellEditor:SimpleDropdown,cellEditorParams:{values:this.state.entityList.map((item) => {
              return {
                value: item.entity_id,
                label: item.name,
              };
            }),},valueFormatter:(params)=>getLabelFromDropdown(params),editable:!this.props.viewOnly,},
    {
        field:'site_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(this.props.language,'site',"Site"), 
        cellRenderer : SiteCellRenderer,
        inputId:'site_id',
        cellEditor:AdvancedDropdown,
        editable:!this.props.viewOnly,
        cellEditorParams:{
            lynSettings: {
                dependentSelector:['entity_id'],
                apiUrl:Settings.apiUrl +  '/get_site_entity',
                apiRequestMethod:'get',
                options: (apiResponse) => {
                    let output = apiResponse.data.data.map( item => {
                        return {
                            label: `${item.name}`,
                            value: item.site_id
                        }
                    });
                    return output;
                }
            }
        }
    },
    {
        field:'warehouse_id',minWidth: 180,cellDataType:false,headerName:Helper.getLabel(this.props.language,'warehouse',"Warehouse"),
        cellRenderer : WarehouseCellRenderer,
        inputId:'warehouse_id',
        cellEditor:AdvancedDropdown,
        editable:!this.props.viewOnly,
        cellEditorParams:{
            lynSettings: {
                dependentSelector:['site_id'],
                resetDependentSelector:['entity_id'],
                apiUrl:Settings.apiUrl +  '/get_warehouse_site',
                apiRequestMethod:'get',
                options: (apiResponse) => {
                    let output = apiResponse.data.data.map( item => {
                        return {
                            label: `${item.name}`,
                            value: item.warehouse_id
                        }
                    });
                    return output;
                }
            }
        }
    },
      {
        field: "requester_id",
        headerName: Helper.getLabel(
          this.props.language,
          "requester",
          "Requester"
        ),
        cellEditor:SimpleDropdown,
        cellEditorParams:{
          values: this.state.requesterList.map((item) => {
            return {
              value: item.employee_id,
              label: item.displayname,
            };
          }),
        },
        select2Settings:select2SettingsEmployee,
        valueFormatter:(params)=>{return params?.data?.requester_id ? params?.data?.requester_name:getLabelFromDropdown(params)},
        editable:!this.props.viewOnly,
        inputId:'requester_id_grid_distribution'      
      },
      {
        field: "ordered_qty",
        headerName: Helper.getLabel(
          this.props.language,
          "quantity",
          "Quantity"
        ),
        editable:!this.props.viewOnly,
      },
      {
        field: "delivery_date",
        headerName: Helper.getLabel(
          this.props.language,
          "delivery_date",
          "Delivery Date"
        ),
        name: "delivery_date",
        cellEditor:DateEditor,
        editable:!this.props.viewOnly,
        valueFormatter:(params)=> params.value?DatepickerFunctions.convertDateFromDataBase(params.value) || "-": "-"
      },
    ];
    let lineId = this.props.cartItem?.line_id;
    if(this.props.is_rfi_pr){
      Columns_Headings_Distribution = Columns_Headings_Distribution?.filter((item)=>{return item.field !== 'budget_check' && item.field !== 'budget_check_response'})
    }
    return (
      <div className="container-fluid purchase_orders_lines_list_view_distribution_general_grid">
        {this.state.isLoading ||
        this.state.isPRLineSiteDataLoading ||
        this.state.isSitesLoading || this.state.isEntityLoading || this.state.isRequesterLoading || this.props.isDistributionClose ||
        this.state.isDeleting ? (
          <SimpleLoading />
        ) : (
          <AgGridNew
            onAddClickCheck={this.addClickCheckHandler}
            onRowEditingStopped={this.saveHandler}
            handleDeleteSelectedRows={this.deleteHandler}
            apiUrl={Settings.apiPurchaseOrderUrl + "/linesites/" + lineId + `/${this.props.source_id}`}
            fetchData={this.fetchData}
            gridId={
              this.props.gridId ??
              "rs_purchase_requisition_lines_list_view_distribution_general_grid"
            }
            onCellClicked={this.onCellClickDistributionGrid}
            columnDefs={Columns_Headings_Distribution}
            hideFooter={true}
            viewOnly={this.props.viewOnly}
            isAddBtnDisable={this.props.viewOnly}
            isDeleteBtnDisable={this.props.viewOnly}
            uniqueField={'linesite_id'}
            onSelectionChanged={this.props.onSelectionChanged}
            onGridReady={(grid) => {
              gridApi = grid
              this.props.updateGridObj(grid);
            }}
        />
        )}
        { this.state?.budgetCheckRes?.openPopup ? <VendorCommentsPopup applyFormating={true} setStateObj={this.setBudgetCheckRes} stateObj={this.state.budgetCheckRes} /> : '' }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    userData: state.auth.user,
    isDistributionClose: state.prShop.isDistributionClose,
  };
};

export default connect(mapStateToProps)(LineDistribution);
