import React, {useState, useEffect, Fragment, useCallback, useRef} from 'react'
import { connect } from 'react-redux' 
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { generateBaseUrl, getLabelFromDropdown,  gridLinkValueFormat,  statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import EmployeePositionModal from './EmployeePositionModal'

let gridObj= null
const PositionArchive = (props) => { 
  const [positionId, setPositionId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [positionList, setPositionList] = useState([]);
  const [exportLoading, setExportLoading] = useState(false)
  const totalRecords = useRef(0)
  let employeePositionListCalled = false;

  const handleGridReady = (params) => {
    gridObj = params
  }

  useEffect(() => { 
    getPositions();
  }, [])

  const getPositions = () => {
    if(employeePositionListCalled) {
      return;
    }
    let api = Api
    employeePositionListCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+`/get_active_postion`,{}).then(function(res){
      if(res.data.status==="success"){ 
        let UPDATED_STATE = []
        res.data.data.forEach((element) => {
          UPDATED_STATE.push({
          value: element.position_id,
          label: element.name
        })
      })
        setPositionList(UPDATED_STATE) 
      }
    }).catch((res) => {
      getPopupMessageBasedOnStatus(res)
    })
  }
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'code') { 
      setPositionId(event?.data?.position_id);
      setShowCreateModal(true); 
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delEmployeePosition_ID = element?.data?.position_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiOrgUrl+`/position/${delEmployeePosition_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delEmployeePosition_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api?.refreshServerSide({ purge: true })
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const reloadGrid = () => {
    gridObj?.api?.refreshServerSide({ purge: true });
    gridObj?.api?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setPositionId(null);
  };
  
  const Columns_Headings = [
    {field:'code', filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language,'1802002',"Code"), editable: false, cellRenderer: (params) => gridLinkValueFormat(params)},
    {field:'name', filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language,'1803003',"Name"), editable: false},
    {field:'status', headerName: Helper.getLabel(props.language,'1804004',"Status"),  filterParams: { values: [true, false], keyCreator: (params) => params.value, valueFormatter: (params) => params.value ? 'Active' : 'Inactive' }, cellRenderer: (params) => statusLabelValueFormat(params), filter: 'agSetColumnFilter' },
    {
      field:'parent_position_id', 
      headerName: Helper.getLabel(props.language,'1805005',"Parent position"), 
      cellEditor: SimpleDropdown,
      isAllowZero:true,
      cellEditorParams: {
        values: positionList
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agTextColumnFilter',
      editable: false 
    }
  ];

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = `${Settings.apiOrgUrl}/EMPosition`
    const params = ""
    let pagination = false
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current
    
    const baseUrl = generateBaseUrl({ gridObj,apiUrl, params, pagination, defaultAdvanceFilterObj,pageSize })

    const transformData = (data) => {
      return data.map((item) => {
        return {
          ...item,
          parent_position_id: positionList.find((position) => position.value === item.parent_position_id)?.label,
          status: item?.status ? "Active" : "Inactive",
        }
      })
    }

    await exportToXlsx({ url: baseUrl, columns:Columns_Headings, fileName: 'positions_details', additionalFunction: transformData })
    
    setExportLoading(false)
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.setupForms.employee_position.employee_position)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className="employee_position_archive">
      <div className="container-fluid">
        <NavigationHeder hideMoreBtn={true} backUrl="/master-data" title={Helper.getLabel(props.language, "1801001", "Employee Position")}>
          <ButtonNew
            isDisable={!security.canCreate(frontendIds.setupForms.employee_position.employee_position_main_create_button)}
            isActive={true}
            onClick={onClickNewButton}
            title={Helper.getLabel(props.language, "000002", "New")}
          />
        </NavigationHeder>

        <AgGridNew
          apiUrl={`${Settings.apiOrgUrl}/EMPosition`}
          hideDeleteBtn={!security.canDelete(frontendIds.setupForms.employee_position.employee_position_main_delete_button)}
          uniqueField={"position_id"}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={handleGridReady}
          gridId={"rs_employee_position_list"}
          afterDeleteBtns={
            <div className="import_sample_template px-2">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          btnsWrapperStyle="w-100 justify-content-between px-2"
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
        />
      </div>
      {showCreateModal && <EmployeePositionModal reloadParentGrid={reloadGrid} position_id={positionId} setShowCreateModal={setShowCreateModal} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.setupForms.employee_position.employee_position
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(PositionArchive, SecurityOptions))));