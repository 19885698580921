/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Helper from '../../../inc/Helper'; 
import Settings from '../../../inc/Settings';
import FieldsRenderer from './FieldsRenderer';
import { Link } from 'react-router-dom';

const GeneralTab = (props) => {
  //States Dropdowns 
  const [vendorSites, setVendorSites] = useState([])
  //

  useEffect(() => {
    VendorSitesAPI(props?.state?.vendor_id)
  }, [props?.state?.vendor_id])
  //End

  //API Calls Reduction 
  let vendorSitesCalled = false
  //End

  //API Calls
  


  const VendorSitesAPI = (id) => {
    if(vendorSitesCalled || !id) {
      return;
    }
      let api = Api
      vendorSitesCalled = true
      api.setUserToken()
      api.axios().get(Settings.apiVendorUrl+`/vendorsites/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        let VendorSites = []
        res.data.data.forEach(item => {  
        VendorSites.push({
          value: item.vendorsite_id,
          label: item.name
        })
      }) 
      setVendorSites(VendorSites)
      } 
    }).catch((res) => { 
      // Helper.alert(res.response.data.message, 'error')
    })
  }

  //End
 
    let textBoxInputSettings = [ 
        {label:props.type==="invoice on account"?Helper.getLabel(props.language,'invoice_number',"Invoice no."):Helper.getLabel(props.language,'document_number',"Document number"), inputType: "text", disable:props.isDocumentNumberDisable, className:'col-lg-3', name:props.type==="invoice on account"||props.type==="credit notes"?'reference_number':'document_number'},
        {label:Helper.getLabel(props.language,'version',"Version"), inputType: "text", disable:true, className:'col-lg-3', name:'version'},
        {label:Helper.getLabel(props.language,'description',"Description"), inputType: "text", name:'description',disable:!props.isEditable,maxLength:255},
        {label:Helper.getLabel(props.language,'purchase_status',"Purchase status"),  inputType: "text", disable:true, name:'purchase_status_label' ,className:'col-lg-3'},
        {label:Helper.getLabel(props.language,'document_status',"Document status"),  inputType: "text", disable:true, name:'document_status_label' ,className:'col-lg-3'},
        {label:Helper.getLabel(props.language,'attention_info',"Attention information"), inputType: "text", name:'attention_information',disable:!props.isEditable,maxLength:255},
        {mainTitle: 'Vendor', mainClassName: 'vendor', inputBoxData: [
            {label:Helper.getLabel(props.language,'vendor_code_name',"Vendor"), id: 'vendor_id', required: true, options: props.ApiData, inputType: "dropdown",disable:true, className:'col-lg-4', name:'vendor_id',placeHolder:Helper.getLabel(props.language,'select_vendor',"Select vendor")},
            {label:Helper.getLabel(props.language,'vendor_site',"Vendor site"), required: true, options: vendorSites, inputType: "dropdown",disable:!props.isEditable, className:'col-lg-4', name:'vendorsite_id', id: 'vendorsite_id',placeHolder:Helper.getLabel(props.language,'select_vendor_site',"Select vendor site")},       
        ]},
        {mainTitle: 'Document date', mainClassName: 'document_date', inputBoxData: [
            {label:Helper.getLabel(props.language,'document_date',"Document date"), required: true, inputType: "datepicker_new", inputClassName: 'document_date', disable:!props.isEditable, className:'col-lg-4', name:'document_date'},
            {label:Helper.getLabel(props.language,'accounting_date',"Accounting date"), required: true, inputType: "datepicker_new", inputClassName: 'accounting_date', disable:!props.isEditable, className:'col-lg-4', name:'accounting_date'},
            (props.type=== "contract" || props.type==="purchase agreement") &&   {label:Helper.getLabel(props.language,'start_date',"Start date"), inputType: "datepicker_new", inputClassName: 'state_date', disable:!props.isEditable, className:'col-lg-4', name:'start_date',emptyDate:true,required:props.type==="purchase agreement"},
            (props.type=== "contract" || props.type==="purchase agreement") && {label:Helper.getLabel(props.language,'end_date',"End date"), inputType: "datepicker_new", inputClassName: 'end_date', disable:!props.isEditable, className:'col-lg-4', name:props?.type==="purchase agreement"?'expiry_date':'end_date',emptyDate:true,required:props.type==="purchase agreement"} 
        ]},
        {mainTitle: 'Tax information', mainClassName: 'tax_info', inputBoxData: [
            {label:Helper.getLabel(props.language,'vendortax_id',"Vendor tax group"), options: props.taxGroups, inputType: "dropdown", disable:!props.isEditable, className:'col-lg-4', name:'taxgroup_id', id: 'taxgroup_id',placeHolder:Helper.getLabel(props.language,'select_vendor_tax_group',"Select vendor tax group")},
            {label:Helper.getLabel(props.language,'taxgroupitem',"Tax group item"), options: props.taxCodes, inputType: "dropdown", disable:!props.isEditable, className:'col-lg-4', name:'taxgroupitem_id', id:'taxgroupitem_id',placeHolder:Helper.getLabel(props.language,'select_tax_group_item',"Select tax group item")},
            (props.type === "contract" &&  {label:Helper.getLabel(props.language,'vat',"VAT"), options: props.vatTypes, inputType: "dropdown", disable:!props.isEditable, className:'col-lg-4', name:'vat', id:'vat',placeHolder:Helper.getLabel(props.language,'select_vat_type',"Select VAT type"), required:true, disableClear:true} )
        ]}
    ]

    if(props.type==="invoice on account"){
      textBoxInputSettings = textBoxInputSettings.filter(item => item.label !== Helper.getLabel(props.language, 'version', "Version"));
    }
   
  return ( 
     <>
      <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={{...props.state,document_status_label:props.document_status_label,purchase_status_label: props.purchase_status_label}} />
      {props.type==="2" && <div>
        <div className="mt-2 col-12 vendor"><h3 className="mb-3">Purchase agreement details</h3></div>
        <div className="col-lg-6"><div className="form-group"><label>Purchase agreement number <span>*</span></label><div className="rs_input_box_wraper"><Link to={`/purchase-agreement/edit/${props?.state?.agreement_id}`} state={{isPrevPath:true}}>{props?.state?.agreement_document_number}</Link></div></div></div>
      </div>}
    </>
      )
}


const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (GeneralTab) 