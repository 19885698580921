import React, { useEffect, useState, Fragment, useCallback } from 'react';
import Helper from '../../../../inc/Helper';
import SimpleLoading from '../../../Loading/SimpleLoading'; 
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings'; 
import CriteriaDetails from './CriteriaDetails';
import $ from 'jquery';
import Collapse from '../../../inc/Collapse';
import { connect } from 'react-redux';
import AddTemplatePopup from './AddTemplatePopup';
import ImportTemplatePopup from './ImportTemplatePopup';
import ViewTeamModule from './ViewTeamModule';  
import MasterDetails from './MasterDetails';
import SectionDetails from './SectionDetails';
import GridResponsiveDropdownMenu from '../../../grid/GridResponsiveDropdownMenu';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { getDisplayNamesFromArray, getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';


// LYNKAZ - Common Component , Master Scoring/Sections/Criterias ::

// RFQ_id? -> existing/individual id at which the user is working, 

// default Object initialization --
let defaultGridObj = null
let defaultGridObjSections = null 

const CommonScoringSections = ({language, RFQ_id, isFieldsEditable=false, collapseTitle, externalMode = false}) => {
    // grid data states / default - dropdown states -
    const [masterGridData, setMasterGridData] = useState([]) 
    const [typeDropdown, setTypeDropdown] = useState([])

    // loading/rendering states - 
    const [loadingSections, setLoadingMasterGridData] = useState(false)

    // criteria/team/sections/master details -
    const [criteriaDetails, setCriteriaDetails] = useState({
        showPopup: false,
        sectionName: null,
        id: null
    })

    const [teamDetails, setTeamDetails] = useState({
        showPopup: false,
        templateName: null,
        id: null
    })

    const [masterDetails, setMasterDetails] = useState({
        masterData: [],
        showPopup: false,
        id: null
    })

    const [sectionDetails, setSectionDetails] = useState({
        showPopup: false,
        technical_score_weight: null,
        financial_score_weight: null,
        id: null
    })

    // templates actions handlers -
    const [template, setTemplate] = useState({
        addTemplatePopup: false,
        importTemplatePopup: false
    })
 
    let typeCalled = false
  
    useEffect(() => { 
        getScoringMasterData() 
        TypeDropdownAPI()
    }, [])
  
    const getScoringMasterData = () => {
        if(!RFQ_id){
            console.log('its returning here',  RFQ_id)
            return;
        } 
        setLoadingMasterGridData(true)
        let api = Api 
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+`/scoringMaster_RFQ/${RFQ_id}`,{}).then(function(res){
            if(res.data.status==="success"){
                getSectionMasterData(res.data.data.scoringmaster_rfq_id)
                .then((data) => {
                    setMasterGridData([{
                        ...res.data.data,
                        sections: data
                    }]) 
                    setLoadingMasterGridData(false) 
                })
                .catch((error) => { 
                    setMasterGridData([{
                        ...res.data.data,
                        sections: []
                    }]) 
                    setLoadingMasterGridData(false) 
                }) 
            }
        }).catch((error) => { 
            setLoadingMasterGridData(false)
            setMasterGridData([])
            console.log(error)
        })
    }

    const getSectionMasterData = (id) => {  
        return new Promise((resolve, reject) => {
            let api = Api
            api.setUserToken()
            api.axios().get(Settings.apiPurchaseRequisitionUrl+`/scoringsections/${id}?for_rfq=True`, {})
            .then((res) => {
                if(res.data.status === "success"){ 
                    resolve(res.data.data)
                } else {
                    reject(new Error('API call failed'))
                }
            })
            .catch((error) => {
                reject(error)
            })
        }) 
    }
  
    // Dropdown Calls --
    
    const TypeDropdownAPI = () => {
        if(typeCalled){
          return;
        } 

        let api = Api
        typeCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/enum/RF_ScoringSection_type`,{}).then(function(res){
            if(res.data.status==="success"){    
                let UPDATED_DATA = []
                res.data.data.forEach(element => {
                    UPDATED_DATA.push({
                        value: element.id,
                        label: element.name
                    })
                })
                setTypeDropdown(UPDATED_DATA) 
            }
        }).catch((error) => {  
            console.log(error)
        })
    }
    
    // -- 

    // actions buttons handlers 
 
    const AddTemplate = () => { setTemplate((prevState) => { return { ...prevState, addTemplatePopup: true }}) }

    const ImportTemplate = () => { setTemplate((prevState) => { return { ...prevState, importTemplatePopup: true }}) }

    const ViewTeam = () => {
        if(!defaultGridObj) return; 
        let selectedItems = defaultGridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert('Please select a section','error')
            return;
        }
        if(!selectedItems[0].scoringmaster_rfq_id){
            Helper.alert( 'Please create a section first then Team','error')
            return;
        } 
        setTeamDetails((prevState) => {
            return {
                ...prevState,
                showPopup: true,
                templateName: selectedItems[0].name,
                id: selectedItems[0].scoringmaster_rfq_id
            }
        })
    }

    const TemplatesActionButtons = () => {
        const DropdownItems = [
            {
                id: 1,
                icon: '/images/icons/add-new.svg',
                name: 'Import template',
                onClick: ImportTemplate,
                disableBeforeClick: isFieldsEditable
            }, 
            {
                id: 2,
                icon: '/images/icons/add-new.svg',
                name: 'Add section',
                onClick: AddSection,
                disableBeforeClick: isFieldsEditable
            },
            {
                id: 3,
                icon: '/images/icons/add-new.svg',
                name: 'Add criteria',
                onClick: AddCriteria,
                disableBeforeClick: isFieldsEditable
            },
            {
                id: 4,
                icon: '/images/icons/eye.svg',
                name: 'View team',
                onClick: ViewTeam,
                disableBeforeClick: isFieldsEditable
            }
        ];
        
        if (externalMode) return '';
        return (
            <Fragment>
                <GridResponsiveDropdownMenu dropdownItems={DropdownItems} />
                <div className='common-grid-responsive-btns'> 
                    <button disabled={isFieldsEditable} className={`rs_h_controller rs_setvalue_btn visible_on_select_item ${isFieldsEditable ? 'ctl-dissable' : ''}`} onClick={ImportTemplate}>
                        <img src='/images/icons/add-new.svg' />
                        <span>Import template</span>
                    </button>

                    <button disabled={isFieldsEditable} className={`rs_h_controller rs_setvalue_btn visible_on_select_item ${isFieldsEditable ? 'ctl-dissable' : ''}`} onClick={AddSection}>
                        <img src='/images/icons/add-new.svg' />
                        <span>Add section</span>
                    </button>

                    <button disabled={isFieldsEditable} className={`rs_h_controller rs_setvalue_btn visible_on_select_item ${isFieldsEditable ? 'ctl-dissable' : ''}`} onClick={AddCriteria}>
                        <img src='/images/icons/eye.svg' />
                        <span>Add criteria</span>
                    </button>
 
                    <button disabled={isFieldsEditable} className={`rs_h_controller rs_setvalue_btn visible_on_select_item ${isFieldsEditable ? 'ctl-dissable' : ''}`} onClick={ViewTeam}>
                        <img src='/images/icons/eye.svg' />
                        <span>View team</span>
                    </button>
                </div>
            </Fragment>
        )
    }

    const AddSection = () => {
        if(!defaultGridObj) return;
        let selectedItems = defaultGridObj.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert( 'Please select a template','error')
            return;
        }
        if(!selectedItems[0].scoringmaster_rfq_id){
            Helper.alert( 'Please create a template first then section','error')
            return;
        } 
        setSectionDetails((prevState) => {
            return {
                ...prevState,
                id: selectedItems[0].scoringmaster_rfq_id,
                financial_score_weight: selectedItems[0].financial_score_weight,
                technical_score_weight: selectedItems[0].technical_score_weight,
                showPopup: true
            }
        })
    }

    const AddCriteria = () => {
        if(!defaultGridObjSections) return;
        let selectedItems = defaultGridObjSections.api.getSelectedRows();
        if(selectedItems.length <=0){
            Helper.alert( 'Please select a section','error')
            return;
        }
        if(!selectedItems[0].scoringsectiondetailrfq_id){
            Helper.alert( 'Please create a section first then criteria','error')
            return;
        } 
        setCriteriaDetails((prevState) => {
            return {
                ...prevState,
                showPopup: true,
                sectionName: selectedItems[0].name,
                id: selectedItems[0].scoringsectiondetailrfq_id
            }
        })
    }

    const ActionButton = () => {
        if (externalMode) return '';
        return (
            <button disabled={isFieldsEditable} className={`rs_h_controller rs_header_controller visible_on_select_item ${isFieldsEditable ? 'ctl-dissable' : ''}`} onClick={AddTemplate}>
                <img src='/images/icons/add-new.svg' />
                <span className='add_scoring_template'>Add template</span>
            </button>
        )
    }

    // --- 

    let dataSource = {
        getRows: async function (params) {
          params?.success({
                rowData: masterGridData?.map((item) => ({
                    ...item,
                    gridId: `${Date.now()}_${getNewValue()}`
                })),
                rowCount: masterGridData?.length
            })
        }
    };

    const onGridReady = (params) => { 
        if(params) {
            defaultGridObj = params
            setTimeout(()=>{ 
                params?.api?.setGridOption('serverSideDatasource', dataSource);
            }, 200)
        } 
    };

    const onGridReadySections = (grid) => { defaultGridObjSections = grid };

    const onSelectionChangedMasterTemplate = (event) => {
        let childGridSelectedRows = defaultGridObjSections?.api?.getSelectedRows();
        let selectedRows = event?.api?.getSelectedRows();  
        
        if(selectedRows.length <=0 || !selectedRows[0].scoringmaster_rfq_id){ 
            if(childGridSelectedRows?.length !== 0) {
                defaultGridObj?.api?.forEachNode((node) => node.setSelected(true))  
            }
            if(!isFieldsEditable) {
                $('.rs_setvalue_btn').addClass('ctl-dissable');
                $('.visible_on_select_item').addClass('ctl-dissable');
            } 
        } else { 
            if(!isFieldsEditable) { 
                $('.rs_setvalue_btn_crt').removeClass('ctl-dissable');
                $('.visible_on_select_item_crt').removeClass('ctl-dissable');
            } 
        }
    }

    const TemplateCellClicked = (event) => {
        if(event.colDef.field === 'scoringmaster_rfq_id' && event.data.scoringmaster_rfq_id){
            setMasterDetails((prevState) => {
                return {
                    ...prevState,
                    id: event.data.scoringmaster_rfq_id,
                    showPopup: true,
                    masterData: [event.data]
                }
            })
        } 
    }
 
    const onSelectionChangeForSections = (event) => { 
        let parentGridSelectedRows = defaultGridObj?.api?.getSelectedRows();
        let selectedRows = event.api.getSelectedRows();

        if(selectedRows.length <=0 || !selectedRows[0].scoringsectiondetailrfq_id){ 
            if(!isFieldsEditable) {
                $('.rs_setvalue_btn').addClass('ctl-dissable');
                $('.visible_on_select_item').addClass('ctl-dissable');
            } 
        }else {
            if(parentGridSelectedRows?.length === 0) {
                defaultGridObj?.api?.forEachNode((node) => node.setSelected(true))
            }
            if(!isFieldsEditable) {
                $('.rs_setvalue_btn_crt').removeClass('ctl-dissable');
                $('.visible_on_select_item_crt').removeClass('ctl-dissable');
            } 
        }  
    }

    const valueFormatForSectionType = (params) => {
        return `${params.value == 0 ? 'Technical' : 'Financial'}`
    };

    const GridColumnsMaster = [
        {field:'name', filter: 'agTextColumnFilter', cellRenderer: 'agGroupCellRenderer' , headerName:Helper.getLabel(language,'name',"Name*"), editable:false},
        {field:'financial_score_weight', filter: 'agNumberColumnFilter', editable: false, headerName:Helper.getLabel(language,'financial_score_weight',"Financial score weight*")},
        {field:'technical_score_weight', filter: 'agNumberColumnFilter', headerName:Helper.getLabel(language,'technical_score_weight',"Technical score weight*"), editable: false},
    ];
    if (!externalMode && !isFieldsEditable) GridColumnsMaster.push( {field:'scoringmaster_rfq_id', filter: false, sortable:false, headerName: '', cellRenderer: () => gridLinkValueFormat(null, 'Edit template'), editable:false} );

    const detailColumnDefs = [
        { field: 'name', filter: 'agTextColumnFilter', editable: false, cellRenderer: 'agGroupCellRenderer', checkboxSelection:true, headerName: Helper.getLabel(language, 'name', "Name*"), editable: false },
        { field: 'sequence', filter: 'agNumberColumnFilter', editable: false, headerName: Helper.getLabel(language, 'sequence', "Sequence*") },
        { field: 'weight', filter: 'agNumberColumnFilter', editable: false, headerName: Helper.getLabel(language, 'weight', "Weight") },
        {
            field:'section_type', 
            cellRenderer: (params) => getDisplayNamesFromArray(params, typeDropdown, 'value', 'label'),
            headerName:Helper.getLabel(language,'type',"Type"), 
            editable:false,
            valueFormatter: valueFormatForSectionType,
            filterParams: {
              values: (params) => getOptions(params, typeDropdown), 
              keyCreator: (params) => optionsKeyCreator(params, 'value'), 
              valueFormatter: params => params.value.label 
            }, 
            filter: 'agSetColumnFilter'
        }
    ]; 

    // 3D Grid Settings -

    let masterSettings = {
        detailRowAutoHeight: true,
        masterDetail: true,
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: detailColumnDefs,
            defaultColDef: {
              sortable: true,
              flex: 1,
            },
            masterDetail: true,
            detailRowAutoHeight: true,
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        { field: 'sequence', filter: 'agNumberColumnFilter', editable: false, headerName: 'Sequence*', checkboxSelection:true },
                        { field: 'name', filter: 'agTextColumnFilter', editable: false, headerName: 'Name*' },
                        { field: 'description', filter: 'agTextColumnFilter', editable: false, headerName: 'Description' },
                        { field: 'comments_mandatory', filter: 'agSetColumnFilter', cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', cellRendererParams: { disabled: true }, editable: false, headerName:'Comments mandatory',
                            filterParams: { 
                                values: [true, false], 
                                keyCreator: (params) => params.value, 
                                valueFormatter: (params) => params.value ? 'true' : 'false' 
                            } 
                        }
                    ],
                    defaultColDef: {
                        sortable: true,
                        flex: 1,
                    }
                },
                getDetailRowData: function (params) { 
                    params.successCallback(params?.data?.criteria);
                },
                template: '<div style="background-color: #FDF4E7; padding: 0px; box-sizing: border-box;">' +
                '  <div style="color:#2D74E0; padding-top: 10px; margin-left: 15px; padding-bottom: 10px; font-size: 18px; font-weight: 600;">Criteria</div>' +
                '  <div ref="eDetailGrid" style=" "></div>' +
                '</div>'
            },
                onSelectionChanged: onSelectionChangeForSections,
                onGridReady: onGridReadySections 
            },

            getDetailRowData: function (params) {
                let Updated_sections = []
                params?.data?.sections?.forEach((item) => {
                    Updated_sections.push({
                        ...item,
                        financial_score_weight: params.data.financial_score_weight,
                        technical_score_weight: params.data.technical_score_weight
                    })
                })
                params.successCallback(Updated_sections);
            }
        },
    };

    // ---

    // API executions - Grid - Sections
 
    const DeleteHandlerSection = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        let delCommonScoring_ID = element?.data?.scoringmaster_rfq_id;
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/scoringMaster/${delCommonScoring_ID}?for_rfq=True`)
            const rowNode = defaultGridObj?.api?.getRowNode(delCommonScoring_ID)

            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                defaultGridObj?.api?.applyServerSideTransaction(transaction)
            }, 200)
 
            defaultGridObj?.api?.refreshServerSide({ purge: true });
            defaultGridObj?.api?.deselectAll();
            getScoringMasterData();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
          getPopupMessageBasedOnStatus(err)
        }
    }, []);
 
    // -----

    return (
        <div className='section_criterias_common'>  
            <Collapse open={true} title={collapseTitle}>
                <div className='grid_scoring_section'>
                    { loadingSections ? <SimpleLoading /> : 
                        <AgGridNew 
                            // isDisabledCheckbox={isFieldsEditable}
                            hideAddBtn={true} 
                            allBtnHide={externalMode} 
                            isAddBtnDisable={isFieldsEditable}
                            isDeleteBtnDisable={isFieldsEditable}
                            hideDeleteBtn={false}
                            onSelectionChanged={onSelectionChangedMasterTemplate}
                            handleDeleteSelectedRows={DeleteHandlerSection} 
                            rowType={'single'} 
                            uniqueField={'scoringmaster_rfq_id'}
                            gridId={'rfq_scoring_section_grid'}
                            columnDefs={GridColumnsMaster}
                            apiUrl={null} 
                            masterSettings={masterSettings} 
                            height={500}
                            onGridReady={onGridReady} 
                            beforeDeleteBtns={<ActionButton/>} 
                            afterDeleteBtns={<TemplatesActionButtons />}
                            pagination={false} 
                            onCellClicked={TemplateCellClicked}
                        />
                    }
                </div>
            </Collapse>

            {/* Detailed View Components */}
            { criteriaDetails.showPopup && <CriteriaDetails sectionName={criteriaDetails.sectionName} rfqMode={true} reloadGrid={getScoringMasterData} setCriteriaDetails={setCriteriaDetails} scoringsectiondetail_id={criteriaDetails.id} /> }
            { teamDetails.showPopup && <ViewTeamModule templateName={teamDetails.templateName} reloadGrid={getScoringMasterData} setTeamDetails={setTeamDetails} scoringmaster_id={teamDetails.id} /> }
            { masterDetails.showPopup && <MasterDetails gridData={masterDetails.masterData} reloadGrid={getScoringMasterData} setMasterDetails={setMasterDetails} masterscoring_id={masterDetails.id} /> }
            { sectionDetails.showPopup && <SectionDetails financial_weight={sectionDetails.financial_score_weight} technical_weight={sectionDetails.technical_score_weight} reloadGrid={getScoringMasterData} setSectionDetails={setSectionDetails} scoringmaster_id={sectionDetails.id} /> }

            {/* Template View Components */}
            { template.addTemplatePopup && <AddTemplatePopup reloadScoringGrid={getScoringMasterData} rfq_id={RFQ_id} closePopup={setTemplate} /> }
            { template.importTemplatePopup && <ImportTemplatePopup rfq_id={RFQ_id} reloadScoringGrid={getScoringMasterData} closePopup={setTemplate} /> }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

export default connect(mapStateToProps) (CommonScoringSections);