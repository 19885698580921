import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { connect } from 'react-redux'
import Api from '../../../../inc/Api'
import '../../Purchase Order/ExtPO.css'
import { useNavigate, useLocation } from 'react-router-dom'
import Helper from '../../../../inc/Helper'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ButtonNew from '../../../inc/ButtonNew'
import WorkConfirmationApiCalls from '../CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import Settings from '../../../../inc/Settings'
import MasterComonent from '../../../Backend/MasterComonent'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation'
import getWcColumns from './WcColumnsHeader'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import { generateBaseUrl } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'
import { transformObjectWorkConfirmation } from '../../../../inc/TransformerFunctions'

let gridObj = null

const WorkConfirmationList = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const workConfirmationApiCalls = new WorkConfirmationApiCalls()

  const [loading, setLoading] = useState(false)
  const totalRecords = useRef()
  const [exportLoading, setExportLoading] = useState(false)
  const [shTypes, setShTypes] = useState([])
  const [documentStatus, setDocumentStatus] = useState([])

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchaseorder_id = getSearchParam('purchaseorder_id')
  const status = getSearchParam('status')
  const createdBy = getSearchParam('createby')

  const getAsyncData = async () => {
    setLoading(true)
    const shtypes = await workConfirmationApiCalls.getAllShType()
    const documentStatus = await workConfirmationApiCalls.getAllShDocumentStatus()
    if (documentStatus) setDocumentStatus(documentStatus)
    if(shtypes) setShTypes(shtypes)
    if (documentStatus) setLoading(false)
  }

  const getAPIUrl = () => { 
    const shtype = shTypes?.find((item) => item.name === 'Work Confirmation')
    let url = Settings.apiPurchaseOrderUrl + `/sh_headers/${shtype?.id}`;
    return url;
  };

  const getAdditionalParams = () => {
    let addParams
    if(status){
      addParams = `&status=${status}`
    }
    if (purchaseorder_id) {
      addParams = `&purchaseorder_id=${purchaseorder_id}`
    }
    if(createdBy){
      addParams = `&createdby=${createdBy}`
    } 
    return addParams
  }

  useEffect(() => {
    getAsyncData()
  }, [location?.search])

  const cellHandler = (event) => {
    if (event.colDef.field === 'receiving_reference_number') {
      navigate(
        `/work-confirmation-details?shipment_id=${event.data?.shipment_id}`,
        {state:purchaseorder_id !== null ? {document_number:location?.state?.document_number,type:'Contract'}:''}
      )
    }
    if (event.colDef.field === 'document_number' && !purchaseorder_id) {
      let backUrlState;

      if(status){
        backUrlState = `/work-confirmation-list?status=${status}`;
      } else if(createdBy){
        backUrlState = `/work-confirmation-list?createby=${createdBy}`;
      } else {
        backUrlState = `/work-confirmation-list`;
      }

      if(props?.auth?.user?.usertype===0){
        if(event.data.purchaseorder_type===1){
          navigate(`/contract-order/edit/${event.data?.purchaseorder_id}`, { state: { prevRoutePath: backUrlState } }) 
        } 
        return;
      }

      event.data.purchaseorder_type === 1 ? navigate(
        `/contract-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&shipment_id=${event.data?.shipment_id}`
      )
      : navigate(
        `/purchase-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&shipment_id=${event.data?.shipment_id}`
      )  
    }
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}`
      )
    }
  }

  const handleGridReady= (params) => {
    gridObj = params
  }

  const Columns_Headings = getWcColumns({
    language:props.language,
    documentStatus:documentStatus,
    applyLinkToDocumentNumberCol: !purchaseorder_id
  })

  const isNewWorkConfirmationAllowed = () => {
    if (!location?.state) {
      return true;
    }

    const purchaseStatus = location.state.purchase_status?.name?.toLowerCase();
    const documentStatus = location.state.document_status?.toLowerCase();

    if ((purchaseStatus === 'open' && documentStatus === 'draft') || (purchaseStatus === 'reopen' && documentStatus==='draft') || (purchaseStatus === 'freeze' && documentStatus==='draft') || (purchaseStatus === 'withdraw freeze' && documentStatus==='draft')) {
      Helper.alert(`New Work Confirmaton creation is not allowed because your ${location.state.document_name} document is in draft state.`, "error");
      return false;
    } else if (purchaseStatus === 'close' || purchaseStatus === 'hold' || purchaseStatus === 'canceled' || purchaseStatus === 'amendment in process') {
      Helper.alert(`New Work Confirmation creation is not allowed because your ${location.state.document_name} document is ${purchaseStatus.replace("/_/g","_")}.`, "error");
      return false;
    }
    return true;
  };

  const handleNewButtonClick = () => {
    if(isNewWorkConfirmationAllowed()){
      purchaseorder_id?navigate(`/work-confirmation/new?purchaseorder_id=${purchaseorder_id}`):navigate(`/work-confirmation/new`)
      localStorage.removeItem('savedSteps')
    }
  }

  const onShipmentHeaderDelete  = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl +
          `/sh_header/${element?.data?.shipment_id}`)
      const rowNode = gridObj?.api?.getRowNode(element.data?.shipment_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      Helper.alert(res?.data?.message, 'success')
      if (res && index === lastIndex) {
        gridObj?.api?.refreshServerSide({ purge: true });
        gridObj?.api?.deselectAll();
      }
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const typeTitle = () => {
    if(parseInt(status)===0){
      return "Open"
    }else if(parseInt(status)===1){
      return "Posted"
    }else if(createdBy){
      return "Created by Me"
    } else {
      return "All"
    }
  }

  const External_Columns_Headers = Columns_Headings.filter((item)=>item.field!=="accounting_date")

  let url = getAPIUrl()
  let additionalParams = getAdditionalParams()

  const renderGuiList = () => { 
    if(props?.auth?.user?.usertype === 0){
      return {
        screen: Gui_id_list.receiving.work_confirmation.work_confirmation_main,
        addBtn: Gui_id_list.receiving.work_confirmation.work_confirmation_main_create_button,
        deleteBtn: Gui_id_list.receiving.work_confirmation.work_confirmation_main_delete_button
      }
    } else {
      return ''
    }
  };

  let security = props?.security;

  if (renderGuiList() !== "" && !security.canView(renderGuiList()?.screen)) {
    return (
      <Fragment>
        <MasterComonent>
          <Alert 
            message="You do not have any access to the following screen, please contact your administrator!" 
            type="danger" 
          />
        </MasterComonent>
      </Fragment>
    );
  };

  let WCBackUrlRoute = location.search === '' ? props.auth?.user?.usertype === 0 ? `/receive` : `/dashboard` : (status || createdBy) ? '/receive' : (status || createdBy) ? '/receive' : -1

  const getTransformedObjForExport = (data) => transformObjectWorkConfirmation(data, documentStatus);

  const exportData = async () => {
    setExportLoading(true);

    const apiUrl = url;
    const params = additionalParams;

    let pagination = true;
    let defaultAdvanceFilterObj = false;
    let pageSize = totalRecords.current;

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
    await exportToXlsx({ url: baseUrl, columns: (props.auth?.user?.usertype === 1 ? External_Columns_Headers : Columns_Headings), fileName: "work_confirmation_details", additionalFunction: getTransformedObjForExport });
    
    setExportLoading(false);
  };

  return (
    <div
      className={
        props.user?.usertype === 0
          ? 'work-confirmation-internal-screen'
          : 'purchase_orders_listing_archive external-theme'
      }
    >
     <MasterComonent>
      <div className="container-fluid">
        <NavigationHeder 
          title={ purchaseorder_id
            ? `<span style="color:#757575;">${Helper.getLabel(
                props.language,
                'po_listing',
                `Contract Orders List > </span><span style="color:#313638"> Contract Order Details : ${location?.state?.document_number || purchaseorder_id}`
              )} > </span>  Work Confirmations List`
            : `Work Confirmations - ${typeTitle()}`}
          backUrl={
            WCBackUrlRoute
          }
          hideMoreBtn={true}
        >
          <ButtonNew
            onClick={handleNewButtonClick}
            isDisable={renderGuiList() !== "" && !security.canCreate(renderGuiList()?.addBtn)}
            title={Helper.getLabel(props.language, 'new', 'New')}
          />
        </NavigationHeder>
        {exportLoading ? <OverlayLoader /> : null}
        {loading ? (
          <SimpleLoading />
        ) : (
          <div> 
           {props.auth?.user?.usertype===1 &&
            <ExtHeaderLine
              title={Helper.getLabel(
                props.language,
                'work_confirmations',
                'Work Confirmations - All',
              )}
            />
            }
             <AgGridNew
                apiUrl={url}
                additionalParams={additionalParams}
                hideDeleteBtn={ (renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn)) }
                filterAdditionalParams={`&sh_type=1${additionalParams||''}`}
                pagination={true}
                columnDefs={props.auth?.user?.usertype===1?External_Columns_Headers:Columns_Headings}
                onGridReady={handleGridReady}
                handleDeleteSelectedRows={onShipmentHeaderDelete}
                onCellClicked={cellHandler}
                uniqueField={'shipment_id'}
                hideAddBtn={true}
                height={500}
                gridId={`wc-listing-page`}
                afterDeleteBtns={
                  <div className="import_sample_template px-2">
                    <button onClick={exportData}>
                      <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                    </button>
                  </div>
                }
                btnsWrapperStyle={`w-100 justify-content-between px-2`}
                fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
              />
          </div>
        )}
      </div>
     </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    user: state.auth.user,
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.receiving.work_confirmation.work_confirmation_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(WorkConfirmationList, SecurityOptions))));