import React, { Fragment, useCallback, useRef, useState, } from 'react' 
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings' 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper' 
import Alert from '../../../inc/Alert'
import ButtonNew from '../../../inc/ButtonNew' 
import NavigationHeder from '../../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import { generateBaseUrl, gridLinkValueFormat, statusLabelValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import VendorReasonModal from '../Create/VendorReasonModal'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'

let gridObj = null;
const ReasonArchive = (props) => { 
  const [vendorReasonId, setVendorReasonId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [exportLoading,setExportLoading] = useState(false)
  const totalRecords = useRef(0)

  const handleGridReady = (params) => {
    gridObj = params
  }
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'description') {
      setVendorReasonId(event?.data?.reason_id);
      setShowCreateModal(true);
    }
  }
  
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delVendorReason_ID = element?.data?.reason_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendorreason/${delVendorReason_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delVendorReason_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api?.refreshServerSide({ purge: true })
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const reloadGrid = () => {
    gridObj?.api?.refreshServerSide({ purge: true });
    gridObj?.api?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setVendorReasonId(null);
  };

  const Columns_Headings = [
    { field: 'description', headerName: Helper.getLabel(props.language,'description',"Description"), editable: false, cellRenderer: gridLinkValueFormat },
    {
      field: 'reason_for_vendor', 
      filter: 'agSetColumnFilter', 
      filterParams: { 
        values: [true, false], 
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'Active' : 'Inactive' 
      },
      cellRenderer: (params) => statusLabelValueFormat(params), 
      headerName: Helper.getLabel(props.language,'status',"Status"),
      editable: false
    },
    {
      field: 'tax_related',
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
      cellRenderer: 'agCheckboxCellRenderer', 
      cellEditor: 'agCheckboxCellEditor', 
      cellRendererParams: { disabled: true },
      editable: false, 
      minWidth: 160,
      valueGetter: (params) => {
          if(!params?.data?.tax_related || !params?.data){
              return false
          }
          else {
              return params.data?.tax_related
          }
      },
      headerName: Helper.getLabel(props.language, "tax_related_reason", 'Tax related reason')
  },
  ];

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiVendorUrl + "/vendorreason"
    const params = ""
    let pagination = false
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize })

    const transformObj = (data) => {
      return data.map((item) => {
        return { ...item, tax_related: item.tax_related ? "YES" : "NO", reason_for_vendor: item.reason_for_vendor ? "YES" : "NO" }
      })
    }

    await exportToXlsx({ url: baseUrl, columns: Columns_Headings, fileName: "vendor_details", additionalFunction: transformObj })
    setExportLoading(false)
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_reason.vendors_reason)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <>
    {exportLoading ? <OverlayLoader /> : null}
    <div className="vendors_reason_archive">
      <div className="container-fluid">
        <NavigationHeder hideMoreBtn={true} backUrl="/master-data" title={Helper.getLabel(props.language, "vendors_reason", "Vendors Reason")}>
          <ButtonNew onClick={onClickNewButton} title={Helper.getLabel(props.language, "new", "New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_reason.vendors_reason_main_create_button)} />
        </NavigationHeder>

        <AgGridNew
          apiUrl={Settings.apiVendorUrl + "/vendorreason"}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.vendor_reason.vendors_reason_main_delete_button)}
          uniqueField={"reason_id"}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={handleGridReady}
          gridId={"rs_vendors_reason_list"}
          afterDeleteBtns={
            <div className="import_sample_template px-2">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          btnsWrapperStyle={`w-100 justify-content-between px-2`}
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
        />
      </div>
      {showCreateModal && <VendorReasonModal reloadParentGrid={reloadGrid} vendor_reason_id={vendorReasonId} setShowCreateModal={setShowCreateModal} />}
    </div>
    </>
  )
};
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_reason.vendors_reason
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(ReasonArchive, SecurityOptions))))