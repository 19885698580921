import React, { useEffect, useState } from "react";
import $ from "jquery";
import Popup from "../../Popup/Popup";
import RFQCommonApiCalls from "./../RFQCommonApiCalls";
import Helper from "../../../inc/Helper";
import { Form } from "react-bootstrap";
import Button from "../../inc/Button";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

const ConvertToModal = ({
  language,
  handleCloseModal,
  selectedDocType,
  setSelectedHeaderID,
  setSelectedDocType,
  handleConvertTo,
  isConverting,
  selectedPRs
}) => {
  const commonApiCalls = new RFQCommonApiCalls();
  const [isLoading, setIsLoading] = useState(true);
  const [targetDocumentTypes, setTargetDocumentTypes] = useState();

  useEffect(() => {
    const getAsyncData = async () => {
      try {
        const targetDocTypes = await commonApiCalls.getTargetDocumentTypes();
        if (targetDocTypes) {
          let targetDocTypeData = targetDocTypes
          setIsLoading(false);
          if (selectedPRs[0]?.is_rfi_pr){
            targetDocTypeData = targetDocTypeData.filter((item)=>{return (item?.name?.toLowerCase() === "request for information")});
          }else if(selectedPRs[0]?.is_ro_pr){
            targetDocTypeData = targetDocTypeData.filter((item)=>{return (item?.name?.toLowerCase() === "release order")});
          }else{
            targetDocTypeData = targetDocTypeData.filter((item)=>{return (item?.name?.toLowerCase() !== "request for information" && item?.name?.toLowerCase() !== "purchase agreement" && item?.name?.toLowerCase() !== "release order")});
          }
          setTargetDocumentTypes(targetDocTypeData);
        }
      } catch (err) {
        setIsLoading(false);
      }
    };
    getAsyncData();
  }, []);

  return (
    <>
    <Popup
      autoOpen={true}
      width="900px"
      className="rf_radio_form_modal"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setSelectedHeaderID(null);
        setSelectedDocType(null);
        handleCloseModal();
      }}
    >
      {/* {isLoading || isConverting ? (
       <></>
      ) : ( */}
        <>
          <h5>
            {Helper.getLabel(
              language,
              "select_document_type_to_convert",
              "Select document type to convert"
            )}
          </h5>
          <Form>
            {targetDocumentTypes?.map((docType, index) => (
              <div key={docType?.id}>
                <label
                  htmlFor={`convert_to_modal_option_${index}`}
                  className={
                    selectedDocType === docType.id
                      ? "form_check_selected form_check_row"
                      : "form_check_row"
                  }
                >
                  <Form.Check
                    name="select_header_id"
                    type="radio"
                    id={`convert_to_modal_option_${index}`}
                    value={docType?.id}
                    onChange={(e) => {
                      setSelectedDocType(parseInt(e.target.value));
                    }}
                  />
                  <span>{docType?.name}</span>
                </label>
                <hr />
              </div>
            ))}
          </Form>
          <div
            className="addDocumentNumber-body-buttons"
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              isActive={false}
              className="black-btn-style"
              title={Helper.getLabel(language, "Close", "Close")}
              onClick={() => {
                $("html").removeClass("rs_popup_open");
                setSelectedHeaderID(null);
                setSelectedDocType(null);
                handleCloseModal();
              }}
            />
            <Button
              className="solid_btn"
              isActive={true}
              title={Helper.getLabel(language, "select", "Select")}
              onClick={() => {
                handleConvertTo();
              }}
            />
          </div>
        </>
      {/* )} */}
    </Popup>
     {isLoading || isConverting ? <OverlayLoader isLogoCenter={true} /> : null}
    </>
  );
};

export default ConvertToModal;
