import DatepickerFunctions from "../../../../inc/DatepickerHelper"
import Helper from "../../../../inc/Helper"
import { displayViewAttachedLogo, getBidStatus, getOptions, getRecallBidStatus, getStatusNameById, gridLinkValueFormat, gridRFQExpireFormat, optionsKeyCreator } from "../../../grid/ag/commonFunctions/GridCommonFunctions"

const getRfqExternalColumns = ({language, rfqQuotationType, rfqStatusList,type, customDateComparator}) => {
    return [
        {
          field: 'document_number',
          minWidth: 260,
          headerName: type===1 ? Helper.getLabel(
            language,
            'document_number',
            'RFI vendor reply no.',
          ) : Helper.getLabel(
            language,
            'document_number',
            'RFQ vendor reply no.',
          ),
          editable: false,
          cellRenderer: gridLinkValueFormat,
        },
        {
          field: 'RFQ_document_number',
          minWidth: 260,
          headerName:  type===1 ? Helper.getLabel(
            language,
            'request_for_information',
            'RFI document number',
          ) : Helper.getLabel(
            language,
            'request_for_quotation',
            'RFQ document number',
          ),
          editable: false,
        },
        {
          field: 'title',
          minWidth: 300,
          headerName: Helper.getLabel(
            language,
            'document_title',
            'Title',
          ),
          editable: false,
        },
        {
          field:"onhold",
          headerName: Helper.getLabel(language, "on_hold", "On hold"),
          editable: false,
          minWidth: 150,
          cellRenderer:'agCheckboxCellRenderer',
          cellEditor:'agCheckboxCellEditor',
          cellRendererParams: { disabled: true },
          filter: 'agSetColumnFilter',
          filterParams: { 
            values: [true, false], 
            keyCreator: (params) => params.value, 
            valueFormatter: (params) => params.value ? 'true' : 'false' 
          }, 
          valueGetter: (params) => {
            if(!params?.data?.onhold || !params?.data){
                return false
            }
            else {
                return params.data?.onhold
            }
          },
        },
        {
          field: 'vendorreplystatus_external',
          minWidth: 180,
          headerName: Helper.getLabel(language, 'status', 'Status'),
          editable: false,
          cellRenderer: (params) => getStatusNameById(params.value, rfqStatusList),
          filter: 'agSetColumnFilter',
          filterParams: {
            values: (params) => getOptions(params, rfqStatusList),
            keyCreator: (params) => optionsKeyCreator(params, 'id'),
            valueFormatter: (params) => params.value.name,
          },
        },
        {
          field: 'publish_datetime',
          minWidth: 210,
          filter: 'agDateColumnFilter',
          headerName: Helper.getLabel(
            language,
            'publish_date_time',
            'Publish date and time',
          ), 
          valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ,
          
        },
        {
          field: 'expiry_datetime',
          minWidth: 210,
          filter: 'agDateColumnFilter',
          headerName: Helper.getLabel(
            language,
            'expiration_date_time',
            'Expiration date and time',
          ),
          filter:'agDateColumnFilter',
          comparator: customDateComparator,
          cellRenderer: (params) => gridRFQExpireFormat(params),     
        },
        {
          field: 'quotation_type',
          minWidth: 170,
          headerName: Helper.getLabel(language, 'bid_type', 'Bid type'),
          editable: false,
          cellRenderer: (params) => getStatusNameById(params.value, rfqQuotationType),
          filter: type==="auction"?false:'agSetColumnFilter',
          sortable: type!=="auction",
          filterParams: {
            values: (params) => getOptions(params, rfqQuotationType),
            keyCreator: (params) => optionsKeyCreator(params, 'id'),
            valueFormatter: (params) => params.value.name,
          },
        },
        {
          field: 'interested_to_participate',
          minWidth: 220,
          headerName: Helper.getLabel(
            language,
            'interested_to_participate',
            'Interested to participate',
          ),
          filter:false,
          sortable:false,
          cellRenderer: (params) => getBidStatus(params.value),
        },
        {
          field: 'recall_bid',
          minWidth: 120,
          headerName: Helper.getLabel(language, 'recall_bid', 'Recall bid'),
          cellRenderer: (params) => getRecallBidStatus(params.data.expiry_datetime),
          filter:false,
          sortable:false
        },
        {
          field: 'attached',
          minWidth: 120,
          headerName: Helper.getLabel(language, 'attached', 'Attached'),
          cellClass: 'rfq-attached-cell',
          cellRenderer: displayViewAttachedLogo,
          filter:false,
          sortable:false
        },
        {
          field: 'accounting_date',
          minWidth: 200, 
          headerName: Helper.getLabel(
            language,
            'accounting_date',
            'Accounting date',
          ),
          editable: false,
          filter:'agDateColumnFilter',
          valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) , 
          hide:true
        },
        {
          field:'discount_amount',
          minWidth:200,
          hide:true,
          headerName: Helper.getLabel(
            language,
            'discount_amount',
            'Discount amount',
          ),
          filter:'agNumberColumnFilter',
          editable:false
        },
        {
          field:'discount_percentage',
          minWidth:200,
          hide:true,
          filter:'agNumberColumnFilter',
          headerName: Helper.getLabel(
            language,
            'discount_percentage',
            'Discount percentage',
          ),
          editable:false
        },
      ]
}


export default getRfqExternalColumns