import React, { Component } from "react";
import { connect } from "react-redux";

import Helper from "../../../inc/Helper";
import ActionTypes from "../../../inc/ActionsTypes";

import EditGrey from "../../../assets/edit-grey.svg";
import DeleteGrey from "../../../assets/delete-grey.svg";
import DropdownIcon from "../../../assets/dropdown-grey.svg";
import ImageNotFound from "../../../assets/image-not-found.jpg";
import DistributionTabs from "../common/DistributionTabs";
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import RFQAttachmentPopup from "../../External Screens/RFQ Vendor Reply/RFQList/RFQAttachmentPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faCheck, faExclamationTriangle, faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import LinkRateCardPopup from "../../RequestForQuotation/RFQDetailForm/LinkRateCardPopup";

class CartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
      quantity: 0,
      material_service_not_included:this.props.cartItem?.material_service_not_included ?? null,
      scope_of_work:this.props.cartItem?.scope_of_work ?? null,
      requirement_description:this.props.cartItem?.requirement_description ?? null,
      showAttachmentPopup:false,
      attachments:[],
      itemCataloguePopup:false
    };

    this.onCreateFirstDistribution = this.onCreateFirstDistribution.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this)
    this.onAttachmentClick = this.onAttachmentClick.bind(this)
    this.onLinkItemCatalogueClick = this.onLinkItemCatalogueClick.bind(this)
    this.onAttachmentClose = this.onAttachmentClose.bind(this)
    this.getPRAttachments = this.getPRAttachments.bind(this)
    this.lines = this.props.lines.bind(this); 
  }
  componentDidMount() {
    this.setState({ quantity: this.props.cartItem.ordered_qty });
    if (
      this.props.selectedCartItems.indexOf(this.props.cartItem.line_id) !== -1
    ) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.selectedCartItems) !==
      JSON.stringify(prevProps.selectedCartItems)
    ) {
      if (
        this.props.selectedCartItems.indexOf(this.props.cartItem.line_id) !== -1
      ) {
        this.setState({ checked: true });
      } else {
        this.setState({ checked: false });
      }
    }
  }

  getPRAttachments(id, source_id) {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + `/get_attachments/${source_id}/${id}?attachment_for=0`)
      .then((res) => { 
        if (res.data.status === "success") {
          let isExist = res?.data?.data?.length > 0 
          this.props.handleAttachmentUpload(this.props.cartItem,isExist)
          this.setState({
            attachments: id !== null ? res.data.data : null
          });
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res);
      });
  };
  checkLineTypeName(id) {
    const type = this.props.lineTypesList?.find((obj) => obj.id === id);
    return type ? type.name : null;
  }

  async onCreateFirstDistribution(source_id,source_integator) {
    let api = Api;
    api.setUserToken();
    try {
      const res = await api.axios().put(
        Settings.apiPurchaseOrderUrl +
          "/linesite/" +
          source_integator + "/" + this.props.source_id,

        { distributionsource_id: source_id }
      );
      if (res) {
        this.setState({ distributionSourceId: source_id });
      }
    } catch (error) {
       getPopupMessageBasedOnStatus(error)
    }
  }

  onAttachmentClick(){
    this.getPRAttachments(this?.props?.cartItem?.line_id,this.props?.is_rfi_pr ?Settings.source_id.pr_rfi_lines:Settings.source_id.pr_lines)
    this.setState((prevState) => ({
      showAttachmentPopup: !prevState.showAttachmentPopup
  }));  
  }

  onAttachmentClose(){
    this.setState((prevState) => ({
      showAttachmentPopup: !prevState.showAttachmentPopup
    }));  
  }

  onLinkItemCatalogueClick(){
    this.setState({
      itemCataloguePopup:true
    })
  }

  getImageUrl(){
    const {
      cartItem,
    } = this.props;
    return cartItem?.images[0]?.image

  }

  getBudgetIcon(){
    const { cartItem } = this.props
    if(cartItem?.budget_check?.toLowerCase()==="passed"){
      return faCheck
    }else if(cartItem?.budget_check?.toLowerCase()==="passed_with_warning"){
      return faExclamationTriangle
    }else if(cartItem?.budget_check?.toLowerCase()==="not_performed"){
      return faHourglassEnd
    }
    else{
      return faTimes
    }
  }

  getBudgetColor(){
    const { cartItem } = this.props
    if(cartItem?.budget_check?.toLowerCase()==="passed"){
      return "#3CBA54"
    }else if(cartItem?.budget_check?.toLowerCase()==="passed_with_warning"){
      return "#d83b01"
    }else{
      return "#D73535"
    }
  }

  getTrimmedName(item) {
    const lineItemName = item?.line_type === 1 ? item?.category_name : item?.comments
    const name =
      item?.line_type === 0 ? item?.item_name : lineItemName ;

    return name?.length > 80 ? name.slice(0, 80) + "..." : name;
  }

  render() {
    const {
      cartItem,
      activeDistribution,
      toggleDistribution,
      handleSelect,
      viewOnly,
    } = this.props;

    return (
      <div className="cartItem-item position-relative">
        <div className="cartItem-content">
          <div className="cartItem-content-check">
            {!viewOnly ? (
              <input
                type="checkbox"
                onClick={(event) => event.stopPropagation()}
                onChange={() => handleSelect([cartItem.line_id])}
                checked={this.state.checked}
              />
            ) : null}
            <img
              src={
                cartItem?.images && cartItem?.images[0]?.image !== null
                  ? this.getImageUrl()
                  : ImageNotFound
              }
              alt="item"
            />
          </div>
          <div className="cartItem-content-info">
            <div className="cartItem-content-info-titlebar">
              <div className="cartItem-content-info-titlebar-title">
                {this.getTrimmedName(cartItem)}
              </div>
              {!viewOnly ? (
                <div className="cartItem-content-info-titlebar-icons align-items-center">
                  {this.props.is_rfi_pr && <div className="position-relative"><button className="border-0 bg-transparent more_options_btn" onClick={this.onLinkItemCatalogueClick}>Link Item Catalogue</button>{cartItem?.rate_card_linked && <div className="orange-attachment-dot" style={{top:'45%',right:"-5px"}}></div>}</div>}
                  {!this.props.is_rfi_pr && <div className="d-flex justify-content-center align-items-center">Budget Status: {(cartItem?.budget_check===null || cartItem?.budget_check===undefined)?" - ":<FontAwesomeIcon icon={this.getBudgetIcon()} color={this.getBudgetColor()} className="ms-1" style={{height:'1.5em'}} />}</div>}
                  <button className="m-0 bg-transparent p-0 border-0 position-relative" onClick={this.onAttachmentClick}>
                    <img src={'/images/icons/attach.svg'} alt='attach' />
                    {cartItem?.attachment_exists && <div className="orange-attachment-dot"></div>}
                  </button>
                  <button onClick={() => {
                      this.props.showOldCartModal(cartItem);
                    }} className="m-0 bg-transparent p-0 border-0">
                  <img
                    src={EditGrey}
                    alt="edit"
                  />
                  </button>
                  <button onClick={() => {
                      this.props.deleteCartItem(cartItem);
                   }} className="m-0 bg-transparent p-0 border-0">
                  <img
                    src={DeleteGrey}
                    alt="delete"
                    style={{height:"20px"}}
                  />
                  </button>
                </div>
              ) :  this.props.editMode && 
              <div className="cartItem-content-info-titlebar-icons align-items-center">
               {this.props.is_rfi_pr && <div className="position-relative"><button className="border-0 bg-transparent more_options_btn" onClick={this.onLinkItemCatalogueClick}>Link Item Catalogue</button>{cartItem?.rate_card_linked && <div className="orange-attachment-dot" style={{top:'45%',right:"-5px"}}></div>}</div>}
               {!this.props.is_rfi_pr && <div className="d-flex justify-content-center align-items-center">Budget Status: {(cartItem?.budget_check===null || cartItem?.budget_check===undefined)?" - ":<FontAwesomeIcon icon={this.getBudgetIcon()} color={this.getBudgetColor()} className="ms-1" style={{height:'1.5em'}} />}</div>}
              <button className="m-0 bg-transparent p-0 border-0 position-relative" onClick={this.onAttachmentClick}>
                <img src={'/images/icons/attach.svg'} alt='attach' />
                {cartItem?.attachment_exists && <div className="orange-attachment-dot"></div>}
              </button>
              <button  
              onClick={() => {
                this.props.showOldCartModal(cartItem);
              }} className="m-0 bg-transparent p-0 border-0">
              <img
              src={EditGrey}
              alt="edit"
            /></button>
            </div>}
            </div>
            <div className="cartItem-content-info-specs">
            <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "line_number",
                    "Line number"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.line_number
                    ? cartItem?.line_number
                    : "-"}
                </span>
            </div>
            <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(this.props.language, "quantity", "Quantity")}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.ordered_qty
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.ordered_qty)
                      )
                    : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "unit_price",
                    "Unit price"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.unit_price
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.unit_price)
                      )
                    : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "item_type",
                    "Item type"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {this.checkLineTypeName(cartItem?.line_type)}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "need_by_date",
                    "Need by date"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.delivery_date ? cartItem?.delivery_date : "-"}
                </span>
              </div>
              <div className="cartItem-content-info-specs-spec">
                <span className="cartItem-content-info-specs-spec-label">
                  {Helper.getLabel(
                    this.props.language,
                    "line_amount",
                    "Line amount"
                  )}
                </span>{" "}
                <span className="cartItem-content-info-specs-spec-value">
                  {cartItem?.line_amount
                    ? Helper.addThousandSeparator(
                        parseFloat(cartItem?.line_amount)
                      )
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="search-filters-seemore"
          onClick={() => {
            this.props.updateActiveCartItem(cartItem);
            toggleDistribution(cartItem);
          }}
          onKeyDown={() => {}}
        >
          <img
            src={DropdownIcon}
            alt="dropdown"
            style={
              this.state.activeDistribution === cartItem?.line_id
                ? { transform: "rotate(180deg)" }
                : {}
            }
          />
          {Helper.getLabel(
            this.props.language,
            "view_distribution",
            "View distribution"
          )}
        </div>
        {activeDistribution === cartItem.line_id ? (
          <DistributionTabs
            viewOnly={this.props.viewLineDistribution?this.props.viewLineDistribution:this.props.currentDocumentStatus !== 0}
            gridId="cart-grids-seg-additional"
            source_id={this.props.source_id}
            onCreateFirstDistribution={this.onCreateFirstDistribution}
            currentDocumentStatus={this.props.currentDocumentStatus}
            type={this.props.type}
            material_service_not_included={this.state.material_service_not_included}
            scope_of_work={this.state?.scope_of_work}
            requirement_description={this.state?.requirement_description}
            isDisable={true}
            is_rfi_pr={this.props.is_rfi_pr}
          />
        ) : null}
       {this.state?.showAttachmentPopup && <RFQAttachmentPopup
          closeModal={this.onAttachmentClose}
          data={this?.state?.attachments}
          language={this?.props?.language}
          type={"line view"}
          source_id={this.props?.is_rfi_pr ? Settings.source_id.pr_rfi_lines:Settings.source_id.pr_lines}
          attachmentsource_integrator={cartItem?.line_id}
          getAttachments={()=>{this.getPRAttachments(cartItem?.line_id,this.props?.is_rfi_pr ?Settings.source_id.pr_rfi_lines:Settings.source_id.pr_lines)}}
          onAttachmentUpload={() => { this.getPRAttachments(cartItem?.line_id,this.props?.is_rfi_pr ?Settings.source_id.pr_rfi_lines:Settings.source_id.pr_lines) }}
          style={{
            top: 65,
            right:50,
          }}
          isPR={true}
          attachmentsource_id={cartItem?.attachmentsource_id}
          allowEdit={viewOnly} 
          />}
        {
           this.state.itemCataloguePopup && <LinkRateCardPopup language={this.props.language} lineId={cartItem?.line_id} setRateCardPopup={(value) => this.setState({ rateCardPopup: value })} sourceId={this.props?.is_rfi_pr ?Settings.source_id.pr_rfi_lines:Settings.source_id.pr_lines} isDisable={viewOnly} onClose={this.lines}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    showOldCartModal: (payload) => {
      dispatch({ type: ActionTypes.SHOW_EDITOLDCART_MODAL, payload });
    },
    updateActiveCartItem: (payload) => {
      dispatch({ type: ActionTypes.UPDATE_ACTIVRCARTITEM, payload });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
