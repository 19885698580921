/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Tabs from '../../Widgets/Tabs/Tabs'
import UserFeed from './UserFeed'
import CommonTabContent from './ExternalUserTab/CommonTabContent'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { useNavigate } from 'react-router-dom'
import SimpleLoading from '../../Loading/SimpleLoading'
import CommonTabCards from './ExternalUserTab/CommonTabCards'

const ContactInfo = ({emailId,phoneNO}) => {
  return (
    <>
      <div className='d-flex align-items-center gap-2 mb-2'>
        <img alt="mail-icon" src="/images/icons/white-mail-icon.svg"  />
        <a href={`mailto:${emailId}`} className='support-info-text'>{emailId}</a>
      </div>
      <div className='d-flex align-items-center gap-2'>
        <img alt="phone-icon" src="/images/icons/phone-white-icon.svg" />
        <a href={`tel:${phoneNO}`} className='support-info-text'>{phoneNO}</a>
      </div>
    </>
  )
}

const HowToTitle = ({text,dotColorStyle}) => {
  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex align-items-center gap-3'>
        <div className={`common-dot-style ${dotColorStyle}`}></div>
        <p className='how-to-text-style'>{text}</p>
      </div>
      <div className=''>
        <img alt="pdf" src="/images/icons/pdf-pink.svg" style={{cursor:"pointer"}}/>
      </div>
    </div>
  )
}

const RightSection = (props) => {
  const [rfqStats, setRfqStats] = useState({})
  const [purchaseOrderData, setPurchaseOrderData] = useState({})
  const [workConfirmationStats,setWorkConfirmationStats]=useState({})
  const [invoiceStats,setInvoiceStats]=useState({})
  const [goodsReceivingStats,setGoodsReceivingStats]=useState({})
  const [releaseOrderStats,setReleaseOrderStats]=useState({})
  const [contractOrderStats,setContractOrderStats]=useState({})
  const [purchaseAgreementStats,setPurchaseAgreementStats]=useState({})
  const [auctionStats,setAuctionStats]=useState({})
  const [rfiStats,setRfiStats]=useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isFeedLoading,setIsFeedLoading]=useState(false)
  const [sources,setSources]=useState([])
  const [feedData,setFeedData]=useState([])
  const [totalFeedRecords,setTotalFeedRecords]=useState(10)
  const [page, setPage] = useState(1);
  const perPage = 10; 
  const [showLoadMore, setShowLoadMore] = useState(false);

  const navigate = useNavigate()
  const displayRequest = true

  const howToData = [
    {
      id:1,
      text:"Submit RFQ",
      dotStyle:"green-dot-style",
      link:props?.url?.tenant?.tenantData?.bid_submission_attachment
    },
    {
      id:2,
      text:"Submit Invoice",
      dotStyle:"yellow-dot-style",
      link:props?.url?.tenant?.tenantData?.invoice_submission_attachment
    },
    {
      id:3,
      text:"Submit Work Confirmation",
      dotStyle:"green-dot-style",
      link:props?.url?.tenant?.tenantData?.work_confirmation_attachment
    },
    {
      id:3,
      text:"Submit Goods Receiving",
      dotStyle:"pink-dot-style",
      link:props?.url?.tenant?.tenantData?.grn_submission_attachment
    },
    {
      id:4,
      text:"Reset Password",
      dotStyle:"blue-dot-style",
      link:props?.url?.tenant?.tenantData?.reset_password_attachment
    },
    {
      id:5,
      text:"Create New User",
      dotStyle:"orange-dot-style",
      link:props?.url?.tenant?.tenantData?.create_new_user_attachment
    },
    {
      id:6,
      text:"Vendor Registration Process",
      dotStyle:"yellow-dot-style",
      link:props?.url?.tenant?.tenantData?.vendor_registration_attachment
    },
    {
      id:7,
      text:"Update Vendor Profile",
      dotStyle:"green-dot-style",
      link:props?.url?.tenant?.tenantData?.profile_update_attachment
    }
  ]

  const commonApiCall = (setState,sourceId,shipmentType,rfqType) => {
    setIsLoading(true)
    let api = Api
    api.setUserToken()
    let apiUrl = shipmentType===0 || shipmentType === 1?Settings.loginUrl + `/dashboard_external/${sourceId}?shipment_type=${shipmentType}`:Settings.loginUrl + `/dashboard_external/${sourceId}`
    if(sourceId === Settings.source_id.RfqVendorReply){
       apiUrl += `?rfq_type=${rfqType}`
    }
    api
      .axios()
      .get(apiUrl)
      .then((res) => {
        setState(res.data.data)
        setIsLoading(false)
      })
  }

  const getRfqStats = () => {
    commonApiCall(setRfqStats,Settings.source_id.RfqVendorReply,null,0)
  }

  const getPurchaseOrdersStats = () => {
    commonApiCall(setPurchaseOrderData,12)
  }

  const getContractOrderStats = () => {
    commonApiCall(setContractOrderStats,Settings.source_id.contract)
  }

  const getWorkConfirmationStats = ()=>{
    commonApiCall(setWorkConfirmationStats,Settings?.source_id.work_confirmation,1)
  }

  const getGoodsReceivingsStats = ()=>{
    commonApiCall(setGoodsReceivingStats,Settings?.source_id?.goods_receiving,0)
  }

  const getReleaseOrderStats = ()=>{
    commonApiCall(setReleaseOrderStats,Settings.source_id.release_order)
  }

  const getInvoiceStats = ()=>{
    commonApiCall(setInvoiceStats,Settings.source_id.invoice)
  }

  const getPurchaseAgreementStats = ()=>{
    commonApiCall(setPurchaseAgreementStats,Settings.source_id.purchase_agreement)
  }

  const getAuctionStats = ()=>{
    commonApiCall(setAuctionStats,Settings.source_id.RfqVendorReply,null,2)
  }

  const getRfiStats = ()=>{
    commonApiCall(setRfiStats,Settings.source_id.RfqVendorReply,null,1)
  }

   //get_sources
   const getSources= () => {  
    let api = Api
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/source`).then(function(res){
      if(res.data.status==="success"){ 
        setSources(res.data.data)
      }
    }) 
  }

  const getFeeds = () => {
    let api = Api
    api.setUserToken()
    if(page===1){
      setIsFeedLoading(true)
    }
    api
      .axios()
      .get(Settings.apiUrl + `/feed?page_number=${page}&page_size=${perPage}`)
      .then((res) => {
        setFeedData((prevData) => {
          const newData = res.data.data.filter(
            (newFeed) => !prevData.some((existingFeed) => existingFeed.feed_id === newFeed.feed_id)
          );
          return [...prevData, ...newData];
        });
        setTotalFeedRecords(res?.data?.total_records)
        setIsFeedLoading(false)
      }).catch((error)=>{
        setIsFeedLoading(false)
      })
  }
  const totalFeedPages = Math.ceil(totalFeedRecords / perPage);

  const loadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    const newTotalItems = totalFeedRecords;
    const newShowLoadMore = newTotalItems > page * perPage;
    setShowLoadMore(newShowLoadMore);
  }, [feedData]);

  useEffect(() => {
    getRfqStats()
    getPurchaseOrdersStats()
    getWorkConfirmationStats()
    getGoodsReceivingsStats()
    getInvoiceStats()
    getReleaseOrderStats()
    getContractOrderStats()
    getSources()
    getFeeds()
    getPurchaseAgreementStats()
    getAuctionStats()
    getRfiStats()
  }, [])

  useEffect(()=>{
    getFeeds()
  },[page])

  let navs = [
    {
      title: Helper.getLabel(
        props.language,
        'request_for_quotation',
        'Request for Quotation'
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'purchase_orders',
        'Purchase Order'
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        'release_orders',
        'Release Order'
      ),
    },
    { title: Helper.getLabel(props.language, 'contract', 'Contract Order') },
    {
      title: Helper.getLabel(
        props.language,
        'purchase_agreement',
        'Purchase Agreement'
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        'goods_receivings_note',
        'Goods Receiving Note'
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        'work_confirmation',
        'Work Confirmation'
      ),
    },
    { title: Helper.getLabel(props.language, 'invoice', 'Invoice') },
    { title: Helper.getLabel(props.language, 'auction', 'Auction') },
    { title: Helper.getLabel(props.language, 'request_for_information', 'Request for Information') },
  ]

  const getPercentage = (data, total) => {
    const percentage = (data / total) * 100
    return percentage
  }

  return (
    <section className='user_feed_area'>
      <div className='container-fluid'>
        <div className='row feed_header'>
          <Tabs navs={navs} showAll={false} component="dashboard" scrollOffset='300'>
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={rfqStats?.total_count}
                totalTitle='Total Request for Quotations'
              >
                 <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={rfqStats?.submitted_record ?? 0}
                  dataDraft={rfqStats?.new_record ?? 0}
                  dataUnderReview={rfqStats?.rejected_record ?? 0}
                  dataPaid={rfqStats?.awarded_record ?? 0}
                  totalCount={rfqStats?.total_count ?? 0}
                  isUnderReview={true}
                  isPaid={true}
                  firstCardStatus="New"
                  secondCardStatus="Submitted"
                  thirdCardStatus="Rejected"
                  fourthCardStatus="Awarded"
                  onTabClickStatus1={()=>navigate('/rfq-vendor-reply/rfq-list?status=1')}
                  onTabClickStatus2={()=>navigate('/rfq-vendor-reply/rfq-list?status=2')}
                  onTabClickStatus3={()=>navigate('/rfq-vendor-reply/rfq-list?status=4')}
                  onTabClickStatus4={()=>navigate('/rfq-vendor-reply/rfq-list?status=6')}
                />  
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={purchaseOrderData?.total_count}
                totalTitle='Total Purchase Orders'
              >
                 <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={purchaseOrderData?.confirmed_record ?? 0}
                  dataDraft={purchaseOrderData?.draft_record ?? 0}
                  dataUnderReview={purchaseOrderData?.under_review ?? 0}
                  totalCount={purchaseOrderData?.total_count ?? 0}
                  isUnderReview={true}
                  onTabClickStatus1={()=>navigate(`/purchase-order-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/purchase-order-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/purchase-order-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={releaseOrderStats?.total_count}
                totalTitle='Total Release Orders'
              >
                <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={releaseOrderStats?.confirmed_record ?? 0}
                  dataDraft={releaseOrderStats?.draft_record ?? 0}
                  dataUnderReview={releaseOrderStats?.under_review ?? 0}
                  totalCount={releaseOrderStats?.total_count ?? 0}
                  isUnderReview={true}
                  onTabClickStatus1={()=>navigate(`/release-order-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/release-order-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/release-order-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={contractOrderStats?.total_count}
                totalTitle='Total Contract Orders'
              >
                <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={contractOrderStats?.confirmed_record ?? 0}
                  dataDraft={contractOrderStats?.draft_record ?? 0}
                  dataUnderReview={contractOrderStats?.under_review ?? 0}
                  totalCount={contractOrderStats?.total_count ?? 0}
                  isUnderReview={true}
                  onTabClickStatus1={()=>navigate(`/contract-order-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/contract-order-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/contract-order-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={0}
                totalTitle='Total Purchase Agreements'
              >
                <CommonTabCards
                   getPercentage={getPercentage}
                   dataConfirmed={purchaseAgreementStats?.confirmed_record || 0}
                   dataDraft={purchaseAgreementStats?.draft_record || 0}
                   dataUnderReview={purchaseAgreementStats?.under_review || 0}
                   totalCount={purchaseAgreementStats?.total_count || 0}
                   secondCardStatus="Confirmed"
                   isUnderReview={true}
                   onTabClickStatus1={()=>navigate(`/purchase-agreement-list?status=0`)}
                   onTabClickStatus2={()=>navigate(`/purchase-agreement-list?status=1`)}
                   onTabClickStatus3={()=>navigate(`/purchase-agreement-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={goodsReceivingStats?.total_count}
                totalTitle='Total Good Receivings Note'
              >
                <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={goodsReceivingStats?.confirmed_record ?? 0}
                  dataDraft={goodsReceivingStats?.draft_record ?? 0}
                  dataUnderReview={goodsReceivingStats?.under_review ?? 0}
                  totalCount={goodsReceivingStats?.total_count}
                  secondCardStatus="Posted"
                  isUnderReview={true}
                  onTabClickStatus1={()=>navigate(`/goods-receiving-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/goods-receiving-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/goods-receiving-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={workConfirmationStats?.total_count}
                totalTitle='Total Work Confirmations'
              >
                <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={workConfirmationStats?.confirmed_record ?? 0}
                  dataDraft={workConfirmationStats?.draft_record ?? 0}
                  dataUnderReview={workConfirmationStats?.under_review ?? 0}
                  totalCount={workConfirmationStats?.total_count}
                  secondCardStatus="Approved"
                  isUnderReview={true}
                  onTabClickStatus1={()=>navigate(`/work-confirmation-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/work-confirmation-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/work-confirmation-list?status=2`)}
                />
              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={invoiceStats?.total_count}
                totalTitle='Total Invoices'
              >
                <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={invoiceStats?.approved_record ?? 0}
                  dataDraft={invoiceStats?.draft_record ?? 0}
                  dataUnderReview={invoiceStats?.under_review ?? 0}
                  dataPaid={invoiceStats?.paid ?? 0}
                  totalCount={invoiceStats?.total_count}
                  isUnderReview={true}
                  isPaid={true}
                  secondCardStatus="Approved"
                  fourthCardStatus="Rejected"
                  onTabClickStatus1={()=>navigate(`/invoice-list?status=0`)}
                  onTabClickStatus2={()=>navigate(`/invoice-list?status=1`)}
                  onTabClickStatus3={()=>navigate(`/invoice-list?status=2`)}
                  onTabClickStatus4={()=>navigate(`/invoice-list?status=3`)}
                />

              </CommonTabContent>
            )}
            {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={rfqStats?.total_count}
                totalTitle='Total Auction'
              >
                 <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={auctionStats?.submitted_record ?? 0}
                  dataDraft={auctionStats?.new_record ?? 0}
                  dataUnderReview={auctionStats?.rejected_record ?? 0}
                  dataPaid={auctionStats?.awarded_record ?? 0}
                  totalCount={auctionStats?.total_count}
                  isUnderReview={true}
                  isPaid={true}
                  firstCardStatus="New"
                  secondCardStatus="Submitted"
                  thirdCardStatus="Rejected"
                  fourthCardStatus="Awarded"
                  onTabClickStatus1={()=>navigate('/auction?status=1')}
                  onTabClickStatus2={()=>navigate('/auction?status=2')}
                  onTabClickStatus3={()=>navigate('/auction?status=4')}
                  onTabClickStatus4={()=>navigate('/auction?status=6')}
                />  
              </CommonTabContent>
            )}
             {isLoading ? (
              <SimpleLoading />
            ) : (
              <CommonTabContent
                displayRequest={displayRequest}
                totalCount={rfiStats?.total_count}
                totalTitle='Total Request for Informations'
              >
                 <CommonTabCards
                  getPercentage={getPercentage}
                  dataConfirmed={rfiStats?.submitted_record ?? 0}
                  dataDraft={rfiStats?.new_record ?? 0}
                  dataUnderReview={rfiStats?.rejected_record ?? 0}
                  dataPaid={rfiStats?.awarded_record ?? 0}
                  totalCount={rfiStats?.total_count}
                  isUnderReview={true}
                  isPaid={true}
                  firstCardStatus="New"
                  secondCardStatus="Submitted"
                  thirdCardStatus="Rejected"
                  fourthCardStatus="Awarded"
                  onTabClickStatus1={()=>navigate('/rfi-vendor-reply/rfi-list?status=1')}
                  onTabClickStatus2={()=>navigate('/rfi-vendor-reply/rfi-list?status=2')}
                  onTabClickStatus3={()=>navigate('/rfi-vendor-reply/rfi-list?status=4')}
                  onTabClickStatus4={()=>navigate('/rfi-vendor-reply/rfi-list?status=6')}
                />  
              </CommonTabContent>
            )}
          </Tabs>
        </div>
     <div className='user-feed-main-grid'>
      <div> 
       {isFeedLoading ? <SimpleLoading /> : <div className='row'>
          {feedData.length>0?feedData?.slice(0, page * perPage).map((item)=>{
            const title = sources?.find((source)=>source.source_id===item.source_id)?.source_name
            return <UserFeed
            key={item.feed_id}
            commentsBoxRequired={true}
            commentsBtnRequired={true}
            entityDetailsRequired={true}
            numberOfComments={item.notes_count}
            sourceName={title}
            fields={item.feed_json}
            item={item}
            setFeedData={setFeedData}
            get_feed_data={getFeeds}
          />
          }):<p className='mt-4 text-center'>No Feed Found.</p>}
        </div>}
        {showLoadMore && feedData.length > 0 && page < totalFeedPages && (
          <div className='d-flex align-items-center justify-content-center mt-2' style={{cursor:"pointer"}}>
            <div className="link-style" onClick={loadMore}>Load More...</div>
          </div>
        )} 
        </div>
        <div className='mt-3'>
          <div className='feed-support-purple d-flex align-items-center flex-column'>
            <img alt="logo" src="/images/logo_white.svg" height={25} className='mb-3' style={{marginLeft:"-5px"}}/>
            <p className='support-title mb-3 text-center'>Say Goodbye to Procurement Stress <br /> with Lynkaz.</p>
            <ContactInfo emailId="inquiry@lynkaz.com" phoneNO="+971 50 456 9042"/>
            <button className='mt-4 get-started-btn'  onClick={() => window.open('https://www.lynkaz.com/', '_blank')}>Get Started</button>
          </div>
          <div className='feed-support-blue'>
            <h6 className='support-title mb-3'>Need Assistance</h6>
            <img alt="profile" src="/images/icons/profile-blue.svg" className='profile-style' />
            <ContactInfo emailId={props?.url?.tenant?.tenantData?.external_portal_support_email} phoneNO={props?.url?.tenant?.tenantData?.external_portal_support_number} />
          </div>
          <div className='how-to-info-main mt-3'>
          <div className='d-flex gap-2 align-items-center'>
            <h6 className='how-to-title'>How to</h6>
            <img src="/images/icons/question-mark.svg" alt="question-icon" />
          </div>
          <p className='how-to-subtitle mt-2'>Step by step guide just for you!</p>
          <div className='d-flex flex-column gap-4'>
            {
              howToData.map((item)=>(
               <a href={item.link} key={item.id} className='link-how-to-title'><HowToTitle text={item.text} dotColorStyle={item.dotStyle} /></a>
              ))
            }
          </div>
        </div>
        </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
    url:state.url
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list,
}

export default connect(mapStateToProps)(
  ApplySecurityRoles(RightSection, SecurityOptions)
)
