import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import NavigationHeder from "../Navigations/NavigationHeder";
import SimpleLoading from "../Loading/SimpleLoading";
import AgGrid from "../grid/ag/ag-grid";
import CustomHtmlCell from "../grid/ag/cellRender/CustomHtmlCell";
import Helper from "../../inc/Helper";
import ButtonNew from "../inc/ButtonNew";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import RFQCommonApiCalls from "../RequestForQuotation/RFQCommonApiCalls";
import CreateRFIModal from "./CreateRFIModal";

const RequestForInformation = (props) => {
  const commonApiCalls = new RFQCommonApiCalls();
  const navigate = useNavigate();
  const [rfqHeaders, setRfqHeaders] = useState([]);
  const [rfqStatusList, setRfqStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateRFIModal, setShowCreateRFIModal] = useState();
  const [employee, setEmployee] = useState([]);
  const [entity, setEntity] = useState([]);
  const [sites, setSites] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [securityLevel, setSecurityLevel] = useState([]);
  const [quotationType, setQuotationType] = useState([]);
  const api = Api;

  useEffect(() => {
    getAsyncData();
    initModalData();
  }, []);

  const getQuotationType = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/enum/RQ_type")
      .then((res) => {
        setQuotationType(res.data.data);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getAllEmployee = () => {
    api
      .axios()
      .get(`${Settings.apiOrgUrl}/employee`)
      .then((res) => {
        if (res.data.status === "success") {
          setEmployee(res.data?.data);
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getAllEntity = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/get_entities")
      .then((res) => {
        if (res.data.status === "success") {
          setEntity(res.data?.data);
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const getSites = async () => {
    const res = await commonApiCalls.getAllSites();
    setSites(res);
  };

  const getWarehouse = async () => {
    const res = await commonApiCalls.getAllWarehouses();
    setWarehouse(res);
  };

  const getSecurityLevel = () => {
    api
      .axios()
      .get(Settings.loginUrl + "/enum/RQ_securitylevel")
      .then((res) => {
        setSecurityLevel(res.data.data);
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  };

  const initModalData = () => {
    api.setUserToken();
    getSites();
    getWarehouse();
    getSecurityLevel();
    getQuotationType();
    getAllEntity();
    getAllEmployee();
  };

  const getAsyncData = async () => {
    try {
      setIsLoading(true);
      const headerData = await commonApiCalls.getAllRfqHeaders();
      const rfqStatusData = await commonApiCalls.getAllRfqStatus();
      if (headerData) {
        setRfqHeaders(headerData);
        setIsLoading(false);
      }
      if (rfqStatusData) setRfqStatusList(rfqStatusData);
    } catch (error) {
      Helper.alert(
        error?.response?.status === 500
          ? Helper.getLabel(
              this.props.language,
              "something_went_wrong",
              "Something went wrong"
            )
          : error?.response?.data?.message,
        "error"
      );
    }
  };

  const cellHandler = (event, navigate) => {
    if (event?.value && event?.colDef?.field === "document_number") {
      navigate(
        "/request-for-quotation/rfqdetailform?rfq_view_id=" +
          event?.data?.RFQ_id +
          "&return=rfqheaderlist"
      );
    }
  };

  const Columns_Headings = [
    {
      field: "document_number",
      headerName: Helper.getLabel(
        props.language,
        "document_number",
        "Document Number"
      ),
      cellRenderer: CustomHtmlCell,
      valueFormatter: (params) =>
        params.value
          ? `<div class="rs_grid_simple_link">${params.value}</div>`
          : "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "rfq_status",
      headerName: Helper.getLabel(props.language, "rfi_status", "RFI status"),
      valueFormatter: (params) => getStatusNameById(params.value),
      editable: false,
      minWidth: 150,
    },
    {
      field: "publish_datetime",
      headerName: Helper.getLabel(
        props.language,
        "publish_date_time",
        "Publish Date Time"
      ),
      valueFormatter: (params) => Helper.convertDateFromDb(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(
        props.language,
        "delivery_date",
        "Delivery Date"
      ),
      valueFormatter: (params) => Helper.convertDateFromDb(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "expiry_datetime",
      headerName: Helper.getLabel(
        props.language,
        "expiry_datetime",
        "Expiry Date Time"
      ),
      valueFormatter: (params) => Helper.convertDateFromDb(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
  ];

  const getStatusNameById = (id) => {
    const status = rfqStatusList.find((option) => option.id === id);
    return status ? status.name : null;
  };

  const createNewRFI = () => {
    setShowCreateRFIModal(true);
  };

  return (
    <div className="items_archive">
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/request"
          title={Helper.getLabel(
            props.language,
            "request_for_information",
            "Request for Information"
          )}
        >
          <ButtonNew onClick={createNewRFI} />
        </NavigationHeder>
        {isLoading ? (
          <SimpleLoading />
        ) : (
          <AgGrid
            id="rs_items_list"
            onCellClicked={(event) => {
              return cellHandler(event, navigate);
            }}
            hideDelete={true}
            dissableCheckBox={true}
            header={Columns_Headings}
            data={rfqHeaders}
            addBtnHide={true}
            hideSave={true}
            viewOnly={true}
          />
        )}
      </div>
      {showCreateRFIModal && (
        <CreateRFIModal
          employee={employee}
          entity={entity}
          sites={sites}
          warehouse={warehouse}
          securityLevel={securityLevel}
          quotationType={quotationType}
          language={props.language}
          setShowCreateRFIModal={setShowCreateRFIModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

export default connect(mapStateToProps)(RequestForInformation);
