import React, {useState, useEffect, Fragment, useCallback} from 'react';
import Conversion from '../../Form Module Layout/Conversion/Conversion';
import './CurrencyConversion.css'; 
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker"; 
import Settings from '../../../inc/Settings';
import Api from '../../../inc/Api';
import NavigationHeder from '../../Navigations/NavigationHeder'; 
import { checkIfArrayIsEmpty, customDateComparator, getPopupMessageBasedOnStatus, isEmptyObject } from '../../../inc/Validation';
import Helper from '../../../inc/Helper'; 
import SimpleLoading from '../../Loading/SimpleLoading';
import { connect } from 'react-redux';
import Gui_id_list from '../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../inc/Alert'; 
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown';
import { getLabelFromDropdown, getNewValue, getOptions, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions';

/* Validations: from date, to date logics, exchange_currency_rate should be not lesser than 0 and it should be integer always */

let gridApiCurrency = null;
let AddBtnCurrency;
let transactionCurrency;

let gridApiExchangeRates = null;
let AddBtnExchangeRates;
let transactionExchangeRates;

const CurrencyConversion = (props) => {
  const [fromCurrency, setFromCurrency] = useState([])
  const [toCurrency, setToCurrency] = useState([])
  
  const [exchangeRatesGrid, setExchangeRatesGrid] = useState([]);
  const [currencyDetails, setCurrencyDetails] = useState({
    from_currency: '',
    to_currency: '',
    id: undefined,
    unit: ''
  })

  const [disableAddBtn, setDisableAddBtn] = useState(true);
  const [loadingExchangeRates, setLoadingExchangeRates] = useState(false);
 
  let currencyCalled = false

  useEffect(() => {  
    get_currencies_list_data()
  }, [])
   
  const get_currencies_list_data = () => {
    if(currencyCalled){
      return;
    }
    let api = Api
    currencyCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
      if(res.data.status==="success"){
        let Currencies_List_API = []
       res.data.data.forEach(item => {  
        Currencies_List_API.push({
          value: item.currency_id,
          label: item.currency_code
        })
       })
       setFromCurrency(Currencies_List_API)
       setToCurrency(Currencies_List_API)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const ClickedCurrencyRates = (data) => {
    let currentNode = data.api.getSelectedNodes();
    setLoadingExchangeRates(true);

    if(checkIfArrayIsEmpty(currentNode) || 
      isEmptyObject(currentNode[0]) || 
      currentNode[0].data.exchange_combination_id == 0 ||
      currentNode[0].data.exchange_combination_id == null
    ) { 
      setDisableAddBtn(true);
      setCurrencyDetails({});
      setExchangeRatesGrid([]);
      setTimeout(() => {
        setLoadingExchangeRates(false);
      }, 200) 
      return;
    }
      setCurrencyDetails(prevState => {
        return {
          ...prevState, 
          to_currency: currentNode[0]?.data?.to_currency, 
          from_currency: currentNode[0]?.data?.from_currency,
          id: currentNode[0]?.data?.exchange_combination_id, 
          unit: '1'
        }
      });
      setExchangeRatesGrid(currentNode[0]?.data?.rates);
      setDisableAddBtn(false);
      setTimeout(() => {
        setLoadingExchangeRates(false);
      }, 200);
  }

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: exchangeRatesGrid?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`
        })),
        rowCount: exchangeRatesGrid?.length
      })
    },
  };

  const onExchangeRatesGridReady = (params) => {
    if(params) {
      gridApiExchangeRates = params?.api;
      setTimeout(()=>{ 
        params?.api?.setGridOption('serverSideDatasource', dataSource);
      }, 200)
    }
  }

  const handleBtnExchangeRate = (value, transactionData) => {
    AddBtnExchangeRates = value
    transactionExchangeRates = transactionData
  }

  const onRowValueChangedExchangeRate = (event) => {  
    if (event?.data?.exchange_rate_id) {
      updateData(event)
    } else {
      addNewData(event)
    }
  }
  
  const updateData = async (event) => {  
    try {
      let payload = {
        exchange_rate: parseFloat(event?.data?.exchange_rate),
        valid_from: DatepickerFunctions.gridDateForDatabase(event?.data?.valid_from) 
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiUrl+`/currency/exchangerate/${event?.data?.exchange_rate_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiCurrency?.refreshServerSide({ purge: true }); 
          gridApiExchangeRates?.refreshServerSide({ purge: true });

          gridApiCurrency?.deselectAll();
          gridApiExchangeRates?.deselectAll();
        }, 100);

        setExchangeRatesGrid([]);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const addNewData = async (event) => {    
    try {
      let payload = [{
        exchangecombination_id: currencyDetails?.id,
        exchange_rate: parseFloat(event?.data?.exchange_rate),
        valid_from: DatepickerFunctions.gridDateForDatabase(event?.data?.valid_from) 
      }];

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+'/currency/exchangerate', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiCurrency?.refreshServerSide({ purge: true }); 
          gridApiExchangeRates?.refreshServerSide({ purge: true });

          gridApiCurrency?.deselectAll();
          gridApiExchangeRates?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtnExchangeRates) {
            gridApiExchangeRates?.applyServerSideTransaction(transactionExchangeRates);
          }
        }, 1000);

        setExchangeRatesGrid([]);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const onDeleteCurrencyExchangeRates = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/currency/exchangerate/${element?.data?.exchange_rate_id}`)
      const rowNode = gridApiExchangeRates?.getRowNode(element?.data?.exchange_rate_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApiExchangeRates?.applyServerSideTransaction(transaction)
      }, 200);

      gridApiCurrency?.refreshServerSide({ purge: true }); 
      gridApiExchangeRates?.refreshServerSide({ purge: true });
      
      gridApiCurrency?.deselectAll();
      gridApiExchangeRates?.deselectAll();

      setExchangeRatesGrid([]); 
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const handleBtnCurrency = (value, transactionData) => {
    AddBtnCurrency = value
    transactionCurrency = transactionData
  }

  const onRowValueChangedCurrency = (event) => {  
    if (!event?.data?.exchange_combination_id) {
      addNewCurrency(event)
    }
  }
 
  const addNewCurrency = async (event) => {  
    try {
      let payload = {
        from_currency_id: event?.data?.from_currency,
        to_currency_id: event?.data?.to_currency
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiUrl+'/currency/exchangecombination', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApiCurrency?.refreshServerSide({ purge: true });
          gridApiCurrency?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtnCurrency) {
            gridApiCurrency?.applyServerSideTransaction(transactionCurrency);
          }
        }, 1000);

        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
  
  const DeleteCurrency = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();

    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/currency/exchangecombination/${element?.data?.exchange_combination_id}`)
      const rowNode = gridApiCurrency?.getRowNode(element?.data?.exchange_combination_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApiCurrency?.applyServerSideTransaction(transaction)
      }, 200);

      gridApiCurrency?.refreshServerSide({ purge: true })
      gridApiCurrency?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
     
  /* Grid Column Headers */

  const gridColumnHeadersConversionRates = [
    {
      field: 'valid_from',
      headerName: Helper.getLabel(props.language, '3600103', 'From Date'),
      filter: 'agDateColumnFilter', 
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateFromDataBase(params?.value)
          : '-'
      },
      editable: true,
      cellEditor: DateEditor,
      minWidth: 150,
      comparator: customDateComparator
    },
    { field:'exchange_rate', filter: 'agNumberColumnFilter', editable:true, headerName: Helper.getLabel(props.language, '3600303', 'Exchange Rate') }
  ];

  const gridColumnHeadersCurrencies = [
    {
      field: 'from_currency',
      headerName: Helper.getLabel(props.language, '3600403', 'From Currency'),
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180,
      cellEditorParams: {
        values: fromCurrency
      },
      editable: (params) => !params?.data?.exchange_combination_id,
      cellDataType: false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter: 'agSetColumnFilter',
      inputId: 'from_currency_drp',
      filterParams: {
        values: (params) => getOptions(params, fromCurrency),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    },
    {
      field: 'to_currency',
      headerName: Helper.getLabel(props.language, '3600503', 'To Currency'),
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180,
      cellEditorParams: {
        values: toCurrency
      },
      editable: (params) => !params?.data?.exchange_combination_id,
      cellDataType: false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter: 'agSetColumnFilter',
      inputId: 'to_currency_drp',
      filterParams: {
        values: (params) => getOptions(params, toCurrency),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    },
    { field:'unit', filter: false, sortable: false, valueFormatter: () => '1', headerName:Helper.getLabel(props.language, '3600603', 'Unit'), editable:false } 
  ];

  if(!props?.security.canView(Gui_id_list.formModules.currency_conversion.currency_conversion_main)){ 
    return <Fragment>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  return ( 
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/setup' title={Helper.getLabel(props.language, '3600703', 'Currency Conversion')} />

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-xl-4 col-lg-5 col-md-12 col-12 mt-2 mb-2 p-0 ps-4 grid-contain'>
            <AgGridNew
              apiUrl={Settings.apiUrl+'/currency/exchangecombination'}
              pagination={false}
              hideAddBtn={!props?.security.canCreate(Gui_id_list.formModules.currency_conversion.currency_conversion_main_add_button)}
              hideDeleteBtn={!props?.security.canDelete(Gui_id_list.formModules.currency_conversion.currency_conversion_main_delete_button)}
              columnDefs={gridColumnHeadersCurrencies}
              rowType="single"
              onSelectionChanged={ClickedCurrencyRates}
              onRowValueChanged={onRowValueChangedCurrency} 
              handleDeleteSelectedRows={DeleteCurrency}
              height={500}
              onGridReady={(params) => gridApiCurrency = params?.api}
              handleAddButton={handleBtnCurrency}
              uniqueField={`exchange_combination_id`}
              gridId={'currency-conversion-grid'}
            />
          </div>
          <div className='col-xl-8 col-lg-7 col-md-12 p-0 pe-2 col-12'>  
            <Conversion>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-12 currency'>
                    <div className='row gy-0 mb-2 mt-2'>
                      <div className='col-10 col-lg-10 col-md-4'>
                        <div className='row'>
                          <div className='col-4'>
                            <label className='mb-3'>{Helper.getLabel(props.language, '3600403', 'From Currency')}</label> 
                            <h3 className='unit-currency'>{currencyDetails?.from_currency}</h3>
                          </div>
                          <div className='col-4'>
                          <label className='mb-3'>{Helper.getLabel(props.language, '3600503', 'To Currency')}</label>
                            <h3 className='unit-currency'>{currencyDetails?.to_currency}</h3> 
                          </div>
                          <div className='col-4'>
                          <label className='mb-3'>{Helper.getLabel(props.language, '3600603', 'Unit')}</label>
                            <h3 className='unit-currency'>{currencyDetails?.unit}</h3>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='rs_currency_conversion_rates_grid'>
                { loadingExchangeRates ? <SimpleLoading /> : 
                  <AgGridNew
                    apiUrl={null}
                    pagination={false}
                    hideAddBtn={!props?.security.canCreate(Gui_id_list.formModules.currency_conversion.currency_conversion_rates_main_add_button)}
                    hideDeleteBtn={!props?.security.canDelete(Gui_id_list.formModules.currency_conversion.currency_conversion_rates_main_delete_button)}
                    columnDefs={gridColumnHeadersConversionRates}  
                    onRowValueChanged={onRowValueChangedExchangeRate}
                    isAddBtnDisable={disableAddBtn} 
                    handleDeleteSelectedRows={onDeleteCurrencyExchangeRates}
                    height={500}
                    onGridReady={onExchangeRatesGridReady}
                    handleAddButton={handleBtnExchangeRate}
                    uniqueField={`exchange_rate_id`}
                    gridId={'currency-conversion-rates-grid'}
                  /> 
                }
              </div>
            </Conversion>
          </div>
        </div>
      </div>
    </Fragment>
  ) 
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.formModules.currency_conversion.currency_conversion_main
};

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(CurrencyConversion, SecurityOptions))));