import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'; 
import NavigationHeder from '../../../Navigations/NavigationHeder'; 
import $ from 'jquery'
import { connect } from 'react-redux'; 
import Helper from '../../../../inc/Helper'; 
import ButtonNew from '../../../inc/ButtonNew';
import Collapse from '../../../inc/Collapse';
import CommonTemplatesPopup from './CommonTemplatesPopup';
import { useNavigate } from 'react-router-dom';
import CustomHtmlCell from '../../../grid/ag/cellRender/CustomHtmlCell';
import MasterComonent from '../../../Backend/MasterComonent';
import GridResponsiveDropdownMenu from '../../../grid/GridResponsiveDropdownMenu';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import { checkIfArrayIsEmpty, exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation';
import TermsHTMLDisplayPopup from './TermsHTMLDisplayPopup';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import { generateBaseUrl, getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';
import Gui_id_list from '../../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../../inc/Alert';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import { transformObjectTermsConditions } from '../../../../inc/TransformerFunctions';

/*

Master & RFQ/PO/CO/RO/PA Level: Terms & Conditions Templates Module,

*/

// default Object initialization --
let defaultGridObjTermsConditions = null; 
let defaultDocumentTypeArray = [];

const TermConditionsTemplates = ({language, security, disableComponent=false, transactional_document_type=[], TransactionalLevel=false, sourceintegration_id, module_source_id}) => {
    const navigate = useNavigate();

    const [allTemplateContentLoading, setAllTemplateContentLoading] = useState(false);

    const [enumDocumentType, setEnumDocumentType] = useState([]);
    const [parentHeadings, setParentHeadings] = useState([]);

    const totalRecordsMasterTermsConditions = useRef();
    const [exportLoadingMasterTermsConditions, setExportLoadingMasterTermsConditions] = useState(false);

    const [addTemplatesDetails, setAddTemplatesDetails] = useState({
        openPopup: false,
        importTemplate: false, 
        editModeOfTemplate: false,
        details: {}
    });

    const [templateContentDetails, setTemplateContentDetails] = useState({
        openPopup: false,
        templateTitle: '',
        templateDetails: []
    })

    const [detailViewContent, setDetailViewContent] = useState({
        openPopup: false,
        content: '',
        content_otherlanguage: '',
        heading_otherlanguage: '',
        title: null
    });
    
    // API Reduction variables

    let getAllTemplatesListingsCalled = false;
    let getAllEnumDocumentTypesCalled = false;

    useEffect(() => {
        getTermsConditionsTemplates();
        getEnumDocumentTypes();
    }, [])

    // API Functions
    const getTermsConditionsTemplates = () => {
        if (getAllTemplatesListingsCalled) return;
        getAllTemplatesListingsCalled = true;
    
        let apiURL = `/tnc_template`;
        if (TransactionalLevel) {
            if(!module_source_id || !sourceintegration_id) {
                Helper.alert('source_id & sourceintegration_id are required!', 'error');
                return;
            }
            apiURL = `/tnc_document_templates/${module_source_id}/${sourceintegration_id}`
        };
      
        let api = Api;
        api.setUserToken();
        api.axios()
            .get(Settings.apiUrl + apiURL, {})
            .then(async function (res) {
                if (res.data.status === "success") { 
                    let promises = res.data.data.map(async (item) => {
                        const getTemplateHeadingData = await getTermsConditionsHeadings(item.tnctemplate_id);
                        return {
                            ...item,
                            headings: getTemplateHeadingData,
                        };
                    });
                      
                    let Updated_response = await Promise.all(promises);
                    const allHeadings = Updated_response.flatMap((item) => item.headings);
                    
                    const dropdownData = allHeadings.map((item) => ({
                        value: item.templateheading_id,
                        label: item.heading,
                    }));

                    setParentHeadings(dropdownData);
                }
            })
            .catch((res) => { 
                getPopupMessageBasedOnStatus(res);
            });
    };
    const getTermsConditionsHeadings = async (template_id) => {
        if (!template_id) return [];

        let apiURL = `/tnc_heading?template_id=${template_id}`;
        if (TransactionalLevel) apiURL += `&for_documents=true`;

        let api = Api;
        api.setUserToken();

        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") { 

                let promises = res.data.data.map(async (item) => {
                    try {
                        const headingsContent = await getTermsConditionsHeadingsContent(item?.templateheading_id);
                        return {
                            ...item,
                            content: headingsContent, 
                            index: parseInt(item?.index),
                        };
                    } catch (error) {
                        console.error("Error fetching heading content:", error);
                        return null; 
                    }
                });  
 
                let Updated_response = await Promise.all(promises);  
                return Updated_response.filter((item) => item !== null);
            }
        } catch (error) {
            console.error("Error fetching headings:", error);
            return [];
        }
    };

    const getTermsConditionsHeadingsContent = async (templateheading_id) => {
        if (!templateheading_id) return []; 
    
        let apiURL = `/tnc_content?templateheading_id=${templateheading_id}`;
        if (TransactionalLevel) apiURL += `&for_documents=true`;
    
        let api = Api;
        api.setUserToken();
    
        try {
            const res = await api.axios().get(Settings.apiUrl + apiURL, {});
            if (res.data.status === "success") { 
                return res.data.data;
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const getEnumDocumentTypes = () => {
        if(getAllEnumDocumentTypesCalled) return;
        getAllEnumDocumentTypesCalled = true;

        let api = Api
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/enum/DocumentTypeTnC`,{}).then(function(res){
            if(res.data.status==="success"){  
                let Updated_data = [];
                res.data.data.forEach((item) => {
                    Updated_data.push({
                        label: item.name,
                        value: item.id+''
                    })
                });
                setEnumDocumentType(Updated_data);  
            }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res);
        });
    };

    // 2D Grid Settings & Header Cols With Functions -

    const displayViewLogo = () => {
        return `<div class="logo_inside_grid"><img src="/images/icons/view.svg" style="height:24px;" /></div>`
    };

    const gridValueFormatterForParent = (params) => {
        let findedParent = parentHeadings?.find(val => val.value == params.value);
        return `${ findedParent ? findedParent.label : '-' }`
    };

    const onContentCellClicked = (event) => {
        if(event.colDef.field === 'content') {  
            setDetailViewContent((prevState) => {
                return {
                    ...prevState,
                    openPopup: true, 
                    content: event?.data?.content, 
                    heading_otherlanguage: event?.data?.heading_otherlanguage,
                    title: event?.data?.heading,
                    content_otherlanguage: event?.data?.content_otherlanguage
                }
            })
        }
    };

    const TermsConditionParentGridHeaderCols = [
        {field:'title', filter: 'agTextColumnFilter', cellClass: !disableComponent ? 'rs_grid_simple_link rfq-link' : '', cellRenderer: 'agGroupCellRenderer' , headerName:Helper.getLabel(language,'title',"Title"), editable:false},
        {field:'description', filter: 'agTextColumnFilter', editable: false, headerName:Helper.getLabel(language,'description',"Description")},
        {
            field: 'document_type', 
            headerName: Helper.getLabel(language,'document_type',"Document type"),
            editable: false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            minWidth: 180,
            cellEditorParams: { values: enumDocumentType },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, enumDocumentType),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label,
            }
        },
        { field: 'all_content', editable: false, headerName: Helper.getLabel(language,'content',"Content"), cellRenderer: CustomHtmlCell, valueFormatter: displayViewLogo }
    ];

    const TermsConditionsChildGridHeaderCols = [
        { field: 'index', cellRenderer: 'agGroupCellRenderer', resizable: true, editable: false, headerName: Helper.getLabel(language,'index',"Index"), },
        { field: 'heading', resizable: true, editable: false, headerName: Helper.getLabel(language,'heading',"Heading") },
        { field: 'parentheading_id', valueFormatter: gridValueFormatterForParent, cellRenderer: CustomHtmlCell, resizable: true, editable: false, headerName: Helper.getLabel(language,'parent',"Parent") }, 
        { field: 'content', editable: false, headerName: Helper.getLabel(language,'content',"Content"), cellRenderer: CustomHtmlCell, valueFormatter: displayViewLogo }
    ];
 
    let masterSettings = {
        detailRowAutoHeight: true,
        masterDetail: true,
        detailCellRendererParams: {
            detailGridOptions: { 
                detailRowAutoHeight: true, 
                columnDefs: TermsConditionsChildGridHeaderCols,
                onCellClicked: onContentCellClicked,
                rowSelection: 'single',
                defaultColDef: {
                    sortable: true,
                    flex: 1,
                }
            },
            getDetailRowData: async function (params) {
                try {
                    let apiURL = `/tnc_heading?template_id=${params?.data?.tnctemplate_id}`;
                    if (TransactionalLevel) apiURL += `&for_documents=true`;
                
                    let api = Api;
                    api.setUserToken();
                    const response = await api.axios().get(Settings.apiUrl + apiURL);
                
                    if (response.status === 200) {
                        const dataWithContent = await Promise.all(response.data.data.map(async (item) => {
                            try {
                                const headingsContent = await getTermsConditionsHeadingsContent(item?.templateheading_id);
                                return {
                                    ...item,
                                    content: headingsContent,
                                    index: parseInt(item?.index),
                                };
                            } catch (error) {
                                console.error("Error fetching heading content:", error);
                                return null;
                            }
                        }));
                 
                        const filteredData = dataWithContent?.filter(item => item !== null);
                 
                        params.successCallback(filteredData);
                    }
                } catch (error) {
                    getPopupMessageBasedOnStatus(error);
                }
            },
            template: '<div style="padding: 10px 30px; box-sizing: border-box;">' + 
            '  <div ref="eDetailGrid"></div>' +
            '</div>'
        },
    };

    // ---

    // Grid related functions/objects,
    
    const onGridReady = (grid) => { defaultGridObjTermsConditions = grid };

    const onSelectionChangedParentTemplate = (event) => {
        let selectedRows = event.api.getSelectedRows();  
        if(selectedRows.length <=0 || !selectedRows[0].tnctemplate_id){
            $('.rs_setvalue_btn').addClass('ctl-dissable');
            $('.visible_on_select_item').addClass('ctl-dissable');
        }else{
            $('.rs_setvalue_btn').removeClass('ctl-dissable');
            $('.visible_on_select_item').removeClass('ctl-dissable');
        } 
    };

    const getAllTemplateHeadingsContent = async (tnctemplate_id) => {
        try {
            const getTemplateHeadingData = await getTermsConditionsHeadings(tnctemplate_id); 
            return getTemplateHeadingData;
        } catch {
            return [];
        }
    };

    const retrieveTemplateDetails = (event) => {
        setAllTemplateContentLoading(true); 
        getAllTemplateHeadingsContent(event?.data?.tnctemplate_id)
        ?.then((apiResponse) => { 
            if(checkIfArrayIsEmpty(apiResponse)) {
                setTemplateContentDetails((prevState) => {
                    return {
                        ...prevState,
                        templateDetails: [],
                        templateTitle: '',
                        openPopup: false
                    }
                });
                Helper.alert('No content available for preview','error');
                setAllTemplateContentLoading(false);
                return;
            };

            setTemplateContentDetails((prevState) => {
                return {
                    ...prevState,
                    templateDetails: apiResponse,
                    templateTitle: event?.data?.title ?? '',
                    openPopup: true
                }
            });
            setAllTemplateContentLoading(false);
        }).catch((errResponse) => { 
            setTemplateContentDetails((prevState) => {
                return {
                    ...prevState,
                    templateDetails: [],
                    templateTitle: '',
                    openPopup: false
                }
            });
            setAllTemplateContentLoading(false);
            getPopupMessageBasedOnStatus(errResponse); 
        });
    };

    const onGridCellClicked = (event) => {
        if (event.colDef.field === 'all_content') { retrieveTemplateDetails(event) };
        
        if(disableComponent) return;

        if (event.colDef.field === 'title') {
            if(!TransactionalLevel){
                defaultDocumentTypeArray = event?.data?.document_type?.map(label => enumDocumentType?.find(type => type?.label === label)?.value) || [];
            }

            setAddTemplatesDetails(prevState => ({
                ...prevState,
                openPopup: true,
                importTemplate: false,
                editModeOfTemplate: true,
                details: event?.data
            }));
        };
 
    };

    const onAddHeadingsClick = () => { 
        if(!defaultGridObjTermsConditions || disableComponent) return;

        let selectedItems = defaultGridObjTermsConditions.api.getSelectedRows();

        let redirect_url = `/add-headings-template/${selectedItems[0]?.tnctemplate_id}`;
        if(TransactionalLevel) redirect_url+=`?transactional=true`;
        
        if(selectedItems.length <=0){
            Helper.alert('Please select a Template','error')
            return;
        };
        if(!selectedItems[0].tnctemplate_id) return;

        navigate(redirect_url); 
    };
    
    const onNewTemplateCreationClick = () => {
        if(disableComponent) return;

        defaultDocumentTypeArray = [];
        setAddTemplatesDetails((prevState) => {
            return {
                ...prevState,
                openPopup: true,
                importTemplate: false,
                editModeOfTemplate: false,
                details: {}
            }
        })
    };

    const onImportTemplateClick = () => {
        if(disableComponent) return;

        setAddTemplatesDetails((prevState) => {
            return {
                ...prevState,
                openPopup: true,
                importTemplate: true,
                editModeOfTemplate: false,
                details: {}
            }
        })
    };

    const onDeleteTermConditions = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let ApiURL = ``;
        if (TransactionalLevel) ApiURL += `?for_documents=true`;

        let delTermsConditions_ID = element?.data?.tnctemplate_id;
        
        try {
            const res = await api
                .axios()
                .delete(Settings.apiUrl + `/tnc_template/${delTermsConditions_ID}${ApiURL}`)
            const rowNode = defaultGridObjTermsConditions?.api?.getRowNode(delTermsConditions_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                defaultGridObjTermsConditions?.api?.applyServerSideTransaction(transaction)
            }, 200)

            defaultGridObjTermsConditions?.api?.refreshServerSide({ purge: true })
            defaultGridObjTermsConditions?.api?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const reloadParentTermsConditionsGrid = () => {
        defaultGridObjTermsConditions?.api?.refreshServerSide({ purge: true })
        defaultGridObjTermsConditions?.api?.deselectAll();
    };
   
    const TemplatesActionButtons = () => { 
        return (
            <div className='templates_action_btns d-flex justify-content-center align-items-center'>
                {TransactionalLevel &&
                    ( 
                        <Fragment> 
                            <button onClick={onNewTemplateCreationClick} disabled={disableComponent} className={`rs_h_controller ${disableComponent ? 'ctl-dissable' : ''}`}> 
                                <img alt="add" src='/images/icons/add-new.svg' />
                                <span>Add template</span>
                            </button>

                            <div className='common-grid-responsive-btns'> 
                                <button onClick={onImportTemplateClick} disabled={disableComponent} className={`rs_h_controller ${disableComponent ? 'ctl-dissable' : ''}`}> 
                                    <img alt="add" src='/images/icons/add-new.svg' />
                                    <span>Import template</span>
                                </button>
                            </div> 
                        </Fragment>
                    )
                }
                <div className={TransactionalLevel ? 'common-grid-responsive-btns' : ''}> 
                    <button onClick={onAddHeadingsClick} disabled={disableComponent} className={`rs_h_controller ${disableComponent ? 'ctl-dissable' : ''}`}> 
                        <img src='/images/icons/add-new.svg' />
                        <span>Headings</span>
                    </button>
                </div>
            </div>
        )
    };

    const DisplayResponsiveDropdown = () => {
        const TransactionLevelDropdownItems = [];
        if (TransactionalLevel) {
            TransactionLevelDropdownItems.push({
                id: 1,
                icon: '/images/icons/add-new.svg',
                name: 'Import template',
                onClick: onImportTemplateClick,
                disableBeforeClick: disableComponent
            });
        };

        TransactionLevelDropdownItems.push({
            id: 2,
            icon: '/images/icons/add-new.svg',
            name: 'Headings',
            onClick: onAddHeadingsClick,
            disableBeforeClick: TransactionalLevel && disableComponent ? true : false
        });
        return ( <GridResponsiveDropdownMenu dropdownItems={TransactionLevelDropdownItems} /> )
    };

    // Module Mapping Based on Data Type,
    const MasterLevelTermsConditions = () => {
        const exportMasterTermsConditionsData = async () => {
            setExportLoadingMasterTermsConditions(true);

            const apiUrl = Settings.apiUrl + '/tnc_template';
            const params = '';

            let pagination = false;
            let defaultAdvanceFilterObj = false;
            let gridObj = defaultGridObjTermsConditions;
            let pageSize = totalRecordsMasterTermsConditions.current;
            let updatedGridCols = TermsConditionParentGridHeaderCols?.filter(item => item.field !== 'all_content');

            const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
            await exportToXlsx({ url: baseUrl, columns: (updatedGridCols), fileName: ("terms_conditions_details"), additionalFunction: transformObjectTermsConditions });
            
            setExportLoadingMasterTermsConditions(false);
        }
        return (
            <MasterComonent> 
                <div className='container-fluid'>
                    <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(language,'terms_conditions_templates', "Terms & Conditions Templates")}>  
                        <ButtonNew
                            isDisable={
                                !security.canCreate(
                                    Gui_id_list.masterData.terms_conditions_templates.terms_conditions_templates_main_create_button
                                )
                            }
                            onClick={onNewTemplateCreationClick} 
                            title='New' 
                        />
                    </NavigationHeder>  

                    {exportLoadingMasterTermsConditions ? <OverlayLoader /> : null}

                    <Collapse open={true} title={`Terms & Condition`}>
                        <div className='grid_terms_conditions grid_save_btn_hide'>
                            <AgGridNew
                                apiUrl={Settings.apiUrl + '/tnc_template'} 
                                columnDefs={TermsConditionParentGridHeaderCols} 
                                masterSettings={masterSettings} 
                                handleDeleteSelectedRows={onDeleteTermConditions} 
                                onSelectionChanged={onSelectionChangedParentTemplate}
                                onGridReady={onGridReady} 
                                uniqueField={'tnctemplate_id'} 
                                hideDeleteBtn={!security.canDelete(Gui_id_list.masterData.terms_conditions_templates.terms_conditions_templates_main_delete_button)}
                                hideAddBtn={true}
                                height={500}
                                rowType={'single'}
                                beforeDeleteBtns={<TemplatesActionButtons />} 
                                pagination={false}
                                onCellClicked={onGridCellClicked}
                                gridId={'rfq_master_level_terms_conditions'}
                                // afterDeleteBtns={
                                //     <div className="import_sample_template px-2 ms-auto">
                                //         <button onClick={exportMasterTermsConditionsData}>
                                //             <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                                //         </button>
                                //     </div>
                                // }
                                btnsWrapperStyle={`w-100 px-2`}
                                fetchData={(response) => handleFetchDataForListing(response, totalRecordsMasterTermsConditions)}
                            />
                        </div>
                    </Collapse> 
                </div>
            </MasterComonent>
        )
    };

    const TransactionalLevelTermsConditions = () => {
        return (
            <div className='container-fluid p-0 mt-5 contract-terms-external'>
                <Collapse open={true} title={`Terms & Conditions`}>
                    <div className='grid_terms_conditions grid_save_btn_hide'>
                        <AgGridNew
                            apiUrl={module_source_id&&sourceintegration_id?Settings.apiUrl + `/tnc_document_templates/${module_source_id}/${sourceintegration_id}`:null} 
                            columnDefs={TermsConditionParentGridHeaderCols} 
                            masterSettings={masterSettings} 
                            hideDeleteBtn={disableComponent}
                            handleDeleteSelectedRows={onDeleteTermConditions} 
                            onSelectionChanged={onSelectionChangedParentTemplate}
                            onGridReady={onGridReady} 
                            uniqueField={'tnctemplate_id'}
                            rowType={'single'}
                            hideAddBtn={true}
                            height={500}
                            afterDeleteBtns={<DisplayResponsiveDropdown />}
                            beforeDeleteBtns={<TemplatesActionButtons />} 
                            pagination={false} 
                            onCellClicked={onGridCellClicked}
                            gridId={'rfq_level_terms_conditions'}
                        />
                    </div>
                </Collapse> 
            </div>
        )
    };

    if(!security.canView(Gui_id_list.masterData.terms_conditions_templates.terms_conditions_templates_main) && !TransactionalLevel){
        return ( 
            <MasterComonent> 
                <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
            </MasterComonent>  
        )
    };

    return (
        <div className='terms_conditions_templates'>
            { allTemplateContentLoading ? <OverlayLoader isPopupInTab={true} /> : null }
            { TransactionalLevel ? TransactionalLevelTermsConditions() : MasterLevelTermsConditions() }

            {/* Popup View Components */}
            { addTemplatesDetails.openPopup && <CommonTemplatesPopup transactional_document_type={transactional_document_type} defaultDocumentTypeArray={defaultDocumentTypeArray} sourceintegration_id={sourceintegration_id} module_source_id={module_source_id} TransactionalLevel={TransactionalLevel} reloadParentGrid={reloadParentTermsConditionsGrid} documentTypes={enumDocumentType} editModeOfTemplate={addTemplatesDetails.editModeOfTemplate} nodeObj={addTemplatesDetails.details} importTemplate={addTemplatesDetails.importTemplate} setClosePopup={setAddTemplatesDetails} /> }
            { detailViewContent.openPopup && <TermsHTMLDisplayPopup templateTitle={detailViewContent.title} content={detailViewContent.content} heading_otherlanguage={detailViewContent.heading_otherlanguage} content_otherlanguage={detailViewContent.content_otherlanguage} ClosePopupView={setDetailViewContent} /> }
            { templateContentDetails.openPopup && <TermsHTMLDisplayPopup detailedView={true} templateTitle={templateContentDetails?.templateTitle} completeTemplateDetails={templateContentDetails?.templateDetails} ClosePopupView={setTemplateContentDetails} /> }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.masterData.terms_conditions_templates.terms_conditions_templates_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(TermConditionsTemplates, SecurityOptions)))); 