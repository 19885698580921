/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { connect } from 'react-redux';
import Helper from '../../../inc/Helper'; 
import FieldsRenderer from '../../Purchase Orders Internal/Edit PO/FieldsRenderer';

const GeneralBasic = (props) => { 
    let textBoxInputSettings = [ 
        {label:Helper.getLabel(props.language,'document_number',"Document number"), inputType: "text", disable:props.isDocumentNumberDisable, className:'col-lg-3', name:props.type==="invoice on account"||props.type==="credit notes"?'reference_number':'document_number'},
        {label:Helper.getLabel(props.language,'version',"Version"), inputType: "text", disable:true, className:'col-lg-3', name:'version'},
        {label:Helper.getLabel(props.language,'description',"Description"), inputType: "text", name:'description',disable:!props.isEditable,maxLength:255},
        {label:Helper.getLabel(props.language,'document_status',"Document status"),  inputType: "text", disable:true, name:'document_status_label' ,className:'col-lg-3'},
        {mainTitle: 'Dates', mainClassName: 'document_date', inputBoxData: [
            {label:Helper.getLabel(props.language,'start_date',"Start date"), inputType: "datepicker_new", inputClassName: 'state_date', disable:!props.isEditable, className:'col-lg-4', name:'start_date',emptyDate:true,required:props.type==="purchase agreement"},
            {label:Helper.getLabel(props.language,'end_date',"End date"), inputType: "datepicker_new", inputClassName: 'end_date', disable:!props.isEditable, className:'col-lg-4', name:'expiry_date',emptyDate:true,required:props.type==="purchase agreement"} 
        ]},
        {mainTitle: 'Tax information', mainClassName: 'tax_info', inputBoxData: [
            {label:Helper.getLabel(props.language,'tax_id',"Tax group"), options: props.taxGroups, inputType: "dropdown", disable:!props.isEditable, className:'col-lg-4', name:'taxgroup_id', id: 'taxgroup_id',placeHolder:Helper.getLabel(props.language,'select_tax_group',"Select Tax group"), reRenderRequired:true},
            {label:Helper.getLabel(props.language,'taxgroupitem',"Tax group item"), options: props.taxCodes, inputType: "dropdown", disable:!props.isEditable, className:'col-lg-4', name:'taxgroupitem_id', id:'taxgroupitem_id',placeHolder:Helper.getLabel(props.language,'select_tax_group_item',"Select tax group item"), reRenderRequired:true} 
        ]}
    ]

    if(props.type===0){
      textBoxInputSettings = textBoxInputSettings.filter((item)=>item.label!==Helper.getLabel(props.language,'document_status',"Document status"))
    }

  return ( 
      <FieldsRenderer fieldsArray={textBoxInputSettings} updateState={props.setState} currentState={{...props.state,document_status_label:props.document_status_label}} />
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 
export default connect(mapStateToProps) (GeneralBasic) 