import React, { Fragment, useCallback, useState, useRef } from 'react'
import { connect } from 'react-redux';
import Api from '../../../inc/Api';
import Gui_id_list from '../../../inc/Gui_id_list';
import Helper from '../../../inc/Helper';
import Settings from '../../../inc/Settings';
import { getPopupMessageBasedOnStatus, exportToXlsx, handleFetchDataForListing } from '../../../inc/Validation';  
import Alert from '../../inc/Alert'; 
import NavigationHeder from '../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { generateBaseUrl } from '../../grid/ag/commonFunctions/GridCommonFunctions'

let gridObj = null;
const Size = (props) => {
  let AddBtn;
  let transaction; 

  const [exportLoading, setExportLoading] = useState(false)
  const totalRecords = useRef(0)

  const handleGridReady = (params) => {
    gridObj = params
  }

  const updateSizes = async (event) => {
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiItemUrl+`/itemsize/${event?.data?.itemsize_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridObj?.api.refreshServerSide({ purge: true });
          gridObj?.api?.deselectAll();
        }, 100);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }
 
  const addNewSizes = async (event) => {
    try {
      let payload = {
        name: event?.data?.name
      };
      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiItemUrl+'/item_size', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridObj?.api?.refreshServerSide({ purge: true });
          gridObj?.api?.deselectAll();
        }, 100);
        setTimeout(() => {
          if (AddBtn) {
            gridObj?.api.applyServerSideTransaction(transaction);
          }
        }, 1000);
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delSize_ID = element?.data?.itemsize_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiItemUrl+`/itemsize/${delSize_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delSize_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api.refreshServerSide({ purge: true })
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
  
  const sizeValidator = (params) => {
    if( params.newValue.length > 255 ){
      Helper.alert('Name limit exceeded!', 'error') 
      return false
    }
    params.data.name = params.newValue
    return true
  }

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.itemsize_id) {
      updateSizes(event)
    } else {
      addNewSizes(event)
    }
  }

  let Columns_Headings = [ 
    {field:'name', editable: true, valueSetter: sizeValidator, headerName: Helper.getLabel(props.language, 'name', 'Name') } 
  ]

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiItemUrl+'/item_size'
    const params = ""
    let pagination = false
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current
    
    const baseUrl = generateBaseUrl({ gridObj,apiUrl, params, pagination, defaultAdvanceFilterObj,pageSize })

    await exportToXlsx({ url: baseUrl, columns:Columns_Headings, fileName: 'sizes_details' })
    
    setExportLoading(false)
  }

  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView( Gui_id_list.masterData.item_master_modules.size.size_main)){
    return <Fragment> 
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <Fragment> 
      <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title='Size' /> 
      <div className='rs_size_grid'>
        <AgGridNew
          apiUrl={Settings.apiItemUrl+'/item_size'}
          pagination={false}
          columnDefs={Columns_Headings}
          onRowValueChanged={onRowValueChanged}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.item_master_modules.size.size_main_delete_button)}
          hideAddBtn={!security.canCreate(frontendIds.masterData.item_master_modules.size.size_main_create_button)}
          handleDeleteSelectedRows={DeleteHandler}
          height={500}
          onGridReady={handleGridReady}
          handleAddButton={handleBtn}
          uniqueField={`itemsize_id`}
          gridId={'sizes-master-data'}
          afterDeleteBtns={
            <div className="import_sample_template px-2 ms-auto">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          btnsWrapperStyle="w-100 px-2"
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
        />
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.masterData.item_master_modules.size.size_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(Size, SecurityOptions)))