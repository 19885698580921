import React from 'react';
import RFQBiddingDetails from '../../RFQ Vendor Reply/RFQBiddingDetails';

const RFIBiddingDetails = () => {
  return (
    <div>
       <RFQBiddingDetails type={1} />
    </div>
  );
}

export default RFIBiddingDetails;
