import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import Api from '../../../../inc/Api';
import FormValidator from '../../../../inc/FormValidator';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings';
import { areObjectsDifferent, checkIfArrayIsEmpty, checkValue, exportToXlsx, getNextIncrementalNumber, getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'; 
import Alert from '../../../inc/Alert';
import Button from '../../../inc/Button';
import Collapse from '../../../inc/Collapse';
import LinkBtn from '../../../inc/LinkBtn';
import SimpleLoading from '../../../Loading/SimpleLoading';
import NavigationHeder from '../../../Navigations/NavigationHeder'; 
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import CriteriaDetails from './CriteriaDetails'; 
import GridCheckbox from '../../../grid/ag/cellEditor/GridCheckbox';
import CheckboxRender from '../../../grid/ag/cellRender/CheckboxRender'; 
import GeneralTemplateModule from './GeneralTemplateModule';
import ViewTeamModule from './ViewTeamModule';
import GridResponsiveDropdownMenu from '../../../grid/GridResponsiveDropdownMenu';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';
import { generateBaseUrl, getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import { transformObjectScoringSections } from '../../../../inc/TransformerFunctions';

/* Name is required, Weight Validations ->  cannot exceed 100, Sequence is an interger, and required, same for weight  */

// default Object initialization --
let defaultTemplateObj = null
let defaultGridObj = null
let AddBtn = null
let transaction = null

const EditScoringTemplate = (props) => {
  const {id} = useParams();
  const wrapperDiv = useRef(null);
  const [editTemplate, setEditTemplate] = useState({})
  const [sectionsExportLoading, setSectionsExportLoading] = useState(false) 

  const [typeDropdown, setTypeDropdown] = useState([]) 

  const [loading, setLoading] = useState(true)
   
  const [teamDetails, setTeamDetails] = useState({
    showPopup: false,
    templateName: null,
    id: null
  })

  const [criteriaDetails, setCriteriaDetails] = useState({
    showPopup: false,
    sectionName: null,
    id: null
  })

  // Validations --
  const [scoringWeights, setScoringWeights] = useState(false)
  const [height, setHeight] = useState(0);

  // Validations --

  let validator ; 

  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'name', displayName:Helper.getLabel(props.language,'name','Name'),types:['Required']}
    ]
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()

  // ----

  // Reduction Calls --

  let typeCalled = false
  let scoringTemplateCalled = false 

  // --

  // API Calls Executions
  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    getScoringTemplateAPI() 
    TypeDropdownAPI()
  }, [])

  // --

  // API Calls Functions --

  const getScoringTemplateAPI = () => {
    if(scoringTemplateCalled){
      return;
    }
    setLoading(true)
    let api = Api
    scoringTemplateCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiPurchaseRequisitionUrl+`/scoringMaster/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        defaultTemplateObj = res.data.data
        setLoading(false)  
        setEditTemplate(res.data.data)
      }
    }).catch((error) => { 
      setLoading(false)
      console.log(error)
    })
  }
 
  // Dropdown Calls --

  const TypeDropdownAPI = () => {
    if(typeCalled){
      return;
    } 
    let api = Api
    typeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/RF_ScoringSection_type`,{}).then(function(res){
      if(res.data.status==="success"){    
        let UPDATED_DATA = []
        res.data.data.forEach(element => {
          UPDATED_DATA.push({
            value: element.id,
            label: element.name
          })
        })
        setTypeDropdown(UPDATED_DATA) 
      }
    }).catch((error) => {  
      console.log(error)
    })
  }

  // --

  // Screen Modules - Functions  
  const ScoringModule = () => { 
    return (
      <div className='scoring_modules_wrapper common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
        {GeneralModule()}
        {SectionModule()}
      </div>
    )
  }

  const GeneralModule = () => { 
    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'general_info',"General Information")}>
        <GeneralTemplateModule setScoringWeights={setScoringWeights} updateState={setEditTemplate} currentState={editTemplate} />
      </Collapse>
    )
  }

  const SectionModule = () => {
    const AddCriteria = () => {
      if(!defaultGridObj){
        return;
      }
      let selectedItems = defaultGridObj.api.getSelectedRows();
      if(selectedItems.length <=0){
        Helper.alert('Please select a section!' ,'error')
        return;
      }
      if(!selectedItems[0].scoringsectiondetail_id){
        Helper.alert('Please create a section first then criteria!','error')
        return;
      }
      setCriteriaDetails((prevState) => {
        return {
          ...prevState,
          showPopup: true,
          sectionName: selectedItems[0].name,
          id: selectedItems[0].scoringsectiondetail_id
        }
      })
    }

    const ViewTeam = () => {
      if(!defaultGridObj){
        return;
      }
      let selectedItems = defaultGridObj.api.getSelectedRows(); 
      if(selectedItems.length <=0){
        Helper.alert('Please select a section!','error')
        return;
      }
      if(!selectedItems[0].scoringsectiondetail_id){
        Helper.alert('Please create a section first then view team!','error' ) 
        return;
      }
      setTeamDetails((prevState) => {
        return {
          ...prevState,
          showPopup: true,
          templateName: editTemplate.name,
          id: id
        }
      })
    }
  
    const ActionButton = () => {
      const DropdownItems = [
        {
          id: 1,
          icon: '/images/icons/add-new.svg',
          name: 'Add criteria',
          onClick: AddCriteria 
        },
        {
          id: 2,
          icon: '/images/icons/eye.svg',
          name: 'View Team',
          onClick: ViewTeam 
        } 
      ];
      return (
        <>
          <div className='action-btns-scoring-templates-wrapper'>
            <GridResponsiveDropdownMenu dropdownItems={DropdownItems} />
            <div className='common-grid-responsive-btns'> 
              <button className='rs_h_controller rs_setvalue_btn visible_on_select_item ctl-dissable' onClick={AddCriteria}> 
                <img src='/images/icons/add-new.svg' />
                <span>Add criteria</span>
              </button>

              <button className='rs_h_controller rs_setvalue_btn visible_on_select_item ctl-dissable' onClick={ViewTeam}> 
                <img src='/images/icons/eye.svg' />
                <span>View Team</span>
              </button>
            </div>
          </div>

          <div className="import_sample_template px-2 ms-auto">
            <button onClick={exportData}>
              <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
            </button>
          </div>
        </>
      )
    }

    const onGridReady = (grid) => { defaultGridObj = grid }

    const onSelectionChanged = (event) => {
      let selectedRows = event.api.getSelectedRows(); 
      if(selectedRows.length <=0 || !selectedRows[0].scoringsectiondetail_id){
        $('.rs_setvalue_btn').addClass('ctl-dissable');
        $('.visible_on_select_item').addClass('ctl-dissable');
      }else{
        $('.rs_setvalue_btn').removeClass('ctl-dissable');
        $('.visible_on_select_item').removeClass('ctl-dissable');
      } 
    }

    const SequenceDefaultValueSetter = (params) => {
      if(params.data.sequence == undefined) {
        let sequencesArray = []
        params.api.forEachNode((node) => {
          if(node.data.sequence === 0) return;
          sequencesArray.push({sequence: node.data.sequence !== undefined ? parseInt(node.data.sequence) : undefined})
        }) 
        let sequenceVal = getNextIncrementalNumber(sequencesArray) 
        params.node.setDataValue('sequence', sequenceVal) 
        params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
        return; 
      };
      if(params.data.sequence !== 0) return;
  
      let sequencesArray = []
      params.api.forEachNode((node) => {
        if(node.data.sequence === 0 || params.data.sequence == undefined) return;
        sequencesArray.push({sequence: parseInt(node.data.sequence)})
      }) 
      let sequenceVal = getNextIncrementalNumber(sequencesArray) 
      params.node.setDataValue('sequence', sequenceVal) 
      params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
    }
 
    const ScoringDistributionValidations = (params) => {
      let totalTechnicalWeight = 0;
      let totalFinancialWeight = 0;
     
      params.api.forEachNode((node) => {
        const weight = parseFloat(node.data.weight);
        if (!isNaN(weight) && weight >= 0) {
          if (parseInt(node.data.section_type) === 0) {
            totalTechnicalWeight += weight;
          } else if (parseInt(node.data.section_type) === 1) {
            totalFinancialWeight += weight;
          }
        }
      });
     
      if (totalTechnicalWeight > 100) {
        Helper.alert('Invalid Technical Weight Distribution!', 'error'); 
        return;
      }
    
      if (totalFinancialWeight > 100) {
        Helper.alert('Invalid Financial Weight Distribution!', 'error'); 
        return;
      }
    
      if(!params?.data?.scoringsectiondetail_id){
        addNewDataSection(params)
      } else {
        updateDataSection(params)
      }  
    };

    const handleBtn = (value, transactionData) => {
      AddBtn = value
      transaction = transactionData
    }

    const GridColumnSections = [
      { field: 'name', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(props.language, 'name', "Name*") },
      { field: 'sequence', filter: 'agNumberColumnFilter', editable: true, headerName: Helper.getLabel(props.language, 'sequence', "Sequence*") },
      { field: 'weight', filter: 'agNumberColumnFilter', editable: true, headerName: Helper.getLabel(props.language, 'weight', "Weight") },
      {
        field: 'section_type', 
        headerName: Helper.getLabel(props.language, 'type', "Type"),
        editable: true,
        cellEditor: SimpleDropdown,
        isAllowZero: true,
        minWidth: 180, 
        cellEditorParams: { values: typeDropdown },
        cellDataType:false,
        valueFormatter: (params)=> getLabelFromDropdown(params),
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, typeDropdown),
          keyCreator: (params) => optionsKeyCreator(params, 'value'),
          valueFormatter: params => params.value.label,
        }
      }
    ]

    let masterSettings = {
      detailRowAutoHeight: true,
      masterDetail: true,  
      detailCellRendererParams: {
        detailGridOptions: {
          rowSelection: 'single',
          columnDefs: [
            {field: 'sequence', filter: 'agNumberColumnFilter', editable: false, headerName:'Sequence*'},
            {field: 'name', filter: 'agTextColumnFilter', editable: false, headerName:'Name*'},
            {field: 'description', filter: 'agTextColumnFilter', editable: false, headerName:'Description'},
            { field: 'comments_mandatory', filter: 'agSetColumnFilter', cellRenderer: 'agCheckboxCellRenderer', cellEditor: 'agCheckboxCellEditor', cellRendererParams: { disabled: true }, editable: false, headerName:'Comments mandatory',
              filterParams: { 
                values: [true, false], 
                keyCreator: (params) => params.value, 
                valueFormatter: (params) => params.value ? 'true' : 'false' 
              } 
            }
          ],
          defaultColDef: {
            sortable: true,
            flex: 1,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.criteria); 
        },
      }
    }
    
    const getTransformedObjForExport = (data) => transformObjectScoringSections(data, typeDropdown);

    const exportData = async () => {
      if(!id) return;

      setSectionsExportLoading(true);
  
      const apiUrl = id?Settings.apiPurchaseRequisitionUrl+`/scoringsections/${id}`:null;
      const params = '';
      
      let pagination = false;
      let defaultAdvanceFilterObj = false; 
      
      const baseUrl = generateBaseUrl({ gridObj: defaultGridObj, apiUrl, params, pagination, defaultAdvanceFilterObj });
      await exportToXlsx({ url: baseUrl, columns: GridColumnSections, fileName: 'section_details', additionalFunction: getTransformedObjForExport })
      
      setSectionsExportLoading(false);
    }

    return (
      <Collapse open={true} title={Helper.getLabel(props.language,'section',"Section")}>
        <div className='grid_scoring_section grid_save_btn_hide'>
          <AgGridNew
            apiUrl={id?Settings.apiPurchaseRequisitionUrl+`/scoringsections/${id}`:null}  
            handleDeleteSelectedRows={DeleteHandlerSection}
            onRowEditingStopped={SequenceDefaultValueSetter}
            masterSettings={masterSettings}
            onSelectionChanged={onSelectionChanged}
            rowType={'single'} 
            afterDeleteBtns={<ActionButton />}
            addLabel={'Add Section'} 
            handleAddButton={handleBtn}
            columnDefs={GridColumnSections}  
            onRowValueChanged={ScoringDistributionValidations}
            onGridReady={onGridReady}
            height={500}
            btnsWrapperStyle="w-100 px-2" 
            gridId={'master_data_scoring_section_grid'}
            uniqueField={'scoringsectiondetail_id'}
            pagination={false}
          />
        </div>
      </Collapse>
    )
  }

  //--

  // API executions - Grid - Sections
 
  const updateDataSection = async (event) => { 
    try {
      let payload = {
        name: event?.data?.name,
        sequence: event?.data?.sequence ? parseInt(event?.data?.sequence) : null,
        weight: event?.data?.weight ? parseFloat(event?.data?.weight) : null,
        section_type: event?.data?.section_type ? parseInt(event?.data?.section_type) : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/scoringsection/${event?.data?.scoringsectiondetail_id}`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          defaultGridObj?.api?.refreshServerSide({ purge: true });
          defaultGridObj?.api?.deselectAll();
        }, 100);

        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }

  const addNewDataSection = async (event) => {  
    try {
      let payload = {
        scoringmaster_id: id,
        sections: [{...event?.data, criteria: []}]
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+'/scoringSection', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          defaultGridObj?.api?.refreshServerSide({ purge: true });
          defaultGridObj?.api?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtn) {
            defaultGridObj?.api?.applyServerSideTransaction(transaction);
          }
        }, 1000);
        
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteHandlerSection = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_section_id = element?.data?.scoringsectiondetail_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl+`/scoringsection/${delete_section_id}`)
      const rowNode = defaultGridObj?.api?.getRowNode(delete_section_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        defaultGridObj?.api?.applyServerSideTransaction(transaction)
      }, 200) 

      defaultGridObj?.api?.refreshServerSide({ purge: true }); 
      defaultGridObj?.api?.deselectAll();
      
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);
 
  const reloadSectionsGrid = () => {
    defaultGridObj?.api?.refreshServerSide({ purge: true }); 
    defaultGridObj?.api?.deselectAll();
  };

  // -----

  const ExecuteTemplateUpdateAPI = () => {   
    if(!areObjectsDifferent(defaultTemplateObj, editTemplate)){
      return;
    }

    setLoading(true);
    let api = Api
    api.setUserToken()
    api.axios().put(Settings.apiPurchaseRequisitionUrl+`/scoringMaster/${id}`, {
      name: editTemplate.name,
      auto_disqualification_financial_score_prct: !checkValue(editTemplate.auto_disqualification_financial_score_prct) ? null : editTemplate.auto_disqualification_financial_score_prct,
      auto_disqualification_technical_score_prct: !checkValue(editTemplate.auto_disqualification_technical_score_prct) ? null : editTemplate.auto_disqualification_technical_score_prct,
      financial_score_weight: !checkValue(editTemplate.financial_score_weight) ? null : parseFloat(editTemplate.financial_score_weight), 
      technical_score_weight: !checkValue(editTemplate.technical_score_weight) ? null : parseFloat(editTemplate.technical_score_weight),
      show_evaluation_criteria_to_bidder: editTemplate.show_evaluation_criteria_to_bidder == 'on' ? true : false
    }).then(function(res){
      if(res.data.status==="success"){     
        Helper.alert(res.data.message) 
        getScoringTemplateAPI()
        setLoading(false);
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      getScoringTemplateAPI()  
      setLoading(false);
    })
  }
 
  // --

  // Save handler ---
 
  const SaveHandler = (e) => {   
    let validData = {
      ...editTemplate
    }

    if(!validator.isValid(validData)){
      validator.displayMessage();
      validator.reset() 
      return;
    }
      
    if (!/^\d*\.?\d*$/.test(editTemplate.technical_score_weight) && editTemplate.technical_score_weight !== null) {
      Helper.alert('Technical score weight invalid number', 'error')
      return;
    }

    if (!/^\d*\.?\d*$/.test(editTemplate.auto_disqualification_technical_score_prct) && editTemplate.auto_disqualification_technical_score_prct !== null) {
      Helper.alert('Auto disqualification technical score invalid number', 'error')
      return;
    }

    if (!/^\d*\.?\d*$/.test(editTemplate.financial_score_weight) && editTemplate.financial_score_weight !== null) {
      Helper.alert('Financial score weight invalid number', 'error')
      return;
    }

    if (!/^\d*\.?\d*$/.test(editTemplate.auto_disqualification_financial_score_prct) && editTemplate.auto_disqualification_financial_score_prct !== null) {
      Helper.alert('Auto disqualification financial score invalid number', 'error')
      return;
    }
  
    if(scoringWeights){
      Helper.alert('Distribute scoring weights about 100%', 'error')
      return;
    } 

    ExecuteTemplateUpdateAPI() 
  }

  // ---

  // Screen Accessibility -
 
  let security = props.security;
  let frontendIds = Gui_id_list;
  if(!security.canView(Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template_edit_screen)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  }

  return (
    <div className={'edit_scoring_template'}>
      { loading ? <OverlayLoader isPopupInTab={true} /> : null }
      { sectionsExportLoading ? <OverlayLoader isPopupInTab={true} /> : null }
      <div className='container-fluid'>
        <div ref={wrapperDiv}>
          <NavigationHeder hideMoreBtn={true} backUrl='/scoring-template' title={ `<span style="color:#000;">${Helper.getLabel(props.language,'edit_scoring_template', "Edit Scoring Template")} #</span> ${editTemplate?.name || ''}` }>
            <LinkBtn isActive= {false} to="/scoring-template" title={Helper.getLabel(props.language,'close',"Close")} className='black-btn-style' />
            <Button isDisable={!security.canCreate(frontendIds.procure.request_for_quotation.control_forms.scoring_template.scoring_template_edit_screen_save_button)} className="rfq_save_btn" isActive= {false} title={Helper.getLabel(props.language,'save',"Save")} onClick={SaveHandler} />
          </NavigationHeder> 
        </div>
 
        { ScoringModule() }
      </div>

      {teamDetails.showPopup && <ViewTeamModule templateName={teamDetails.templateName} modeRFQ={false} reloadGrid={reloadSectionsGrid} setTeamDetails={setTeamDetails} scoringmaster_id={teamDetails.id} />}
      {criteriaDetails.showPopup && <CriteriaDetails sectionName={criteriaDetails.sectionName} reloadGrid={reloadSectionsGrid} setCriteriaDetails={setCriteriaDetails} scoringsectiondetail_id={criteriaDetails.id} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return { 
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.request_for_quotation.control_forms.scoring_template.scoring_template_edit_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditScoringTemplate, SecurityOptions))))