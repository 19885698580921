import React, { Fragment, useCallback, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../inc/Api';
import Gui_id_list from '../../../../inc/Gui_id_list';
import Helper from '../../../../inc/Helper';
import Settings from '../../../../inc/Settings'; 
import MasterComponentWraper from '../../../Backend/MasterComponentWraper'; 
import Alert from '../../../inc/Alert';
import ButtonNew from '../../../inc/ButtonNew'; 
import NavigationHeder from '../../../Navigations/NavigationHeder';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation';
import AgGridNew from '../../../grid/ag/ag-grid-new';
import VendorGroupModal from '../Create/VendorGroupModal';
import { generateBaseUrl, gridLinkValueFormat } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

let gridObj = null;
const GroupArchive = (props) => {
  const [vendorGroupId, setVendorGroupId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false); 
  const [exportLoading,setExportLoading] = useState(false)
  const totalRecords = useRef(0)

  
  const handleGridReady = (params) => {
    gridObj = params
  }
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'name') { 
      setVendorGroupId(event?.data?.vendorgroup_id);
      setShowCreateModal(true);
    }
  }
 
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delVendorGroup_ID = element?.data?.vendorgroup_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendorgroup/${delVendorGroup_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delVendorGroup_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api?.refreshServerSide({ purge: true })
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const reloadGrid = () => {
    gridObj?.api?.refreshServerSide({ purge: true });
    gridObj?.api?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setVendorGroupId(null);
  };

  const Columns_Headings = [
    {field:'name', headerName:Helper.getLabel(props.language,'name',"Name"), editable: false, cellRenderer: gridLinkValueFormat},
    {field:'external_code', headerName:Helper.getLabel(props.language,'code',"Code"), editable: false},
  ];

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiVendorUrl + "/vendorgroup"
    const params = ""
    let pagination = false
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize })

    await exportToXlsx({ url: baseUrl, columns: Columns_Headings, fileName: "vendor_details" })
    setExportLoading(false)
  }
  
  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendor_group.vendors_group)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  };

  return (
    <>
    {exportLoading ? <OverlayLoader /> : null}
    <div className="vendors_group_archive">
      <div className="container-fluid">
        <NavigationHeder hideMoreBtn={true} backUrl="/master-data" title={Helper.getLabel(props.language, "vendors_group", "Vendors Group")}>
          <ButtonNew onClick={onClickNewButton} title={Helper.getLabel(props.language, "new", "New")} isDisable={!security.canCreate(frontendIds.masterData.vendor_group.vendors_group_main_create_button)} />
        </NavigationHeder>

        <AgGridNew
          apiUrl={Settings.apiVendorUrl + "/vendorgroup"}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.vendor_group.vendors_group_main_delete_button)}
          uniqueField={"vendorgroup_id"}
          pagination={false}
          columnDefs={Columns_Headings}
          hideAddBtn={true}
          height={500}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={handleGridReady}
          gridId={"rs_vendors_group_list"}
          afterDeleteBtns={
            <div className="import_sample_template px-2">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          btnsWrapperStyle={`w-100 justify-content-between px-2`}
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
        />
      </div>
      {showCreateModal && <VendorGroupModal reloadParentGrid={reloadGrid} vendor_group_id={vendorGroupId} setShowCreateModal={setShowCreateModal} />}
    </div>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_group.vendors_group
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(GroupArchive, SecurityOptions))))