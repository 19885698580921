import React from 'react'
import Popup from '../../../Popup/Popup'
import $ from 'jquery'
import Settings from '../../../../inc/Settings'
import RFQBiddingProposal from './RFQBiddingProposal'

const RateCardLineDetails = ({ setShowPopup, rfqData, lineId, isEditable, apiUrl, lineAmount }) => {
  return (
    <div>
      <Popup
        autoOpen={true}
        onClose={() => {
          $('html').removeClass('rs_popup_open')
          setShowPopup(false)
        }}
        width="1100px"
      >
        <RFQBiddingProposal source_id={Settings.source_id.vendorreply_rate_card} apiUrl={apiUrl ?? Settings.apiPurchaseOrderUrl+ `/getline_ratecardheader/99/${lineId}?for_vendorreply=true`} rfqData={rfqData} gridId={'proposal_grid_2'} type="rfi" isEditable={isEditable} lineAmount={lineAmount} />
      </Popup>
    </div>
  )
}

export default RateCardLineDetails
