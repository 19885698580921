import React, { Component } from "react";
import { connect } from "react-redux";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import MasterComonent from "../Backend/MasterComonent";
import Button from "../inc/Button";
import NavigationHeder from "../Navigations/NavigationHeder";
import NavCartWishlist from "./common/NavCartWishlist";
import EditOldCartModal from "./modals/EditOldCartModal";
import Helper from "../../inc/Helper";

class ShopLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0
    }

    this.wrapperDivRef = React.createRef(); 
  }
 
  componentDidMount() {
    this.getWishlistCount();
    this.resizeWindowHandle();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.wrapperDivRef.current &&
      this.wrapperDivRef.current.clientHeight !== prevState.height
    ) {
      this.resizeWindowHandle();
    }
  }

  getWishlistCount() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/wishlist")
      .then(function (res) {
        that.props.changeWishlistCount(res.data.data?.length);
        that.props.updateWishlistIds([...res.data.data]);
      });
  } 

  resizeWindowHandle = () => { 
    Helper.resizeWindowHandle(
      this.wrapperDivRef,
      (newHeight) => {
        if (this.state.height !== newHeight) {
          this.setState({ height: newHeight });
        }
      },
      window
    );
  };

  handleChildRender = () => { 
    this.resizeWindowHandle();
  };

  render() { 
    return (
      <div style={{position:'relative'}}>
        <MasterComonent>
          <div className="pr-wrapper scrollBarBlinkControl">
            <div ref={this.wrapperDivRef}> 
              <NavigationHeder
                backUrl={this.props.backUrl}
                title={this.props.title}
                hideMoreBtn={this.props.hideMoreBtn}
              >
                {this.props.hideMoreBtn ? (
                  <NavCartWishlist disableAllActionBtns={this.props.disableAllActionBtns} hideNextBtn={this.props.hideNextBtn} handleBackBtn={this.props.handleBackBtn} nextBtnHandler={this.props.nextBtnHandler} isCartVisible={this.props.isCartVisible} isContinueShopping={this.props.isContinueShopping} prId={this.props.prId} currentDocumentStatus={this.props.currentDocumentStatus} prHeader={this.props.prHeader} />
                ) : (
                  <>
                    <Button isActive={false} title="Back" />
                    <Button isActive={true} title="Save" />
                  </>
                )}
              </NavigationHeder> 
            </div>

            <div className='common_scrollbar_styles' style={{ height: `${this.state?.height - 30}px`, overflowY: 'auto'}}> 
              {React.cloneElement(this.props.children, { onChildRender: this.handleChildRender })}
            </div>
          </div> 
        </MasterComonent>
        {this.props.showEditOldCartModal ? <EditOldCartModal viewOnly={this.props.viewOnly} prId={this.props.prId} is_rfi_pr={this.props.prHeader?.is_rfi_pr} source_id={this.props.prHeader?.source_id}/> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showEditNewCartModal: state.prShop.showEditNewCartModal,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
    updateWishlistIds: (payload) =>
      dispatch({ type: "UPDATE_WISHLIST_IDS", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopLayout);
