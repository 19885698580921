import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../inc/Helper';
import Collapse from '../inc/Collapse';
import LinkBtn from '../inc/LinkBtn';
import RsWithRouter from '../inc/RsWithRouter';
import SimpleLoading from '../Loading/SimpleLoading';
import NavigationHeder from '../Navigations/NavigationHeder';
import BlueCallToAction from '../Widgets/BlueCallToAction/BlueCallToAction';
import Tabs from '../Widgets/Tabs/Tabs';
import Button from '../inc/Button';
import AgGrid from '../grid/ag/ag-grid';
import Api from '../../inc/Api';
import Settings from '../../inc/Settings';
import $ from 'jquery';
import FormValidator from '../../inc/FormValidator';
import { getPopupMessageBasedOnStatus } from '../../inc/Validation';
class SegmentValues extends Component {
    constructor(props){
        super(props);
        this.isEmployeLoaded = false;
        this.isLoadedSegment = false;
        this.isLoadedSegmentValues = false;
        this.saveButtonTimeout = null;
        this.segment_id = null;
        this.gridObj = null;
        this.validationConfigure();
        this.state = {
            segment:{},
            isLoading:false,
            allEmployee:[],
            gridData:[],
            allParentsSegments:[],
            allParentsSegmentsValues:[],
        }
    }
    validationConfigure(){
        const language = this.props.language;
        let fieldConfig = []
        this.validator = new FormValidator(fieldConfig,language);
    }
    componentDidMount(){
        this.loadEmploye();
        this.loadSegment();
        this.loadParentsSegments();
        this.loadParentsSegmentsValues();
    }
    loadEmploye(){
        if(this.isEmployeLoaded){
            return;
        }
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiOrgUrl + '/employee_dropdown?status=true').then(res => {
            that.setState({
                allEmployee:res.data.data
            })
        })
    }
    loadSegment(forceLoad = false){
        if(this.isLoadedSegment && !forceLoad){
            return;
        }
        this.isLoadedSegment = true;
        let {id} = this.props.rs_router.params;
        this.segment_id = id;
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment/'+id).then(res => {
            that.setState({
                isLoading:false,
                segment:res.data.data
            },function(){
                that.loadSegmentValues(true)
            })
        }).catch(error => {
            that.setState({
                isLoading:false
            })
            console.log(error)
        })
    }
    loadParentsSegments(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/get_segments').then(res => {
            that.setState({
                allParentsSegments:res.data.data
            })
        })
    }
    loadParentsSegmentsValues(){
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrls.segments + '/segment_parent').then(res => {
            that.setState({
                allParentsSegmentsValues:res.data.data
            })
        })
    }
    loadSegmentValues(forceLoad = false){

        let api = Api;
        api.setUserToken();
        let that = this;
        that.setState({
            isLoading:true,
        })
        api.axios().get(Settings.apiUrls.segments +'/segmentvalue').then(res => {
            let gridData = [];
            if(res.data.status === 'success'){
                let responseData = res.data.data.reverse();
                gridData = responseData.filter( item => { 
                    return item.segment_id == that.segment_id ? item : false
                })
            } 
            that.setState({
                gridData:gridData,
                isLoading:false,
            })
        }).catch(error =>{
            that.setState({
                isLoading:false,
            })
        })
    }
    
    onSaveHandler(e){
        $('.segments_page_value .rs_grid_btn_save').unbind('click');
        clearTimeout(this.saveButtonTimeout); // clear before assign new value inside the timmer
        this.saveButtonTimeout = setTimeout(function(){ // asign timeout
            $('.segments_page_value .rs_grid_btn_save').trigger('click');
        },100)
    }
    addComponentTop(){
        let language = this.props.language;
        let segment = this.state.segment;
        let items = [
            {title:Helper.getLabel(language,'segment_name','Segment Name'),subtitle:segment.name},
            {title:Helper.getLabel(language,'display_name','Display Name'),subtitle:segment.display_name},
            {title:Helper.getLabel(language,'external_code','External Code'),subtitle:segment.external_code}
        ];
        return(
          <div className='rfq-internal-blue'>
            <BlueCallToAction items={items}/>
          </div>
        )
    }
    onSaveSegmentValue(items){
        if(!this.validator.isValid({},[this.gridObj])){
            this.validator.displayMessage(this);
            return;
        }
        let newValues = [],oldValues = [];
        items.forEach(item => {
            let itemData = {
                segment_id:this.state.segment.segment_id,
                code:item.code,
                name:item.name,
                external_code:item.external_code,
                parent_segment:item.parent_segment == 'yes' || item.parent_segment === true ? true : false,
                owner:item.owner == 0 ? null : item.owner ,
                isactive: item.isactive == 'yes' || item.isactive === true ? true : false,
                allow_budgeting:item.allow_budgeting == 'yes' || item.allow_budgeting === true? true : false,
                allow_posting:item.allow_posting == 'yes' || item.allow_posting === true ? true : false,
                parentsegment_id: Helper.getNullableValue(item.parentsegment_id),
                parentsegmentvalue_id: Helper.getNullableValue(item.parentsegmentvalue_id),
                translationsource_id:null
            }
            if(item.segmentvalue_id){
                itemData.segmentvalue_id = item.segmentvalue_id;
                oldValues.push(itemData)
            }else{
                newValues.push(itemData)
            }
        });
        this.addNewSegmentsValues(newValues);
        this.updateSegmentsValues(oldValues);
    }
    addNewSegmentsValues(items){
        if(items.length <=0){
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let itemsNeedToCreate = items.length;
        items.forEach(item => {
            api.axios().post(Settings.apiUrls.segments+'/segmentvalue',item).then( res => {
                itemsNeedToCreate--;
                if(itemsNeedToCreate<=0){
                    Helper.alert(res.data.message)
                    that.loadSegmentValues(true)
                }
            }).catch(error => {
                getPopupMessageBasedOnStatus(error)
                itemsNeedToCreate--;
                if(itemsNeedToCreate<=0){
                    that.loadSegmentValues(true)
                }
            })
        });
    }
    updateSegmentsValues(items){
        if(items.length <=0){
            return;
        }
        let that = this;
        that.setState({
            isLoading:true
        })
        let api = Api;
        api.setUserToken();
        let itemsNeedToUpdate = items.length;
        items.forEach(item => {
            api.axios().put(Settings.apiUrls.segments+'/segmentvalue/'+item.segmentvalue_id,item).then( res => {
                itemsNeedToUpdate--;
                if(itemsNeedToUpdate<=0){
                    Helper.alert(res.data.message)
                    that.loadSegmentValues(true)
                }
            }).catch(error => {
                getPopupMessageBasedOnStatus(error)
                itemsNeedToUpdate--;
                if(itemsNeedToUpdate<=0){
                    that.loadSegmentValues(true)
                }
            })
        });
    }
    onGridReady(grid){
        this.gridObj = grid;
    }
    onAllowPostingChangeHandler(event,params,value){
        var cellEditorInstances = params.api.getCellEditorInstances();
        cellEditorInstances.forEach(cellEditorIns => {
            if(cellEditorIns.params.colDef.field == 'parent_segment'){
                cellEditorIns.updateValue('no')
            }
        });
    }
    onParentChangeHandler(event,params,value){
        if(value == 'yes'){
            var cellEditorInstances = params.api.getCellEditorInstances();
            cellEditorInstances.forEach(cellEditorIns => {
                if(cellEditorIns.params.colDef.field == 'allow_posting'){
                    cellEditorIns.updateValue('no')
                }
            });
        }
        
    }
    onDeleteHandler(data){
        let api = Api;
        let that = this;
        api.setUserToken();
        data.forEach(dataItem => {
            if(dataItem.segmentvalue_id){
                api.axios().delete(Settings.apiUrls.segments+'/segmentvalue/' +dataItem.segmentvalue_id).then(res => {
                    Helper.alert(res.data.message);
                }).catch( error => {
                    that.gridObj.componentObj.undorDelete()
                    getPopupMessageBasedOnStatus(error);
                })
            }
            
        })
    }
    render() {
        const language = this.props.language;
        let segment= this.state.segment;
        let navs = [
            {title:Helper.getLabel(language,'general',"General"), isActive: true},
        ];
        let employeeList = this.state.allEmployee.map( employee => {
            return {
                label: `${employee.displayname} - ${employee.code}`,
                value: employee.employee_id
            }
        })
        let allParentsSegments = this.state.allParentsSegments.map( pSegment => {
            return {
                label: `${pSegment.name} - ${pSegment.display_name}`,
                value: pSegment.segment_id
            }
        })
        let allParentsSegmentsValues = this.state.allParentsSegmentsValues.map( pSegmentValue => {
            return {
                label: `${pSegmentValue.code} - ${pSegmentValue.name}`,
                value: pSegmentValue.segmentvalue_id
            }
        })
       
        let gridHeader= [
            {field:'code', headerName: Helper.getLabel(language,"code",'Code *'), validationRule:{types:['Required'],max:segment.segmentValue_character}},
            {field:'segment_id', headerName: Helper.getLabel(language,"segment",'Segment'),hide:true},
            {field:'segmentvalue_id', headerName: Helper.getLabel(language,"segmentvalue_id",'segmentvalue_id'),hide:true},
            {field:'name', headerName: Helper.getLabel(language,"name",'Name *'), validationRule:{types:['Required']}},
            {field:'external_code', headerName: Helper.getLabel(language,"external_code",'External code')},
            {field:'owner', inputId:'sg_owner', headerName: Helper.getLabel(language,"owner",'Owner'),inputType:'dropdown',options:employeeList,validationRule:{types:['Number']}},
            {field:'parentsegment_id', inputId:'parentsegment_id', headerName: Helper.getLabel(language,"segment_parent",'Segment parent'),inputType:'dropdown',options:allParentsSegments,validationRule:{types:['Number']}},
            {field:'parentsegmentvalue_id',inputId:'parentsegmentvalue_id', headerName: Helper.getLabel(language,"segment_parent_value",'Segment parent value'),inputType:'dropdown',options:allParentsSegmentsValues,validationRule:{types:['Number']}},
            {field:'parent_segment', cellDataType:false, headerName: Helper.getLabel(language,"parent_segment",'Parent'), inputType:'checkbox', onChange: this.onParentChangeHandler.bind(this) },
            {field:'isactive', cellDataType:false, headerName: Helper.getLabel(language,"active",'Active'), inputType:'checkbox'},
            {field:'allow_budgeting', cellDataType:false, headerName: Helper.getLabel(language,"allow_budgeting",'Allow budgeting'), inputType:'checkbox' },
            {field:'allow_posting', cellDataType:false, headerName: Helper.getLabel(language,"allow_posting",'Allow posting'), inputType:'checkbox', onChange: this.onAllowPostingChangeHandler.bind(this) },
        ];
        if(this.state.isLoading){
            return <SimpleLoading/>
        }
        
        return (
            <div className='segments_page segments_page_value'>
                <NavigationHeder hideMoreBtn={true} backUrl="/segments" title={Helper.getLabel(language,'segment_value','Segments Value')}>
                    <LinkBtn to="/segments" isActive={false} className="black-btn-style" title={Helper.getLabel(language,'Close','Close')}/>
                    
                    <LinkBtn to={'/segments/set-rollup-groups/'+segment.segment_id}    className="blue" icon="/images/icons/rollup-white.svg" title={Helper.getLabel(language,'group','Group')}/>
                    {this.state.isSaving ? <div style={{width:"150px"}}><SimpleLoading/></div> :  <Button onClick = { this.onSaveHandler.bind(this)  }  isActive={false} className="rfq_save_btn" title={Helper.getLabel(language,'save','Save')}/> }
                </NavigationHeder>
                <Tabs navs={navs} isHideNav={true} showAll={true} scrollOffset="300" addComponentTop = { this.addComponentTop.bind(this)} >
                    <Collapse  title={Helper.getLabel(language,'values',"Values")} className="mb-5 grid_save_btn_hide" open={true}>
                        <AgGrid 
                            id="segments_value_grid" 
                            onSave={ this.onSaveSegmentValue.bind(this) }  
                            header={gridHeader} 
                            data={this.state.gridData} 
                            settings={{singleClickEdit:false}}
                            onDelete = {this.onDeleteHandler.bind(this)}
                            onGridReady={ this.onGridReady.bind(this)}
                        />
                    </Collapse>
                </Tabs>
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        language:state.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return({
    })
}
export default  connect (mapStateToProps,mapDispatchToProps)  ( RsWithRouter(SegmentValues));