import React, {useState, useRef, useEffect } from 'react'
import Dropdown from '../../Forms/Dropdown'
import Helper from '../../../inc/Helper'
import Input from '../../Forms/Input'
import SimpleLoading from '../../Loading/SimpleLoading'
import Settings from '../../../inc/Settings'
import WorkConfirmationApiCalls from '../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import { objectIsEmpty } from '../../../inc/Validation'
import { Link } from 'react-router-dom'
import OverlayCommon from '../../RequestForQuotation/RFQDetailForm/HeaderView/OverlayCommon'

const ReadableInput = ({
  value1,
  value2,
  languageLabel,
  label,
  invoiceHeader,
  language,
}) => {
  return (
    <Input
      label={`${Helper.getLabel(language, languageLabel, label)}`}
      value={invoiceHeader ? value1 : value2}
      disable={true}
      onChange={(e) => {}}
    />
  )
}

const CommonInputDatePicker = ({
  language,
  label,
  invoiceHeader,
  setState,
  state,
  name,
  type,
  isTooltip = false,
  toolTipText
}) => {
  return (
    <NewInputDatePicker
      label={<>{Helper.getLabel(language, name, label)} {isTooltip && <OverlayCommon toolTipText={toolTipText} />} </>}
      id={name}
      name={name}
      value={state}
      disable={invoiceHeader?.document_status === 1||type==="internal"}
      onChange={(date) => {
        setState(date)
      }}
    />
  )
}

const InvoiceForm = ({
  language,
  isLoading,
  invoiceHeader,
  selectedDocument,
  setSelectedDocument,
  invoiceNumber,
  setInvoiceNumber,
  setInvoiceDescription,
  setInvoiceDate,
  invoiceDate,
  setDocumentDate,
  documentDate,
  invoiceDescription,
  location,
  invoiceList,
  setInvoiceHeader,
  type,
  selectedValues,
  setSelectedValues,
  userType
}) => {
  const [documentStatus, setDocumentStatus] = useState({
    documet_status: null,
    purchase_order_id: null,
    type: null,
  })
  const [poHeaderTypes, setPOHeaderTypes] = useState([])

  let currentSearchTerm = ''
  const allPurchaseOrderData = useRef([])
  const allReceivingData = useRef([])

  const invoiceApiCalls = new WorkConfirmationApiCalls()

  useEffect(() => {
    if (invoiceHeader) {
      setInvoiceDate(invoiceHeader.invoice_date)
      setDocumentDate(invoiceHeader.document_date)
      setInvoiceNumber(invoiceHeader.reference_number)
      setInvoiceDescription(invoiceHeader.description)
    }
  }, [invoiceHeader])

  const getAllPOHeaders = async () => {
    const poAllHeaderTypes = await invoiceApiCalls.getAllPOHeaderType()
    if (poAllHeaderTypes) {
      setPOHeaderTypes(poAllHeaderTypes)
    }
  }

  useEffect(() => {
    getAllPOHeaders()
  }, [])

  const getOptions = (
    selectedDocument,
    invoiceHeader,
    selectedDocumentValue,
    invoiceHeaderValue,
  ) => {
    let options = []

    if (selectedDocument && typeof selectedDocumentValue !== 'undefined') {
      options = [
        {
          value: selectedDocumentValue,
          label: selectedDocumentValue,
        },
      ]
    } else if (
      (invoiceHeader || location) && 
      typeof invoiceHeaderValue !== 'undefined') {
       if (typeof invoiceHeaderValue === 'object' && invoiceHeaderValue !== null) {
        Object.values(invoiceHeaderValue).forEach((value) => {
            options.push({
              value: value,
              label: value,
            });
        });
      }else {
        options = [
          {
            value: invoiceHeaderValue,
            label: invoiceHeaderValue,
          },
        ];
      }
    }
    return options
  }
  
  const mapApiReceivingItems = (item) => {
    return {
      id: item.shipment_id || 'null',
      text: item.receiving_reference_number,
    }
  } 
  
  const mapApiPurchaseOrderItems = (item) => {
    return {
      id: item.purchase_order_id || 'null',
      text: item.document_number + ' - ' + item.description?.substring(0,40) || " ",
    }
  }

  const determineDocumentType = () => {
     if (objectIsEmpty(selectedDocument)) {
    return documentStatus?.type;
  } else {
    return selectedDocument?.purchaseorder_type ?? selectedDocument?.type;
  }
  }

  const sourceDocumentType = poHeaderTypes?.find(
    (item) =>
      item.id ===
      (!objectIsEmpty(invoiceHeader) ? invoiceHeader?.purchaseorder_type : determineDocumentType()),
  )

 return (
    <div className="generalInformation">
      {isLoading ? (
        <SimpleLoading />
      ) : (
          <div className="generalInformation-basic">
            <div>
              <div className="work-confirmation-grid">
                <div className="generalInformation-basic-grid">
                  <Dropdown
                    id="select-document"
                    label={`${Helper.getLabel(
                      language,
                      'source_document_no',
                      'Source document number',
                    )}`}
                    required={true}
                    options={getOptions(
                      selectedDocument,
                      invoiceHeader,
                      selectedDocument?.document_number,
                      invoiceHeader?.PO_document_number,
                    )}
                    isDisable={
                      (invoiceHeader &&
                      ((invoiceList?.length===0 && invoiceHeader?.document_number!==null)||invoiceHeader?.document_status === 1)) || type==="internal"
                    }
                    apiUrl={Settings.apiPurchaseOrderUrl + '/PO_header'}
                    currentSearchTerm={currentSearchTerm}
                    mapFunction={mapApiPurchaseOrderItems}
                    allDataRef={allPurchaseOrderData} 
                    pagination={true}
                    additionalParams={{document_status:1}}
                    placeHolder={`${Helper.getLabel(
                      language,
                      'please_select',
                      'Please select',
                    )}`}
                    value={
                      selectedDocument?.document_number ||
                      invoiceHeader?.PO_document_number ||
                      documentStatus?.document_number
                    }
                    onChange={(e) => {
                      const selectedId = Number(e.target.value)
                      setSelectedDocument({})
                      invoiceList?.length>=1&&setInvoiceHeader(null)
                      setInvoiceNumber(''); 
                      setInvoiceDescription(''); 
                      setInvoiceDate(new Date())
                      setDocumentDate(new Date())
                      if (selectedId) {
                        // Checks if value exists
                        let matchingData = allPurchaseOrderData.current.find(
                          (item) =>
                            Number(item.purchase_order_id) === selectedId,
                        )
                        if (matchingData.document_number === null) {
                          Helper.alert(
                            'Document number cannot be null. Please select other option.',
                            'error',
                          )
                        }
                        if (matchingData) {
                          let documentNumber = matchingData.document_number
                          let purchaseOrderId = matchingData.purchase_order_id
                          setDocumentStatus({
                            document_number: documentNumber,
                            purchase_order_id: purchaseOrderId,
                            type: matchingData.type,
                          })
                          setSelectedDocument(matchingData)
                        }
                      } else {
                        setDocumentStatus({})
                      }
                    }}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="source_document_type"
                    label="Source document type"
                    invoiceHeader={invoiceHeader}
                    value1={sourceDocumentType?.name}
                    value2={sourceDocumentType?.name}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="description"
                    label="Description"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.source_description}
                    value2={selectedDocument?.description}
                  />
                  <Dropdown
                    label={`${Helper.getLabel(
                      language,
                      'good_receiving / work_confirmation_no.',
                      'Good receivings / Work confirmation no. *',
                    )}`}
                    placeHolder={`${Helper.getLabel(
                      language,
                      'please_select',
                      'Please Select',
                    )}`}
                    id="select-receiving"
                    select2Settings={{multiple:true}}
                    options={getOptions(
                      selectedDocument,
                      invoiceHeader,
                      !invoiceHeader ? selectedDocument?.receiving_reference_number : undefined,
                      invoiceHeader?.receiving_reference_number,
                    )}
                    onChange={(e) => {
                      const selectedOptions = Array.from(e.target.selectedOptions).map((option) => parseInt(option.value));
                      setSelectedValues(selectedOptions)
                      if (e.target.value.includes('null')) {
                        Helper.alert(
                          'Good receiving / Work confirmation no. cannot be null. Please select other option.',
                          'error',
                        )
                      } 
                      else {
                        const selected = allReceivingData?.current?.find(
                          (doc) =>
                            doc.shipment_id === parseInt(e.target.value),
                        )
                        setSelectedDocument(selected || {})
                      }
                    }}
                    value={
                      invoiceHeader ? 
                      typeof invoiceHeader?.receiving_reference_number === 'object'
                        ? Object.values(invoiceHeader.receiving_reference_number)
                        : [] : selectedValues || [] }
                    isDisable={
                      (invoiceHeader &&
                      (invoiceHeader.document_number !== null ||
                        invoiceHeader.document_status === 1)) || objectIsEmpty(selectedDocument)
                    }
                    pagination={true}
                    apiUrl={Settings.apiPurchaseOrderUrl + '/get_receivings'}
                    currentSearchTerm={currentSearchTerm}
                    mapFunction={mapApiReceivingItems}
                    allDataRef={allReceivingData} 
                    reRenderRequired={true}
                    additionalParams={{purchaseorder_id:
                      documentStatus?.purchase_order_id || selectedDocument?.purchase_order_id || selectedDocument?.purchaseorder_id}}
                  />
                     {type==="internal" && <><ReadableInput
                    language={language} 
                    languageLabel="entity_name"  
                    label="Entity name"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.entity_name}
                    value2={invoiceHeader?.entity_name} 
                  />   
                  <ReadableInput
                    language={language} 
                    languageLabel="site_name" 
                    label="Site name"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.site_name}
                    value2={invoiceHeader?.site_name}
                  />
                  <ReadableInput 
                    language={language} 
                    languageLabel="warehouse_name" 
                    label="Warehouse name"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.warehouse_name}
                    value2={invoiceHeader?.warehouse_name} 
                  /></>}
                </div>
              </div>
              <div className="work-confirmation-grid">
                <h6>Vendor</h6>
                <div className="generalInformation-basic-grid">
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_code"
                    label="Vendor code"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.vendor_code}
                    value2={selectedDocument?.vendor_code}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_name"
                    label="Vendor name"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.vendor_name}
                    value2={selectedDocument?.vendor_name}
                  />
                  <ReadableInput
                    language={language}
                    languageLabel="vendor_site"
                    label="Vendor site"
                    invoiceHeader={invoiceHeader}
                    value1={invoiceHeader?.vendorsite_name}
                    value2={selectedDocument?.vendorsite_name}
                  />
                </div>
              </div>
              <div className="work-confirmation-grid">
                <h6>Invoice Details</h6>
                <div className="general-information-grid-custom">
                  <Input
                    label={`${Helper.getLabel(
                      language,
                      'invoice_no',
                      'Invoice no.',
                    )}`}
                    value={invoiceNumber}
                    disable={type==="internal"}
                    onChange={(e) => {
                      setInvoiceNumber(e.target.value)
                    }}
                    required={true}
                    maxLength={50}
                  />
                  <Input
                    label={`${Helper.getLabel(
                      language,
                      'invoice_description',
                      'Invoice description',
                    )}`}
                    value={invoiceDescription}
                    disable={type==="internal"}
                    onChange={(e) => {
                      setInvoiceDescription(e.target.value)
                    }}
                    maxLength={100}
                    required={userType===1}
                  />
                </div>
                <div className="work-confirmation-grid">
                  <h6>Dates</h6>
                  <div className="generalInformation-basic-grid">
                    {userType===0 && <CommonInputDatePicker
                      name={'document_date'}
                      language={language}
                      label={'Document date'}
                      invoiceHeader={invoiceHeader}
                      setState={setDocumentDate}
                      state={documentDate}
                      editValue={invoiceHeader?.document_date}
                      type={type}
                    />}
                    <CommonInputDatePicker
                      name={'invoice_date'}
                      language={language}
                      label={'Invoice date'}
                      isTooltip={true}
                      toolTipText={"Please ensure that the selected invoice date matches the invoice date mentioned on the uploaded invoice document. Any discrepancies may lead to rejection or delays in processing. "}
                      invoiceHeader={invoiceHeader}
                      setState={setInvoiceDate}
                      state={invoiceDate}
                      editValue={invoiceHeader?.invoice_date}
                      type={type}
                    />
                  </div>
                </div>
                {!objectIsEmpty(invoiceHeader) && userType=== 0 && <div className='work-confirmation-grid'>
                   <h6>Work Confirmation / Goods Receiving Details</h6>
                   <div>
                    {typeof invoiceHeader?.receiving_reference_number === 'object' 
                       ? Object.entries(invoiceHeader.receiving_reference_number).map(([key, value]) => (
                           <React.Fragment key={key}>
                             <Link to={invoiceHeader?.purchaseorder_type===1?`/work-confirmation-details?shipment_id=${key}`:`/goods-receiving-details?shipment_id=${key}`}>{value}</Link>
                              {Object.keys(invoiceHeader.receiving_reference_number).indexOf(key) < Object.keys(invoiceHeader.receiving_reference_number).length - 1 ? ', ' : ''}
                           </React.Fragment>
                         ))
                       : "-"
                    }
                   </div>
                </div>}
              </div>
            </div>
          </div>
      )}
    </div>
  )
}

export default InvoiceForm
