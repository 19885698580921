import React, {Fragment, useState, useEffect, useMemo, useCallback, useRef} from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew'  
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import {useDispatch} from 'react-redux'
import { gridIdActions } from '../../../store/grid-id'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import { checkForRolesPermission, checkIfArrayIsEmpty, exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new' 
import { generateBaseUrl, getLabelFromDropdown, getOptions, getStatusNameById, gridLinkValueFormat, gridValueFormatChip, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import CreateVendorModal from './CreateVendorModal'
import Button from "../../inc/Button";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";
import { transformObjectVendors } from '../../../inc/TransformerFunctions'

let gridObj = null
const VendorsArchive = (props) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const vendor_status = urlParams?.get('status');
  const document_status = urlParams?.get('document_status'); 
  const vendors_deleted = urlParams?.get('deleted') ? 1 : 0

  const [vendorType, setVendorType] = useState([])
  const [vendorStatus, setVendorStatus] = useState([]) 
  const [vendorDocumentStatus, setVendorDocumentStatus] = useState([]) 
  const [showCreateVendorModal, setShowCreateVendorModal] = useState(false)
  const [isLoading,setIsLoading]=useState(false)
  const totalRecords = useRef()
  const [exportLoading,setExportLoading] = useState(false)

  const dispatch = useDispatch()

  let vendorTypesCalled = false
  let vendorStatusCalled = false
  let vendorDocumentStatusCalled = false

  const navigate = useNavigate()

  const handleGridReady = (params) => {
    gridObj = params
  }

  const resetCentralState = () => { 
    dispatch(gridIdActions.VendorID({payload: undefined}))  
  }

  useEffect(() => {
    resetCentralState()
    get_vendors_types_data()
    VendorStatusList()
    VendorDocumentStatusList()
  }, [])

  const CellHandler = (event) => {
    if(event.colDef.field === 'code') {  
      let prevRedirectRoute = 
        vendor_status == "2" ? 
        '/vendors?status=2' :
        vendor_status == "1" ?
        '/vendors?status=1' : 
        vendors_deleted ? 
        '/vendors?deleted=true' :
        document_status ? 
        '/vendors?document_status=2' : 
        '/vendors';
      
      navigate(`/edit-vendor/${event.data.vendor_id}`, { 
        state: { 
          prevRoutePath: prevRedirectRoute
        }
      })
    }
  }
  
  const VendorStatusList = () => {
    if(vendorStatusCalled){
      return;
    }
    let api = Api
    vendorStatusCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/vendorstatus`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setVendorStatus(res.data.data)
      }
    })
  }

  const VendorDocumentStatusList = () => {
    if(vendorDocumentStatusCalled){
      return;
    }
    let api = Api
    vendorDocumentStatusCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/vendor_documentstatus`,{}).then(function(res){
      if(res.data.status==="success"){ 
        setVendorDocumentStatus(res?.data?.data)
      }
    })
  }

  const get_vendors_types_data = () => {
    if(vendorTypesCalled){
      return;
    }
    let api = Api
    vendorTypesCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/vendortype',{}).then(function(res){
      if(res.data.status==="success"){  
        let Vendor_List_API = []
        res.data.data.forEach(item => {  
        Vendor_List_API.push({
            value: item.id,
            label: item.name
          })
        })
        setVendorType(Vendor_List_API) 
      }
    })
  }

  const sendReminderToVendor = () => {
    Helper.confirmMe(() => {
      sendReminderApiCall()
    }, "This action will automatically send an email to all vendors who have joined but have not completed their registration.", null, "Do you want to trigger reminder emails to all unregistered vendors?",620)
  }

  const sendReminderApiCall = () => {
    let api = Api
    api.setUserToken()
    setIsLoading(true)
    api.axios().get(Settings.apiVendorUrl+`/vendor_reminder_email`).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res?.data?.message, 'success')
        setIsLoading(false)
      }
    }).catch((error)=>{
      getPopupMessageBasedOnStatus(error)
      setIsLoading(false)
    })
  }

  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delVendor_ID = element.data?.vendor_id;

    if(element?.data?.document_status == 'Under Review'){
      Helper.alert('Vendors under review cannot be deleted.', 'error');
      return;
    }
    
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendor/${delVendor_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delVendor_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api.refreshServerSide({ purge: true })
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])
 
  const gridColumnHeaders = [
    { field: 'code', minWidth: 430, filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language, '60020103', 'Code'), editable: false, cellRenderer: (params) => gridLinkValueFormat(params) },
    { field: 'name', minWidth: 430, filter: 'agTextColumnFilter', headerName: Helper.getLabel(props.language, '60030103', 'Name'), editable: false },
    { field: 'users', sortable: false, minWidth: 430, headerName: Helper.getLabel(props.language, '60060103', 'User emails'), cellRenderer: (params) => gridValueFormatChip(params, false), editable: false },
    {
      field: 'vendor_type', 
      headerName: Helper.getLabel(props.language, '60040103','Vendor type'), 
      minWidth:430,
      cellEditorParams: {
        values: vendorType
      },
      filter:'agSetColumnFilter',
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filterParams: {
        values: (params) => getOptions(params, vendorType),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      },
      editable: false
    },
    { field: 'category', sortable: false, filter: 'agTextColumnFilter', minWidth: 430, headerName: Helper.getLabel(props.language, '60050103', 'Category'), editable: false, cellRenderer: (params) => gridValueFormatChip(params, true, 'name') },
    { field: 'invitation_code', sortable: false, minWidth: 180, headerName: Helper.getLabel(props.language, '60050103', 'Invitation code'), editable: false, valueFormatter:(params)=>{return params.value || "-"} },
    {
      field: 'document_status', filter: 'agSetColumnFilter', 
      cellRenderer: (params)=> getStatusNameById(null, vendorDocumentStatus, true, params.value),
      filterParams: {
        values: (params) => getOptions(params, vendorDocumentStatus),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: params => params.value.name
      },
      minWidth: 430, 
      headerName: Helper.getLabel(props.language, '60030104', 'Workflow status'), 
      editable: false 
    },
    {
      field: 'status_name', 
      filter:'agSetColumnFilter',
      cellRenderer: (params)=> getStatusNameById(null, vendorStatus, true, params.value),
      filterParams: {
        values: (params) => getOptions(params, vendorStatus),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: params => params.value.name
      }, 
      minWidth: 430, 
      headerName: Helper.getLabel(props.language, '60030105', 'Status'), 
      editable: false 
    }
  ];
 
  const getTransformedObjForExport = (data) => transformObjectVendors(data, vendorType);

  const exportData = async () => {
    setExportLoading(true);

    const apiUrl = `${Settings.apiVendorUrl}/vendor`;
    const params = `${vendors_deleted ? `&is_deleted=${vendors_deleted}` : ""}${vendor_status == 2 ? `&registration_completed=false&status=${vendor_status}` : vendor_status ? `&status=${vendor_status}` : ""}${
      document_status ? `&document_status=${document_status}` : ""
    }`;

    let pagination = true;
    let defaultAdvanceFilterObj = false;
    let pageSize = totalRecords.current;

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
 
    await exportToXlsx({ url: baseUrl, columns: gridColumnHeaders, fileName: "vendor_details", additionalFunction: getTransformedObjForExport });
    setExportLoading(false);
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData.vendors.vendors)){
    return <Fragment>
    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </Fragment>
  } 
  return (
    <>
    {exportLoading ? <OverlayLoader /> : null}
    <div className="vendors_archive">
      <div className="container-fluid">
        <NavigationHeder
          backUrl="/master-data"
          title={Helper.getLabel(
            props.language,
            "60010103",
            `Vendors - ${
              vendorStatus?.find((val) => val.id == vendor_status)?.name ===
              "Draft"
                ? "Joined but not completed registration"
                : vendorStatus?.find((val) => val.id == vendor_status)?.name ===
                  "Approved"
                ? "Approved"
                : vendors_deleted
                ? "Deleted"
                : (document_status ? 'Under Review' : "All") 
            }`
          )}
          hideMoreBtn={true}
        >
          {
            vendor_status == 2 && <Button className="rfq_save_btn" title={Helper.getLabel(props.language, "send-reminder", "Send Reminder")} onClick={sendReminderToVendor} />
          }
          <ButtonNew
            onClick={() => setShowCreateVendorModal(true)}
            title={Helper.getLabel(props.language, "new", "New")}
            isDisable={
              !security.canCreate(
                frontendIds.masterData.vendors.vendors_main_create_button
              )
            }
          />
        </NavigationHeder>

        <AgGridNew
          apiUrl={`${Settings.apiVendorUrl}/vendor`}
          additionalParams={`${vendors_deleted ? `&is_deleted=${vendors_deleted}` : ''}${vendor_status == 2 ? `&registration_completed=false&status=${vendor_status}` : vendor_status ? `&status=${vendor_status}` : ''}${document_status ? `&document_status=${document_status}` : ''}`}
          pagination={true}
          columnDefs={gridColumnHeaders}
          hideAddBtn={true}
          hideDeleteBtn={!security.canDelete(frontendIds.masterData.vendors.vendors_main_delete_button) || (vendors_deleted ? true : false) || checkForRolesPermission(props?.user?.roles, 'Vendor') || document_status}
          height={500}
          uniqueField={'vendor_id'}
          onCellClicked={CellHandler}
          handleDeleteSelectedRows={DeleteHandler}
          onGridReady={handleGridReady} 
          gridId={`rs_vendors_listings`}
          afterDeleteBtns={
            <div className="import_sample_template px-2">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          btnsWrapperStyle={`w-100 ${vendors_deleted?"justify-content-end":"justify-content-between"} px-2`}
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
        />
      </div>
      {showCreateVendorModal && <CreateVendorModal setShowCreateVendorModal={setShowCreateVendorModal} />}
      {isLoading && <OverlayLoader  isLogoCenter={true} isPopupInTab={true}  />}
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth, 
    user:state.auth.user,
    language:state.language
  }
};

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendors.vendors
};

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(VendorsArchive, SecurityOptions))));