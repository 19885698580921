import React, { Component } from "react";

import RsWithRouter from "../../inc/RsWithRouter";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import CheckDeleteActionBar from "../common/CheckDeleteActionBar";
import CartItem from "./CartItem";
import OrderSummary from "../common/OrderSummary";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import Helper from "../../../inc/Helper";
import { connect } from "react-redux";
import SimpleLoading from "../../Loading/SimpleLoading";
import ActionTypes from "../../../inc/ActionsTypes";

import ShoppingCart from "../../../assets/shopping-cart.svg";
import ApplyVendorToLinesModal from "../modals/ApplyVendorToLinesModal";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import WorkFlowCommonHeaderAPICalls from "../../Header/WorkflowCommonHeaderAPICalls";
import WorkFlowOffCanvas from "../../Header/WorkFlowOffCanvas";
import OverlayLoader from "../common/OverlayLoader";

/*

hideNoteAttachmentComp : for hiding the note attachment component since, its being used exactly same from header view.

*/
class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      cartItems: this.props.viewOnly ? this.props.cartItems : [],
      categories: [],
      workflow_action_history: [],
      showCanvas: false,
      showCurrentAssignees: false,
      activeDistribution: null,
      selectedCartItems: [],
      selectedCategories: [],
      lineTypesList: [],
      selectAll: false,
      discount: 0,
      total:{},
      tax: 0,
      isApplyingVendor: false,
      sources: [],
      isDeleteLineLoading:false
    };

    this.toggleSelect = this.toggleSelect.bind(this);
    this.deleteCartItem = this.deleteCartItem.bind(this);
    this.handleDeleteAPICall = this.handleDeleteAPICall.bind(this);
    this.toggleDistribution = this.toggleDistribution.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.deleteAllCartItems = this.deleteAllCartItems.bind(this);
    this.decrementCartCount = this.decrementCartCount.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.getSearchParam = this.getSearchParam.bind(this)
    this.handleApplyVendorsToAll = this.handleApplyVendorsToAll.bind(this);
    this.handleAttachmentUpload = this.handleAttachmentUpload.bind(this)
  }

  componentDidMount() {
    if (this.props.onChildRender) {
      this.props.onChildRender();
    }
    this.getAllSources(); 
    setTimeout(() => {
      this.getAllTax();
    }, 1000);
    this.getCartValues() 
  }


  componentDidUpdate(prevProps) {
    // refetch cart data on cart item edit
    if (
      (prevProps.showEditOldCartModal !== this.props.showEditOldCartModal ||
        prevProps.didCartUpdate !== this.props.didCartUpdate) &&
      this.props.showEditOldCartModal === false &&
      this.props.didCartUpdate === true
    ) {
      // if (!this.props.viewOnly) 
      this.getCartItems();
      this.props.toggleDidCartUpdate();
    }                           
    
    if(prevProps.source_id !== this.props.source_id){
      this.getCartItems()
    }
  }

  getSearchParam = (name) => {
    const params = new URLSearchParams(this.props.rs_router.location?.search)
    return params.get(name)
  }

  async getWorkFlowCalls() {  
    const workflowCalls = new WorkFlowCommonHeaderAPICalls(); 
    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(this.props.source_id, this.getSearchParam('pr_view_id'));
    
    if(workflowActionHistory) this.setState({ workflow_action_history: workflowActionHistory });
  }

  getCartValues(){
    const prHeaderId = this.getSearchParam('pr_view_id')
    this.getWorkFlowCalls();
    if ((prHeaderId && this.props.currentDocumentStatus===0)  || prHeaderId
    ) {
      this.getCartItems();
      this.fetchItemGroups();
      this.getLineTypesList();
    } else {
      this.getCartItems();
      this.getAdvancedCartItems()
      this.fetchItemGroups();
      this.getLineTypesList();
    } 
  }

  async handleApplyVendorsToAll() {
    let api = Api;
    const formData = {
      vendor_id: parseFloat(this.props.lastChangedVendor?.vendor_id),
      vendorsite_id:parseInt(this.props.lastChangedVendor?.vendorsite_id),
      taxgroup_id:parseInt(this.props.lastChangedVendor.taxgroup_id)
    };

    try {
      this.setState({ isApplyingVendor: true });
      for (let i = 0; i < this.state.cartItems?.length; i++) {
        const line_id = this.state.cartItems[i].line_id;

        const res = await api
          .axios()
          .put(
            Settings.apiPurchaseOrderUrl + "/line/" + line_id + "/" + this.props.source_id,
            formData
          );

        if (i === this.props.checkoutLineIds?.length - 1) {
          this.setState({ isApplyingVendor: false });
          Helper.alert(
            Helper.getLabel(
              this.props.language,
              "vendor_applied_to_all_cart_lines",
              "Vendor Applied To All Cart Lines"
            ),
            "success"
          );
          this.props.handleCloseApplyVendorModal();
          this.props.toggleDidCartUpdate();
        }
      }
    } catch (error) {
      this.setState({ isApplyingVendor: false });
      getPopupMessageBasedOnStatus(error)
    }
  }

  getAllTax() {
    const prHeaderId = this.getSearchParam('pr_view_id')
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.loginUrl +
          `/get_taxtransaction_detail/${this.props.source_id}/${prHeaderId}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            // isLoading: false,
            tax: res.data?.data.reduce((sum, obj) => {
              return sum + (obj.total_tax || 0);
            }, 0),
          });
        }
      })
      .catch((error) => {
       
      });
  }

  getAllSources() {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/source")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({ sources: res.data?.data });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      });
  }

  setStateHandlingCanvas(value){
    this.setState({ showCanvas: value });
  }

  setStateHandlingCurrentAssignee(value){
    this.setState({ showCurrentAssignees: value });
  }

  getAdvancedCartItems() {
    const possibleCategoryIds = this.state.cartItems
      ?.filter((item) => item.itemgroup_id != null)
      .map((item) => item.itemgroup_id);

    const possibleCategories = this.state.categories?.filter((category) =>
      possibleCategoryIds?.includes(category.itemgroup_id)
    );

    possibleCategories.push({
      itemgroup_id: -1,
      name: Helper.getLabel(
        this.props.language,
        "miscellaneous",
        "Miscellaneous"
      ),
    });

    const advCartItems = [];

    for (let categoryObj of possibleCategories) {
      let newCategoryObj = {
        ...categoryObj,

        cartItems: this.getCartItemsForCategory(
          categoryObj.itemgroup_id !== -1 ? categoryObj.itemgroup_id : null
        ),
      };
      if (newCategoryObj?.cartItems?.length > 0)
        advCartItems.push(newCategoryObj);
    }

    return advCartItems;
  }

  getCartItemsForCategory(itemgroupId) {
    return this.state?.cartItems?.filter(
      (item) => item.itemgroup_id === itemgroupId
    );
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      });
  }

  async getTotal(integratorId,source_id){
    let api = Api
    api.setUserToken()
    if(!integratorId || !source_id){
      return
    }
    try{
      let total = await api.axios().get(Settings.apiPurchaseOrderUrl+`/total/${source_id}/${integratorId}`)
        this.setState({
            total:total.data.data
        })
    }catch(error){
      
    }
  }

  

  async getCartItems() {
    let api = Api;
    api.setUserToken();
    this.setState({ isLoading: true });
    const existingPRId = this.getSearchParam('pr_view_id');

    if(!existingPRId || !this.props.source_id){
      return
    }
    await this.getTotal(existingPRId,this.props.source_id)
    let url = Settings.apiPurchaseOrderUrl + `/lines/${this.getSearchParam('pr_view_id')}/${this.props.source_id}`

    const cartItemsRes = await api
      .axios()
      .get(
        url
      );

    const headerDataRes = await api
      .axios()
      .get(Settings.apiPurchaseRequisitionUrl + "/pr_header/" + existingPRId);

    if (cartItemsRes.data?.status === "success" && headerDataRes) {
      const cartItems = cartItemsRes.data?.data;
      const newCartItems = [];
      const cartItemIds = [];
      const itemGroupIds = [];
      for (let i = 0; i < cartItems.length; i++) {
        try {
          cartItems[i].delivery_date = DatepickerFunctions?.convertDateFromDataBase(
            headerDataRes.data.data?.delivery_date
          );
        } catch (error) {
          cartItems[i].images = null;
        }
        newCartItems.push(cartItems[i]);
        cartItemIds.push(cartItems[i].line_id);
        const itemGroupId = cartItems[i]?.itemgroup_id ?? -1;
        if (itemGroupIds.indexOf(itemGroupId) === -1) {
          itemGroupIds.push(itemGroupId);
        }
      }

      if (this.props?.checkoutLineIds) {
        const checkoutIds = [];
        cartItemsRes.data?.data.forEach((item) => {
          checkoutIds.push(item.line_id);
        });
        this.props.updateCheckoutIds(checkoutIds);
      }

      if (this.props.checkoutLineIds?.length === 0) {
        // if previously selected line ids dont exist, select all
        const allCatsSelected = this.checkAllCategoriesSelected(
          this.getSelectedCategories(cartItemIds)
        );
        this.setState({
          isLoading: false,
          cartItems: [...newCartItems],
          selectedCategories: [...itemGroupIds],
          selectedCartItems: [...cartItemIds],
          selectAll: allCatsSelected,
        });
      } else {
        const selectedCategories = [];
        const selectedCartItems = this.props.checkoutLineIds;
        // check categories to be selected if any
        this.getAdvancedCartItems()?.forEach((advancedCartItem) => {
          const allCartItemsSelected = advancedCartItem.cartItems.every(
            (cartItem) => {
              return selectedCartItems?.includes(cartItem.line_id);
            }
          );
          if (allCartItemsSelected) {
            selectedCategories.push(advancedCartItem.itemgroup_id);
          }
        });
        const allCatsSelected = this.checkAllCategoriesSelected(
          this.getSelectedCategories(selectedCartItems)
        );
        this.setState({
          isLoading: false,
          cartItems: [...newCartItems],
          selectedCategories: this.getSelectedCategories(
            this.props.checkoutLineIds
          ),
          selectedCartItems,
          selectAll: allCatsSelected,
        });
      }
    }
  }

  getLineIdsByCatId(id) {
    const itemGroup = this.advancedCartItems().find(
      (item) => item.itemgroup_id === id
    );
    if (!itemGroup) {
      return [];
    }
    const lineIds = itemGroup.cartItems.map((cartItem) => cartItem.line_id);

    return lineIds;
  }

  getSelectedCategories(selectedIds) {
    const selectedCategories = [];
    this.getAdvancedCartItems()?.forEach((advancedCartItem) => {
      const allCartItemsSelected = advancedCartItem.cartItems.every(
        (cartItem) => {
          return selectedIds?.includes(cartItem.line_id);
        }
      );
      if (allCartItemsSelected) {
        selectedCategories.push(advancedCartItem.itemgroup_id);
      }
    });
    return selectedCategories;
  }

  checkAllCategoriesSelected(selectedIds) {
    return this.getAdvancedCartItems().every((cartItem) => {
      return selectedIds.includes(cartItem.itemgroup_id);
    });
  }

  deleteCartItem = (item) => {
    Helper.confirmMe(() => {
      this.handleDeleteAPICall(item)
    }, `1 Item will be deleted.`, null, "Are you sure you want to delete this item?",400)
  };


  async handleDeleteAPICall(item) {
    let api = Api;
    api.setUserToken();
    let prId = this.getSearchParam('pr_view_id');
    this.setState({isDeleteLineLoading:true})
    try {
      const res = await api.axios().delete(
        `${Settings.apiPurchaseOrderUrl}/line/${item.line_id}/${this.props.source_id}`
      );
  
      if (res.status === 202) {
        this.setState((prevState) => {
          const newCartItems = prevState.cartItems.filter(cartItem => cartItem.line_id !== item.line_id);
          const newSelectedCartItems = prevState.selectedCartItems.filter(cartItemId => cartItemId !== item.line_id);
          this.getTotal(prId, this.props.source_id);
          return { cartItems: newCartItems, selectedCartItems: newSelectedCartItems, isDeleteLineLoading: false };
        }, () => {
          const newCheckoutIds = this.props.checkoutLineIds.filter(id => !this.state.selectedCartItems.includes(id));
          this.props.updateCheckoutIds(newCheckoutIds);
          this.decrementCartCount();
          Helper.alert(res.data?.message, "success");
        });
      }
    } catch (error) {
      this.setState({isDeleteLineLoading:false})
      getPopupMessageBasedOnStatus(error);
    }
  } 

  handleAttachmentUpload(item,isExist) {
    this.setState((prevState) => {
      const updatedCartItems = prevState.cartItems.map((cartItem) => {
        if (cartItem.line_id === item.line_id) {
          return { ...cartItem, attachment_exists: isExist }; 
        }
        return cartItem;
      });
      return { cartItems: updatedCartItems };
    });
  };
  
  // deleteallcartitems
  deleteAllCartItems() {
    Helper.confirmMe(() => {
      this.handleAllCartItemsDelete()
    }, `${this.state?.selectedCartItems?.length} Item will be deleted.`, null, "Are you sure you want to delete all items?",430)
  }

  async handleAllCartItemsDelete(){
    if (!this.state.selectedCartItems.length) return; 
  
    this.setState({ isDeleteLineLoading: true }); 
  
    const { selectedCartItems } = this.state;
    let allSuccess = true; 
  
    for (const lineId of selectedCartItems) {
      try {
        await this.handleDeleteAPICall({ line_id: lineId });  
      } catch (error) {
        allSuccess = false;
        console.error(`Failed to delete line ${lineId}:`, error);
      }
    }
    this.setState({ isDeleteLineLoading: false }); 
    if (allSuccess) {
      Helper.alert("All selected items deleted successfully.", "success");
    } else {
      Helper.alert("Some items could not be deleted.", "error");
    }
  }
  
  fetchItemGroups() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.apiItemUrl + "/item_group")
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            categories: [...res.data.data],
          });
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      });
  }

  toggleSelect(ids) {
    const selectedCartItems = [...this.state.selectedCartItems];
    ids?.forEach((id) => {
      const existingIndex = selectedCartItems.indexOf(id);
      if (existingIndex === -1) {
        selectedCartItems.push(id);
      } else {
        selectedCartItems.splice(existingIndex, 1);
      }
    });
    const allCatsSelected = this.checkAllCategoriesSelected(
      this.getSelectedCategories(selectedCartItems)
    );
    this.setState({
      selectedCategories: this.getSelectedCategories(selectedCartItems),
      selectedCartItems,
      selectAll: allCatsSelected,
    });
    this.props.updateCheckoutIds(selectedCartItems);
  }

  toggleSelectByCat(itemgroupId, selected) {
    this.setState((prevState) => {
      const advCartItems = this.getAdvancedCartItems();
      const selectedCategories = [...prevState.selectedCategories];
      let selectedCartItems = [...prevState.selectedCartItems];
      const existingCatIndex = selectedCategories.indexOf(itemgroupId);
      if (existingCatIndex === -1) {
        selectedCategories.push(itemgroupId);
      } else {
        selectedCategories.splice(existingCatIndex, 1);
      }

      const catInAction = advCartItems.find(
        (cartItems) => cartItems.itemgroup_id === itemgroupId
      );
      const cartItemIds = catInAction.cartItems?.map(
        (cartItem) => cartItem.line_id
      );

      if (selected) {
        selectedCartItems.push(
          ...cartItemIds.filter((item) => !selectedCartItems.includes(item))
        );
      } else {
        selectedCartItems = selectedCartItems.filter(
          (item) => !cartItemIds.includes(item)
        );
      }

      const allCatsSelected = this.checkAllCategoriesSelected(
        this.getSelectedCategories(selectedCartItems)
      );

      this.props.updateCheckoutIds(selectedCartItems);

      return {
        selectedCategories,
        selectedCartItems,
        selectAll: allCatsSelected,
      };
    });
  }

  toggleSelectAll() {
    this.setState((prevState) => {
      const advCartItems = this.getAdvancedCartItems();
      const selectedCategories = [...prevState.selectedCategories];
      const selectAll = !prevState.selectAll;

      if (selectAll) {
        advCartItems.forEach((cat) => {
          if (!selectedCategories.includes(cat)) {
            selectedCategories.push(cat.itemgroup_id);
          }
        });
      } else {
        selectedCategories.length = 0;
      }
      return { selectedCategories };
    });
    this.setState((prevState) => {
      const advCartItems = this.getAdvancedCartItems();
      const selectedCartItems = [...prevState.selectedCartItems];
      const selectAll = !prevState.selectAll;

      advCartItems.forEach((cat) => {
        if (selectAll) {
          cat.cartItems.forEach((item) => {
            if (!selectedCartItems.includes(item.line_id)) {
              selectedCartItems.push(item.line_id);
            }
          });
        } else {
          selectedCartItems.length = 0;
        }
      });
      this.props.updateCheckoutIds(selectedCartItems);
      return { selectedCartItems };
    });
    this.setState({ selectAll: !this.state.selectAll });
  }

  toggleDistribution(item) {
    if (this.state.activeDistribution === item.line_id) {
      this.setState({ activeDistribution: null });
    } else {
      this.setState({ activeDistribution: item?.line_id });
    }
  }

  decrementCartCount() {
    this.props.changeCartCount(
      this.props.cartCount >= 1
        ? this.props.cartCount - 1
        : this.props.cartCount
    );
  }

  handleNextClick() {
    this.props.rs_router.navigate(`/purchase-requisition/summary?pr_view_id=${this.getSearchParam('pr_view_id')}`);
  }

  getSourceId(name) {
    for (let i = 0; i < this.state.sources?.length; i++) {
      if (this.state.sources[i]?.source_name === name) {
        return this.state.sources[i]?.source_id;
      }
    }

    return null;
  }

  render() {
    return (
        <div className="shoppingCart-container">
          {this.state.isDeleteLineLoading && <OverlayLoader isPopupInTab={true} isLogoCenter={true} />}
          <div className="shoppingCart-container-items">
          {this.state.isLoading ? (
              <SimpleLoading />
            ) : (<> <CheckDeleteActionBar
              icon={ShoppingCart}
              title={
                this.props.viewOnly
                  ? Helper.getLabel(
                      this.props.language,
                      "purchase_requisition_lines",
                      "Purchase Requisition Lines"
                    )
                  : Helper.getLabel(
                      this.props.language,
                      "shopping_cart",
                      "Shopping Cart"
                    )
              }
              itemCount={this.state.cartItems?.length ?? 0}
              selectAll={this.state.selectAll}
              toggleSelectAll={this.toggleSelectAll}
              deleteAll={this.deleteAllCartItems}
              disable={this.state.selectedCartItems.length === 0}
              viewOnly={this.props.viewOnly}
            />
              <div className="cartItems-wrapper">
                {this.getAdvancedCartItems()?.map((itemsByCat) => {
                  return (
                    <div className="cartItem" key={itemsByCat.itemgroup_id}>
                      <div className="cartItem-category">
                        {!this.props.viewOnly ? (
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              this.toggleSelectByCat(
                                itemsByCat.itemgroup_id,
                                e.target.checked
                              );
                            }}
                            checked={this.state.selectedCategories.includes(
                              itemsByCat.itemgroup_id
                            )}
                          />
                        ) : null}{" "}
                        {itemsByCat.name}
                      </div>
                      <div className="cartItem-items">
                        {itemsByCat.cartItems.map((item) => {
                          return (
                            <CartItem
                              key={item.item_id}
                              cartItem={item}
                              deleteCartItem={this.deleteCartItem}
                              handleAttachmentUpload={this.handleAttachmentUpload}
                              selectedCartItems={this.state.selectedCartItems}
                              handleSelect={this.toggleSelect}
                              lines={() => this.getCartItems()} 
                              toggleDistribution={this.toggleDistribution}
                              activeDistribution={this.state.activeDistribution}
                              lineTypesList={this.state.lineTypesList}
                              viewOnly={this.props.viewOnly}
                              editMode={this.props.editMode}
                              // source_id={this.props?.is_rfi_pr ? this.getSourceId("RFI/RO Purchase Requisition line Distribution"):this.getSourceId(
                              //   "Purchase Requisition line Distribution"
                              // )}
                              source_id={this.props.source_id}
                              currentDocumentStatus={this.props.currentDocumentStatus}
                              viewLineDistribution={this.props.viewLineDistribution}
                              type={this.props.type}
                              is_rfi_pr={this.props.is_rfi_pr}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              </>
            )}
            <ApplyVendorToLinesModal
              show={this.state.cartItems?.length===1 ? false:this.props.showApplyVendorModal}
              hide={this.props.handleCloseApplyVendorModal}
              isLoading={this.state.isApplyingVendor}
              handleApplyVendorsToAll={this.handleApplyVendorsToAll}
            />
          </div>
          
          {!this.props?.hideNoteAttachmentComp &&
          <div className='parent-sidebar-cn'>
            <div className="cart-sidebar">
              <MasterSidebar sidebarStckyContainerId="abc_tabs_sections">
                {this.props.hideOrderSummary ? null : <OrderSummary
                  title={Helper.getLabel(
                    this.props.language,
                    "requisition_summary",
                    "Requisition summary"
                  )}
                  itemCount={
                    this.props.viewOnly
                      ? this.state.cartItems?.length
                      : this.state.cartItems?.length
                  }
                  subtotal={this.state?.total?.subtotal}
                  discount={this.state?.total?.line_discount}
                  tax={this.state.total?.tax}
                  total={this.state?.total?.total}
                  buttonText={false}
                  // onButtonClick={() => this.handleNextClick()}
                />}
                <NoteAttachmentHistory  
                  openCanvas={this.setStateHandlingCanvas.bind(this)}
                  openCurrentAssignees={this.setStateHandlingCurrentAssignee.bind(this)}
                  hideHistory={!this.state.workflow_action_history?.history?.length}
                  historyArray={this.state.workflow_action_history}
                  source_id={this.props.source_id}
                  notesource_integrator={this.getSearchParam('pr_view_id')}
                  attachmentsource_integrator={this.getSearchParam('pr_view_id')}
                  hideVendorPortal={true}
                  condition={this.props.currentDocumentStatus!==0}
                />
              </MasterSidebar>

              {this.state.showCanvas && 
                <WorkFlowOffCanvas
                  isOpen={this.state.showCanvas}
                  type={{ label: "Action History", value: 9 }}
                  closeSidebar={this.setStateHandlingCanvas.bind(this)}
                  source_id={this.props.source_id}
                  source_item_id={this.getSearchParam("pr_view_id")}
                />
              }

              {this.state.showCurrentAssignees &&
                <WorkFlowOffCanvas
                  isOpen={this.state.showCurrentAssignees}
                  type={{ label: "View current assignment", value: 43 }}
                  closeSidebar={this.setStateHandlingCurrentAssignee.bind(this)}
                  source_id={this.props.source_id}
                  source_item_id={this.getSearchParam("pr_view_id")}
                />
              }
            </div>
          </div>}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
    showEditOldCartModal: state.prShop.showEditOldCartModal,
    didCartUpdate: state.prShop.didCartUpdate,
    checkoutLineIds: state.prShop.checkoutLineIds,
    showApplyVendorModal: state.prShop.showApplyVendorModal,
    lastChangedVendor: state.prShop.lastChangedVendor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: ActionTypes.CHANGE_CART_COUNT, payload }),
    updateCheckoutIds: (payload) =>
      dispatch({ type: ActionTypes.UPDATE_CHECKOUT_LINE_IDS, payload }),
    toggleDidCartUpdate: () => {
      dispatch({ type: ActionTypes.TOGGLE_DIDCARTUPDATE });
    },
    toggleVendorChanged: () =>
      dispatch({ type: ActionTypes.TOGGLE_VENDOR_CHANGED }),
    handleCloseApplyVendorModal: () =>
      dispatch({ type: ActionTypes.CLOSE_APPLY_VENDOR_MODAL }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RsWithRouter(Cart));
