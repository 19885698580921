import React from "react";
import RFQDetailForm from "../../components/RequestForQuotation/RFQDetailForm";

const RFQInformationFormScreen = () => {
  return (
      <RFQDetailForm type={1}/>
  );
};

export default RFQInformationFormScreen;
