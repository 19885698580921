import React, { Component, useCallback, useEffect, useState } from 'react'
import Api from '../../../../inc/Api'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import AgGrid from '../../../grid/ag/ag-grid'
import Collapse from '../../../inc/Collapse'
import RsDataLoader from '../../../inc/RsDataLoader'
import AmendmentBillingRulePopup from './AmendmentBillingRulePopup'
import FormValidator from '../../../../inc/FormValidator'
import { commonThousandSeparator, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import NumberCellEditor from '../../../grid/ag/cellEditor/NumberCellEditor'
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown'
import { getLabelFromDropdown } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import NumberCellRenderer from '../../../grid/ag/cellRender/NumberCellRenderer'
import DateEditor from '../../../grid/ag/cellEditor/CustomDatePicker'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import SimpleLoading from '../../../Loading/SimpleLoading'
import CurrencyRenderer from '../../../grid/ag/cellRender/CurrencyRenderer'
// /**
// isEditable=
// employeeList = {employeeList}
// ownerDefaultValue={this.state.activePOLine ? this.state.activePOLine.data.requester_id : 0 }
// line_id={this.state.activePOLine ? this.state.activePOLine.data.line_id : null }
// units = {units}
// grid={this.poLineGrid}
// defaultQuantity = {this.state.activePOLine ? this.state.activePOLine.data.ordered_qty : 0 }
// defaultUnitPrice = {this.state.activePOLine ? this.state.activePOLine.data.unit_price : 0 }
// defaultUnit= {this.state.activePOLine ? this.state.activePOLine.data.unit : 0 }
// defaultAmount= {this.state.activePOLine ? this.state.activePOLine.data.line_amount : 0 }
// source_id = {Settings.source_id.purchase_order}
// master_save_button_id = {this.props.master_save_button_id}
// line_type={this.state.activePOLine ? this.state.activePOLine.data.line_type : ''}
//  */
// class BillingRuleView extends Component {
//     constructor(props){
//         super(props);
//         this.grid= null;
//         this.validationConfigure();
//         this.isStopAutoSave = false;
//         this.isMasterSaveEventApplied = false;
//         this.state = {
//             isLoading:false,
//             billingRoles:[],
//             itemData:null,
//             amendmentPopupShow:false,
//             billingRuleTypes:[],
//             billingRuleStatus:[],
//             line_id: null
//         }
//     }
//     componentDidMount(){
//         let that = this;
//         this.setState({
//             line_id:this.props.line_id
//         }, function(){
//             that.loadBillingRoles()
//         })
//         that.loadBillingRuleType();
//         that.loadItemFromId();
//         that.loadBillingRuleStatus();
//         if(this.props.master_save_button_id){
//             let btnSave = document.getElementById(this.props.master_save_button_id);
//             if(btnSave){
//                 btnSave.addEventListener('click',function(event){
//                     event.preventDefault();
//                     clearTimeout(that.isMasterSaveEventApplied)
//                     that.isMasterSaveEventApplied = setTimeout(function(){
//                         that.loadBillingRoles()
//                     },200)
//                 })
//             }
//         }
//     }
//     validationConfigure(){
//         const language = this.props.language;
//         let fieldConfig = [
//             {name:'type',displayName:Helper.getLabel(language,'type','Type'),types:['Required']},
//             {name:'amount',displayName:Helper.getLabel(language,'amount','Amount'),types:['Required']},
//             {name:'unit_price',displayName:Helper.getLabel(language,'unit_price','Unit price'),types:['Required']},
//             {name:'status',displayName:Helper.getLabel(language,'status','Status'),types:['Required','Number']},
//         ]
//         this.billing_validator = new FormValidator(fieldConfig,language);
//     }
//     loadItemFromId(){
//         let item_id = this.props.item_id;
//         if(!item_id){
//             this.setState({
//                 isLoading:false,
//                 itemData:null
//             })
//             return;
//         }
//         this.setState({
//             isLoading:true,
//             itemData:null
//         })
//         let api = Api;
//         api.setUserToken();
//         let that = this;
//         api.axios().get(Settings.apiItemUrl+'/item/'+item_id).then(res=>{
//             that.setState({
//                 isLoading:false,
//                 itemData:res.data.data
//             })
//         }).catch(error => {
//             getPopupMessageBasedOnStatus(error)
//         })
//     }
//     loadBillingRoles(){
//         if(!this.state.line_id){
//             return;
//         }
//         this.setState({
//             billingRoles:[],
//             // isLoading:true
//         })
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         let url = Settings.apiPurchaseOrderUrl + '/get_billingrule/'+this.state.line_id+'/'+this.props.source_id;
//         if(this.props.viewType && this.props.viewType =='version'){
//             url = Settings.apiPurchaseOrderUrl + '/billingrule_version/'+this.state.line_id+'/'+this.props.version+'/'+this.props.source_id;
//         }
//         api.axios().get(url).then(res=>{
//             that.setState({
//                 isLoading:false,
//                 billingRoles:res.data.data
//             })
//         }).catch(error => {
//             that.setState({
//                 isLoading:false
//             })
//         })
//     }
//     loadBillingRuleType(){
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         let url = Settings.apiUrl + '/enum/billingrule_type';
//         api.axios().get(url).then(res=>{
//             that.setState({
//                 billingRuleTypes:res.data.data
//             })
//         })
//     }
//     loadBillingRuleStatus(){
//         let that = this;
//         let api = Api;
//         api.setUserToken();
//         let url = Settings.apiUrl + '/enum/billingrule_status';
//         api.axios().get(url).then(res=>{
//             that.setState({
//                 billingRuleStatus:res.data.data
//             })
//         })
//     }
//     onSaveHandler(data){
//         let totalApiCalls = data.length;
//         let that = this;
//         data.forEach(dataItem => {
//             if(dataItem.billingrule_id){
//                 that.updateBillingRoles(dataItem.billingrule_id,dataItem,function(res){
//                     totalApiCalls--;
//                     if(totalApiCalls<=0){
//                         that.afterDeleteOrUpdate(res.data.message)
//                     }
//                 },function(error){
//                     totalApiCalls--;
//                     getPopupMessageBasedOnStatus(error)
//                     if(totalApiCalls<=0){
//                         that.afterDeleteOrUpdate(error.response.data.message,'error')
//                     }
//                 })
//             }else{
//                 that.addNewBillingRoles(dataItem,function(res){
//                     totalApiCalls--;
//                     if(totalApiCalls<=0){
//                         that.afterDeleteOrUpdate(res.data.message)
//                     }
//                 },function(error){
//                     totalApiCalls--;
//                     getPopupMessageBasedOnStatus(error)
//                     if(totalApiCalls<=0){
//                         that.afterDeleteOrUpdate(error.response.data.message,'error')
//                     }
//                 })
//             }
//         });
//     }
//     afterDeleteOrUpdate(message,messageType= 'success',isReload = true){

//         if(isReload){
//             this.loadBillingRoles();
//         }
//         Helper.alert(message,messageType)
//     }
//     addNewBillingRoles(newDataObj,callBackSuccess = (res) => {},callbackError = (res) => {}){
//         let api = Api;
//         api.setUserToken();
//         let newData = {
//             line_id:this.state.line_id,
//             type:newDataObj.type,
//             ...this.getDataForDb(newDataObj),
//             status:newDataObj.status,
//             //owner: newDataObj.owner
//         }
//         api.axios().post(Settings.apiPurchaseOrderUrl+'/billingrule/'+this.props.source_id,newData).then(res => {
//             callBackSuccess(res)
//             this.loadBillingRoles()
//         }).catch(error => {
//             callbackError(error)
//         })
//     }
//     updateBillingRoles(rule_id,newDataObj,callBackSuccess = (res) => {},callbackError = (res) => {}){
//         let api = Api;
//         api.setUserToken();
//        let newData = {
//         ...this.getDataForDb(newDataObj)
//        }
//         api.axios().put(Settings.apiPurchaseOrderUrl+'/billingrule/'+rule_id+'/'+this.props.source_id,newData).then(res => {
//             callBackSuccess(res)
//             this.loadBillingRoles()
//         }).catch(error => {
//             callbackError(error)
//         })
//     }
//     onDeleteHandler(data){
//         let api = Api;
//         api.setUserToken();
//         data.forEach( dataItem => {
//             if(dataItem.billingrule_id){
//                 api.axios().delete(Settings.apiPurchaseOrderUrl+'/billingrule/'+dataItem.billingrule_id+'/'+this.props.source_id).then(res=>{
//                     Helper.alert(res.data.message)
//                     this.loadBillingRoles();
//                 }).catch(error => {
//                     getPopupMessageBasedOnStatus(error)
//                 })
//             }

//         })
//     }
//     componentDidUpdate(prevProps){
//         if(prevProps.line_id != this.props.line_id ){
//             let that = this;
//             this.setState({
//                 line_id:this.props.line_id
//             },function(){
//                 that.loadBillingRoles()
//                 that.loadItemFromId();
//             })
//         }
//     }
//     isBillingRuleTypeQuanity(){

//     }
//     switchCellMode(cells = [],status){
//         cells.forEach(cell => {
//             this.grid.columnApi.getColumn(cell).getColDef().editable = status;
//         })
//     }
//     getResetObject(keyArray){
//         let output = {};
//         keyArray.forEach(keyItem => {
//             output[keyItem] = ''
//         });
//         if(!keyArray.includes('quantity')){
//             output.quantity = this.getDefaltQuantityValue();
//         }
//         if(!keyArray.includes('unit_price')){
//             output.unit_price = this.props.defaultUnitPrice
//         }
//         if(!keyArray.includes('unit')){
//             output.unit = this.props.defaultUnit
//         }
//         if(!keyArray.includes('amount')){
//             output.amount = this.props.defaultAmount
//         }
//         return output;
//     }
//     enableDisableFields(billingRoleTypeValue){
//         this.isStopAutoSave  = true;
//         let that = this;
//         let getFocusedCell = this.grid.api.getFocusedCell();
//         let getSelectedNode = this.grid.api.getSelectedNodes();
//         this.grid.api.stopEditing();
//         let resetData ={};
//         if(billingRoleTypeValue == '0'){
//             resetData = this.getResetObject(['quantity','percentage', 'amount', 'needby_date', 'start_date', 'end_date']);
//         }else if(billingRoleTypeValue == '1'){ //Percentage
//             resetData = this.getResetObject(['quantity','percentage', 'amount', 'needby_date', 'start_date', 'end_date']);
//         }else if(billingRoleTypeValue == '2'){ //Milestone
//             resetData = this.getResetObject(['quantity','percentage', 'amount', 'needby_date', 'start_date', 'end_date']);
//         }else if(billingRoleTypeValue == '3'){ //Lump Sum
//             resetData = this.getResetObject(['quantity','percentage', 'amount', 'needby_date', 'start_date', 'end_date']);
//         }

//         if(getSelectedNode.length >=1){
//             getSelectedNode[0].setData({
//                 ...getSelectedNode[0].data,
//                 type: billingRoleTypeValue,
//                 ...resetData
//             })
//         }

//         setTimeout(function(){
//             that.grid.api.startEditingCell({
//                 rowIndex:getFocusedCell ? getFocusedCell.rowIndex : 0,
//                 colKey:'type'
//             })
//             that.isStopAutoSave  = false;
//         },100)

//     }
//     initBillingTypeChange(event,params){
//         this.enableDisableFields(event.target.value);
//         let totalQtyOrPercentageUsed = 0,totalAmountUsed = 0,remainingPerQty = 0, remainingAmount = 0;
//         params.api.forEachNode(node => {
//             if(node.rowIndex != params.rowIndex){
//                 totalQtyOrPercentageUsed += Helper.getOnlyNumber(node.data.percentage_or_quantity)
//                 totalAmountUsed += Helper.getOnlyNumber(node.data.amount)
//             }
//         })
//         remainingAmount = Helper.getOnlyNumber(this.props.defaultAmount) - totalAmountUsed;

//         if(event.target.value =='0'){
//             remainingPerQty = Helper.getOnlyNumber(this.props.defaultQuantity) - totalQtyOrPercentageUsed;
//         }else{
//             remainingPerQty = 100 - totalQtyOrPercentageUsed;
//         }
//         let editorInsAr = params.api.getCellEditorInstances();
//         editorInsAr.forEach( cellEditorIns => {
//             if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'amount'){
//                 if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
//                     cellEditorIns.updateValue(remainingAmount)
//                 }
//             }
//             if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'percentage_or_quantity'){
//                 if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
//                     cellEditorIns.updateValue(remainingPerQty)
//                 }
//             }
//         })
//         params.node.setDataValue('percentage_or_quantity', remainingPerQty);
//         params.node.setDataValue('amount', remainingAmount);

//     }
//     getDataForDb(dataObj){

//         return{
//             billingrulecategory_id:null,
//             status:null,
//             type: this.isDisableField(dataObj.type,'type')  ? null : Helper.getOnlyInt(dataObj.type),
//             description: this.isDisableField(dataObj.type,'description')  ? null : Helper.getNullableValue(dataObj.description),
//             amount: parseFloat(Helper.getNullableValue(dataObj.amount)),
//             needby_date:  this.isDisableField(dataObj.type,'needby_date')  ? null :  DatepickerFunctions.convertDateForDataBase(dataObj.needby_date),
//             start_date:  this.isDisableField(dataObj.type,'start_date')  ? null :  DatepickerFunctions.convertDateForDataBase(dataObj.start_date),
//             end_date:  this.isDisableField(dataObj.type,'end_date')  ? null :  DatepickerFunctions.convertDateForDataBase(dataObj.end_date),
//             remaining_percentage_or_qty:  this.isDisableField(dataObj.type,'remaining_percentage_or_qty')  ? null :  Helper.getOnlyNumber(dataObj.remaining_percentage_or_qty),
//             remaining_amount:  this.isDisableField(dataObj.type,'remaining_amount')  ? null :  Helper.getCurrencyForDb(dataObj.remaining_amount),
//             owner:  this.isDisableField(dataObj.type,'owner') || Helper.getOnlyInt(dataObj.owner)  == 0 ? null : Helper.getOnlyInt(dataObj.owner)  ,
//             percentage_or_quantity:  this.isDisableField(dataObj.type,'percentage_or_quantity')  ? null :  Helper.getOnlyNumber(dataObj.percentage_or_quantity),
//             over_delivery_allowed_percentage:  this.isDisableField(dataObj.type,'over_delivery_allowed_percentage')  ? null :  Helper.getOnlyNumber(dataObj.over_delivery_allowed_percentage),
//             item_detail_required:  dataObj.item_detail_required && (dataObj.item_detail_required == 'yes' || dataObj.item_detail_required === true) ? true : false,
//             unit:  Helper.getOnlyNumber(dataObj.unit) == 0  ? null : Helper.getOnlyNumber(dataObj.unit),
//             unit_price:  Helper.getNullableValue(dataObj.unit_price),
//             line_number:   Helper.getNullableValue(dataObj.line_number),
//             source_id:   this.props.source_id,
//             amendmentqtyorpercentage:null
//         }
//     }
//     isDisableField(billingType,field){
//         if(this.props.isEditable === false){
//             return false;
//         }
//         let line_type = this.props.line_type;
//         let item = this.state.itemData;
//         if(field == 'needby_date'){
//             if(line_type == '0' && item?.itemmaster_type =='0'){
//                 return false;
//             }
//             if(line_type == '0' && item?.itemmaster_type =='1' && billingType == '0'){
//                 return false;
//             }
//             if( (line_type == '1' || line_type == '2' ) && billingType == '0'){
//                 return false;
//             }
//             return true;
//         }
//         if(field == 'end_date' || field == 'start_date'){
//             if(line_type == '0' && item?.itemmaster_type =='0'){
//                 return true;
//             }
//             if(line_type == '0' && item?.itemmaster_type =='1' && billingType == '0'){
//                 return true;
//             }
//             if( (line_type == '1' || line_type == '2' ) && billingType == '0'){
//                 return true;
//             }
//             return false;
//         }
//         if(field == 'unit_price'){
//             if(line_type == '0' && item?.itemmaster_type =='0'){
//                 return true;
//             }
//             if(line_type == '0' && item?.itemmaster_type =='1' && ( billingType == '0' || billingType == '1' ) ){
//                 return true;
//             }
//             if( (line_type == '1' || line_type == '2' ) && ( billingType == '0' || billingType == '1' )  ){
//                 return true;
//             }
//             return false;
//         }
//         if(field == 'unit'){
//             if(line_type == '0' && item?.itemmaster_type =='0'){
//                 return true;
//             }
//             if(line_type == '0' && item?.itemmaster_type =='1' && billingType == '0' ){
//                 return true;
//             }
//             if( (line_type == '1' || line_type == '2' ) && billingType == '0'  ){
//                 return true;
//             }
//             return false;
//         }
//         if(field == 'amount'){
//             if(billingType == '0'){
//                 return true;
//             }

//             return false;
//         }
//         return false;
//     }
//     hasCompletedRequredFields(data){

//     }
//     onRowEditingStopped(event){
//         let dataItem = event.data;
//         let that = this;
//         if(this.grid.componentObj.checkValidationError()){
//             this.grid.api.startEditingCell({
//                 rowIndex: event.rowIndex,
//                 colKey: 'type'
//             });
//             return;
//         }
//         if(this.isStopAutoSave){
//             return;
//         }
//         if(!Helper.getNullableValue(dataItem.billingrule_id)){
//             that.addNewBillingRoles(dataItem,(res)=>{
//                 event.node.setData({
//                     ...event.data,
//                     billingrule_id:res.data.billingrule_id
//                 });
//                 that.afterDeleteOrUpdate(res.data.message,res.data.status == 'fail' ? 'error' : 'success',false )
//             },(error) => {
//                 getPopupMessageBasedOnStatus(error)
//             })
//         }
//     }
//     onRowValueChanged(event){
//         let dataItem = event.data;
//         let that = this;
//         if(this.grid.componentObj.checkValidationError()){
//             this.grid.api.startEditingCell({
//                 rowIndex: event.rowIndex,
//                 colKey: 'type'
//             });
//             return;
//         }
//         if(this.isStopAutoSave){
//             return;
//         }
//         if(dataItem.billingrule_id){
//             that.updateBillingRoles(dataItem.billingrule_id,dataItem, (res) => {
//                 Helper.alert(res.data.message)
//             })
//         }
//     }
//     getDefaltQuantityValue(){
//         let maxValue = Helper.getOnlyNumber(this.props.defaultQuantity);
//         let distributedQuanity = 0;
//         this.grid.api.forEachNode(node => {
//             distributedQuanity += Helper.getOnlyNumber(node.data.quantity)
//         })
//         return maxValue - distributedQuanity;
//     }
//     getQuanityEditorMaxValue(oldValue,params,inputBox,editorInput){
//         let valueUsed = 0;
//         params.api.forEachNode(node => {
//             if(node.rowIndex != params.rowIndex){
//                 valueUsed += Helper.getOnlyNumber(node.data.percentage_or_quantity)
//             }
//         })
//         valueUsed += Helper.getPositiveNumber(oldValue);

//         let maxValueAllow = Helper.getOnlyNumber(this.props.defaultQuantity);
//         if(params.data.type != '0'){
//             maxValueAllow = 100;
//         }

//         if(valueUsed >maxValueAllow){
//             maxValueAllow = maxValueAllow - ( valueUsed - Helper.getPositiveNumber(oldValue) )
//         }
//         return maxValueAllow;
//     }
//     getQtyOrPercentageDefaultValue(params){
//         return this.props.misc_charges?100:0;
//     }

//     percentageMaxValue(oldValue,params,editorInput,event){
//         let valueUsed = 0;
//         params.api.forEachNode(node => {
//             if(node.rowIndex != params.rowIndex){
//                 valueUsed += Helper.getOnlyNumber(node.data.percentage)
//             }
//         })
//         valueUsed += Helper.getPositiveNumber(editorInput.value);
//         let maxValueAllow = 100;
//         if(valueUsed > 100){
//             maxValueAllow = 100 - ( valueUsed - Helper.getPositiveNumber(editorInput.value) )
//         }

//         return maxValueAllow;
//     }
//     getDefaltAmountValue(){
//         return "0";
//     }
//     getAmountEditorMaxValue(oldValue,params,inputBox,editorInput){
//         let maxValueAllow = Helper.getOnlyNumber(this.props.defaultAmount);
//         let valueUsed = 0;
//         params.api.forEachNode(node => {
//             if(node.rowIndex != params.rowIndex){
//                 valueUsed += Helper.getOnlyNumber(node.data.amount)
//             }
//         })
//         valueUsed += Helper.getPositiveNumber(oldValue);
//         if(valueUsed >maxValueAllow){
//             maxValueAllow = maxValueAllow - ( valueUsed - Helper.getPositiveNumber(oldValue) )
//         }
//         return maxValueAllow;
//     }
//     getDefaultLineNumber(params){
//         let output = 1;
//         params.api.forEachNode(nodeItem => {
//             if( Helper.getOnlyNumber(nodeItem.data.line_number) >= output ){
//                 output = Helper.getOnlyNumber(nodeItem.data.line_number)  + 1
//             }
//         })
//         return output;
//     }
//     isEditableField(params){
//         let fildName = params.colDef.field;
//         if(this.isViewOnlyMode()){
//             return false;
//         }
//         return !this.isDisableField(params.data.type,fildName)
//     }
//     onQuntityChangeHandler(event,params){
//         let quantityUnitPrice = this.props.defaultAmount / this.props.defaultQuantity;
//         let newAmount = quantityUnitPrice * event.target.value;
//         if(params.data.type !='0'){
//             newAmount = ( event.target.value /100  ) * this.props.defaultAmount;
//         }
//         let editorInsAr = params.api.getCellEditorInstances();
//         editorInsAr.forEach( cellEditorIns => {
//             if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'amount'){
//                 if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
//                     cellEditorIns.updateValue(newAmount)
//                 }else{

//                 }
//             }
//         })
//         params.node.setDataValue('amount', newAmount);
//     }
//     onAmountChangeHandler(event,params){
//         let quantityUnitPrice = this.props.defaultAmount / this.props.defaultQuantity;
//         let percentage_or_quantity = Math.random();
//         if(params.data.type !='0'){
//             percentage_or_quantity = ( event.target.value / this.props.defaultAmount) * 100 ;
//         }
//         let editorInsAr = params.api.getCellEditorInstances();
//         editorInsAr.forEach( cellEditorIns => {
//             if(cellEditorIns.params && cellEditorIns.params.colDef.field == 'percentage_or_quantity'){
//                 if(cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function'){
//                     cellEditorIns.updateValue(percentage_or_quantity)
//                 }else{

//                 }
//             }
//         })
//         params.node.setDataValue('percentage_or_quantity', percentage_or_quantity);
//     }
//     getAmendmentButton(){
//         if(this.props.viewType == 'amendment'){
//             if(this.props.disableDecreaseAmendment){
//                 return(
//                     <p class="rs_h_controller  ctl-dissable"><img src="/images/icons/pen-gray-with-iocon.svg" alt="Decrease amendment"/><span>{Helper.getLabel(null,'decrease_amendment','Decrease amendment')}</span></p>
//                 )
//             }
//             return(
//                 <p class="rs_h_controller" onClick={ this.onClickAmendmentButton.bind(this) }><img src="/images/icons/pen-gray-with-iocon.svg" alt="Decrease amendment"/><span>{Helper.getLabel(null,'decrease_amendment','Decrease amendment')}</span></p>
//             )
//         }
//     }
//     afterDeleteButton(){
//         return(
//             <>
//                 {this.getAmendmentButton()}
//                 <div className='top_labels'>
//                     <p><span>{Helper.getLabel(null,'total','Total')} :</span> {Helper.formateCurrency(this.props.defaultAmount)}</p>
//                 </div>
//             </>
//         );
//     }
//     isQuantityTypeExistInGrid(gridOptionsObj){
//         let isFoundQuantityType = false;
//         if(gridOptionsObj){
//             gridOptionsObj.api.forEachNode((node,key) => {
//                 if(node.data.type == '0' && key>=1){
//                     isFoundQuantityType = true;
//                     return isFoundQuantityType;
//                 }
//             })
//         }
//         return isFoundQuantityType;
//     }
//     getDisableBillingTypes (params){
//         let line_type = this.props.line_type;
//         let totalItems = params.api.getDisplayedRowCount();
//         let output = [];
//         let isQuantityExist = this.isQuantityTypeExistInGrid(params);
//         if(isQuantityExist){
//             return [1,2,3];
//         }else{
//             if(line_type == '0'){
//                 let item = this.state.itemData;
//                 if(item?.itemmaster_type =='0'){
//                     output = [1,2,3]
//                 }else{
//                     if(totalItems >= 2){
//                         output = [0]
//                     }
//                 }
//             }else{
//                 if(totalItems >= 2){
//                     output = [0]
//                 }
//             }
//         }

//         return output;
//     }
//     getDefaultValueOfType(prarams){

//         let totalItems = prarams.api.getDisplayedRowCount();

//         let isQuantityExist = this.isQuantityTypeExistInGrid(prarams);
//         if(isQuantityExist){
//             return '0';
//         }
//         if(totalItems>=1){
//             return "1";
//         }
//         return '0';
//     }
//     onClickAmendmentButton(){
//         this.setState({
//             amendmentPopupShow:true
//         })
//     }
//     onCloseSegmentSitePopup(){
//         this.setState({
//             amendmentPopupShow:false
//         })
//     }
//     getGridHeadings(){
//         let language = null;
//         let units = this.props.units;
//         let types = this.state.billingRuleTypes.map( item => {
//             return {
//                 label:item.name,
//                 value:item.id
//             }
//         })
//         let filterType = this.state.billingRuleTypes
//         .filter(item => item.name === "Lump Sum")
//         .map(item => ({
//           label: item.name,
//           value: item.id
//         }));

//         let status = this.state.billingRuleStatus.map( item => {
//             return {
//                 label:item.name,
//                 value:item.id
//             }
//         })

//         const gridValidationRules ={
//             description: {types:[], min:1, max:80},
//             type: {types:['Number']},
//             quantity: {types:['Number']},
//             unit: {types:[]},
//             unit_price: {types:['Number']},
//             percentage: {types:['Number'], max:100,min:1},
//             amount: {types:['Number','Required']},
//             needby_date: {types:[]},
//             start_date: {types:[]},
//             end_date: {types:[]},
//             owner: {types:[]},
//             remaining_amount: {types:['Required']},
//             remaining_percentage: {types:['Number','Required']},
//             status: {types:[]}
//         }
//         let ownerDefaultValue = this.props.ownerDefaultValue;
//         const Columns_Headings = [
//             {field:'line_number', editable: this.isEditableField.bind(this),  headerName:Helper.getLabel(language,'line_no',"Line No."),minWidth: 150 ,inputType:'number',  defaultValue: this.getDefaultLineNumber.bind(this)},
//             {field:'type', dropdownDisableAllowClear:true, disableOptions:this.getDisableBillingTypes.bind(this), headerName:Helper.getLabel(language,'type',"Type *"), options:types,defaultValue: this.getDefaultValueOfType.bind(this),minWidth: 150, inputType: "dropdown",inputId:'billingRuleTypeInput', onChange: this.initBillingTypeChange.bind(this),editable: (params) => { return params.data.billingrule_id ? false : true }},
//             {field:'owner', headerName:Helper.getLabel(language,'owner',"Owner"), editable: this.isEditableField.bind(this), validationRule:gridValidationRules.owner, options:this.props.employeeList, inputType: "dropdown",minWidth: 200,defaultValue:ownerDefaultValue},
//             {field:'description', headerName:Helper.getLabel(language,'description',"Description"),minWidth: 350, },
//             {field:'unit', headerName:Helper.getLabel(language,'unit',"Unit"), editable: this.isEditableField.bind(this), options: units, inputType: "dropdown",minWidth: 200,defaultValue:this.props.defaultUnit },
//             {field:'unit_price', inputType:'number', headerName:Helper.getLabel(language,'unit_price',"Unit Price *"), editable: false ,minWidth: 200, defaultValue:this.props.defaultUnitPrice },
//             {field:'percentage_or_quantity', inputType:'number',onlyPositive:true, headerName:Helper.getLabel(language,'percentage_or_quantity',"Percentage or quantity"), editable: this.isEditableField.bind(this),minWidth: 250,  defaultValue: this.getQtyOrPercentageDefaultValue.bind(this) , maxValue: this.getQuanityEditorMaxValue.bind(this) , onChange:this.onQuntityChangeHandler.bind(this) },
//             {field:'amount', inputType:'number', headerName:Helper.getLabel(language,'amount',"Amount *"), editable: this.isEditableField.bind(this),minWidth: 250, defaultValue: this.getDefaltAmountValue.bind(this) , maxValue: this.getAmountEditorMaxValue.bind(this), onChange:this.onAmountChangeHandler.bind(this) },
//             {field:'needby_date', headerName:Helper.getLabel(language,'needbydate',"Need By Date"), editable: this.isEditableField.bind(this), inputType: "datepicker",minWidth: 250, },
//             {field:'start_date', headerName:Helper.getLabel(language,'start_date',"Start Date"), editable: this.isEditableField.bind(this), inputType: "datepicker",minWidth: 250,},
//             {field:'end_date', headerName:Helper.getLabel(language,'end_date',"End Date"), editable: this.isEditableField.bind(this), inputType: "datepicker",minWidth: 250,},
//             {field:'remaining_amount', inputType:'number', headerName:Helper.getLabel(language,'remaining_amount',"Remaining amount"), editable: this.isEditableField.bind(this),minWidth: 200, defaultValue:"0" },
//             {field:'remaining_percentage_or_qty', inputType:'number', headerName:Helper.getLabel(language,'remaining_percentage_or_qty',"Remaining percentage or quantity"), editable: this.isEditableField.bind(this),minWidth: 300, defaultValue:"0" },
//             {field:'over_delivery_allowed_percentage', inputType:'number', headerName:Helper.getLabel(language,'over_delivery_allowed_percentage',"Over delivery allowed percentage"), editable: this.isEditableField.bind(this),minWidth: 300, defaultValue:"0" },
//             {field:'item_detail_required', inputType:'checkbox', headerName:Helper.getLabel(language,'item_detail_required',"Item detail required"), editable: this.isEditableField.bind(this),minWidth: 250 },
//             {field:'status',editable:false, headerName:Helper.getLabel(language,'status',"Status *"),  options:status, inputType: "dropdown",minWidth: 150,defaultValue:'0' },
//         ]
//         const Misc_Charges_Columns = [
//             {field:'type', dropdownDisableAllowClear:true, headerName:Helper.getLabel(language,'type',"Type *"), options:this.props.misc_charges?filterType:types, minWidth: 150, inputType: "dropdown",inputId:'billingRuleTypeInput', onChange: this.initBillingTypeChange.bind(this),editable: (params) => { return params.data.billingrule_id ? false : true }},
//             {field:'description', headerName:Helper.getLabel(language,'description',"Description"),minWidth: 350, },
//             {field:'unit_price', inputType:'number', headerName:Helper.getLabel(language,'unit_price',"Unit Price *"), editable: false ,minWidth: 200, defaultValue:this.props.defaultUnitPrice },
//             {field:'percentage_or_quantity', inputType:'number',onlyPositive:true, headerName:Helper.getLabel(language,'percentage_or_quantity',"Percentage or quantity"), editable: false,minWidth: 250,  defaultValue: this.getQtyOrPercentageDefaultValue.bind(this)},
//             {field:'amount', inputType:'number', headerName:Helper.getLabel(language,'amount',"Amount *"), editable: this.isEditableField.bind(this),minWidth: 250, defaultValue: this.getDefaltAmountValue.bind(this) , maxValue: this.getAmountEditorMaxValue.bind(this), onChange:this.onAmountChangeHandler.bind(this) },
//         ]
//         return this.props.misc_charges?Misc_Charges_Columns:Columns_Headings;
//     }
//     isViewOnlyMode(){
//         if( Helper.getOnlyNumber(this.props.defaultAmount ) <= 0){
//             return true;
//         }
//         return !this.props.isEditable
//     }
//     render() {
//         if(this.state.isLoading){
//             return <div className='po_billing_rule_tab_loader'><Collapse title="Billing Rules" open={true} wraperStyle={{minHeight:'750px'}}><SimpleLoading /></Collapse></div>
//         }
//         let language = null;
//         let units = this.props.units;
//         let gridData = this.state.billingRoles;
//         let customSettings = {
//             singleClickEdit:false,
//         }
//         const Columns_Headings = this.getGridHeadings();
//         return (
//             <div className='po_billing_rule_tab'>
//                     { this.state.amendmentPopupShow ? <AmendmentBillingRulePopup {...this.props} po_line_id={this.props?.line_id} amendment_id = { this.props.amendment_id ? this.props.amendment_id : null }  onClose={this.onCloseSegmentSitePopup.bind(this)} /> :  ''}
//                     <Collapse title="Billing Rules" open={true}>
//                         <AgGrid

//                             readOnlyGrid={this.isViewOnlyMode()}
//                             settings={customSettings}
//                             //doubleClickEdit = {true}
//                             noValidationOnRowEditingStop={true}
//                             onGridReady={ (grid=> { this.grid = grid; })}
//                             onDelete = { this.onDeleteHandler.bind(this)}
//                             //deleteConfig={{ apiUrl: Settings.apiPurchaseOrderUrl + '/billingrule' , idName:'billingrule_id', that:this , reloadFuntion:()=> {this.loadBillingRoles()}}}
//                             id={`po_billing_role_${this.props.billingrulegrid_id || "1"}`}
//                             onSave={ this.onSaveHandler.bind(this)}
//                             header={Columns_Headings} data={gridData}
//                             onRowValueChanged ={ this.onRowValueChanged.bind(this)}
//                             onRowEditingStopped ={ this.onRowEditingStopped.bind(this)}
//                             hideSave={true}
//                             afterDeleteButtons = { this.afterDeleteButton.bind(this)}
//                         />
//                     </Collapse>
//             </div>

//         );
//     }
// }

// export default RsDataLoader(BillingRuleView);

let grid = null
let isStopAutoSave = false
let AddBtn = false
let transaction

const BillingRuleView = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [itemData, setItemData] = useState(null)
  const [amendmentPopupShow, setAmendmentPopupShow] = useState(false)
  const [billingRuleTypes, setBillingRuleTypes] = useState([])
  const [billingRuleStatus, setBillingRuleStatus] = useState([])
  const [line_id, setLineId] = useState(null)

  let newAddItems = {item_detail_required:false}
  let totalDropdownPageEmployeeItem = 1;

  useEffect(() => {
    setLineId(props.line_id)
    loadBillingRuleType()
    loadItemFromId()
    loadBillingRuleStatus()
  }, [props.line_id])

  const loadItemFromId = () => {
    const item_id = props.item_id
    if (!item_id) {
      setIsLoading(true)
      setTimeout(()=>{
        setIsLoading(false)
      },300)
      setItemData(null)
      return
    }
    setIsLoading(true)
    setItemData(null)
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(`${Settings.apiItemUrl}/item/${item_id}`)
      .then((res) => {
        setItemData(res.data.data)
        setIsLoading(false)
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const loadBillingRuleType = () => {
    const api = Api
    api.setUserToken()
    const url = `${Settings.apiUrl}/enum/billingrule_type`
    api
      .axios()
      .get(url)
      .then((res) => {
        setBillingRuleTypes(res.data.data)
      })
  }

  const loadBillingRuleStatus = () => {
    const api = Api
    api.setUserToken()
    const url = `${Settings.apiUrl}/enum/billingrule_status`
    api
      .axios()
      .get(url)
      .then((res) => {
        setBillingRuleStatus(res.data.data)
      })
  }

  const handleAddBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    let dataItem = event.data
    dataItem = {
      ...dataItem,
      percentage_or_quantity: dataItem.percentage_or_quantity,
    }
    if (isStopAutoSave) {
      return
    }
    if (dataItem.billingrule_id) {
      updateBillingRoles(dataItem.billingrule_id, dataItem, (res) => {
        Helper.alert(res.data.message)
      })
    } else {
      addNewBillingRoles(dataItem, (res) => {
        Helper.alert(res.data.message)
      })
    }
  }

  const onCloseSegmentSitePopup = () => {
    setAmendmentPopupShow(false)
  }

  const onClickAmendmentButton = () => {
    setAmendmentPopupShow(true)
  }

  const AfterDeleteButton = () => {
    return (
      <>
        {getAmendmentButton()}
        <div className='top_labels'>
          <p>
            <span>{Helper.getLabel(null, 'total', 'Total')} :</span> {Helper.formateCurrency(props.defaultAmount)}
          </p>
        </div>
      </>
    )
  }

  const getQtyOrPercentageDefaultValue = (params) => {
    return props.misc_charges ? 100 : 0
  }

  const getAmendmentButton = () => {
    if (props.viewType == 'amendment') {
      if (props.disableDecreaseAmendment) {
        return (
          <p className='rs_h_controller  ctl-dissable'>
            <img src='/images/icons/pen-gray-with-iocon.svg' alt='Decrease amendment' />
            <span>{Helper.getLabel(null, 'decrease_amendment', 'Decrease amendment')}</span>
          </p>
        )
      }
      return (
        <p className='rs_h_controller' onClick={onClickAmendmentButton}>
          <img src='/images/icons/pen-gray-with-iocon.svg' alt='Decrease amendment' />
          <span>{Helper.getLabel(null, 'decrease_amendment', 'Decrease amendment')}</span>
        </p>
      )
    }
  }

  const isEditableField = (params) => {
    let fieldName = params.colDef.field
    if (isViewOnlyMode()) {
      return false
    }
    return !isDisableField(params.data.type, fieldName, params)
  }

  const getDefaultLineNumber = (params) => {
    let output = 1
    params.api.forEachNode((nodeItem) => {
      if (Helper.getOnlyNumber(nodeItem.data.line_number) >= output) {
        output = Helper.getOnlyNumber(nodeItem.data.line_number) + 1
      }
    })
    return output
  }

  const getDefaultAmountValue = () => {
    return '0'
  }

  const getDefaltQuantityValue = () => {
    let maxValue = Helper.getOnlyNumber(props.defaultQuantity)
    let distributedQuanity = 0
    grid.api.forEachNode((node) => {
      distributedQuanity += Helper.getOnlyNumber(node?.data?.quantity)
    })
    return maxValue - distributedQuanity
  }

  const getAmountEditorMaxValue = (oldValue, params) => {
    let maxValueAllow = Helper.getOnlyNumber(props.defaultAmount)
    let valueUsed = 0
    params.api.forEachNode((node) => {
      if (node.rowIndex != params.rowIndex) {
        valueUsed += Helper.getOnlyNumber(node?.data?.amount)
      }
    })
    valueUsed += Helper.getPositiveNumber(oldValue)
    if (valueUsed > maxValueAllow) {
      maxValueAllow = maxValueAllow - (valueUsed - Helper.getPositiveNumber(oldValue))
    }
    return maxValueAllow
  }

  const getGridHeadings = () => {
    let language = null
    let units = props.units
    let types = billingRuleTypes.map((item) => {
      return {
        label: item.name,
        value: item.id,
      }
    })
    let filterType = billingRuleTypes
      .filter((item) => item.name === 'Lump Sum')
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))

    let status = billingRuleStatus.map((item) => {
      return {
        label: item.name,
        value: item.id,
      }
    })

  let select2SettingsEmployee = {
    ajax: {
      url: (params) =>{
        totalDropdownPageEmployeeItem = params.page || 1;
        return `${Settings.apiOrgUrl}/employee_dropdown?status=true`;
      },
      processResults: function (data) {
        let isMorePageExist = ( data.total_records - ( totalDropdownPageEmployeeItem * Settings.dropdownPageSize )) > 0 ? true : false ; 
        let result = data?.data?.map( item => {
          return {
            id: item.employee_id,
            text: item.displayname
          }
        })
        return {
          results: result,
          pagination: {
            more: isMorePageExist
          }
        };
      }
    }
  };
  

    let ownerDefaultValue = props.ownerDefaultValue
    const Columns_Headings = [
      { field: 'line_number', editable: false, headerName: Helper.getLabel(language, 'line_no', 'Line number'), minWidth: 150, cellEditor: NumberCellEditor, defaultValue: getDefaultLineNumber },
      {
        field: 'type',
        dropdownDisableAllowClear: true,
        disableOptions: getDisableBillingTypes,
        headerName: Helper.getLabel(language, 'type', 'Type'),
        minWidth: 150,
        filter: false,
        sortable: false,
        cellEditor: SimpleDropdown,
        cellEditorParams: { values: types },
        inputId: 'billingRuleTypeInput',
        onChange: initBillingTypeChange,
        editable: (params) => {
          return params.data.billingrule_id ? false : true
        },
        defaultValue: getDefaultValueOfType,
        valueFormatter: (params) => getLabelFromDropdown(params),
      },
      {
        field: 'owner',
        headerName: Helper.getLabel(language, 'owner', 'Owner'),
        editable: isEditableField,
        cellEditor: SimpleDropdown,
        cellEditorParams: { values: (params) => { return params?.data?.billingrule_id && params?.data?.owner !== null ? [{value:params?.data?.owner,label:params?.data?.owner_name}] : props.employeeList; },},
        select2Settings:select2SettingsEmployee,
        minWidth: 200,
        // defaultValue: ownerDefaultValue,
        valueFormatter: (params) => params.data.owner_name,
      },
      { field: 'description', headerName: Helper.getLabel(language, 'description', 'Description'), minWidth: 350, editable: isEditableField },
      {
        field: 'unit',
        headerName: Helper.getLabel(language, 'unit', 'Unit'),
        editable: (params) => {
          return isEditableField(params)
        },
        cellEditor: SimpleDropdown,
        cellEditorParams: { values: units },
        minWidth: 200,
        defaultValue: props.defaultUnit,
        valueFormatter: (params) => getLabelFromDropdown(params),
      },
      { field: 'unit_price', inputType: 'number', headerName: Helper.getLabel(language, 'unit_price', 'Unit price'), editable: false, minWidth: 200, defaultValue: props.defaultUnitPrice,valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"},  },
      {
        field: 'percentage_or_quantity',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        positive: true,
        headerName: Helper.getLabel(language, 'percentage_or_quantity', 'Percentage or quantity'),
        editable: isEditableField,
        minWidth: 250,
        defaultValue: getQtyOrPercentageDefaultValue,
        maxValue: getQuanityEditorMaxValue,
        onChange: onQuantityChangeHandler,
      },
      {
        field: 'amount',
        cellEditor: NumberCellEditor,
        cellRenderer: CurrencyRenderer,
        headerName: Helper.getLabel(language, 'amount', 'Line amount'),
        editable: isEditableField,
        minWidth: 250,
        defaultValue: getDefaultAmountValue,
        maxValue: getAmountEditorMaxValue,
        onChange: onAmountChangeHandler,
        valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
      },
      {
        field: 'needby_date',
        headerName: Helper.getLabel(language, 'needbydate', 'Need by date'),
        editable: isEditableField,
        cellEditor: DateEditor,
        minrWidth: 250,
        valueFormatter: (params) => (DatepickerFunctions.convertDateFromDataBase(params.value)),
      },
      {
        field: 'start_date',
        headerName: Helper.getLabel(language, 'start_date', 'Start date'),
        editable: (params) => {
          return isEditableField(params)
        },
        cellEditor: DateEditor,
        cellEditorParams: { empty: true },
        minWidth: 250,
        valueFormatter: (params) => (DatepickerFunctions.convertDateFromDataBase(params.value)),
      },
      {
        field: 'end_date',
        headerName: Helper.getLabel(language, 'end_date', 'End date'),
        editable: (params) => {
          return isEditableField(params)
        },
        cellEditor: DateEditor,
        minWidth: 250,
        valueFormatter: (params) => (DatepickerFunctions.convertDateFromDataBase(params.value)),
      },
      {
        field: 'remaining_amount',
        cellEditor: NumberCellEditor,
        cellRenderer: CurrencyRenderer,
        headerName: Helper.getLabel(language, 'remaining_amount', 'Remaining amount'),
        editable: isEditableField,
        minWidth: 200,
        defaultValue: '0',
        valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
      },
      {
        field: 'remaining_percentage_or_qty',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        headerName: Helper.getLabel(language, 'remaining_percentage_or_qty', 'Remaining percentage or quantity'),
        editable: isEditableField,
        minWidth: 300,
        defaultValue: '0',
      },
      {
        field: 'over_delivery_allowed_percentage',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        headerName: Helper.getLabel(language, 'over_delivery_allowed_percentage', 'Over delivery allowed percentage'),
        editable: isEditableField,
        minWidth: 300,
        defaultValue: '0',
      },
      {
        field: 'item_detail_required',
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellRendererParams: { disable: true },
        headerName: Helper.getLabel(language, 'item_detail_required', 'Item detail required'),
        editable: isEditableField,
        minWidth: 250,
      },
      {
        field: 'status',
        editable: false,
        headerName: Helper.getLabel(language, 'status', 'Status'),
        cellEditor: SimpleDropdown,
        cellEditorParams: { values: status },
        minWidth: 150,
        defaultValue: '0',
        valueFormatter: (params) => getLabelFromDropdown(params),
      },
    ]
    const Misc_Charges_Columns = [
      {
        field: 'type',
        dropdownDisableAllowClear: true, 
        headerName: Helper.getLabel(language, 'type', 'Type'),
        cellEditorParams: { values: props.misc_charges ? filterType : types },
        minWidth: 150, 
        cellEditor: SimpleDropdown,
        inputId: 'billingRuleTypeInput',
        onChange: initBillingTypeChange,
        editable: (params) => {
          return params.data.billingrule_id ? false : true
        },
      },
      { field: 'description', headerName: Helper.getLabel(language, 'description', 'Description'), minWidth: 350, editable: isEditableField },
      {
        field: 'unit_price',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        headerName: Helper.getLabel(language, 'unit_price', 'Unit price'),
        editable: false,
        minWidth: 200,
        defaultValue: props.defaultUnitPrice,
      },
      {
        field: 'percentage_or_quantity',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        onlyPositive: true,
        headerName: Helper.getLabel(language, 'percentage_or_quantity', 'Percentage or quantity'),
        editable: false,
        minWidth: 250,
        defaultValue: getQtyOrPercentageDefaultValue,
      },
      {
        field: 'amount',
        cellEditor: NumberCellEditor,
        cellRenderer: NumberCellRenderer,
        headerName: Helper.getLabel(language, 'amount', 'Amount'),
        editable: isEditableField,
        minWidth: 250,
        defaultValue: getDefaultAmountValue,
        maxValue: getAmountEditorMaxValue,
        onChange: onAmountChangeHandler,
      },
    ]
    return props.misc_charges ? Misc_Charges_Columns : Columns_Headings
  }

  const getResetObject = (keyArray) => {
    let output = {}
    keyArray.forEach((keyItem) => {
      output[keyItem] = ''
    })
    if (!keyArray.includes('quantity')) {
      output.quantity = getDefaltQuantityValue()
    }
    if (!keyArray.includes('unit_price')) {
      output.unit_price = props.defaultUnitPrice
    }
    if (!keyArray.includes('unit')) {
      output.unit = props.defaultUnit
    }
    if (!keyArray.includes('amount')) {
      output.amount = props.defaultAmount
    }
    return output
  }

  const addNewBillingRoles = (newDataObj, callBackSuccess = (res) => {}, callbackError = (res) => {}) => {
    const api = Api
    api.setUserToken()
    const newData = {
      line_id: props.line_id,
      type: newDataObj.type,
      owner: parseInt(newDataObj.owner),
      ...getDataForDb(newDataObj),
      status: parseInt(newDataObj.status) || 0,
      percentage_or_quantity: Number(parseFloat(newDataObj?.percentage_or_quantity)?.toFixed(6)),
      item_detail_required:newDataObj?.item_detail_required,
      needby_date: newDataObj?.needby_date === null || newDataObj?.needby_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.needby_date)),
      start_date: newDataObj?.start_date === null || newDataObj?.start_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.start_date)),
      end_date: newDataObj?.end_date === null || newDataObj?.end_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.end_date))
    }
    if(newData?.percentage_or_quantity===0){
      Helper.alert('Percentage/quantity can not be zero.','error')
      return
    }
    api
      .axios()
      .post(`${Settings.apiPurchaseOrderUrl}/billingrule/${props.source_id}`, newData)
      .then((res) => {
        callBackSuccess(res)
        setTimeout(() => {
          grid?.api?.refreshServerSide({ purge: true })
        }, 200)
        grid?.api?.deselectAll()
        setTimeout(() => {
          if (AddBtn) {
            grid?.api?.applyServerSideTransaction(transaction);
          }
        }, 1000);
      })
      .catch((error) => {
        callbackError(error)
        getPopupMessageBasedOnStatus(error)
      })
  }

  const isQuantityTypeExistInGrid = (gridOptionsObj) => {
    let isFoundQuantityType = false
    if (gridOptionsObj) {
      gridOptionsObj.api.forEachNode((node) => {
        if (node?.data?.type == 0 && node?.rowIndex >= 1) {
          isFoundQuantityType = true
          return isFoundQuantityType
        }
      })
    }
    return isFoundQuantityType
  }

  const updateBillingRoles = (rule_id, newDataObj, callBackSuccess = (res) => {}, callbackError = (res) => {}) => {
    const api = Api
    api.setUserToken()
    const newData = {
      ...getDataForDb(newDataObj),
      percentage_or_quantity: Number(parseFloat(newDataObj?.percentage_or_quantity)?.toFixed(6)),
      owner: parseInt(newDataObj?.owner),
      unit: parseInt(newDataObj?.unit),
      item_detail_required:newDataObj?.item_detail_required,
      needby_date: newDataObj?.needby_date === null || newDataObj?.needby_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.needby_date)),
      start_date: newDataObj?.start_date === null || newDataObj?.start_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.start_date)),
      end_date: newDataObj?.end_date === null || newDataObj?.end_date === undefined ? null : DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(newDataObj?.end_date))
    }
    api
      .axios()
      .put(`${Settings.apiPurchaseOrderUrl}/billingrule/${rule_id}/${props.source_id}`, newData)
      .then((res) => {
        callBackSuccess(res)
        setTimeout(() => {
          grid?.api?.refreshServerSide({ purge: true })
        }, 200)
        grid?.api?.deselectAll()
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const onDeleteHandler = useCallback(async (element,index,lastIndex) => {
    let api = Api
    api.setUserToken()
    try {
      const res = await api.axios().delete(`${Settings.apiPurchaseOrderUrl}/billingrule/${element?.data?.billingrule_id}/${props.source_id}`)
      const rowNode = grid?.api?.getRowNode(element?.data?.billingrule_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        grid?.api?.applyServerSideTransaction(transaction)
      }, 200)
      if(index===lastIndex){
        grid?.api?.refreshServerSide({purge:true})
      }
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  let url = `${Settings.apiPurchaseOrderUrl}/get_billingrule/${props.line_id}/${props.source_id}`
  if (props.viewType && props.viewType === 'version') {
    url = `${Settings.apiPurchaseOrderUrl}/billingrule_version/${line_id}/${props.version}/${props.source_id}`
  }

  const getQuanityEditorMaxValue = (oldValue, params) => {
    let valueUsed = 0
    params.api.forEachNode((node) => {
      if (node.rowIndex != params.rowIndex) {
        valueUsed += Helper.getOnlyNumber(node?.data?.percentage_or_quantity)
      }
    })
    valueUsed += Helper.getPositiveNumber(oldValue)

    let maxValueAllow = Helper.getOnlyNumber(props.defaultQuantity)
    if (params.data.type != '0') {
      maxValueAllow = 100
    }

    if (valueUsed > maxValueAllow) {
      maxValueAllow = maxValueAllow - (valueUsed - Helper.getPositiveNumber(oldValue))
    }
    return maxValueAllow
  }

  const enableDisableFields = (billingRoleTypeValue) => {
    isStopAutoSave = true
    let getFocusedCell = grid?.api?.getFocusedCell()
    let getSelectedNode = grid?.api?.getSelectedNodes()
    grid.api.stopEditing()
    let resetData = {}
    if (billingRoleTypeValue == '0') {
      resetData = getResetObject(['quantity', 'percentage' , 'amount', 'needby_date', 'start_date', 'end_date'])
    } else if (billingRoleTypeValue == '1') {
      resetData = getResetObject(['quantity', 'percentage', 'unit' ,  'amount', 'needby_date', 'start_date', 'end_date'])
    } else if (billingRoleTypeValue == '2') {
      resetData = getResetObject(['quantity', 'percentage', 'unit' , 'amount', 'needby_date', 'start_date', 'end_date'])
    } else if (billingRoleTypeValue == '3') {
      resetData = getResetObject(['quantity', 'percentage', 'unit' , 'amount', 'needby_date', 'start_date', 'end_date'])
    }

    if (getSelectedNode.length >= 1) {
      getSelectedNode[0].setData({
        ...getSelectedNode[0].data,
        type: billingRoleTypeValue,
        unit: billingRoleTypeValue !== '0' ? '' : resetData.unit,
        ...resetData,
      })
    }

    setTimeout(function () {
      grid.api.startEditingCell({
        rowIndex: getFocusedCell ? getFocusedCell.rowIndex : 0,
        colKey: 'type',
      })
      isStopAutoSave = false
    }, 300)
  }

  const initBillingTypeChange = (event, params) => {
    enableDisableFields(event.target.value)
    let totalQtyOrPercentageUsed = 0,
      totalAmountUsed = 0,
      remainingPerQty = 0,
      remainingAmount = 0
    params.api.forEachNode((node) => {
      if (node.rowIndex != params.rowIndex) {
        totalQtyOrPercentageUsed += Helper.getOnlyNumber(node?.data?.percentage_or_quantity)
        totalAmountUsed += Helper.getOnlyNumber(node?.data?.amount)
      }
    })
    remainingAmount = Helper.getOnlyNumber(props.defaultAmount) - totalAmountUsed

    if (event.target.value == '0') {
      remainingPerQty = Helper.getOnlyNumber(props.defaultQuantity) - totalQtyOrPercentageUsed
    } else {
      remainingPerQty = 100 - totalQtyOrPercentageUsed
    }
    let editorInsAr = params.api.getCellEditorInstances()
    editorInsAr.forEach((cellEditorIns) => {
      if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'amount') {
        if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
          cellEditorIns.updateValue(remainingAmount)
        }
      }
      if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'percentage_or_quantity') {
        if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
          cellEditorIns.updateValue(remainingPerQty)
        }
      }
    })
    params.node.setDataValue('percentage_or_quantity', remainingPerQty)
    params.node.setDataValue('amount', remainingAmount)
  }

  const getDataForDb = (dataObj) => {
    return {
      billingrulecategory_id: null,
      status: null,
      type: isDisableField(dataObj.type, 'type') ? null : Helper.getOnlyInt(dataObj.type),
      description: isDisableField(dataObj.description, 'description') ? null : Helper.getNullableValue(dataObj.description),
      amount: isDisableField(dataObj.amount, 'amount') ? null :  Number(parseFloat(dataObj.amount)?.toFixed(6)),
      unit_price: isDisableField(dataObj.unit_price, 'unit_price') ? null : Number(parseFloat(dataObj.unit_price)?.toFixed(6)),
      reason: isDisableField(dataObj.reason, 'reason') ? null : Helper.getNullableValue(dataObj.reason),
    }
  }

  const isDisableField = (value, field, params) => {
    if (field === 'start_date' || field === 'end_date') {
      if (parseInt(params?.data?.type) === 0) {
        return true
      }
    }
    if (field === 'needby_date') {
      if (parseInt(params?.data?.type) !== 0) {
        return true
      }
    }
    if (field === 'unit') {
      if (itemData?.itemmaster_type === 0 || (itemData?.itemmaster_type === 1 && parseInt(params?.data?.type) === 0) || (parseInt(params?.data?.type) !== 0)) {
        return true
      }
    }
    return value === null || value === undefined || value === ''
  }

  const getDisableBillingTypes = (params) => {
    let line_type = props.line_type
    let totalItems = params.api.getDisplayedRowCount()
    let output = []
    let isQuantityExist = isQuantityTypeExistInGrid(params)
    if (isQuantityExist) {
      return [1, 2, 3]
    } else {
      if (line_type == '0') {
        let item = itemData
        if (item?.itemmaster_type == '0') {
          output = [1, 2, 3]
        } else {
          if (totalItems >= 2) {
            output = [0]
          }
        }
      } else {
        if (totalItems >= 2) {
          output = [0]
        }
      }
    }
    return output
  }
  const onQuantityChangeHandler = (event, params) => {
    let quantityUnitPrice = props.defaultAmount / props.defaultQuantity
    let newAmount = quantityUnitPrice * event.target.value
    if (params.data.type != '0') {
      newAmount = (event.target.value / 100) * props.defaultAmount
    }
    let editorInsAr = params.api.getCellEditorInstances()
    editorInsAr.forEach((cellEditorIns) => {
      if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'amount') {
        if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
          cellEditorIns.updateValue(newAmount)
        }
      }
    })
    params.node.setDataValue('amount', newAmount)
  }
  const onAmountChangeHandler = (event, params) => {
    let quantityUnitPrice = props.defaultAmount / props.defaultQuantity
    let percentage_or_quantity = Math.random()
    if (params.data.type != '0') {
      percentage_or_quantity = (event.target.value / props.defaultAmount) * 100
    }
    let editorInsAr = params.api.getCellEditorInstances()
    editorInsAr.forEach((cellEditorIns) => {
      if (cellEditorIns.params && cellEditorIns.params.colDef.field == 'percentage_or_quantity') {
        if (cellEditorIns.updateValue && typeof cellEditorIns.updateValue == 'function') {
          cellEditorIns.updateValue(percentage_or_quantity)
        }
      }
    })
    params.node.setDataValue('percentage_or_quantity', percentage_or_quantity)
  }
  const getDefaultValueOfType = (params) => {
    let totalItems = params.api.getDisplayedRowCount()
    let isQuantityExist = isQuantityTypeExistInGrid(params)
    if (isQuantityExist) {
      return '0'
    }
    if (totalItems >= 1) {
      return '1'
    }
    return '0'
  }
  const isViewOnlyMode = () => {
    if (Helper.getOnlyNumber(props.defaultAmount) <= 0) {
      return true
    }
    return !props.isEditable
  }

  const Columns_Headings_Bills = getGridHeadings()
  if(!props.isEditable){
    Columns_Headings_Bills.map((item)=>{
      return {
        ...item,
        editable:false,
      }
    })
  }

  if(props.isExternalReferenceInBilling){
    Columns_Headings_Bills.splice(4,0, {field:'billingrule_id', editable: false, headerName:Helper.getLabel(props.language,'external_reference','External reference'),minWidth: 200})
  }


  return (
    <div className='billing-rule-view'>
      <div className='po_billing_rule_tab'>
        {amendmentPopupShow ? <AmendmentBillingRulePopup {...props} po_line_id={props?.line_id} amendment_id={props.amendment_id ? props.amendment_id : null} onClose={onCloseSegmentSitePopup} /> : ''}
        <Collapse title='Billing Rules' open={true}>
          {/* <AgGrid                            
                            readOnlyGrid={viewOnly} 
                            // settings={customSettings} 
                            noValidationOnRowEditingStop={true} 
                            onGridReady={ (grid=> { grid = grid; })} 
                            onDelete = {onDeleteHandler}
                            id={`po_billing_role_${props.billingrulegrid_id || "1"}`}
                            header={Columns_Headings} 
                            data={gridData}
                            onRowValueChanged ={ onRowValueChanged }
                            // onRowEditingStopped ={ onRowEditingStopped}
                            hideSave={true}
                            afterDeleteButtons = {afterDeleteButton}
                        /> */}
          {isLoading ? (
            <SimpleLoading />
          ) : (
            <AgGridNew
              gridId={`po_billing_role_${props.billingrulegrid_id || '1'}`}
              apiUrl={url}
              onGridReady={(gridObj) => {
                grid = gridObj
              }}
              handleDeleteSelectedRows={onDeleteHandler}
              afterDeleteBtns={<AfterDeleteButton />}
              onRowValueChanged={onRowValueChanged}
              columnDefs={Columns_Headings_Bills}
              newAddItems={newAddItems}
              returnApiCondition={!props?.line_id}
              handleAddButton={handleAddBtn}
              uniqueField={'billingrule_id'}
              hideAddBtn={props?.hideAddBtn}
              isAddBtnDisable={!props.isEditable}
              isDeleteBtnDisable={!props.isEditable}
              hideDeleteBtn={props?.hideDeleteBtn}
            />
          )}
        </Collapse>
      </div>
    </div>
  )
}

export default RsDataLoader(BillingRuleView)