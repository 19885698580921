import React,{useState,useEffect} from "react";
import $ from "jquery";
import Helper from "../../../inc/Helper";
import SimpleLoading from "../../Loading/SimpleLoading";
import Popup from "../../Popup/Popup";
import Collapse from "../../inc/Collapse";
import Button from "../../inc/Button";
import SendIcon from "../../../assets/send.svg"; 
import AgGridMemo from '../../grid/ag/AgGridMemo';
import { useLocation } from "react-router-dom";
import RFQCommonApiCalls from "../RFQCommonApiCalls";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import { getPopupMessageBasedOnStatus } from "../../../inc/Validation";
import axios from "axios";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

let defaultGridObj = null;
let forceRenderGrid = 1;

let cancelTokenSource = axios.CancelToken.source();
const PublishRFQModal = ({
  language,
  setPublishModal,
  rfqHeader,
  setRefreshAfterPublish,
}) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [saveBtn,setSaveBtn]=useState(false)

  const commonApiCalls = new RFQCommonApiCalls();

  const Columns_Headings = [
    {
      field: "vendor_name",
      headerName: Helper.getLabel(language, "vendor_name", "Vendor Name"),
      editable: false,
      minWidth: 120,
    },
    {
      field: "vendorsite_name",
      headerName: Helper.getLabel(language, "vendor_site", "Vendor Site"),
      editable: false,
    },
    {
      field: "contact_number",
      headerName: Helper.getLabel(
        language,
        "primary_contact_person",
        "Primary Contact Person"
      ),
      editable: false,
    },
    {
      field: "vendor_portal",
      headerName: Helper.getLabel(language, "vendor_portal", "Vendor Portal"),
      editable: false,
      inputType: "checkbox",
    },
    {
      field: "notify_vendor",
      headerName: Helper.getLabel(language, "notify_vendor", "Notify Vendor"),
      editable: true,
      inputType: "checkbox",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getAllVendorGridData();
    setIsLoading(false);
  }, []);

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const getAllVendorGridData = async () => {
    const res = await commonApiCalls.getAllRfqVendors(
      getSearchParam("rfq_view_id")
    );

    const filteredVendor = res;

    for (let i = 0; i < res.length; i++) {
      filteredVendor[i].vendor_portal = true;
      filteredVendor[i].notify_vendor = 'yes';
    }
    if(rfqHeader?.rfq_status===0 || rfqHeader?.rfq_status===8){
      setVendorData(filteredVendor);
      forceRenderGrid++
    }else{
      let vendorDataAfterPublish = filteredVendor?.filter((item)=>item?.reply_id === null || item.vendor_replystatus === 0) 
      setVendorData(vendorDataAfterPublish)
      forceRenderGrid++
    }
  };

  const handlePublishApiCall = async (payload) => {
    try{
      const api = Api;
      api.setUserToken();
      const response = await api
        .axios()
        .post(
          `${Settings.apiPurchaseRequisitionUrl}/publish_rfq/${getSearchParam(
            "rfq_view_id"
          )}`,
          payload,
          { cancelToken: cancelTokenSource.token }
        )
       if(response?.data?.status==='success'){
        $("html").removeClass("rs_popup_open");
        setPublishModal(false);
        setRefreshAfterPublish(true)
        setTimeout(() => {
          setRefreshAfterPublish(false);
        }, 100);
        Helper.alert(response?.data?.message, "success");
       }
      }
      catch(error){
         getPopupMessageBasedOnStatus(error)
      };
  }

  const handleSend = async () => {
    const vendorArray = [];
    const rows = defaultGridObj.api.getSelectedRows();

    if (rows.length === 0) {
      Helper.alert("Please Select a row", "error");
      return;
    }
    for (const row of rows) {
      if (row?.notify_vendor === "yes") {
        row.notify_vendor = true;
      } else if (row?.notify_vendor === "no") {
        row.notify_vendor = false;
      }
    }

    for (let i = 0; i < rows.length; i++) {
      vendorArray[i] = {};
      vendorArray[i].vendor_id = rows[i].vendor_id;
      vendorArray[i].notify_vendor = rows[i].notify_vendor;
    }

    const payload = { vendors: vendorArray };
    Helper.createDebouncedAPIFunction([async ()=>{return handlePublishApiCall(payload)}], setSaveBtn, cancelTokenSource, false, 300000)();
  };     
  return (
    <>
    {saveBtn ? <OverlayLoader /> : null}
    <Popup
      autoOpen={true}
      width="1200px"
      beforePopupClose={()=>{
        saveBtn && Helper.alert('You can not close the window because RFQ publishing is in process.','error')
        return !saveBtn}}
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setPublishModal(false);
      }}
    >
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h5 className="create_rfq_modal__title">
              {Helper.getLabel(
                language,
                "sending_request_for_quotation",
                `Sending Request For ${(rfqHeader?.rfq_type === 1 && " Information") || (rfqHeader?.rfq_type === 2 && "Quotation - Auction") || ""}`
              )}
            </h5>
            <Button
              className="solid_btn publish_rfq_blue_btn"
              isActive={true}
              title={<div><span> {Helper.getLabel(language, "send", "Send")} </span> {saveBtn ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}</div>} 
              icon={SendIcon}
              isDisable={saveBtn}
              onClick={() => {
                handleSend();
              }}
            />
          </div>

          <Collapse
            className="mt-4 mb-4"
            open={true}
            title={Helper.getLabel(language, "", "")}
          >
            <AgGridMemo
              id="rfq_publishing_grid"
              header={Columns_Headings}
              data={vendorData}
              forceRender={forceRenderGrid}
              onGridReady={(event) => (defaultGridObj = event)}
              hideSave={true}
              addBtnHide={true}
              hideDelete={true}
            />
          </Collapse>
        </>
      )}
    </Popup>
    </>
  );
};

export default PublishRFQModal;
