import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Api from '../../../inc/Api'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../inc/Validation'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Button from '../../inc/Button' 
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import AgGridNew from '../../grid/ag/ag-grid-new'
import getCommonColumns from './RateCardColumns'
import CreateItemCataloguePopup from "./RateCardPopup"
import { generateBaseUrl } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import DatepickerFunctions from '../../../inc/DatepickerHelper'

let gridObj = null

const ItemCatalogueList = (props) => {
  const [openCreateModePO, setOpenCreateModePO] = useState(false)
  const [documentStatus, setDocumentStatus] = useState([])
  const [exportLoading, setExportLoading] = useState(false)
  const totalRecords = useRef(0)

  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  
  const getDocumentStatusList = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + "/enum/POHeaderDocumentStatus")
      .then(function (res) {
        if (res.data.status === "success") {
           setDocumentStatus(res?.data?.data)
        }
      })
      .catch((error) => {
       
      });
  }

  useEffect(()=>{
    getDocumentStatusList()
  },[])

  const handleGridReady = (params) => {
    gridObj = params
    setLoading(false)
  }

  const cellHandler = (event) => {  
    if(event.colDef.field === 'document_number') { 
      let editUrl = props.editUrl ? props.editUrl : '/item-catalogue/edit';
      navigate(`${editUrl}/${event?.data?.ratecardheader_id}`)
    }
    if(event.colDef.field === 'vendor_code') { 
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }
 
  const DeletePOHandler = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    let delPO_ID = element?.data?.ratecardheader_id;
    let endPoint = '/PA_header/'
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl+endPoint+delPO_ID)
      const rowNode = gridObj?.api?.getRowNode(delPO_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      if (res && index === lastIndex) {
          gridObj?.api?.refreshServerSide({ purge: true });
          gridObj?.api?.deselectAll();
      }
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

    
  const CreateModePurchaseOrder = () => {
    setOpenCreateModePO(true)
  }

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiPurchaseOrderUrl + `/PA_ratecardheader`
    const params = `&header_type=${props.type ?? 1}`
    let pagination = true
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize })

    const convertDateAndTime = (data) => {
      return data.map((item) => {
        return {
          ...item,
          document_status: documentStatus.find((status)=> status.id===item.document_status)?.name,
          createddatetime: DatepickerFunctions.convertDateTimeFromDataBase(item.createddatetime)
        }
      })
    }

    await exportToXlsx({ url: baseUrl, columns:Columns_Headings, fileName: "item_catalogue_details", additionalFunction: convertDateAndTime })
    setExportLoading(false)
  }
 
  const Columns_Headings = getCommonColumns({
    language:props.language,
    purchaseStatus:props.purchaseStatus,
    documentStatus:documentStatus,
  })

  return (
    <div className="purchase_orders_archive">
      {openCreateModePO ? <CreateItemCataloguePopup ClosePopup={setOpenCreateModePO} source_id={props.source_id} /> : ""}
      <div className="container-fluid">
        <NavigationHeder backUrl={props.backUrl ? props.backUrl : "/procure"} title={props.title ? props.title : Helper.getLabel(props.language, "item_catalogue", "Item Catalogue")} hideMoreBtn={true}>
          {props.type === 0 ? (
            <></>
          ) : (
            <Button isDisable={props?.disableCreateBtnFromSecurity} onClick={CreateModePurchaseOrder} isActive={true} icon="/images/icons/plus-white.svg" title={Helper.getLabel(props.language, "new", "New")} />
          )}
        </NavigationHeder>

        {loading ? <SimpleLoading /> : null}
        <AgGridNew
          apiUrl={Settings.apiPurchaseOrderUrl + `/PA_ratecardheader`}
          additionalParams={`&header_type=${props.type ?? 1}`}
          pagination={true}
          columnDefs={Columns_Headings}
          onGridReady={handleGridReady}
          handleDeleteSelectedRows={DeletePOHandler}
          onCellClicked={cellHandler}
          hideAddBtn={true}
          uniqueField={"ratecardheader_id"}
          noNeedRefresh={true}
          hideDeleteBtn={props.type === 0 ? true : props?.disableDeleteBtnFromSecurity}
          // hideDeleteBtn={(renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn)) || getDeleteBtnAccessFromSecurity()}
          height={500}
          gridId={`rate-card-internal-list-${props.type}`}
          afterDeleteBtns={
            <div className="import_sample_template ms-auto px-2">
              <button onClick={exportData}>
                <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
              </button>
            </div>
          }
          fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
          btnsWrapperStyle="w-100 px-2"
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      auth:state.auth,
      user:state.auth.user,
      language:state.language
    }
  }
 
export default connect(mapStateToProps) (MasterComponentWraper(((ItemCatalogueList))))