import React, { Fragment, useCallback, useRef, useState, } from 'react' 
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Gui_id_list from '../../../inc/Gui_id_list'
import Helper from '../../../inc/Helper'
import Settings from '../../../inc/Settings'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Alert from '../../inc/Alert'
import ButtonNew from '../../inc/ButtonNew' 
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../inc/Validation'
import AgGridNew from '../../grid/ag/ag-grid-new'
import { generateBaseUrl, gridLinkValueFormat, statusLabelValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import VendorDestinationsModal from './VendorDesignationsModal'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'


let gridObj = null;
const VendorDesignations = (props) => { 
  const [designationData,setDesignationsData] = useState({})
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const totalRecords = useRef(0)

  const handleGridReady = (params) => {
    gridObj = params
  }
 
  const CellHandler = (event) => {   
    if(event.colDef.field === 'description') {
      setDesignationsData(event?.data);
      setShowCreateModal(true);
    }
  }
  
  const DeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    let delVendorDesignation_ID = element?.data?.contactdesignation_id;
    try {
      const res = await api
        .axios()
        .delete(Settings.apiVendorUrl+`/vendordesignation/${delVendorDesignation_ID}`)
      const rowNode = gridObj?.api?.getRowNode(delVendorDesignation_ID)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api.applyServerSideTransaction(transaction)
      }, 200)
      gridObj?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const reloadGrid = () => {
    gridObj?.api?.refreshServerSide({ purge: true });
    gridObj?.api?.deselectAll();
  };

  const onClickNewButton = () => {
    setShowCreateModal(true);
    setDesignationsData({})
  };

  const Columns_Headings = [
    { field: 'description', headerName: Helper.getLabel(props.language,'description',"Description"), editable: false, cellRenderer: gridLinkValueFormat },
  ];


  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiVendorUrl+'/vendordesignation'
    const params = ""
    let pagination = false
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize })
    await exportToXlsx({ url: baseUrl, columns: Columns_Headings, fileName: "vendor_details" })
    setExportLoading(false)
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.masterData?.vendor_designations?.vendors_designation_main)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />}

  return (
    <>
      {exportLoading ? <OverlayLoader /> : null}
      <div className="vendors_reason_archive">
        <div className="container-fluid">
          <NavigationHeder hideMoreBtn={true} backUrl="/master-data" title={Helper.getLabel(props.language, "vendors_designation", "Vendors Designations")}>
            <ButtonNew
              onClick={onClickNewButton}
              title={Helper.getLabel(props.language, "new", "New")}
              isDisable={!security.canCreate(frontendIds.masterData?.vendor_designations?.vendors_designation_main_create_button)}
            />
          </NavigationHeder>

          <AgGridNew
            apiUrl={Settings.apiVendorUrl + "/vendordesignation"}
            hideDeleteBtn={!security.canDelete(frontendIds.masterData.vendor_designations?.vendors_designation_main_delete_button)}
            uniqueField={"contactdesignation_id"}
            pagination={false}
            columnDefs={Columns_Headings}
            hideAddBtn={true}
            height={500}
            onCellClicked={CellHandler}
            handleDeleteSelectedRows={DeleteHandler}
            onGridReady={handleGridReady}
            gridId={"rs_vendors_designation_list"}
            afterDeleteBtns={
              <div className="import_sample_template px-2">
                <button onClick={exportData}>
                  <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                </button>
              </div>
            }
            btnsWrapperStyle={`w-100 justify-content-between px-2`}
            fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
          />
        </div>
        {showCreateModal && <VendorDestinationsModal reloadParentGrid={reloadGrid} designationData={designationData} setDesignationData={setDesignationsData} setShowCreateModal={setShowCreateModal} />}
      </div>
    </>
  )
};
 
const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.masterData.vendor_designations?.vendors_designation_main
}
 
export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(VendorDesignations, SecurityOptions))))