import React from 'react'
import TechnicalSelectedLinesModalPreferredColumn from '../TechnicalEvaluation/TechnicalSelectedLinesModalPreferredColumn'
import TechnicalSelectedLinesModalAwardToColumn from '../TechnicalEvaluation/TechnicalSelectedLinesModalAwardTo'

const EvaluationAlternateLinesModal = ({rfqHeader,language,setShowLinesPopUpAlternative, vendors, vendorReplyId, scoreReplyHeaderId, linesPopUpType, user, evaluationData, getScoreSummary, alternateLine, vendorLineId, setVendorLineId, popupType, setShowLinesPopUpAlternativeAwardto }) => {
  return (
    <div>
     {popupType === "preferred" ? <TechnicalSelectedLinesModalPreferredColumn
        rfqHeader={rfqHeader}
        language={language}
        setShowLinesAlternative={setShowLinesPopUpAlternative}
        vendors={vendors}
        vendorreplyId={vendorReplyId}
        scoreReplyHeaderId={scoreReplyHeaderId}
        type={linesPopUpType}
        evaluationData={evaluationData}
        getScoreSummary={getScoreSummary}
        user={user}
        alternateLine={alternateLine}
        vendorLineId={vendorLineId}
        setVendorLineId={setVendorLineId}
        lineGridId={'vendor-alternate-lines'}
      /> : 
      <TechnicalSelectedLinesModalAwardToColumn 
        rfqHeader={rfqHeader}
        language={language}
        vendors={vendors}
        vendorreplyId={vendorReplyId}
        scoreReplyHeaderId={scoreReplyHeaderId}
        setShowLinesAlternative={setShowLinesPopUpAlternative}
        getScoreSummary={getScoreSummary}
        evaluationData={evaluationData}
        alternateLine={alternateLine}
        vendorLineId={vendorLineId}
        setVendorLineId={setVendorLineId}
        lineGridId={'vendor-awarding-alternate-lines'}
      />}
    </div>
  )
}

export default EvaluationAlternateLinesModal
