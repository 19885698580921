import React from "react";
import Summary from "../../components/PurchaseRequisition/Checkout/Summary";
import Gui_id_list from "../../inc/Gui_id_list";
import ApplySecurityRoles from "../../components/SecurityRoles/ApplySecurityRoles";

const SummaryScreen = (props) => { 
  return <Summary security={props?.security} />;
};
 
const SecurityOptions = {
  gui_id: Gui_id_list.request.purchase_requisitions.purchase_requisitions_main
};

export default (ApplySecurityRoles(SummaryScreen, SecurityOptions)); 