import React, { Fragment } from 'react'; 
import ItemCatalogueEdit from "../../components/RateCard/RateCardEdit/RateCardEdit";
import Helper from '../../inc/Helper';
import Gui_id_list from '../../inc/Gui_id_list';
import Alert from '../../components/inc/Alert';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import MasterComonent from '../../components/Backend/MasterComonent';
import { connect } from 'react-redux';

const RateCardEditScreen = (props) => {
  let security = props?.security; 

  if(!security.canView(Gui_id_list.procure.rate_card.rate_card_main_edit_screen) || props?.user?.is_buyer !== true){
    return <MasterComonent>
      <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
    </MasterComonent>
  };
  return (
    <div>
      <ItemCatalogueEdit type={0} title={Helper.getLabel(null, 'rate_card_details', 'Rate Card Details')} lineTitle={Helper.getLabel(null, 'rate_card_lines', 'Rate Card Lines')} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return { 
    user:state.auth.user
  }
}

const SecurityOptions = {
  gui_id: Gui_id_list.procure.rate_card.rate_card_main_edit_screen
};

export default connect(mapStateToProps) (((ApplySecurityRoles(RateCardEditScreen, SecurityOptions))));