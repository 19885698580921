/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Popup from '../../Popup/Popup'
import $ from 'jquery'
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button'
import Dropdown from '../../Forms/Dropdown'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import AgGridNew from '../../grid/ag/ag-grid-new'
import Collapse from '../../inc/Collapse'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader"
import NumberCellRenderer from '../../grid/ag/cellRender/NumberCellRenderer'

const LinkRateCardPopup = ({ setRateCardPopup, language, isDisable, lineId, sourceId, onClose }) => {
  const [rateCardOptions, setRateCardOptions] = useState([])
  const [selectedRateCards, setSelectedRateCards] = useState([])
  const [isLoading,setIsLoading]=useState(false)
  const gridObj = useRef(null)

  const getRateCards = () => {
    const api = Api
    api.setUserToken()
    api
      .axios()
      .get(`${Settings.apiPurchaseOrderUrl}/PA_ratecardheader?document_status=1&header_type=1`)
      .then((res) => {
        let rateCardList = []
        rateCardList = res?.data?.data?.map((item) => {
          const description = item?.description?.length >=30 ? item?.description?.substring(0,30) + "..." : item.description
          return {
            value: item?.ratecardheader_id,
            label: `${item?.document_number} - ${description}`,
          }
        })
        setRateCardOptions(rateCardList)
      })
      .catch((error) => {
        console.log('Error in getting actions: ', error)
      })
  }

  useEffect(() => {
    getRateCards()
  }, [])

  const onLinkRateCards = async () => {
    const payload = selectedRateCards?.map(ratecardheader_id => ({
      ratecardheader_id,
      link_id:lineId
    }));
    setIsLoading(true)
    try {
      const api = Api
      api.setUserToken()
      const response = await api.axios().post(Settings.apiPurchaseOrderUrl + `/link_ratecardheader/${sourceId??Settings.source_id.rfi_lines}`,payload)
      console.log("response",response)
      if (response?.data?.status === 'success') {
        setIsLoading(false)
        Helper.alert(response?.data?.message, 'success')
        gridObj?.current?.api?.refreshServerSide({purge:true})
      }
    } catch (error) {
      setIsLoading(false)
      getPopupMessageBasedOnStatus(error)
    }
  }

  const onDeleteHandler = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_rate_card_header = element?.data?.ratecardheader_id;

    try {
        const res = await api
            .axios()
            .delete(Settings.apiPurchaseOrderUrl + `/link_ratecardheader/${sourceId??Settings.source_id.rfi_lines}/${lineId}/${delete_rate_card_header}`)
        const rowNode = gridObj?.current?.api?.getRowNode(delete_rate_card_header)
        if (rowNode) {
            rowNode.setSelected(false)
        }
        setTimeout(() => {
            const transaction = { remove: [element.data] }
            gridObj?.current?.api?.applyServerSideTransaction(transaction)
        }, 200) 
        gridObj?.current?.api?.refreshServerSide({ purge: true })
        gridObj?.current?.api?.deselectAll();
        Helper.alert(res?.data?.message, 'success')
    } catch (err) {
        getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const onPopupClose = () => {
      $('html').removeClass('rs_popup_open')
      setRateCardPopup(false)
      if (typeof onClose === 'function') {
        onClose(); 
      }
  }

  const onRateCardChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => parseInt(option.value))
    setSelectedRateCards(selectedOptions)
  }

  let masterSettings = {
    detailRowAutoHeight: true,
    masterDetail: true,  
    detailCellRendererParams: {
      detailGridOptions: {
        rowSelection: 'single',
        columnDefs: [
          {field: 'linenumber', editable: false, headerName:'Line number'},
          {field: 'item_name', editable: false, headerName:'Item name'},
          {field: 'item_code', editable: false, headerName:'Item code'},
          {field: 'category_name', editable: false, headerName:'Category'},
          {field: 'comments', editable: false, headerName:'Comments'},
          {field: 'ordered_qty', editable: false, headerName:'Quantity'},
          {field: 'unit_price', editable: false, headerName:'Unit price', cellRenderer:NumberCellRenderer},
          {field: 'discount_amount', editable: false, headerName:'Discount amount',cellRenderer:NumberCellRenderer},
          {field: 'discount_percentage', editable: false, headerName:'Discount percentage', minWidth:200,cellRenderer:NumberCellRenderer},
          {field: 'line_amount', editable: false, headerName:'Line amount',cellRenderer:NumberCellRenderer},
        ],
        defaultColDef: {
          sortable: true,
          flex: 1,
          minWidth:180,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.lines); 
      },
    }
  }

  const Columns_Headings = [
      { 
        field: 'document_number', 
        headerName: Helper.getLabel(language,'document_number','Document number'),
        editable:false,
        cellRenderer: 'agGroupCellRenderer',
        minWidth:180
      },
      { 
        field: 'description', 
        headerName: Helper.getLabel(language,'description','Description'),
        editable:false
      },
      { 
        field: 'document_status_name', 
        headerName: Helper.getLabel(language,'document_status','Document status'),
        editable:false,
        minWidth:180
      },
      { 
        field: 'start_date', 
        headerName: Helper.getLabel(language,'start_date','Start date'),
        editable:false,
        valueFormatter:(params)=>{return DatepickerFunctions.convertDateFromDataBase(params.value)}
      },
      { 
        field: 'end_date', 
        headerName: Helper.getLabel(language,'end_date','End date'),
        editable:false,
        valueFormatter:(params)=>{return DatepickerFunctions.convertDateFromDataBase(params.value)}
      },
  ]

  return (
    <>
    <Popup
      autoOpen={true}
      width="1100px"
      onClose={onPopupClose}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 className="create_rfq_modal__title">{Helper.getLabel(language, 'rate_card', 'Item Catalogue')}</h5>
          <div className="d-flex align-items-center gap-2">
            <Button className="solid_btn" title={Helper.getLabel(language, 'close', 'Close')} onClick={onPopupClose} />
            <Button className="solid_btn publish_rfq_blue_btn" isActive={true} title={Helper.getLabel(language, 'link_rate_card', 'Link Item Catalogue')} isDisable={checkIfArrayIsEmpty(selectedRateCards) || isDisable} onClick={onLinkRateCards} />
          </div>
        </div>
        <div>
          <Collapse open={true} title={Helper.getLabel(language, 'link_new_rate_card', 'Link New Item Catalogue')} className="mt-4">
            <div className="col-sm-4">
              <Dropdown
                id="rate-cards"
                label={Helper.getLabel(language, 'item_catalogues', 'Item catalogues')}
                options={rateCardOptions}
                name="rate-cards"
                value={selectedRateCards}
                onChange={onRateCardChange}
                select2Settings={{ multiple: true }}
                isDisable={isDisable}
              />
            </div>
          </Collapse>
          <Collapse open={true} title={Helper.getLabel(language, 'linked_rate_cards', 'Linked Item Catalogues')} className="mt-4">
            <div className="mt-2">
              <AgGridNew
                gridId={'rate_card_grid'}
                columnDefs={Columns_Headings}
                apiUrl={Settings.apiPurchaseOrderUrl + `/getline_ratecardheader/${sourceId?sourceId:Settings.source_id.rfi_lines}/${lineId}`}
                returnApiCondition={!lineId}
                hideAddBtn={true}
                height={500}
                uniqueField={'ratecardheader_id'}
                onGridReady={(event) => {
                  gridObj.current = event
                }}
                handleDeleteSelectedRows={onDeleteHandler}
                masterSettings={masterSettings}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </Popup>
    {isLoading && <OverlayLoader isPopupInTab={true} isLogoCenter={true} />  }
    </>
  )
}

export default LinkRateCardPopup
