const navigationListForPendingActions = [
    {
        source_id: 6,
        link: '/edit-vendor/:id/:instance_id?common_user_access=true'
    },
    {
        source_id: 12,
        link: '/purchase-order/edit/:id/:instance_id?common_user_access=true'
    },
    {
        source_id: 13,
        link: '/release-order/edit/:id/:instance_id?common_user_access=true'
    },
    {
        source_id: 15,
        link: '/purchase-agreement/edit/:id/:instance_id?common_user_access=true'
    },
    {
        source_id: 14,
        link: '/contract-order/edit/:id/:instance_id?common_user_access=true'
    },
    {
        source_id: 9,
        link: '/purchase-requisition/summary?pr_view_id=:id&return=pr&instance_id=:instance_id'
    },
    {
        source_id: 97,
        link: '/purchase-requisition/summary?pr_view_id=:id&return=pr&instance_id=:instance_id'
    },
    {
        source_id: 10,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 11,
        link:'/request-for-information/rfidetailform?rfq_view_id=:id&instance_id=:instance_id&common_user_access=true'
    },  
    {
        source_id: 101,
        link:'/request-for-information/rfidetailform?rfq_view_id=:id&instance_id=:instance_id&common_user_access=true'
    }, 
    {
        source_id: 102,
        link:'/request-for-information/rfidetailform?rfq_view_id=:id&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 103,
        link:'/request-for-information/rfidetailform?rfq_view_id=:id&instance_id=:instance_id&common_user_access=true'
    }, 
    {
        source_id: 16,
        link:'/invoice-details/:id?instance_id=:instance_id?common_user_access=true'
    },
    {
        source_id: 77,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 78,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 79,
        link: '/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=dashboard&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 54,
        link:'/work-confirmation-details?shipment_id=:id&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 87,
        link:'/work-confirmation-details?shipment_id=:id&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 86,
        link:'/goods-receiving-details?shipment_id=:id&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 91,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 94,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 95,
        link:'/request-for-quotation/rfqdetailform?rfq_view_id=:id&return=rfqheaderlist&instance_id=:instance_id&common_user_access=true'
    },
    {
        source_id: 98,
        link: '/item-catalogue/edit/:id/:instance_id?common_user_access=true'
    }
];
export default navigationListForPendingActions;