import DatepickerFunctions from "../../../../inc/DatepickerHelper"
import Helper from "../../../../inc/Helper"
import { getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator } from "../../../grid/ag/commonFunctions/GridCommonFunctions"

const getWcColumns = ({language,documentStatus,applyLinkToDocumentNumberCol}) => {
    return [
        {
          field: 'receiving_reference_number',
          headerName: Helper.getLabel(
            language,
            'work_confirmation_no',
            'Work confirmation no.',
          ),
          minWidth: 220,
          editable: false,
          cellRenderer: (params) => gridLinkValueFormat(params)
        },
        {
          field: 'description',
          minWidth: 180,
          headerName: Helper.getLabel(language, 'description', 'Description'),
          editable: false,
        },
        {
          field: 'document_number',
          headerName: Helper.getLabel(
            language,
            'source_document_no',
            'Source document number',
          ),
          minWidth: 200,
          editable: false,
          cellRenderer: applyLinkToDocumentNumberCol ? gridLinkValueFormat: null,
        },
        {
          field: 'vendor_code',
          minWidth: 200,
          headerName: Helper.getLabel(language, 'vendor_code', 'Vendor code'),
          editable: false,
          cellRenderer:(params)=>gridLinkValueFormat(params)
        },
        {
          field: 'vendor_name',
          minWidth: 200,
          headerName: Helper.getLabel(language, 'vendor_name', 'Vendor name'),
          editable: false,
        },
        {
          field: 'vendorsite_name',
          headerName: Helper.getLabel(language, 'vendor_site', 'Vendor site'),
          editable: false,
          minWidth: 200,
        },
        {
          field: 'document_status',
          minWidth: 200,
          headerName: Helper.getLabel(
            language,
            'document_status',
            'Document status',
          ),
          editable: false,
          cellRenderer: (params) => getStatusNameById(params.value,documentStatus),
          filter:'agSetColumnFilter',
          filterParams: {
            values: (params) => getOptions(params, documentStatus),
            keyCreator: (params) => optionsKeyCreator(params, 'id'),
            valueFormatter: params => params.value.name,
          }
        },
        {
          field: 'accounting_date',
          minWidth: 200,
          filter:'agDateColumnFilter',
          valueFormatter: (params) =>   DatepickerFunctions.convertDateFromDataBase(params.value) , 
          headerName: Helper.getLabel(
            language,
            'accounting_date',
            'Accounting date',
          ),
          editable: false,
        },
        {
          field: 'document_date',
          minWidth: 200,
          filter:'agDateColumnFilter',
          valueFormatter: (params) =>   DatepickerFunctions.convertDateFromDataBase(params.value) , 
          headerName: Helper.getLabel(
            language,
            'document_date',
            'Document date',
          ),
          editable: false,
        },
        {
          field:'delivery_name',
          minWidth:200,
          hide:true,
          headerName: Helper.getLabel(
            language,
            'delivery_name',
            'Delivery name',
          ),
          editable:false
        },
        {
          field:'discount_amount',
          minWidth:200,
          hide:true,
          filter:'agNumberColumnFilter',
          headerName: Helper.getLabel(
            language,
            'discount_amount',
            'Discount amount',
          ),
          editable:false
        },
        {
          field:'discount_percentage',
          minWidth:200,
          hide:true,
          filter:'agNumberColumnFilter',
          headerName: Helper.getLabel(
            language,
            'discount_percentage',
            'Discount percentage',
          ),
          editable:false
        },
      ]
}

export default getWcColumns