import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Alert from '../../inc/Alert'
import Helper from '../../../inc/Helper'
import Button from '../../inc/Button'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import SimpleLoading from '../../Loading/SimpleLoading'
import Tabs from '../../Widgets/Tabs/Tabs'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import SwitchView from '../../../inc/SwitchView'
import POHeaderVersions from './POHeaderVersions'
import { useParams } from 'react-router-dom'
import POLinesVersions from './POLinesVersions'
import POLinesHistoryGrid from './POLinesHistoryGrid'
import POHeaderTabs from './POHeaderTabs'
import { ValidateData, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'

//  ? History / Logs System For Purchase Orders - LYNKAZ
// Route: /purchase-orders/versions-history/:header_id/:line_id

const HistoryPO = (props) => {
    const [headerObj, setHeaderObj] = useState({})
    
    const {header_id, line_id} = useParams()
    const [viewComponent, setViewComponent] = useState("Header View")
    const [loading, setLoading] = useState(false)

    // Dropdown Calls -
    const [vendors, setVendors] = useState([])
    const [sites, setSites] = useState([])
    const [entities, setEntities] = useState([])
    const [taxCode, setTaxCode] = useState([])
    const [taxGroup, setTaxGroup] = useState([]) 
    const [requester, setRequester] = useState([])
    const [ordered, setOrdered] = useState([])
    const [itemGroup, setItemGroup] = useState([])
    const [currency, setCurrency] = useState([])
    const [payment, setPayment] = useState([])
    const [deliveryTerm, setDeliveryTerm] = useState([])
    const [modeOfDelivery, setModeOfDelivery] = useState([]) 
    const [category, setCategory] = useState([])
    const [items, setItems] = useState([])
    const [barcodes, setBarcodes] = useState([])
    const [products, setProducts] = useState([])
    const [units, setUnits] = useState([])
    // ---

    // Tabs Declarations --
    const defaultNavs = []
 
    const addComponentTop = () => {
        let items = [
          {title:Helper.getLabel(props.language,'document_number',"Document Number"), subtitle: headerObj.document_number},
          {title:Helper.getLabel(props.language,'document_type',"Document Type"), subtitle: "Purchase Order"},
          {title:Helper.getLabel(props.language,'vendor_id',"Vendor"), subtitle: headerObj.vendor_id},
          {title:Helper.getLabel(props.language,'vendorname',"Vendor Name"), subtitle: !ValidateData(vendors) && headerObj.vendor_id ? (vendors.find(id => id.value == headerObj.vendor_id) == undefined ? 'Vendor Not Exist!' : vendors.find(id => id.value == headerObj.vendor_id).label) : '' },
        ]

        let tags = [
            {title:Helper.getLabel(props.language,'confirm','Confirmed')},
        ]
     
        return(
          <div className='rfq-internal-blue'>
            <BlueCallToAction items={items} tags={tags}/>  
            <SwitchView setObjectClear={{}} setViewComponent={setViewComponent} viewComponent={viewComponent} />
          </div>
        )
    }
    // ----

    // API Reduction Calls - 
        let VendorsCalled = false
        let HeaderObjCalled = false 
        let taxCodeCalled = false
        let taxGroupCalled = false 
        let sitesCalled = false
        let RequesterOrdererCalled = false
        let entityCalled = false
        let ItemGroupCalled = false
        let CurrencyCalled = false
        let paymentCalled = false
        let deliveryTermCalled = false
        let itemsCalled = false
        let categoryCalled = false
        let unitCalled = false
        let barcodesCalled = false
        let modeOfDeliveryCalled = false
    // --
  
    // API Calls / Renderer Methods -

    useEffect(() => {
        POHeaderObjAPI()
        VendorsAPI()
        TaxCodeAPI()
        TaxGroupAPI()
        SitesAPI()
        EntityAPI()
        RequesterOrdererAPI()
        ItemGroupAPI()
        CurrencyAPI()
        PaymentTermsAPI()
        DeliveryTermsAPI()
        ModeOfDeliveryAPI()
        getItemsData()
        getCategoryData()
        getUnitData()
    }, [])

    // API Calls - 

    const POHeaderObjAPI = () => {
        if(HeaderObjCalled){
          return;
        }
        setLoading(true)
        let api = Api
        HeaderObjCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseOrderUrl+`/PO_header/${header_id}`,{}).then(function(res){
          if(res.data.status==="success"){  
            setHeaderObj(res.data.data)
            setLoading(false)
          }
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
          setLoading(false)
        })
    }

    const VendorsAPI = () => {
        if(VendorsCalled){
          return;
        } 
        let api = Api
        VendorsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiVendorUrl+`/get_active_vendors`,{}).then(function(res){
          if(res.data.status==="success"){  
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.vendor_id,
                    label: element.name
                })
            })
            setVendors(UPDATED_STATE)
        }
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
        })
    }

    const TaxCodeAPI = () => {
        if(taxCodeCalled) {
          return;
        }
          let api = Api
          taxCodeCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiUrl+'/taxcodes',{}).then(function(res){
          if(res.data.status==="success"){
            let TaxCode = []
            res.data.data.forEach(item => {  
            TaxCode.push({
              value: item.taxcodes_id,
              label: `${item.code} - ${item.name}`
            })
          }) 
          setTaxCode(TaxCode)
          } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }
    
    const TaxGroupAPI = () => {
        if(taxGroupCalled) {
          return;
        }
          let api = Api
          taxGroupCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiUrl+'/taxgroup',{}).then(function(res){
          if(res.data.status==="success"){
            let TaxGroup = []
            res.data.data.forEach(item => {  
            TaxGroup.push({
              value: item.taxgroup_id,
              label: item.name
            })
          }) 
          setTaxGroup(TaxGroup)
          } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const SitesAPI = () => {
        if(sitesCalled){
            return;
        }
        let api = Api
        sitesCalled = true
        api.setUserToken()
        api.axios().post(Settings.apiUrl+'/get_site',{
          "code":"",
          "name":""
        }).then(function(res){
          if(res.data.status==="success"){
            let SitesARR = []
            res.data.data.forEach(item => {  
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`
            })
          }) 
          setSites(SitesARR)
          } 
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
        })
    }

    const RequesterOrdererAPI = () => {
        if(RequesterOrdererCalled) {
          return;
        }
          let api = Api
          RequesterOrdererCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiOrgUrl+'/employee_dropdown?status=true',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.employee_id,
              label: `${item.code} - ${item.displayname}`
            })
          }) 
            setRequester(UPDATED_STATE)
            setOrdered(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const EntityAPI = () => {
        if(entityCalled){
            return;
        }
        let api = Api
        entityCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+'/get_entities').then(function(res){
          if(res.data.status==="success"){
            let EntityARR = []
            res.data.data.forEach(item => {  
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`
            })
           }) 
           setEntities(EntityARR)
        } 
        }).catch((res) => { 
          getPopupMessageBasedOnStatus(res) 
        })
    }

    const ItemGroupAPI = () => {
        if(ItemGroupCalled) {
          return;
        }
          let api = Api
          ItemGroupCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiItemUrl+'/item_group',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.itemgroup_id,
              label: `${item.name}`
            })
          }) 
          setItemGroup(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const CurrencyAPI = () => {
        if(CurrencyCalled) {
          return;
        }
          let api = Api
          CurrencyCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiUrl+'/currency',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.currency_id,
              label: `${item.currency_code} - ${item.currency_name}`
            })
          }) 
          setCurrency(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const PaymentTermsAPI = () => {
        if(paymentCalled) {
          return;
        }
          let api = Api
          paymentCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiVendorUrl+'/paymentterm?active=true',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.paymentterm_id,
              label: `${item.name}`
            })
          }) 
          setPayment(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const ModeOfDeliveryAPI = () => {
        if(modeOfDeliveryCalled) {
          return;
        }
          let api = Api
          modeOfDeliveryCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiPurchaseOrderUrl+'/mode_of_delivery',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.mode_of_delivery_id,
              label: `${item.name} | ${item.description ? item.description.slice(0, 50) + (item.description.length > 50 ? "..." : "") : ""}`
            })
          }) 
          setModeOfDelivery(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const DeliveryTermsAPI = () => {
        if(deliveryTermCalled) {
          return;
        }
          let api = Api
          deliveryTermCalled = true
          api.setUserToken()
          api.axios().get(Settings.apiPurchaseOrderUrl+'/delivery_term',{}).then(function(res){
          if(res.data.status==="success"){
            let UPDATED_STATE = []
            res.data.data.forEach(item => {  
            UPDATED_STATE.push({
              value: item.delivery_term_id,
              label: `${item.name} | ${item.description ? item.description.slice(0, 50) + (item.description.length > 50 ? "..." : "") : ""}`
            })
          }) 
          setDeliveryTerm(UPDATED_STATE)
        } 
        }).catch((res) => { 
          console.log(res.response.data.message)
          // getPopupMessageBasedOnStatus(res) 
        })
    }

    const getItemsData = () => {
        if(itemsCalled){
            return;
        } 
        let api = Api
        itemsCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/item`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.item_id,
                    label: element.name
                })
            })
            setItems(UPDATED_STATE)
            setProducts(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    const getCategoryData = () => {
        if(categoryCalled){
            return;
        } 
        let api = Api
        categoryCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/categories`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.category_id,
                    label: `${element.code} - ${element.name}`
                })
            })
            setCategory(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    const getUnitData = () => {
        if(unitCalled){
            return;
        } 
        let api = Api
        unitCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiUrl+`/unit`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.unit_id,
                    label:`${element.code} - ${element.description}`
                })
            })
            setUnits(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    const getBarcodesData = (id) => {
        if(barcodesCalled){
            return;
        } 
        let api = Api
        barcodesCalled = true
        api.setUserToken()
        api.axios().get(Settings.apiItemUrl+`/get_barcodes/${id}`,{}).then(function(res){
        if(res.data.status==="success"){ 
            let UPDATED_STATE = []
            res.data.data.forEach((element) => {
                UPDATED_STATE.push({
                    value: element.item_id,
                    label: element.barcode
                })
            })
            setBarcodes(UPDATED_STATE)
        }
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res)  
        })
    }

    // ----
 
     
    // Security / Screen Accessibility -

    let security = props.security;
    let frontendIds = Gui_id_list;
    if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.purchase_orders_versions.purchase_orders_versions)){
        return <Fragment>
        <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </Fragment>
    }

  return (
    <div className='container-fluid'>
        <NavigationHeder title={ Helper.getLabel(props.language,'versions',"Versions") }>
            <Button isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.purchase_orders_versions.purchase_orders_versions_print_button)} isActive= {false} title={Helper.getLabel(props.language,'print_btn',"Print")} icon={'/images/icons/print.svg'} />
        </NavigationHeder>

        {loading ? <SimpleLoading /> : ( 
            <Tabs navs={defaultNavs} tabContantsId='purchase-orders-header-view-versions-tabs' showAll={true} scrollOffset="300" addComponentTop = {addComponentTop}>
               {viewComponent == 'Header View' ?
                    <div className='row'>
                        <div className='col-lg-3'>
                            <POHeaderVersions id={header_id} />
                        </div>
                        <div className='col-lg-9'>
                            <POHeaderTabs 
                                currency={currency} 
                                vendor={vendors} 
                                entities={entities} 
                                itemGroup={itemGroup} 
                                ordered={ordered} 
                                requester={requester} 
                                sites={sites} 
                                taxGroup={taxGroup} 
                                taxCode={taxCode} 
                                payment={payment}
                                deliveryTerm={deliveryTerm}
                                modeOfDelivery={modeOfDelivery}
                                id={header_id} 
                                viewComponent={viewComponent}
                            />
                        </div>
                    </div>
                :(
                    <div className='row'>
                        <div className='col-lg-3'>
                            <POLinesVersions id={line_id} />
                        </div>
                        <div className='col-lg-9'>
                            <POLinesHistoryGrid 
                                category={category} 
                                units={units} 
                                products={products} 
                                barcodes={barcodes} 
                                items={items} 
                                id={line_id} 
                            />
                        </div>
                    </div>
                )}
            </Tabs>
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.procure.purchase_orders.control_forms.purchase_orders_versions
}

export default connect(mapStateToProps) (MasterComponentWraper(ApplySecurityRoles(HistoryPO, SecurityOptions)) )