/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Api from '../../../../inc/Api'
import MasterComonent from '../../../Backend/MasterComonent'
import Settings from '../../../../inc/Settings'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import Button from '../../../inc/Button'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import Collapse from '../../../inc/Collapse'
import RFQBiddingRequirement from './RFQBiddingRequirement'
import RFQBiddingProposal from './RFQBiddingProposal'
import CommonScoringSections from '../../Request For Quotation/Scoring Module/CommonScoringSections'
import RFQAttachmentPopup from '../RFQList/RFQAttachmentPopup'
import ReasonPopUp from './ReasonPopUp'
import BiddingForm from './BiddingForm'
import Timer from './Timer'
import TotalWidget from '../../../Widgets/TotalWidget/TotalWidget'
import PriceDiscountTab from '../../Purchase Order Screen/PurchaseOrderDetails/PriceDiscountTab'
import ExternalComplianceView from '../../Request For Quotation/Compliances/ExternalComplianceView'
import SimpleLoading from '../../../Loading/SimpleLoading'
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation'
import RequestExtensionModal from './RequestExtensionModal'
import ExtensionRequests from '../../../RequestForQuotation/RFQDetailForm/ExtensionRequests'
import { Alert } from 'react-bootstrap'
import AdditionalInformation from './AdditionalInformation'
import ContractTerms from '../../../RequestForQuotation/RFQDetailForm/HeaderView/ContractTerms'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'
import QuestionnaireForm from '../../../Questionnaire/QuestionnaireForm'
import QuestionBidSubmissionData from './BidSubmissionQuestion'
import RFQAuctionInnerPopup from './RFQAuctionInnerPopup'
import GeneralAuction from '../../../RequestForQuotation/RFQDetailForm/HeaderView/GeneralAuction'
import RFQCommonApiCalls from '../../../RequestForQuotation/RFQCommonApiCalls'
import AuctionPriceCounter from './AuctionPrice'
import AddressList from '../../../Tenant/Address/AddressList'
import ContactAddress from '../../../Tenant/Address/ContactAddress'


//comment data related to rfqLines because it is handling in proposal grid

const RFQBiddingDetails = (props) => {
  const [rfqData, setRFQData] = useState({})
  // const [rfqLines, setRFQLines] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [rfqAttachments, setRFQAttachments] = useState([])
  const [showPopUp, setShowPopUp] = useState(false)
  const [comment, setComment] = useState('')
  const [actions, setActions] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [allFieldsAreZero, setAllFieldsAreZero] = useState(false)
  const [requestExtensionPopup,setRequestExtensionPopup] = useState(false)
  const [extensionRequestsPopup,setExtensionRequestsPopup]=useState(false)
  const [show,setShow]=useState(true)
  const [actionsLoading,setActionsLoading]=useState(false)
  const [questionnaireTest,setQuestionnaireTest]=useState(false)
  const [biddingDetailsPopup,setBiddingDetailsPopup]=useState(false)
  const [isAuctionBidEditable,setIsAuctionBidEditable]=useState(false)
  const [auctionTypeList,setAuctionTypeList]=useState([])
  const [currentPrice, setCurrentPrice] = useState(null);
  const [expiryShow,setExpiryShow]=useState(true)
  const [rfiAlertShow,setRfiAlertShow]=useState(true)
  const [rfiLinesRateCard,setRfiLinesRateCard]=useState('') 
  const [height, setHeight] = useState(0);
  const [alertMsgDivHeight, setAlertMsgDivHeight] = useState(0);

  const handlePriceUpdate = (price) => {
    setCurrentPrice(price);
  };
 
  const location = useLocation()

  const prevRfqDataRef = useRef();

  const alertMsgDivRef = useRef(null);
  const wrapperDiv = useRef(null);

  const navigate = useNavigate()

  let typeBasedUrl = props?.type===1?"/rfi-vendor-reply/rfi-list":"/rfq-vendor-reply/rfq-list"
  const typeTitle = props.type===1 ? 'Request for Information' : 'Request for Quotation'

  const commonApiCalls = new RFQCommonApiCalls()

  useEffect(() => {
    prevRfqDataRef.current = rfqData;
  }, []);

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  let currencyListCalled = false

  const rfq_vendor_id = props.type===1?getSearchParam('RFI_vendorreply_id'):getSearchParam('RFQ_vendorreply_id')

  const handleClose = () => setShow(false);
  
  //Api Calls
  const get_rfq_data = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/RFVendorreplyheader/${rfq_vendor_id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          // setRFQLines(res.data.data.lines)
          setRFQData(res.data.data)
          setLoading(false)
        }
      })
      .catch((res) => { 
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const get_rfq_attachments = () => {
    let api = Api
    api.setUserToken()
    if (rfqData?.RFQ_attachmentsource_id === null || rfqData?.RFQ_attachmentsource_id === undefined || !rfqData) return
    api
      .axios()
      .get(
        Settings.loginUrl +
          `/get_attachments/${rfqData?.RFQ_attachmentsource_id}`,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          setRFQAttachments(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const getAsyncData = async () => {
     if(rfqData?.RFQ_type !== 2){
      return
     }
      try {
        const auctionTypeData = await commonApiCalls.getAuctionTypes()
        setAuctionTypeList(auctionTypeData)
      } catch (err) {
        console.log(err);
      }
  };

  const getHeightFromResizeWindowHandler = (newHeight) => { 
    if(alertMsgDivHeight > 0){ 
      setHeight(newHeight - alertMsgDivHeight - 45)
    } else {
      setHeight(newHeight - 30)
    }
  };

  useEffect(() => {
    if (!alertMsgDivRef.current) return;

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) { setAlertMsgDivHeight(entry.contentRect.height) };
    });

    observer.observe(alertMsgDivRef.current);

    return () => observer.disconnect();
  }, [expiryShow, rfqData, rfiAlertShow, show]);

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv, getHeightFromResizeWindowHandler, window);
  }, [wrapperDiv?.current?.clientHeight, alertMsgDivHeight, loading]);
  
  useEffect(()=>{
    get_rfq_attachments()
  },[rfqData])

  useEffect(()=>{
    getAsyncData()
  },[rfqData])

  const interestedType = (type) => {
    if(type==='interested'){
      return true
    }
    else if(type === 'not interested'){
      return false
    }
    else{
      return null
    }
  }

  const interestedTypeMessage = (res,type) => {
    if(type==='interested'){
      return Helper.alert('Interested in Bid', 'success')
    }
    else if(type === 'not interested'){
      return Helper.alert('Not Interested in Bid', 'success')
    }
    else{
      return null
    }
  }

  const updateBidStatus = (type) => {
    const tempData = {
      interested_to_participate: interestedType(type) ,
      comment_no_participation: type === 'not interested' ? comment : null,
    }
    updateRFQHeader(tempData,type)
  }

  const updateRFQHeader = (tempData,type) => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
          `/RFVendorreplyheader/${rfq_vendor_id}`,
        tempData,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
         type !== 'submit' && interestedTypeMessage(res,type)
          setShowPopUp(false)
          setRFQData((prev) => {
            return { ...prev, ...tempData }
          })
          get_rfq_data()
        }
      })
      .catch(function (error) {
        Helper.alert(error.response.data.message, 'error')
      })
  }

  const onAcceptClick = () => {
    Helper.confirmMe(() => {
      update_reply_actions('accepted')
    }, "Are you sure you want to accept bid at the current price? Please confirm to proceed with this action.", null, "Do you confirm accepting this bid at the current price?",520)
  }

  const get_reply_actions = () => {
    let api = Api
    api.setUserToken()
    setLoading(true)
    api
      .axios()
      .get(Settings.loginUrl + `/enum/RFvendorreply_action`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setActions(res.data.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) 
        setLoading(false)
      })
  }

  const get_currency_dropdown_list = () => {
    if (currencyListCalled) {
      return
    }
    let api = Api
    currencyListCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/currency`, {})
      .then(function (res) {
        if (res.data.status === 'success') {
          setCurrencyList(res.data.data)
        }
      })
  }

  const locationData = window.location.href;
  const match = locationData?.match(/\/\/([a-zA-Z0-9-]+)\./);
  const subdomain = match ? match[1] : null;

  const update_reply_actions = (type) => {
    const matchingAction = actions.find(
      (action) => action.name.toLowerCase() === type,
    )
    const data = {
      rfq_id: null,
      vendorreply_id: parseInt(rfq_vendor_id),
      action: null,
      day_hour: null,
      type: null,
      reason: null,
      vendors: null,
    }
    if (matchingAction) {
      const { id } = matchingAction
      data.action = id
    }
    if(type==="accepted"){
      data.acceptance_price = currentPrice
    }
    let api = Api
    api.setUserToken()
    setActionsLoading(true)
    api
      .axios()
      .post(
        Settings.apiPurchaseRequisitionUrl + `/vendoraction_on_vendorreply`,
        data,
      )
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message, 'success')
          if(type==="recall"){
            setActionsLoading(false)
            updateBidStatus('recall')
          }
          if(type==='submit'){
            setActionsLoading(false)
            setTimeout(()=>{
              navigate(rfqData?.RFQ_type===2?"/auction": typeBasedUrl)
            },800)
            subdomain==='gts-dev' && closeQuestionnaire()
          }
          if(type==='accepted'){
            setActionsLoading(false)
          }
          get_rfq_data()
        }
      })
      .catch((res) => {
        setActionsLoading(false)
        getPopupMessageBasedOnStatus(res) 
      })
  }

  const questionnaireData = () => {
    setQuestionnaireTest(true)
  }

  const closeQuestionnaire = () => {
    setQuestionnaireTest(false)
  }

  const handleBidSubmission = () => {
    Helper.confirmMe(() => {
     subdomain === 'gts-dev' ? questionnaireData() : update_reply_actions('submit')
    }, "Your bid will be submitted once you confirm. However, you can still recall it for any amendments until it expires.", null, "Are you sure you want to submit bid?",400)
  }

  const handleBidRecall = () => {
    Helper.confirmMe(() => {
      update_reply_actions('recall')
    }, "If you recall your bid, you will be able to make changes before submitting it again. Please confirm to proceed with this action.", null, "Are you sure you want to recall bid?",400)
  }

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <MasterSidebar>
        <div className='timer-desktop'>
        <Timer rfqData={rfqData} setAllFieldsAreZero={setAllFieldsAreZero} setIsAuctionBidEditable={setIsAuctionBidEditable} />
        </div>
        {rfqData?.auction_type===2 && <div className='timelapse'>
           <AuctionPriceCounter rfqData={rfqData} onPriceUpdate={handlePriceUpdate} /> 
        </div>}
        <NoteAttachmentHistory
          notesource_integrator={parseInt(rfq_vendor_id)}
          attachmentsource_integrator={parseInt(rfq_vendor_id)}
          source_id={rfqData?.source_id}
          hideHistory={true}
          height={height - 170}
        />
      </MasterSidebar>
    )
  }

  //for menu
  const moreOptions = () => {
    return (
      <div>
        <ul className='actions_nav'>
          <li>
            <Link
              to={`/rfq-vendor-reply/rfq-versions?RFQ_vendorreply_id=${rfq_vendor_id}`}
              state={{document_number:rfqData?.document_number,title:rfqData?.title, rfq_type:rfqData?.RFQ_type}}
            >
              View Versions
            </Link>
          </li>
          <li>
            <Link
              to={`/miscellaneous-charges/${rfq_vendor_id}`}
              state={{document_number:rfqData?.document_number,document_type:"RFQ",description:rfqData?.title,source_id:rfqData?.source_id,editable:(rfqData?.interested_to_participate === false ||
                rfqData?.interested_to_participate === null ||
                allFieldsAreZero === true ||
                bidStatus) }}
            >
              Miscellaneous Charges
            </Link>
          </li>
         {rfqData?.RFQ_type !==2 && <li>
            <Link
              onClick={(e) => {
                e.preventDefault()
                setExtensionRequestsPopup(true)
              }}
            >
              Extension Requests
            </Link>
          </li>}
          {rfqData?.RFQ_type ===2 && <li style={{width:"max-content"}}>
            <Link
              onClick={(e) => {
                e.preventDefault()
                setBiddingDetailsPopup(true)
              }}
            >
              Competitor Bidding Details
            </Link>
          </li>}
        </ul>
      </div>
    )
  }

  //data for grid and tabs
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const navs = [
    {
      title: Helper.getLabel(props.language, 'proposal_lines', 'Proposal Lines'),
      isActive: true,
    },
    { title: Helper.getLabel(props.language, 'requirement', 'Requirement') },
    // {
    //   title: Helper.getLabel(
    //     props.language,
    //     'terms_of_payment',
    //     'Terms of payment',
    //   ),
    // },
      {
        title: Helper.getLabel(
          props.language,
          'compliance',
          'Compliances',
        ),
      },
      {
        title: Helper.getLabel(props.language,'terms_conditions','Terms & Conditions')
      }
  ]

  if(rfqData?.display_scoring_criteria_to_supplier){
    navs.push(
      {
        title: Helper.getLabel(
          props.language,
          'scoring_criteria',
          'Scoring Criteria',
        ),
      }
    )
  }
  
  const headerTabs = [
    {
      title: Helper.getLabel(props.language, 'general', 'General'),
      isActive: true,
    },
    {
      title: Helper.getLabel(props.language, 'additional_information', 'Additional Information'),
    },
    // {
    //   title: Helper.getLabel(
    //     props.language,
    //     'price_and_discount',
    //     'Price & Discount'
    //   ),
    // },
    {
      title: Helper.getLabel(
        props.language,
        'address',
        'Address'
      ),
    },
    {
      title: Helper.getLabel(
        props.language,
        'contact',
        'Contact'
      ),
    },
  ]

  if(rfqData?.RFQ_type===2){
    headerTabs?.push({
      title: Helper.getLabel(
        props.language,
        'auction',
        'Auction'
      ),
    })
  }

  //for close pop up
  const onClose = () => {
    setShowModal(false)
  }

  const handleRequestExtensionClose = () => {
    setRequestExtensionPopup(false)
  }

  const handleExtenstionRequestsPopup = () => {
    setExtensionRequestsPopup(false)
  }

  const handleBiddingDetailsPopup = () => {
    setBiddingDetailsPopup(false)
  }

  //for form attachment click
  const onAttachmentClick = () => {
    setShowModal(true)
    get_rfq_attachments()
  }

  const hasVendorReplyStatus = () => {
    return [2,3, 4, 5, 8, 9].includes(rfqData?.vendor_replystatus)
  }

  const checkLineStatus = () => {
    if (!rfqData?.lines || rfqData?.lines.length === 0) {
      return false;
    }
    return rfqData?.lines.some((line) =>
      [2, 3, 4, 5, 8, 9].includes(line.vendorreplystatus_external)
    )
  }

  const lineStatus = checkLineStatus()
  const bidStatus = hasVendorReplyStatus()

  //for fetch vendor data
  useEffect(() => {
    get_rfq_data()
    get_reply_actions()
    get_currency_dropdown_list()
  }, [])
  
  //for render tabs data
  const rfqBiddingModule = (props) => {
    return (
      <Tabs
        navs={defaultNav}
        showAll={true}
        scrollOffset='300'
        disableSticky={true}
        addComponentRight={addComponentRight}
      >
        <div className='common_scrollbar_styles' style={{ height: `${height}px`, overflowX: 'hidden', overflowY: 'auto'}}> 
          <ExtHeaderLine
            title={Helper.getLabel(props.language, 'bidding', 'Bidding')}
          />
          <div className='timer-responsive'>
            <Timer rfqData={rfqData} setAllFieldsAreZero={setAllFieldsAreZero} setIsAuctionBidEditable={setIsAuctionBidEditable} />
          </div>
          <div className='header-tab-rfq bidding_tab mt-3'>
            <Tabs
              navs={headerTabs}
              showAll={false}
              className='me-5'
              scrollOffset='300'
              disableSticky={true}
            >
              <div className='ps-3 pe-3 mb-3'>
                <Collapse
                  className='mt-3'
                  title={Helper.getLabel(
                    props.language,
                    'general',
                    'General Information'
                  )}
                  open={true}
                >
                  <div className='request_for_quotation_bidding'>
                    <BiddingForm
                      rfqHeader={rfqData}
                      setRfqHeader={setRFQData}
                      currencyList={currencyList}
                      language={props.language}
                      allFieldsAreZero={allFieldsAreZero}
                      isAuctionBidEditable={isAuctionBidEditable}
                      bidStatus={bidStatus}
                      lineLevelStatus={lineStatus}
                      type={props.type}
                    />
                    <button
                      className='rfq-attachment-icon bg-transparent border-0 p-0'
                      style={{
                        textAlign:"left",
                        bottom:
                          rfqData?.interested_to_participate === false
                            ? '16px'
                            : ' ',
                      }}
                      onClick={onAttachmentClick}
                      onKeyDown={()=>{}}
                    >
                      <img
                        src='/images/icons/attach.svg'
                        alt='attachment-logo'
                      />
                      {rfqAttachments?.length>0 && <div style={{ position: "absolute", top: "0.5px", right: "4px", width: "7.5px", height: "7.5px", borderRadius: "50%", backgroundColor: "#FC5E24" }}></div>}
                      {showModal && (
                        <RFQAttachmentPopup
                          data={rfqAttachments}
                          closeModal={onClose}
                          style={{
                            position: 'absolute',
                            right: '8px',
                            top: '85%',
                          }}
                          getAttachments={get_rfq_attachments}
                          onFileUpload={get_rfq_attachments}
                          source_id={rfqData?.source_id}
                          attachmentsource_integrator={rfq_vendor_id}
                          attachmentsource_id={rfqData?.attachmentsource_id}
                        />
                      )}
                    </button>
                  </div>
                </Collapse>
              </div>
              <div className='mt-3'>
                <div className='ps-3 pe-3 mb-3'>
                  <Collapse
                    className='mt-3'
                    title={Helper.getLabel(
                      props.language,
                      'additional_information',
                      'Additional Information'
                    )}
                    open={true}
                  >
                  <AdditionalInformation language={props.language} data={rfqData} isNeedNotes={true} title={props.type===1 && Helper.getLabel(props.language,'rfi_description','RFI description')} />
                  </Collapse>
                </div>
              </div>
              {/* commented for future */}
              {/* <div className='mt-3'>
                <div className='ps-3 pe-3 mb-3'>
                  <Collapse
                    className='mt-3'
                    title={Helper.getLabel(
                      props.language,
                      'price_and_discount',
                      'Price & Discount'
                    )}
                    open={true}
                  >
                    <PriceDiscountTab
                      isEditable={
                        rfqData?.onhold || rfqData?.RFQ_status === 9 || rfqData?.RFQ_status === 2 || isAuctionBidEditable ? false : rfqData?.interested_to_participate === true &&
                        !allFieldsAreZero && rfqData?.vendor_replystatus !== 2  
                      }
                      state={rfqData}
                      setState={setRFQData}
                      rfq_vendor_id={rfq_vendor_id}
                      name="price_include_tax"
                      paymentTermName="paymentterm_id"
                      type="rfq"
                    />
                  </Collapse>
                </div>
              </div> */}
              <div className='mt-3'>
                <div className='ps-3 pe-3 mb-3'>
                  <AddressList address_source_integrator={rfq_vendor_id} addressSourc_id = {rfqData?.addresssource_id} source_id ={Settings.source_id.RfqVendorReply}  disableRecords={true} canEdit={false} />
                </div>
              </div>
              <div className='mt-3'>
                <div className='ps-3 pe-3 mb-3'>
                  <Collapse
                    className='mt-3'
                    title={Helper.getLabel(
                      props.language,
                      'contact',
                      'Contact'
                    )}
                    open={true}
                  >
                  <ContactAddress canEdit={false} contactsource_id={rfqData?.contactsource_id} source_id={props.type===1 ? Settings.source_id.rfi : Settings.source_id.RfqVendorReply} address_id={null} contactsource_integrator={rfqData?.invoice_id} />
                  </Collapse>
                </div>
              </div>
              {
                rfqData?.RFQ_type ===2 &&
                <div className='mt-3'>
                <div className='ps-3 pe-3 mb-3'>
                  <Collapse
                    className='mt-3'
                    title={Helper.getLabel(
                      props.language,
                      'auction',
                      'Auction'
                    )}
                    open={true}
                  >
                  <GeneralAuction
                    rfqHeader={rfqData}
                    hideFieldsForVendor={true}
                    isDisabled={true}
                    auctionTypeList={auctionTypeList}
                  />
                  </Collapse>
                </div>
              </div>
              }
            </Tabs>
          </div>
          <div className='bidding_tab mt-3'>
            <Tabs
              navs={navs}
              showAll={false}
              className='me-5'
              scrollOffset='500'
              disableSticky={true}
            >
              <div className="bidding_proposal_line ps-3 pe-3">
                <RFQBiddingProposal
                  rfqData={rfqData}
                  searchParam={rfq_vendor_id}
                  loading={loading}
                  allFieldsAreZero={allFieldsAreZero}
                  isAuctionBidEditable={isAuctionBidEditable}
                  bidStatus={bidStatus}
                  // lineLevelStatus={lineStatus}
                  rfq_vendor_id={rfq_vendor_id}
                  type={props.type}
                  setRfiLinesRateCard={setRfiLinesRateCard}
                />
              </div>
              <div className="bidding_requirement">
                <RFQBiddingRequirement
                  scopeOfWork={rfqData?.scope_of_work}
                  materialsAndServicesNotIncluded={
                    rfqData?.material_service_not_included
                  }
                  agreementTerms={rfqData?.requirement_description}
                />
              </div>
              {/* <div className="bidding_commercial_proposal ps-3 pe-3 ">
                <RFQTermsofPayment />
              </div> */}
              <div className='compliance_common_grid ps-3 pe-3'>
                <ExternalComplianceView disableEditing={!(rfqData?.vendorreplystatus_external === 1 || rfqData?.vendorreplystatus_external === 2 || isAuctionBidEditable)} rfq_id={rfqData?.RFQ_id} rfq_vendorreply_id={rfq_vendor_id} />
              </div>
              <div className='external_contract_grid ps-3 pe-3'>
                <ContractTerms transactional_document_type={[Settings.document_types.request_for_quotation]} disableComponent={true} rfqType={rfqData?.RFQ_type} sourceintegration_id={rfqData?.RFQ_id}  /> 
              </div>
              <div className="bidding_miscellaneous_charges ps-3 pe-3">
                {rfqData?.display_scoring_criteria_to_supplier === true ? (
                  <CommonScoringSections
                    language={props.language}
                    RFQ_id={rfqData?.RFQ_id}
                    collapseTitle="Scoring Criteria"
                    externalMode={true}
                  />
                ) : null}
              </div>
            </Tabs>
          </div>
          {actionsLoading ? <OverlayLoader isPopupInTab={true} /> : null}
        </div>
      </Tabs>
    )
  }
  
  return (
    <div className="request_for_quotation_detail_screen external-screens responsive-screen-style">
      <MasterComonent>
        <div className='scrollBarBlinkControl'>
          <div ref={wrapperDiv}>
            {rfqData?.interested_to_participate === null ? (
              <NavigationHeder
                backUrl={rfqData?.RFQ_type===2?-1: typeBasedUrl}
                title={`<span style="color:#757575">${props.type===1?'Request for Information List':'Request for Quotation List'} > </span><span style="color:#313638;">${props.type===1?Helper.getLabel(props.language, 'rfq', "Request for Information Details"):Helper.getLabel(props.language, 'rfq', "Request for Quotation Details")} #</span> ${rfqData?.document_number || ''}`}
                moreOptions={moreOptions}
              >
                <Button
                  className="rfq-interested-status-btn"
                  title={Helper.getLabel(
                    props.language,
                    'interested_to_participate',
                    'Interested to participate',
                  )}
                  isDisable={allFieldsAreZero || rfqData?.RFQ_status === 9 || rfqData?.onhold || rfqData?.RFQ_status === 2}
                  onClick={() => updateBidStatus('interested')}
                />
                <Button
                  className='black-btn-style'
                  title={Helper.getLabel( 
                    props.language,
                    'not_interested_to_participate',
                    'Not Interested to participate',
                  )}
                  onClick={() => setShowPopUp(true)}
                  isDisable={allFieldsAreZero || rfqData?.RFQ_status === 9 || rfqData?.onhold || rfqData?.RFQ_status === 2}
                />
                <TotalWidget
                  source_id={rfqData?.source_id}
                  integrator={rfq_vendor_id}
                />
                <Button
                    isActive={false}
                    className='black-btn-style'
                    title={Helper.getLabel(
                      props.language,
                      'close',
                      'Close',
                    )}
                    onClick={()=>navigate(rfqData?.RFQ_type===2?"/auction":typeBasedUrl)}
                  />
              </NavigationHeder>
            ) : (
              <NavigationHeder
                title={`<span style="color:#757575">${props.type===1?'Request for Information List':'Request for Quotation List'} > </span><span style="color:#313638;">${props.type===1?Helper.getLabel(props.language, 'rfq', "Request for Information Details"):Helper.getLabel(props.language, 'rfq', "Request for Quotation Details")} #</span> ${rfqData?.document_number || ''}`}
                moreOptions={moreOptions}
              >
                <Button
                    isActive={false}
                    className='black-btn-style'
                    title={Helper.getLabel(
                      props.language,
                      'close',
                      'Close',
                    )}
                    onClick={()=>navigate(rfqData?.RFQ_type===2?"/auction":typeBasedUrl)}
                  />
                { rfqData?.RFQ_type===2 && rfqData?.auction_type===2 && rfqData?.auction_acceptance_price === null ?
                  <Button
                    isActive={false}
                    className='green-btn-style'
                    title={Helper.getLabel(
                      props.language,
                      'accept_price',
                      'Accept Price',
                    )}
                    isDisable={!(new Date() >= new Date(rfqData?.auction_start_date_time))}
                    onClick={onAcceptClick}
                  />:
                <>
                {rfqData?.interested_to_participate === true && rfqData?.RFQ_type!==2? (
                  <Button
                    isActive={false}
                    className='black-btn-style'
                    title={Helper.getLabel(
                      props.language,
                      'request_an_extension',
                      'Request an Extension',
                    )}
                    onClick={()=>{setRequestExtensionPopup(true)}}
                    isDisable={allFieldsAreZero || rfqData?.onhold || rfqData?.RFQ_status === 9 ||rfqData?.RFQ_status === 2 || rfqData?.vendor_replystatus===2 || rfqData?.vendor_replystatus===3 || !rfqData?.allow_vendor_to_request_extension}
                  />
                ) : null}

                {(parseInt(rfqData?.RFQ_type)===2 && parseInt(rfqData?.auction_type) === 2) ? <></> : <Button
                  icon="/images/icons/refresh-cw.svg"
                  title={Helper.getLabel(props.language, 'recall', 'Recall')}
                  onClick={handleBidRecall}
                  isDisable={allFieldsAreZero || isAuctionBidEditable || rfqData?.RFQ_status === 2|| rfqData?.onhold || rfqData?.RFQ_status === 9 || (rfqData?.vendorreplystatus_external!==2 &&  rfqData?.vendorreplystatus_external!==3 && rfqData?.vendorreplystatus_external!==10)}
                />}

                <TotalWidget
                  source_id={rfqData?.source_id}
                  integrator={rfq_vendor_id}
                />

                {rfqData?.interested_to_participate === true ? (
                  <Button
                    isActive={false}
                    className="rfq_submit_btn"
                    onClick={handleBidSubmission}
                    title={Helper.getLabel(props.language, 'submit', 'Submit')}
                    isDisable={allFieldsAreZero || isAuctionBidEditable || rfqData?.onhold || rfqData?.RFQ_status === 9 ||  rfqData?.RFQ_status === 2|| rfqData?.vendor_replystatus===2 || rfqData?.vendor_replystatus===3}
                  />
                ) : null}
                </>}
              </NavigationHeder>
            )}
          </div>

          <div ref={alertMsgDivRef}>
            {
              allFieldsAreZero && expiryShow && 
              (
                <Alert variant='dark' className="rfq-expired-alert" onClose={() => setExpiryShow(false)} dismissible>
                  <p className='mb-0'>{`This ${typeTitle} is Expired.`}</p>
                </Alert>
              )
            }

            {
              rfiLinesRateCard !== '' && rfiLinesRateCard !== "no" && props?.type===1 && rfiAlertShow && 
              (
                <Alert variant='info' className="bg-transparent" onClose={() => setRfiAlertShow(false)} dismissible>
                  <p className='mb-0'>{rfiLinesRateCard === 'some' ? 'Note: This RFI includes rate card-based lines where the unit price is fixed as a CAP value. Please provide unit-wise pricing for these lines, while other lines may follow standard bidding.':'Note: This RFI is based on a rate card. The unit price is fixed as a CAP value. Please provide your unit-wise pricing in rate card.'}</p>
                </Alert>
              )
            }

            {
              rfqData?.onhold && show && 
              (
                <Alert variant='dark' onClose={handleClose} dismissible>
                  <p className='mb-0'>{`This ${typeTitle} is in hold state. you can't make any change in this ${typeTitle}.`}</p>
                </Alert>
              )
            }

            {
              rfqData?.RFQ_status === 2 && show && 
              (
                <Alert variant='dark' onClose={handleClose} dismissible>
                  <p className='mb-0'>{`This ${typeTitle} is canceled. you can't make any change in this ${typeTitle}.`}</p>
                </Alert>
              )
            }

            {
              rfqData?.RFQ_status === 9 && show && 
              (
                <Alert variant='dark' onClose={handleClose} dismissible>
                  <p className='mb-0'>{`This ${typeTitle} is re-tendered. you can't make any change in this ${typeTitle} untill its publish again.`}</p>
                </Alert>
              )
            }
          </div>

          <div className="tabs_default">  
            {loading ? <SimpleLoading />: rfqBiddingModule(props)}
          </div>

          {showPopUp && (
            <ReasonPopUp
              setShowPopup={setShowPopUp}
              comment={comment}
              language={props.language}
              setComment={setComment}
              handleCommentSave={() => updateBidStatus('not interested')}
            />
          )}
          {
            requestExtensionPopup && <RequestExtensionModal handleCloseModal={handleRequestExtensionClose} language={props.language} rfq_vendor_id={parseInt(rfq_vendor_id)} actions={actions} />
          }
          {
            extensionRequestsPopup && <ExtensionRequests 
            rfq_id={rfqData?.RFQ_id} 
            language={props.language} 
            handleCloseModal={handleExtenstionRequestsPopup}
            usertype={props?.auth?.user?.usertype} />
          }
          {
            questionnaireTest && <QuestionnaireForm  onQuestionnaireClose={closeQuestionnaire} QuestionData = {QuestionBidSubmissionData} handleSubmit={()=>{update_reply_actions('submit')}} info={'This information is about bid submission.'}/>
          }
          {
            biddingDetailsPopup && <RFQAuctionInnerPopup rfq_id={rfqData?.RFQ_id} language={props.language} handleCloseModal={handleBiddingDetailsPopup} />
          }
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(RFQBiddingDetails)
