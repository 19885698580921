import React, { useState, useEffect, useCallback, useRef } from 'react';
import SimpleLoading from '../../../Loading/SimpleLoading';
import Settings from '../../../../inc/Settings';
import Helper from '../../../../inc/Helper';
import LineViewComponent from '../../Edit PO/ListView/LineViewComponent';
import Input from '../../../Forms/Input';
import Collapse from '../../../inc/Collapse'; 
import NavigationHeder from '../../../Navigations/NavigationHeder';
import Button from '../../../inc/Button';
import MasterSidebar from '../../../Sidebars/MasterSidebar';
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory';
import Api from '../../../../inc/Api';
import MasterComponentWraper from '../../../Backend/MasterComponentWraper';
import RsWithRouter from '../../../inc/RsWithRouter';
import FormValidator from '../../../../inc/FormValidator';
import NewInputDatePicker from '../../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../../inc/DatepickerHelper';
import { getPopupMessageBasedOnStatus } from '../../../../inc/Validation';
import { useParams } from 'react-router-dom';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import TabContentWraper from '../../../Widgets/Tabs/TabContentWraper';
import FixedNavigationHeader from '../../../Navigations/FixedNavigationHeder';

let Purchase_Order_id = null;
const EditReturnOrder = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [shipmentHeader, setShipmentHeader] = useState({});
    const [vendor, setVendor] = useState({});
    const [vendorSite, setVendorSite] = useState({});
 
    let validator; 
    let language = null; 
    const {id} = useParams() 
    // Tabs wrapper div
    const [selectedSupNav, setSelectedSupNav] = useState('Header View'); 
    const [scrollDitection, setScrollDitection] = useState('down'); 
    const [height, setHeight] = useState(0); 
    const myDivRef = useRef(null);

    let sectionName = 'return-order-edit'; 
    
    const validationConfigure = () => {
        const language = null;
        let fieldConfig = [
            {name:'receiving_reference_number',displayName:Helper.getLabel(language,'return_number_required','Return number required'),types:['Required']},
            {name:'accounting_date',displayName:Helper.getLabel(language,'accounting_date_are_required','Accounting date are required'),types:['Required']},
            {name:'document_date',displayName:Helper.getLabel(language,'document_date_are_required','Document date are required'),types:['Required']},
        ]
        validator = new FormValidator(fieldConfig,language);
    }

    validationConfigure();

    useEffect(() => {
        if (id) {
            loadShipmentHeader();
        }
    }, [id]);

    useEffect(() => {
        Helper.resizeWindowHandle(myDivRef,setHeight,window)
    },[myDivRef?.current?.clientHeight, scrollDitection, isLoading]);

    const loadShipmentHeader = useCallback(() => {
        if (!id) return;
        let api = Api
        api.setUserToken();
        setIsLoading(true);
        api.axios().get(Settings.apiPurchaseOrderUrl+`/sh_header/${id}`,{}).then(function(res){
                const data = res?.data?.data;
                Purchase_Order_id = data?.purchaseorder_id;
                setShipmentHeader(data);
                loadVendorById(data.vendor_id);
                loadVendorSite(data.vendorsite_id);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShipmentHeader({});
            });
    }, [id]);  
    
    useEffect(() => {
        if (id) {
            loadShipmentHeader();
        }
    }, [id]); 
  
    const loadVendorById = useCallback((vendor_id) => {
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiVendorUrl+'/vendor/'+vendor_id).then(res=>{
            setVendor(res.data.data)
            })
    },[]);

    const loadVendorSite = useCallback((vendorsite_id)=>{
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiVendorUrl+'/vendorsite/'+vendorsite_id).then(res=>{
            setVendorSite(res.data.data)
            })
    }, []);

    const saveHandler=(event, afterSaved) => {
        let api = Api
        api.setUserToken();
        if (!validator.isValid(shipmentHeader)) {
            validator.displayMessage();
            return;
        }
        let newData = {
                receiving_reference_number: shipmentHeader.receiving_reference_number,
                contactsource_id:null,
                attachmentsource_id:null,
                notesource_id:null,
                addresssource_id:null,
                accounting_date:DatepickerFunctions.convertDateForDataBase(shipmentHeader.accounting_date),
                document_date: DatepickerFunctions.convertDateForDataBase(shipmentHeader.document_date)
            }
        setIsLoading(true)
        api.axios().put(Settings.apiPurchaseOrderUrl+'/sh_header/'+shipmentHeader.shipment_id,newData).then(res =>{
            Helper.alert(res.data.message);
            setIsLoading(false);
            if(afterSaved){
                afterSaved()
            }
        }).catch(error=>{
            getPopupMessageBasedOnStatus(error);
            setIsLoading(false);
        })
    }
    const onInputChangeHandler = (event) => {
        setShipmentHeader(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const onDateChangeHandler = (date, name) => {
        setShipmentHeader(prev => ({
            ...prev,
            [name]: date
        }));
    };

    const addComponentRight = () => (
        <MasterSidebar>
            <NoteAttachmentHistory source_id={Settings.source_id.return_order} notesource_integrator= {id}  attachmentsource_integrator={id} hideHistory={true} condition={shipmentHeader?.document_status===2} />
        </MasterSidebar>
    );

    const onPostingHandler = (event) => {
        if (!validator.isValid(shipmentHeader)){
            validator.displayMessage();
            return;
        }
        saveHandler(event,() =>{
            let api = Api;
            api.setUserToken();
            api.axios().get(Settings.apiPurchaseOrderUrl+'/SH_posted/' + id).then(res=>{
                Helper.alert(res.data.message);
                    setIsPosting(false);
                    props.rs_router.navigate((props.backUrl ? `${props.backUrl}/${Purchase_Order_id}` : `/return-order/all/${Purchase_Order_id}`));
                   loadShipmentHeader();
            }).catch(error =>{
                getPopupMessageBasedOnStatus(error)
                    setIsPosting(false)
                  })
        })
    }
    const canEditShipment=()=>{
        return !(shipmentHeader.document_status && parseInt(shipmentHeader.document_status) === 1);
    };

    if (!id) return null; 
 
    let navs = [
        { title: Helper.getLabel(null,'general_information','General Information'), data_section: "one", active_status: "active_tab" },
        { title: Helper.getLabel(null,'lines','Lines'), data_section: "two", active_status: "" }
    ];

    let purchaseorder_id = shipmentHeader?.purchaseorder_id;
    return (
        <div className='goods_receiving_create_note_page'>
            {isLoading || isPosting ? <OverlayLoader isPopupInTab={true} /> : null}  
            <div className='container-fluid scrollBarBlinkControl'>
                <div ref={myDivRef}>
                    <NavigationHeder backUrl={props.backUrl ? props.backUrl + '/' + purchaseorder_id : null} title={Helper.getLabel(language, 'edit_return_order', 'Edit Return Order')} hideMoreBtn={true}>
                        <Button id="btn_master_save" className="blue" isDisable={!canEditShipment()} isActive={false} title={Helper.getLabel(language,'save',"Save")} onClick={saveHandler} />
                        <Button title={"Post"}  isDisable={isPosting || !canEditShipment()} onClick={onPostingHandler} isActive={true} />
 
                    </NavigationHeder>

                    <FixedNavigationHeader
                        sectionName={sectionName}
                        navs={navs}
                        selectedSupNav={selectedSupNav}
                        scrollDitection={scrollDitection}
                    />
                </div>  
                
                <TabContentWraper
                    height={height}
                    setScrollDitection={setScrollDitection}
                    leftSideContent={
                        <>
                            <div
                                className="lists_contant one mb-4"
                                id={`${sectionName}General Information`}
                                style={{ position: "relative" }}
                            >
                                <Collapse open={true} title={Helper.getLabel(language,'general_information','General Information')}>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-4'>
                                            <Input disable={true} name='document_number' label={Helper.getLabel(language, 'document_no','Document number')} value={shipmentHeader?.document_number} onChange={onInputChangeHandler} />
                                        </div>
                                    </div>
                                    <h3 className='inline_title_1'>{Helper.getLabel(language,'vendor','Vendor')}</h3>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <Input disable={true} name="vendor_code" id="vendor_code" value={vendor.code} label={Helper.getLabel(language,'vendor_code','Vendor code')} onChange={onInputChangeHandler}/>
                                        </div>
                                        <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <Input disable={true} name="vendor_name" id="vendor_name" value={vendor.name} label={Helper.getLabel(language, 'vendor_name', 'Vendor name')} onChange={onInputChangeHandler} />
                                        </div>
                                        <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <Input disable={true} name="vendor_site" id="vendor_site" value={vendorSite.name} label={Helper.getLabel(language, 'vendor_site', 'Vendor site')} onChange={onInputChangeHandler} />
                                        </div>
                                        {/* <div className='col-xs-12 col-sm-4 col-md-3'></div> */}
                                    </div>
                                    <h3 className='inline_title_1'>{Helper.getLabel(language,'return_order_details','Return Order Details')}</h3>
                                    <div className='row'>
                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <Input name='receiving_reference_number' hasError={validator.hasError('receiving_reference_number')} disable={!canEditShipment()} required={true} label={Helper.getLabel(language, 'return_order_number', 'Return order number')} value={shipmentHeader.receiving_reference_number} onChange={onInputChangeHandler} />
                                        </div>
                                        <div className='col-xs-12 col-sm-4 col-md-8'>
                                            <Input disable={true}  name='description' inputType="textarea" label={Helper.getLabel(language,'description','Description')} value={shipmentHeader.description} onChange={onInputChangeHandler}/>
                                        </div>
                                    </div>
                                    <h3 className='inline_title_1'>{Helper.getLabel(language,'dates','Dates')}</h3>
                                    <div className='row'>
                                        <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <NewInputDatePicker label ={Helper.getLabel(language,'accounting_date','Accounting Date')} name={"accounting_date"} id = {"accounting_date"} value = {shipmentHeader?.accounting_date ? shipmentHeader?.accounting_date : null }  disable = {!canEditShipment()} onChange ={onDateChangeHandler}/>  
                                        </div>
                                        <div className='col-xs-12 col-sm-4 col-md-4'>
                                            <NewInputDatePicker label ={Helper.getLabel(language,'document_date','Document Date')}  name={"document_date"} id = {"document_date"} value = {shipmentHeader?.document_date ? shipmentHeader?.document_date : null } disable = {!canEditShipment()} onChange ={onDateChangeHandler}/>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>

                            <div
                                className="lists_contant two"
                                id={`${sectionName}Lines`}
                                style={{ position: "relative" }}
                            >
                                {
                                    shipmentHeader?.shipment_id ? 
                                    <LineViewComponent 
                                        defaultValues ={{
                                            /*
                                            requester_id:editPO.requester_id,
                                            warehouse_id:editPO.warehouse_id,
                                            delivery_date:editPO.delivery_date,
                                            taxgroupitem_id:editPO.taxgroupitem_id,
                                            taxgroup_id:editPO.taxgroup_id,
                                            entity_id:editPO.entity_id,
                                            site_id:editPO.site_id,
                                            orderer_id:editPO.orderer_id
                                            */
                                        }}
                                        lineGridId = 'good_receiving_line_grid'
                                        viewType = "shipment"
                                        hideBillingRule={true}
                                        shipmentType = "return_order"
                                        canShipmentEdit = {canEditShipment()}
                                        source_id={Settings.source_id.return_order} 
                                        line_source={Settings.source_id.return_order} 
                                        isEditable={false} 
                                        lineGridTitle = {Helper.getLabel(null,'lines',"Lines")}
                                        header_id={shipmentHeader?.shipment_id}
                                        purchase_status={0}
                                        document_status={0}
                                        delivery_date={null}
                                        master_save_button_id = "btn_master_save"
                                        onbehalf_request =  {false}
                                        vendor_id = {null}
                                        vendorsite_id = {null}
                                    />
                                    : <SimpleLoading/>
                                }
                            </div>
                        </>
                    }
                    rightSideContent={addComponentRight} 
                />
            </div>
        </div>
    );    
};

export default MasterComponentWraper( RsWithRouter(EditReturnOrder));