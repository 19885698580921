import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import NavigationHeder from "../Navigations/NavigationHeder";
import Helper from "../../inc/Helper";
import BlueCallToAction from "../Widgets/BlueCallToAction/BlueCallToAction";
import TopVendorsByRankBarGraph from "./TopVendorsByRankBarGraph";
import TopVendorsByTechnicalScoreBarGraph from "./TopVendorsByTechnicalScoreBarGraph";
import VendorResponseReportLineChart from "./VendorResponseReportLineChart";
import Collapse from "../inc/Collapse";
import AgGrid from "../grid/ag/ag-grid";
import Api from "../../inc/Api";
import Settings from "../../inc/Settings";
import SimpleLoading from "../Loading/SimpleLoading";
import RFQCommonApiCalls from "./RFQCommonApiCalls";
import DatepickerFunctions from "../../inc/DatepickerHelper";
import { getBGStatusColor, getStatusColor } from "./RFQDetailForm/RfqFunctions";
import VendorCommentsPopup from "./VendorCommentsPopup";
import { commonThousandSeparator } from "../../inc/Validation";

const RFQVendorReplyGraph = ({ language }) => {
  const location = useLocation();
  const commonApiCalls = new RFQCommonApiCalls();
  const [vendorsSummary, setVendorsSummary] = useState(); 
  const [scoreSummary, setScoreSummary] = useState();
  const [vendorsData, setVendorsData] = useState();
  const [rfqHeader, setRfqHeader] = useState();
  const [rfqLinesComparison, setRFQLinesComparison] = useState([]);
  const [rateCardComparison, setRateCardComparison] = useState([]);
  const [tags, setTags] = useState();
  const [allRfqStatus, setAllRfqStatus] = useState([]);
  const [vendorNotInterestedObj, setVendorNotInterestedObj] = useState({ field_comment: null, openPopup: false });
  const [vendorStatus, setVendorStatus] = useState([]);
  const wrapperDiv = useRef(null);
  const scrollDivRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [scrollDitection, setScrollDitection] = useState('down');
  
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      filter: true,
      resizable: true,
    },
    autoGroupColumnDef: {
      minWidth: 250,
      headerName: Helper.getLabel(language, "line", "Line")
    }, 
    pivotMode: true, 
    suppressAggFuncInHeader: true, 
    processPivotResultColGroupDef: (colGroupDef) => { 
      return colGroupDef.headerName;
    },
  };

  const Columns_Headings = [
    {
      field: "item_name",
      headerName: Helper.getLabel(language, "line", "Line"),
      editable: false,
      rowGroup: true,
      enableRowGroup: true,
      cellRenderer: (params) => { 
        return `<div class="rs_grid_simple_link">${params.value}</div>`;
      },
    },
    {
      field: "vendor_name",
      editable: false,
      headerName: Helper.getLabel(language, "name", "Name"), 
      pivot: true,
      enablePivot: true
    },
    {
      field: "unit_price",
      headerName: Helper.getLabel(language, "price", "Price"),
      editable: false,
      aggFunc: "sum",
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field: "discounts",
      headerName: Helper.getLabel(language, "discount", "Discount"),
      editable: false,
      aggFunc: "sum",
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"} 
    },
    {
      field: "line_amount",
      headerName: Helper.getLabel(language, "line_amount", "Line amount"),
      editable: false,
      aggFunc: "sum",
      cellRenderer: (params) => { 
        return `<div class="grid_line_amount_color">${ params.value ? commonThousandSeparator(params?.value) : '-' }</div>`;
      }
    },
    //commented for future
    // {
    //   field: 'lead_time',
    //   headerName: Helper.getLabel(language, 'lead_time', 'Lead time'),
    //   minWidth: 200,
    //   editable: false,
    //   aggFunc: "sum",
    //   valueFormatter: (params) => {
    //     return Math.floor(Math.random() * 21); 
    //   }
    // },
  ];
  const getRfqStatus = () => {
    const api = Api;
    api.setUserToken();
      api
        .axios()
        .get(`${Settings.loginUrl}/enum/RFQ_status`)
        .then((res) => {
          setAllRfqStatus(res?.data?.data);
        })
        .catch((error) => console.log("Error in RFQ_status: ", error));
  };

  const getVendorStatus = () => {
    const api = Api;
    api.setUserToken();
      api
        .axios()
        .get(`${Settings.loginUrl}/enum/RFvendorreplyheaderstatus`)
        .then((res) => {
          setVendorStatus(res?.data?.data);
        })
        .catch((error) => console.log("Error in RFQ_status: ", error));
  };
  
  const getRFQStatusNameById = () => {
    let rfq_status = null;
    if (getSearchParam("rfq_view_id")) {
      rfq_status = rfqHeader?.rfq_status;
    }
    const status = allRfqStatus?.find((el) => el.id === rfq_status);
    return status?.name;
  };

  const getTags = () => {
    let tags = [
      {
        title: Helper.getLabel(
          language,
          getRFQStatusNameById()?.toLowerCase(),
          getRFQStatusNameById()
        ),
        type: "error",
      },
    ];
    setTags(tags);
  };

  const addComponentTop = () => {
    let items = [
      {
        title: rfqHeader?.rfq_type===1 ? Helper.getLabel(language, "rfi_number", "RFI Number") : Helper.getLabel(language, "rfq_number", "RFQ Number"),
        subtitle: rfqHeader?.document_number ?? '',
      },
      {
        title: Helper.getLabel(language, "document_type", "Document Type"),
        subtitle: rfqHeader?.quotation_type ?? '',
      },
      {
        title: rfqHeader?.rfq_type===1 ? Helper.getLabel(language, "rfi_description", "RFI Description") : Helper.getLabel(language, "rfq_description", "RFQ Description"),
        subtitle: rfqHeader?.title ?? '',
      },
      {
        title: Helper.getLabel(language, "publish_Date", "Publish Date"),
        subtitle: DatepickerFunctions.convertDateTimeFromDataBase(rfqHeader?.publish_datetime) ?? '',
      },
      {
        title: Helper.getLabel(language, "expiry_date", "Expiry date"),
        subtitle: DatepickerFunctions.convertDateTimeFromDataBase(rfqHeader?.expiry_datetime) ?? '',
      },
    ];
    if(rfqHeader?.rfq_type === 2){
       items = items?.filter((item)=>item?.title !==  Helper.getLabel(language, "expiry_date", "Expiry date"))
    } 
    return (
      <div className="rfq_detail_form rfq-internal-blue mb-4">
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={tags} />
      </div>
    );
  };

  const rfq_id = getSearchParam("rfq_view_id");

  const getRfqHeader = async () => {
    let rfqHeaderData = null;
    let rfqLinesData = null;
    let rfqSummary = null;
    let rateCardComparisonLines = null;

    if (rfq_id) {
      rfqHeaderData = await commonApiCalls.getRFQHeaderByID(rfq_id);
      rfqSummary = await commonApiCalls.getAllRfqVendors(rfq_id);
      rfqLinesData = await commonApiCalls.getRFQLinesComparisons(rfq_id); 
      if(rfqHeaderData?.rfq_type===1){
        rateCardComparisonLines = await commonApiCalls.getRFQLinesComparisons(rfq_id,true);
      }
    }

    if (rfqHeaderData) {
      setRfqHeader(rfqHeaderData);
    }
    if(rfqSummary){ 
      setVendorsSummary(
        rfqSummary.map((vendor) => {
          return {
            vendor_name: vendor.vendor_name,
            vendor_status: vendor.vendor_replystatus,
            vendor_id: vendor.vendorreply_id,
            rank:vendor.rank,
            comment_no_participation: vendor.comment_no_participation
          };
        })
      );
    }
    if (rfqSummary && rfqLinesData) { 
      const vendorNameMap = rfqSummary.reduce((map, vendor) => {
        map[vendor.vendor_id] = vendor.vendor_name;
        return map;
      }, {});
     
      const updatedRFQLinesData = rfqLinesData.map(line => ({
        ...line,
        vendor_name: vendorNameMap[line.vendor_id],
      }));

      const updatedRateCardComparisonData = rateCardComparisonLines?.map(line => ({
        ...line,
        vendor_name: vendorNameMap[line.vendor_id],
      }));
      
      setRFQLinesComparison(updatedRFQLinesData);
      setRateCardComparison(updatedRateCardComparisonData);
    }
  };

  const getStatusNamebyId = (vendor_status) => {
    let status = vendorStatus?.find((item)=>item.id === vendor_status) 
    return status?.name?.replace(/_/g, " ")
  };

  const getScoreSummary = async () => {
    try {
      let api = Api;
      api.setUserToken();
      const response = await api
        .axios()
        .get(
          Settings.apiPurchaseRequisitionUrl +
            `/score_summary/${rfq_id}?evaluation_type=1`
        );

      if (response.data?.status === "success") {
        setScoreSummary(
          response?.data?.data.map((vendor) => {
            return {
              vendor_id: vendor.rfq_vendorreply_id,
              ranking: vendor.ranking,
              score: vendor.final_avg_score,
            };
          })
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getVendorsData = () => {
    const combinedData = [];
    vendorsSummary?.forEach((vendor) => {
      const scoreData = scoreSummary?.find(
        (score) => score.vendor_id === vendor.vendor_id
      );

      if (scoreData) {
        const combinedObject = {
          vendor_id: vendor.vendor_id, 
          vendor_name: vendor.vendor_name,
          vendor_status: vendor.vendor_status,
          score: scoreData.score,
          ranking: scoreData.ranking,
        };

        combinedData.push(combinedObject);
      }
      combinedData.sort((a, b) => b.score - a.score);
      setVendorsData(combinedData);
    });
  };

  const handleScroll = (e) => {
    const scrollTop = scrollDivRef?.current?.scrollTop;  
  
    if (scrollTop > 0) {
      setScrollDitection('up')
    } else {
      setScrollDitection('down')
    }
  }; 

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, scrollDitection]);

  useEffect(() => {
    getRfqHeader();
    getScoreSummary();
  }, []);

  useEffect(() => {
    getRfqStatus();
    getVendorStatus();
    getTags();
  }, [rfqHeader]);

  useEffect(() => {
    getVendorsData();
  }, [scoreSummary, vendorsSummary]);

  return !vendorsData ? (
    <SimpleLoading />
  ) : (
    <div className="container-fluid scrollBarBlinkControl">
      <div ref={wrapperDiv}> 
        <NavigationHeder
          title={Helper.getLabel(
            language,
            "vendor_response_comparsion",
            "Vendor response comparsion"
          )}
          hideMoreBtn={true} 
        />
        {addComponentTop()}
      </div>

      {rfqHeader && ( 
        <div ref={scrollDivRef} className='common_scrollbar_styles' onScroll={handleScroll} style={{ height: `${height}px`, overflowY: 'auto' }}>
          <div className="rfq_vendor_reply_grid" style={{gridTemplateColumns:rfqHeader?.rfq_type===2 && "repeat(2,1fr)"}}>
            <div className="rfq_vendor_reply_grid__item_1">
              <h3>Vendors Summary</h3>

              <table className="rfq__table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {vendorsSummary?.map((vendor) => {
                    return (
                      <tr
                        key={vendor?.RFQvendor_id}
                        className='rfq__table-row'
                      >
                        <td>{vendor?.vendor_name}</td>
                        <td>
                          <div style={{display: 'flex',height:'40px',alignItems:'center'}}>
                            <div
                              style={{color: getStatusColor(vendor?.vendor_status), backgroundColor: getBGStatusColor(vendor?.vendor_status), borderRadius: "80px", width: "fit-content",
                              padding: "8px 15px", height: "auto", display: "inline-block", textAlign: "center",
                              fontWeight: 600, fontSize: "14px",lineHeight: "18px", ...(vendor?.vendor_status === 11 && { textDecoration: "underline", cursor: "pointer", textDecorationColor:'#2D74E0' })}}
                              onClick={() => {
                                if (vendor?.vendor_status === 11) {
                                  setVendorNotInterestedObj({
                                    field_comment: vendor?.comment_no_participation,
                                    openPopup: true
                                  })
                                }
                              }}
                            >
                              {getStatusNamebyId(vendor?.vendor_status)}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            <div className="rfq_vendor_reply_grid__item_2">
              <h3>Top vendors by Rank</h3>
              <TopVendorsByRankBarGraph vendorsData={vendorsSummary} />
            </div>
            {rfqHeader?.rfq_type===2?<></>:<div className="rfq_vendor_reply_grid__item_3">
              <h3>Top vendors by Technical Score</h3>
              <TopVendorsByTechnicalScoreBarGraph vendorsData={vendorsData} />
            </div>}
          </div>

          {rfqHeader?.rfq_type===2?<></>:<div className="rfq_vendor_reply_bottom_grid">
            <div className="rfq_vendor_reply_bottom_grid__item_1">
              <h3 style={{ marginBottom: "30px" }}>Vendor response</h3>

              <div className="item_holder">
                {vendorsData?.map((vendor) => (
                  <div key={vendor?.RFQvendor_id} className="item_flex">
                    <div>
                      <h3>Vendor Name</h3>
                      <p>{vendor.vendor_name}</p>
                    </div>

                    <div>
                      <h4>{parseFloat(vendor.score).toFixed(2)}</h4>
                      <span>Total Score</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="rfq_vendor_reply_bottom_grid__item_2">
              <h3 style={{ marginBottom: "30px" }}>Vendor response report</h3>
              <VendorResponseReportLineChart vendorsData={vendorsData} />
            </div>
          </div>}

          <Collapse
            title={Helper.getLabel(language, "line_summary", "Line Summary")}
            open={true} 
            className="mb-5 mt-5 rfq_vendor_reply_graph"
          >
            <AgGrid
              id="rfq_vendor_reply_graph"
              header={Columns_Headings}
              data={rfqLinesComparison}
              hideSave={true}
              hideDelete={true}
              addBtnHide={true}
              settings={gridOptions}
            />
          </Collapse>

          {rfqHeader?.rfq_type===1 &&
           <Collapse
           title={Helper.getLabel(language, "rate_card_comparison", "Rate Card Comparison")}
           open={true} 
           className="mb-5 mt-5 rfq_vendor_reply_graph"
         >
           <AgGrid
             id="rfq_vendor_reply_graph_rate_card_2"
             header={Columns_Headings}
             data={rateCardComparison}
             hideSave={true}
             hideDelete={true}
             addBtnHide={true}
             settings={gridOptions}
           />
         </Collapse>}
        </div> 
      )}

      { vendorNotInterestedObj?.openPopup === true && <VendorCommentsPopup setStateObj={setVendorNotInterestedObj} stateObj={vendorNotInterestedObj} /> }
    </div>
  );
};

export default RFQVendorReplyGraph;