import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Popup from "../../../Popup/Popup";
import SimpleLoading from "../../../Loading/SimpleLoading";
import Helper from "../../../../inc/Helper";
import LineViewComponent from "../../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Api from "../../../../inc/Api";
import Settings from "../../../../inc/Settings";
import AwardingLinesGrid from "../AwardingLinesGrid";
import TotalWidget from "../../../Widgets/TotalWidget/TotalWidget";
import RateCardLineDetails from "../../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RateCardLineDetails";
import { commonThousandSeparator } from "../../../../inc/Validation";

const TechnicalSelectedLinesModal = ({
  rfqHeader,
  language,
  hideDeleteBtn=false,
  setShowSelectedLinesModal,
  vendor,
  id,
  type,
  user
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vendorAwardingLines,setVendorAwardingLines]=useState([])
  const [rateCardPopup, setRateCardPopup] = useState(false)
  const [lineId, setLineId] = useState(0)

  const awardingGridObj = useRef()
  const awardingTotalRecords = useRef(0)
  
  const getAwardedLines = () => {
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(
        Settings.apiPurchaseRequisitionUrl +
          `/get_awarded_lines/${rfqHeader?.RFQ_id}?rfq_vendorreply_id=${id}`
      )
      .then(function (res) {
        if (res.data.status === "success") {
          setVendorAwardingLines(res.data.data);
        }
      })
      .catch((res) => {
        setIsLoading(false)
      });
  };

  useEffect(()=>{
    getAwardedLines()
  },[])

  const taxTransactionViewCell = (params) => {
    if(params.data.line_id){
        return <div className="rs_inactive_label">View tax</div>;
    }
    return <></>;
  }

  let Columns_Header = [
    {
      field:"line_number",
      headerName:Helper.getLabel(language,'line_number',"Line number"), 
      editable:false,
      minWidth:160,
      sortable:false,
    },
    {
      field:"item_code",
      headerName:Helper.getLabel(language,'item_code',"Item code"), 
      editable:false,
      minWidth:220,
    },
    {
      field:"item_name",
      headerName:Helper.getLabel(language,'item_name',"Item name"), 
      editable:false,
      minWidth:220,
    },
    {
      field:"category_name",
      headerName:Helper.getLabel(language,'category',"Category"), 
      editable:false,
      minWidth:220,
    },
    {
      field:"comments",
      headerName:Helper.getLabel(language,'comments',"Comments"), 
      editable:false,
      minWidth:260,
    },
    {
      field:"itemgroup_name",
      headerName:Helper.getLabel(language,'item_group',"Item group"), 
      editable:false,
      minWidth:220,
    },
    {
      field:"ordered_qty",
      headerName:Helper.getLabel(language,'quantity',"Quantity"), 
      editable:false,
      minWidth:180,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field:"required_qty",
      headerName:Helper.getLabel(language,'purposed_quantity',"Purposed quantity"), 
      editable:false,
      minWidth:180,
      valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
    },
    {
      field:"unit_name",
      headerName:Helper.getLabel(language,'Unit Name',"Unit name"), 
      editable:false,
      minWidth:180,
    }
  ]

  let commercialValues = [{
    field:"unit_price",
    headerName:Helper.getLabel(language,'unit_price',"Unit price"), 
    editable:false,
    minWidth:180,
    valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
  },
  {
    field:"discount_amount",
    headerName:Helper.getLabel(language,'discount_amount',"Discount amount"), 
    editable:false,
    minWidth:180,
    valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
  },
  {
    field:"discount_percentage",
    headerName:Helper.getLabel(language,'discount_percentage',"Discount percentage"), 
    editable:false,
    minWidth:200,
    valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
  },
  {
    field:"line_amount",
    headerName:Helper.getLabel(language,'line_amount',"Line amount"), 
    editable:false,
    minWidth:180,
    valueFormatter:(params)=>{return params.value ? commonThousandSeparator(params.value) : "-"}
  },
  {
    field:'view_tax_tranasction', 
    minWidth: 150, 
    headerName:Helper.getLabel(language,'tax',"Tax"), 
    editable: false,
    cellRenderer:taxTransactionViewCell,
    sortable:false
  }
]

  if(user?.is_buyer || (rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer)){
   Columns_Header = Columns_Header.concat(commercialValues)
  } 

  const rateCardCell = (params) => {
    return <div className="grid_simple_link position-relative">Rate cards {params?.data?.rate_card_linked && <div className="orange-attachment-dot" style={{top:"18px", right:"48%"}}></div>}</div>
  }

  if(rfqHeader?.rfq_type===1){
    Columns_Header?.push({field:'rate_card',headerName:Helper.getLabel(language,'rate_card','Rate card'),editable:false,cellRenderer:rateCardCell, minWidth:180})
  }

  const hideTabs = () => {
    if(rfqHeader?.target_document === 1 && user?.is_buyer){
      return false
    }else if(rfqHeader?.target_document === 1 && !user?.is_buyer && rfqHeader?.allow_view_commericals_in_technical_evaluation){
      return false
    }else{
      return true
    }
  }

  const onCellClicked = (event) => {
    if(event.colDef.field === "rate_card"){
     setRateCardPopup(true)
     setLineId(event.data.line_id)
   }
  }

  const getLineView = () => {
    return (
      <LineViewComponent
        defaultValues={{ requester_id: rfqHeader?.requester_id }}
        source_id={Settings?.source_id?.RfqVendorReply}
        line_source={Settings?.source_id?.RfqVendorReply}
        isEditable={false}
        header_id={vendor?.reply_id}
        header={Columns_Header}
        purchase_status={null}
        document_status={rfqHeader?.rfq_status}
        delivery_date={null}
        onbehalf_request={false}
        vendor_id={null}
        vendorsite_id={null}
        hideDeleteBtn={hideDeleteBtn}
        requester_id={rfqHeader?.requester_id}
        orderer_id={rfqHeader?.order_id}
        setOrderStatusProps={null}
        warehouse_id={rfqHeader?.warehouse_id}
        taxgroup_item={null}
        taxgroup_id={null}
        entity_id={rfqHeader?.entity_id}
        site_id={rfqHeader?.site_id}
        hideGeneral={true}
        hideDistribution={false}
        hideBillingRule={hideTabs()}
        onCellClicked={onCellClicked}
        hideAdditionalInformationTab={true}
        hideExtraColumn={true}
        hideSetup={true}
      />
    );
  };
  return (
    <>
    <Popup
      autoOpen={true}
      width="1200px"
      onClose={() => {
        $("html").removeClass("rs_popup_open");
        setShowSelectedLinesModal(false);
      }}
    >
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <>
          <div
           className="d-flex justify-content-between mb-3"
          >
            <h5 className="create_rfq_modal__title">
              {Helper.getLabel(
                language,
                "vendor_lines",
                "Vendor Lines"
              )}
            </h5>
            <div className="technical-total">
              <TotalWidget source_id={Settings.source_id.RfqVendorReply}
            integrator={type==="awarding"?id:vendor?.reply_id} isDisable={type==="awarding"?false:!rfqHeader?.allow_view_commericals_in_technical_evaluation && !user?.is_buyer}/>
            </div>
          </div>
          <div className="line_scoring_module">
            {type==="awarding"?<AwardingLinesGrid language={language} rfqHeader={rfqHeader} awardingGridObj={awardingGridObj} awardingTotalRecords={awardingTotalRecords} apiUrl={ Settings.apiPurchaseRequisitionUrl + `/get_awarded_lines/${rfqHeader?.RFQ_id}`} additionalParams={`&rfq_vendorreply_id=${id}`} />:getLineView()}
          </div>
        </>
      )}
    </Popup>
    {
      rateCardPopup && <RateCardLineDetails setShowPopup={setRateCardPopup} lineId={lineId} rfqData={rfqHeader} isEditable={false}/>
    }
    </>
  );
};

export default TechnicalSelectedLinesModal;
