import React, { Fragment, useState } from 'react'
import Tabs from '../../../Widgets/Tabs/Tabs'
import MasterSidebar from '../../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../../Widgets/NoteAttachmentHistory'
import InvoiceLineGrid from '../InvoiceLineGrid'
import Helper from '../../../../inc/Helper'
import Settings from '../../../../inc/Settings'
import SimpleLoading from '../../../Loading/SimpleLoading'
import WorkFlowOffCanvas from '../../../Header/WorkFlowOffCanvas'
import { getStatusNameById } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import AdditionalInformation from '../../RFQ Vendor Reply/RFQBiddingDetails/AdditionalInformation'
import Collapse from '../../../inc/Collapse'
import { Link } from 'react-router-dom'

//for render tabs data
const InvoiceData = (props) => {
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

  //data for grid and tabs 
  const defaultNav = [
    {
      title: Helper.getLabel(props.language, 'hide_tab', 'hide_tab'),
      isActive: true,
    },
  ]

  const innerTabs = [
    {
      title: Helper.getLabel(
        props.language,
        'general_information',
        'General Information'
      ),
      isActive: true,
    },
    {
      title: Helper.getLabel(
        props.language,
        'additional_information',
        'Additional Information'
      ),
    },
  ]

  let generalData = [
    {
      id: 1,
      title: 'Source document number',
      subtitle: props.invoiceDetails?.document_number,
    },
    {
      id: 2,
      title: 'Status',
      subtitle:getStatusNameById(props?.document_status,props?.documentStatuses)
    },
    {
      id: 3,
      title: 'Vendor code',
      subtitle: props.invoiceDetails?.vendor_code,
    },
    {
      id: 4,
      title: 'Name',
      subtitle: props.invoiceDetails?.vendor_name,
    },
    {
      id: 5,
      title: 'Site',
      subtitle: props.invoiceDetails?.vendorsite_name,
    },
    {
      id: 6,
      title: 'Product receipt',
      subtitle: typeof props?.invoiceDetails?.receiving_reference_number === 'object' 
        ? Object.entries(props?.invoiceDetails?.receiving_reference_number).map(([key, value]) => (
            <React.Fragment key={key}>
              <Link to={props?.invoiceDetails?.purchaseorder_type===1?`/work-confirmation-details?shipment_id=${key}`:`/goods-receiving-details?shipment_id=${key}`}>{value}</Link>
               {Object.keys(props?.invoiceDetails?.receiving_reference_number).indexOf(key) < Object.keys(props?.invoiceDetails?.receiving_reference_number).length - 1 ? ' , ' : ''}
            </React.Fragment>
          ))
        : "-"
    },
    {
      id: 7,
      title: 'Invoice no.',
      subtitle: props.invoiceDetails?.reference_number,
    },
    {
      id: 8,
      title: 'Description',
      subtitle: props.invoiceDetails?.description,
    },
    {
      id: 9,
      title: 'Accounting date',
      subtitle: Helper.formateDate(props.invoiceDetails?.accounting_date),
    },
    {
      id: 10,
      title: 'Document date',
      subtitle: Helper.formateDate(props.invoiceDetails?.document_date),
    },
  ]

  //for add right sidebar
  const addComponentRight = () => {
    return (
      <div className='parent_sidebar_cn'>
        <MasterSidebar>
          <div style={{ background: '#fff' }}>
            <NoteAttachmentHistory  
              openCanvas={setShowCanvas}
              openCurrentAssignees={setShowCurrentAssignees}
              height={props?.height}
              scrollDitection={props?.scrollDitection}
              hideHistory={props.userType===1?true: !props.workflowActionHistory?.history?.length}
              historyArray={props.workflowActionHistory}
              notesource_integrator={props.invoiceDetails?.invoice_id}
              attachmentsource_integrator={props.invoiceDetails?.invoice_id}
              source_id={Settings.source_id.invoice}
              hideUploaderCondition={(props.userType===1 && props.invoiceDetails?.document_status===1)}
              condition={(props.userType===1 && props.invoiceDetails?.document_status!==0) }
            />
          </div>
        </MasterSidebar>

        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={Settings.source_id.invoice}
            source_item_id={props.invoiceDetails?.invoice_id}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={Settings.source_id.invoice}
            source_item_id={props.invoiceDetails?.invoice_id}
          />
        }    
      </div> 
    )
  }

  if(props.userType===1){
    generalData = generalData.filter((item)=>item.title !== "Accounting date")
  }

  return (
    <Tabs
      navs={defaultNav}
      showAll={true}
      scrollOffset='300'
      disableSticky={true}
      addComponentRight={addComponentRight}
    >
      <div className='common_scrollbar_styles' style={{ height: `${props?.height}px`, overflowX: 'hidden', overflowY: 'auto'}}>
        <div className='bidding_tab mt-3'>
          <div className='mt-1 header-tab-rfq'>
            <Tabs
              navs={innerTabs}
              disableSticky={true}
              scrollOffset='300'
              showAll={false}
            >
              <div className="mt-3 ps-3 pe-3">
                <div className="external-general-information">
                  <div className="purchase-order-inner-details general-details-responsive">
                    {generalData.map((item) => (
                      <div
                        className="row justify-content-between"
                        key={item.id}
                      >
                        <div className="col-sm-6">
                          <span className="purchase-order-external-title">
                            {item.title} :
                          </span>
                        </div>
                        <div className="col-sm-6">
                          <span className={item.className}>{item.subtitle}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='ps-3 pe-3'>
              <Collapse
                  className="mt-3"
                  title={Helper.getLabel(
                    props.language,
                    "additional_information",
                    "Additional Information"
                  )}
                  open={true}
                >
                <AdditionalInformation language={props.language} data={props?.invoiceDetails} isNeedNotes={false} title={Helper.getLabel(
                    props.language,
                    "description",
                    "Description"
                  )} />
                </Collapse>
              </div>  
            </Tabs>
          </div>
        </div>

        <div className='mt-3 external-read-only-grid line-tabs'>
          {props.isLoading ? (
            <SimpleLoading />
          ) : (
            <InvoiceLineGrid
              lineData={props.linesData}
              fetchAllLines={props.getAllLines}
              lineIdOptions={props.lineIdOptions}
              gridType='summary'
              userType={props.userType}
              invoiceHeader={props.invoiceDetails}
              currentStep={4}
            />
          )}
        </div>
      </div>
    </Tabs>
  )
}

export default InvoiceData
