import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'; 
import NavigationHeder from '../../../Navigations/NavigationHeder';  
import { connect } from 'react-redux'; 
import Helper from '../../../../inc/Helper'; 
import ButtonNew from '../../../inc/ButtonNew';
import Collapse from '../../../inc/Collapse'; 
import CommonCompliancePopup from './CommonCompliancePopup';
import { useNavigate } from 'react-router-dom'; 
import MasterComonent from '../../../Backend/MasterComonent';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import GridResponsiveDropdownMenu from '../../../grid/GridResponsiveDropdownMenu';
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation'; 
import AgGridNew from '../../../grid/ag/ag-grid-new';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';
import { generateBaseUrl, getLabelFromDropdown, getNewValue, getOptions, gridLinkValueFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import ComplianceTemplateMasterModal from './ComplianceTemplateMasterModal';
import Gui_id_list from '../../../../inc/Gui_id_list';
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles';
import Alert from '../../../inc/Alert';
import { transformObjectComplianceTemplates, transformObjectTransactionalCompliance } from '../../../../inc/TransformerFunctions';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';

/*

Master & Transactional Level: Compliance Templates Module,

Validations: Category is reqired
*/

// default Object initialization -- 
let forceRenderGrid = 1;
let defaultTemplateId = null;
let transactionalLevelGridAPI  = null;  
let masterLevelComplainceTemplateGridAPI = null;
let AddBtn = null;
let transaction = null;

const ComplianceTemplates = ({language, security, rfq_id, disableComponent=false, TransactionalLevel=false}) => {
    const navigate = useNavigate();
 
    const [complianceHeaders, setComplianceHeaders] = useState([]); 

    const totalRecordsMasterCompliance = useRef();
    const [exportLoadingMasterCompliance, setExportLoadingMasterCompliance] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false); 
    const [exportLoadingTransactionalCompliance, setExportLoadingTransactionalCompliance] = useState(false) 
    const totalRecords = useRef(0)

    const [addTemplatesDetails, setAddTemplatesDetails] = useState({
        openPopup: false,
        addNewTemplate: false,
        importTemplate: false,
        importExcelTemplate: false,
        newCategory: false,
        newCompliance: false,
        details: {}
    });
 
    const [disableAddBtn, setDisableAddBtn] = useState(false); 

    // reduction api flags, 
    let complianceHeaderCalled = false; 

    useEffect(() => { 
        getComplianceHeaders() 
    }, [])
 
    const getComplianceHeaders = () => {
        if(complianceHeaderCalled) return;
 
        let api = Api
        complianceHeaderCalled = true;
        api.setUserToken()
        api.axios().get(Settings.apiPurchaseRequisitionUrl+'/complianceresponseheader',{}).then(function(res){
            if(res.data.status==='success'){
                let Dropdown_data = []
                res.data.data.forEach((item) => {
                    Dropdown_data.push({
                        value: item.complianceheader_id,
                        label: item.compliance_name 
                    })
                });
                forceRenderGrid++
                setComplianceHeaders(Dropdown_data); 
            };
        }).catch((res) => { 
            getPopupMessageBasedOnStatus(res) 
        })
    }
  
    // 2D Grid Settings & Header Cols With Functions -
 
    const MasterLevelComplianceGridHeaderCols = [
        {field:'name', cellRenderer: (params) => gridLinkValueFormat(params), headerName:Helper.getLabel(language,'template_name',"Template name"), editable:false},
        {field:'complianceheader_id', headerName:Helper.getLabel(language,'complianceheader_id',"Compliance header"), editable:false,
            cellEditor: SimpleDropdown,
            isAllowZero:true,
            cellEditorParams: {
                values: complianceHeaders
            },
            cellDataType:false,
            valueFormatter: (params)=> getLabelFromDropdown(params),
            filter:'agSetColumnFilter',
            filterParams: {
                values: (params) => getOptions(params, complianceHeaders),
                keyCreator: (params) => optionsKeyCreator(params, 'value'),
                valueFormatter: params => params.value.label,
            }, 
        },
    ];

    const TransactionalLevelParentGridHeaderCols = [
        {field:'category', editable: disableComponent ? false : true, headerName:Helper.getLabel(language,'category',"Category*") },
        {field:'subcategory', editable: disableComponent ? false : true, headerName:Helper.getLabel(language,'subcategory',"Sub category")},
        {field:'compliance_description', editable: disableComponent ? false : true, headerName:Helper.getLabel(language,'description',"Description") },
    ];

    // ---

    // Grid related functions/objects,
   
    const onMasterLevelTemplateCellClicked = (event) => {
        if(event.colDef.field === 'name') { 
            navigate(`/compliance-templates/edit/${event?.data?.template_id}`)
        }
    };

    const onImportExcelClick = () => {  
        if(disableComponent) return;

        setAddTemplatesDetails((prevState) => {
            return {
                ...prevState,
                openPopup: true,
                importExcelTemplate: true
            }
        })
    }

    const exportTransactionalComplianceData = async () => {
        setExportLoadingTransactionalCompliance(true)
    
        const apiUrl = rfq_id ? Settings.apiPurchaseRequisitionUrl+`/get_rfq_compliance_template_lines/${rfq_id}` : null;
        const params = '';
    
        let pagination = false
        let defaultAdvanceFilterObj = false
        let pageSize = totalRecords.current
        
        const baseUrl = generateBaseUrl({ gridObj: transactionalLevelGridAPI, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
        await exportToXlsx({ url: baseUrl, columns: TransactionalLevelParentGridHeaderCols, fileName: 'compliance_details' })
        
        setExportLoadingTransactionalCompliance(false)
    }
 
    const onAddTemplateCreationClick = () => { 
        if(disableComponent) return;

        setAddTemplatesDetails((prevState) => {
            return {
                ...prevState,
                openPopup: true, 
                newCompliance: true
            }
        })
    }
  
    const onImportTemplateClick = () => {
        if(disableComponent) return;

        setAddTemplatesDetails((prevState) => {
            return {
                ...prevState,
                openPopup: true,
                importTemplate: true
            }
        })
    };
 
    const onDeleteComplianceTemplates = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();

        let delComplianceTemplate_ID = element?.data?.template_id;
        
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl+`/compliance_template/${delComplianceTemplate_ID}`)
            const rowNode = masterLevelComplainceTemplateGridAPI?.api?.getRowNode(delComplianceTemplate_ID)
            if (rowNode) {
                rowNode.setSelected(false)
            }

            setTimeout(() => {
                const transaction = { remove: [element.data] }
                masterLevelComplainceTemplateGridAPI?.api.applyServerSideTransaction(transaction)
            }, 200)

            masterLevelComplainceTemplateGridAPI?.api.refreshServerSide({ purge: true })
            masterLevelComplainceTemplateGridAPI?.api?.deselectAll();

            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const onDeleteComplianceTemplatesLinesTransactionalLevel = useCallback(async (element) => {
        let api = Api;
        api.setUserToken();
      
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseRequisitionUrl + `/compliance_template_line/${element?.data?.compliance_templateline_id}?for_transaction=true`)
            const rowNode = transactionalLevelGridAPI?.api?.getRowNode(element?.data?.compliance_templateline_id)
        
            if (rowNode) {
                rowNode.setSelected(false)
            }
        
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                transactionalLevelGridAPI?.api?.applyServerSideTransaction(transaction)
            }, 200) 
        
            transactionalLevelGridAPI?.api?.refreshServerSide({ purge: true });
            transactionalLevelGridAPI?.api?.deselectAll();
            
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
          getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const TemplatesActionButtons = () => { 
        const DropdownItems = [
            {
                id: 1,
                icon: '/images/icons/add-new.svg',
                name: 'Add compliance',
                onClick: onAddTemplateCreationClick,
                disableBeforeClick: disableComponent || !disableAddBtn
            },
            {
                id: 2,
                icon: '/images/icons/add-new.svg',
                name: 'Import template',
                onClick: onImportTemplateClick,
                disableBeforeClick: disableComponent
            },
            {
                id: 3,
                icon: '/images/icons/excel-logo.svg',
                name: 'Import Lines from Excel',
                onClick: onImportExcelClick,
                disableBeforeClick: disableComponent 
            }
        ];
        return (
            <> 
                <div className='templates_action_btns d-flex justify-content-center align-items-center'>
                    <GridResponsiveDropdownMenu dropdownItems={DropdownItems} />
                    <div className='common-grid-responsive-btns'>  
                        <button onClick={onAddTemplateCreationClick} disabled={disableComponent || !disableAddBtn} className={`rs_h_controller ${disableComponent || !disableAddBtn ? 'ctl-dissable' : ''}`}> 
                            <img src='/images/icons/add-new.svg' />
                            <span>Add compliance</span>
                        </button>
                        
                        <button onClick={onImportTemplateClick} disabled={disableComponent} className={`rs_h_controller ${disableComponent ? 'ctl-dissable' : ''}`}> 
                            <img src='/images/icons/add-new.svg' />
                            <span>Import template</span>
                        </button>
                        
                        <button onClick={onImportExcelClick} disabled={disableComponent} className={`rs_h_controller ${disableComponent ? 'ctl-dissable' : ''}`}> 
                            <img src='/images/icons/excel-logo.svg' />
                            <span>Import Lines from Excel</span>
                        </button> 
                    </div>
                </div>

                <div className="import_sample_template ms-auto px-2">
                    <button onClick={exportTransactionalComplianceData}>
                        <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                    </button>
                </div>
            </>
        )
    };

    const handleFetchData = (response) => { 
        totalRecords.current = response?.data?.total_records
        if(response?.data?.data?.length === 0) {
            setDisableAddBtn(true);
            defaultTemplateId = null
        } else {
            defaultTemplateId = response?.data?.data[0]?.template_id
            setDisableAddBtn(false);
        }
        return {
            rowData: response?.data?.data?.map((item) => ({
                ...item,
                gridId: `${Date.now()}_${getNewValue()}`,
                
            })),
            rowCount: response?.data?.data?.length
        }
    };

    const handleBtn = (value, transactionData) => {
        AddBtn = value
        transaction = transactionData
    };

    const onRowValueChanged = (params) => {
        if(!params?.data?.compliance_templateline_id){
            createTemplateLineForTransactional(params)
        } else {
            updateTemplateLineForTransactional(params)
        }
    };

    const createTemplateLineForTransactional = async (event) => { 
        try { 
            let payload = {
                category: event?.data?.category,
                subcategory: event?.data?.subcategory,
                compliance_description: event?.data?.compliance_description
            };

            let api = Api;
            api.setUserToken();
            
            const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+`/compliance_template_lines/${defaultTemplateId}?for_transaction=true`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    transactionalLevelGridAPI?.api?.refreshServerSide({ purge: true });  
                    transactionalLevelGridAPI?.api?.deselectAll();
                }, 100);
        
                setTimeout(() => {
                    if (AddBtn) {
                        transactionalLevelGridAPI?.api?.applyServerSideTransaction(transaction);
                    }
                }, 1000);
                
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        }
    }

    const updateTemplateLineForTransactional = async (event) => {
        try { 
            let payload = {
                category: event?.data?.category,
                subcategory: event?.data?.subcategory,
                compliance_description: event?.data?.compliance_description
            }

            let api = Api;
            api.setUserToken();
            const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/compliance_template_line/${event?.data?.compliance_templateline_id}?for_transaction=true`, payload);
            if (res.data.status === "success") {
                setTimeout(() => {
                    transactionalLevelGridAPI?.api?.refreshServerSide({ purge: true });
                    transactionalLevelGridAPI?.api?.deselectAll();
                }, 100);
        
                Helper.alert(res?.data?.message);
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
        } 
    }

    const reloadComplianceTemplates = () => {
        transactionalLevelGridAPI?.api?.refreshServerSide({ purge: true });
        transactionalLevelGridAPI?.api?.deselectAll();
    }

    // Module Mapping Based on Data Type,
    const MasterLevelCompliances = () => {
        const getTransformedObjForExport = (data) => transformObjectComplianceTemplates(data, complianceHeaders);

        const exportMasterComplianceData = async () => {
            setExportLoadingMasterCompliance(true);

            const apiUrl = `${Settings.apiPurchaseRequisitionUrl}/compliance_template`;
            const params = '';

            let pagination = false;
            let defaultAdvanceFilterObj = false;
            let gridObj = masterLevelComplainceTemplateGridAPI;
            let pageSize = totalRecordsMasterCompliance.current;

            const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
            await exportToXlsx({ url: baseUrl, columns: (MasterLevelComplianceGridHeaderCols), fileName: ("compliance_templates_details"), additionalFunction: getTransformedObjForExport });
            
            setExportLoadingMasterCompliance(false);
        }
        return (
            <MasterComonent> 
                <div className='container-fluid'> 
                    <NavigationHeder hideMoreBtn={true} backUrl='/master-data' title={Helper.getLabel(language,'list_of_compliance_templates', "List Of Compliance Templates")}>  
                        <ButtonNew
                            isDisable={
                                !security.canCreate(
                                    Gui_id_list.masterData.compliance_templates.compliance_templates_templates_main_create_button
                                )
                            }
                            onClick={() => setShowCreateModal(true)} 
                            title='New' 
                        />
                    </NavigationHeder>

                    {exportLoadingMasterCompliance ? <OverlayLoader /> : null}

                    <Collapse className='grid_save_btn_hide' open={true} title={`Templates`}> 
                        <div className='grid_compliance_templates'>
                            <AgGridNew
                                apiUrl={`${Settings.apiPurchaseRequisitionUrl}/compliance_template`} 
                                uniqueField={'template_id'}
                                pagination={false}
                                hideDeleteBtn={!security.canDelete(Gui_id_list.masterData.compliance_templates.compliance_templates_templates_main_delete_button)}
                                columnDefs={MasterLevelComplianceGridHeaderCols}
                                hideAddBtn={true}
                                height={500}
                                onCellClicked={onMasterLevelTemplateCellClicked}
                                handleDeleteSelectedRows={onDeleteComplianceTemplates}
                                onGridReady={(params) => masterLevelComplainceTemplateGridAPI = params}
                                gridId={"master_level_compliance_templates"}
                                afterDeleteBtns={
                                    <div className="import_sample_template px-2">
                                        <button onClick={exportMasterComplianceData}>
                                            <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                                        </button>
                                    </div>
                                }
                                btnsWrapperStyle={`w-100 justify-content-between px-2`}
                                fetchData={(response) => handleFetchDataForListing(response, totalRecordsMasterCompliance)}
                            />
                        </div>
                    </Collapse>
                </div>
                {showCreateModal && <ComplianceTemplateMasterModal setShowCreateModal={setShowCreateModal}  />}
            </MasterComonent>
        )
    };

    const TransactionalLevelCompliances = () => {
        return (
            <div className='container-fluid p-0 m-0'>
                <Collapse open={true} title={`Compliance Sheet`}>
                    <div className='grid_compliance_sheet grid_save_btn_hide'> 
                        <AgGridNew
                            apiUrl={rfq_id?Settings.apiPurchaseRequisitionUrl+`/get_rfq_compliance_template_lines/${rfq_id}`:null}  
                            handleDeleteSelectedRows={onDeleteComplianceTemplatesLinesTransactionalLevel} 
                            afterDeleteBtns={<TemplatesActionButtons />}  
                            btnsWrapperStyle={`w-100 px-2`}
                            fetchData={handleFetchData}
                            isDeleteBtnDisable={disableComponent}
                            isAddBtnDisable={disableAddBtn || disableComponent} 
                            handleAddButton={handleBtn}
                            columnDefs={TransactionalLevelParentGridHeaderCols}  
                            onRowValueChanged={onRowValueChanged}
                            onGridReady={(params) => transactionalLevelGridAPI = params}
                            height={500}
                            gridId={'transactional_level_compliance_templates'}
                            uniqueField={'compliance_templateline_id'}
                            pagination={false}
                        />
                    </div>
                </Collapse> 
            </div>
        )
    }

    if(!security.canView(Gui_id_list.masterData.compliance_templates.compliance_templates_templates_main) && !TransactionalLevel){
        return (
            <Fragment>
                <MasterComonent> 
                    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
                </MasterComonent>
            </Fragment>
        )
    }

    return (
        <div className='compliance_templates'>
            { exportLoadingTransactionalCompliance ? <OverlayLoader isPopupInTab={true} /> : null }
            { TransactionalLevel ? TransactionalLevelCompliances() : MasterLevelCompliances() }

            {/* Popup View Components */}
            { addTemplatesDetails.openPopup && <CommonCompliancePopup setDisableAddBtn={setDisableAddBtn} defaultTemplateId={defaultTemplateId} reloadParentGrid={reloadComplianceTemplates} rfq_id={rfq_id} newCategory={addTemplatesDetails.newCategory} newCompliance={addTemplatesDetails.newCompliance} importTemplate={addTemplatesDetails.importTemplate} importExcelTemplate={addTemplatesDetails.importExcelTemplate} addNewTemplate={addTemplatesDetails.addNewTemplate} setClosePopup={setAddTemplatesDetails} /> }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        appOptions:state.options,
        auth:state.auth,
        language:state.language
    }
}

const SecurityOptions = {
    gui_id:Gui_id_list.masterData.compliance_templates.compliance_templates_templates_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(ComplianceTemplates, SecurityOptions))));  