/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import NavigationHeder from "../../Navigations/NavigationHeder";
import Helper from "../../../inc/Helper";
import SimpleLoading from "../../Loading/SimpleLoading";
import RFQCommonApiCalls from "../../RequestForQuotation/RFQCommonApiCalls"; 
import Settings from "../../../inc/Settings";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import Gui_id_list from "../../../inc/Gui_id_list";
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Alert from "../../inc/Alert";
import AgGridNew from "../../grid/ag/ag-grid-new";
import { customDateComparator, exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from "../../../inc/Validation";
import { gridLinkValueFormat , getStatusNameById, getOptions, optionsKeyCreator, gridRFQExpireFormat, generateBaseUrl} from "../../grid/ag/commonFunctions/GridCommonFunctions";
import ButtonNew from "../../inc/ButtonNew";
import CreateRFQModal from "../../RequestForQuotation/RFQDetailForm/RFQHeaderList/CreateRFQModal";
import Api from "../../../inc/Api";
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader";

let gridObj= null

const RFI = (props) => {
  const [rfqStatusList, setRfqStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateRFIModal,setShowCreateRFIModal] = useState(false)

  const [employee, setEmployee] = useState([])
  const [entity, setEntity] = useState([])
  const [sites, setSites] = useState([])
  const [warehouse, setWarehouse] = useState([])
  const [quotationType, setQuotationType] = useState([])
  const [exportLoading, setExportLoading] = useState(false)

  const totalRecords = useRef(0)

  const urlParams = new URLSearchParams(useLocation().search);
  const current_user = urlParams?.get('user_id');
 
  const commonApiCalls = new RFQCommonApiCalls();
  const api = Api

  const handleGridReady = (params) => {
    gridObj = params
  }

  const navigate = useNavigate();
  const cellHandler = (event, navigate) => {
    if (event?.value && event?.colDef?.field === "document_number") {
      navigate(
        "/request-for-information/rfidetailform?rfq_view_id=" +
          event?.data?.RFQ_id 
      );
    }
  };

  const getAsyncData = async () => {
    setIsLoading(true)
    try {
      const rfqStatusData = await commonApiCalls.getAllRfqStatus();
      if (rfqStatusData) {
        setRfqStatusList(rfqStatusData);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(true)
      getPopupMessageBasedOnStatus(error);
    }
  };

  const getAllEmployee = () => {
    api
      .axios()
      .get(`${Settings.apiOrgUrl}/employee`)
      .then((res) => {
        if (res.data.status === 'success') {
          setEmployee(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getAllEntity = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/get_entities')
      .then((res) => {
        if (res.data.status === 'success') {
          setEntity(res.data?.data)
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
  }

  const getQuotationType = () => {
    api
      .axios()
      .get(Settings.loginUrl + '/enum/RQ_type')
      .then((res) => {
        setQuotationType(res.data.data)
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, 'error')
      })
   }

  const getSites = async () => {
    const res = await commonApiCalls.getAllSites()
    setSites(res)
  }

  const getWarehouse = async () => {
    const res = await commonApiCalls.getAllWarehouses()
    setWarehouse(res)
  }


  const createNewRFI = () => {
    setShowCreateRFIModal(true)
  }

  const Columns_Headings = [
    {
      field: "document_number",
      headerName: Helper.getLabel(
        props.language,
        "document_number",
        "Document number"
      ),
      cellRenderer:(params) => gridLinkValueFormat(params),
      editable: false,
      minWidth: 200,
    },
    { 
      field: "purchase_requisition_number",
      headerName: Helper.getLabel(
        props.language,
        "purchase_requisition_number",
        "Purchase Requisition number"
      ),
      valueFormatter: (params) =>
        params.value || "-",
      editable: false,
      minWidth: 240,
    },
    {
      field: "target_document_number",
      headerName: Helper.getLabel(
        props.language,
        "target_document_number",
        "Target document number"
      ),
      valueFormatter: (params) =>
        params.value || "-",
      editable: false,
      minWidth: 240,
    },
    {
      field: "title",
      headerName: Helper.getLabel(props.language, "rfq_title", "RFI title"),
      editable: false,
      minWidth: 150,
    },
    {
      field: "createdby",
      headerName: Helper.getLabel(props.language, "createdby", "Created by"),
      editable: false,
      minWidth: 150,
    },
    {
      field:"onhold",
      headerName: Helper.getLabel(props.language, "on_hold", "On hold"),
      editable: false,
      minWidth: 150,
      cellRenderer:'agCheckboxCellRenderer',
      cellEditor:'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      filter: 'agSetColumnFilter',
      filterParams: { 
        values: [true, false], 
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
      valueGetter: (params) => {
        if(!params?.data?.onhold || !params?.data){
            return false
        }
        else {
            return params.data?.onhold
        }
      },
    },
    {
      field: 'rfq_status',
      headerName: Helper.getLabel(props.language, 'rfq_status', 'RFI status'),
      cellRenderer:(params)=>{return getStatusNameById(params.value,rfqStatusList)},
      editable: false,
      minWidth: 200,
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, rfqStatusList),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: params => params.value.name,
      }
    },
    {
      field: "created_datetime",
      headerName: Helper.getLabel(
        props.language,
        "created_date_time",
        "Created date time"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "publish_datetime",
      headerName: Helper.getLabel(
        props.language,
        "publish_date_time",
        "Publish date time"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(
        props.language,
        "delivery_date",
        "Delivery date"
      ),
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) ?? "-",
      editable: false,
      minWidth: 150,
    },
    {
      field: 'expiry_datetime',
      headerName: Helper.getLabel(
        props.language,
        'expiry_datetime',
        'Expiry date and time'
      ),
      filter:'agDateColumnFilter',
      comparator: customDateComparator,
      cellRenderer: (params) => gridRFQExpireFormat(params),
      minWidth: 220,
    },
  ];

  const exportData = async () => {
    setExportLoading(true)
    const apiUrl = Settings.apiPurchaseRequisitionUrl + `/rfq_header`
    const params = current_user ? `&rfq_type=1&current_user=${current_user}` : `&rfq_type=1`
    let pagination = true
    let defaultAdvanceFilterObj = false
    let pageSize = totalRecords.current
    
    const baseUrl = generateBaseUrl({ gridObj,apiUrl, params, pagination, defaultAdvanceFilterObj,pageSize })

    const convertDateAndTime = (data) => {
      return data.map((item) => {
        return {
          ...item,
          expiry_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.expiry_datetime),
          createddatetime: DatepickerFunctions.convertDateTimeFromDataBase(item.createddatetime),
          publish_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.publish_datetime),
          delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
          rfq_status: rfqStatusList?.find((status)=> status.id === item.rfq_status)?.name,
          onhold: item.onhold===true && "YES"
        }
      })
    }

    await exportToXlsx({ url: baseUrl, columns:Columns_Headings, fileName: 'rfi_details', additionalFunction: convertDateAndTime })
    
    setExportLoading(false)
  }

  useEffect(()=>{
    getAsyncData();
    getAllEmployee();
    getSites()
    getWarehouse()
    getAllEntity()
    getQuotationType()
  },[])

  let security = props?.security;
  
  if (props.auth?.user?.usertype===0 && !security.canView(Gui_id_list.request.request_for_informations.request_for_informations_main)) {
    return (
        <Alert 
          message="You do not have any access to the following screen, please contact your administrator!" 
          type="danger" 
        /> 
    );
  }

  return (
    <>
      {exportLoading ? <OverlayLoader /> : null}
      <div className="items_archive">
        <div className="container-fluid">
          <NavigationHeder backUrl="/request" hideMoreBtn={true} title={Helper.getLabel(props.language, 'request_for_information', `Request for Information - ${current_user ? 'Created by me' : 'All'}`)}>
            <ButtonNew isDisable={props.auth?.user?.usertype === 0 && !security.canCreate(Gui_id_list.request.request_for_informations.request_for_informations_main_create_button)} onClick={createNewRFI} />
          </NavigationHeder>
          {isLoading ? (
            <SimpleLoading />
          ) : (
            <AgGridNew
              id="rs_items_list"
              onCellClicked={(event) => {
                return cellHandler(event, navigate)
              }}
              onGridReady={handleGridReady}
              apiUrl={Settings.apiPurchaseRequisitionUrl + `/rfq_header`}
              additionalParams={current_user ? `&rfq_type=1&current_user=${current_user}` : `&rfq_type=1`}
              height={500}
              hideDeleteBtn={true}
              hideAddBtn={true}
              dissableCheckBox={true}
              columnDefs={Columns_Headings}
              pagination={true}
              afterDeleteBtns={
                <div className="import_sample_template ms-auto px-2">
                  <button onClick={exportData}>
                    <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                  </button>
                </div>
              }
              btnsWrapperStyle="w-100 px-2"
              fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
            />
          )}
        </div>
        {showCreateRFIModal && (
          <CreateRFQModal
            employee={employee}
            entity={entity}
            sites={sites}
            warehouse={warehouse}
            quotationType={quotationType}
            language={props.language}
            setShowCreateRFQModal={setShowCreateRFIModal}
            currentRFQType={1}
            type={1}
          />
        )}
      </div>
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.request.request_for_informations.request_for_informations_main
}

export default connect(mapStateToProps) ((MasterComponentWraper(ApplySecurityRoles(RFI, SecurityOptions))))