import $ from 'jquery';
import Api from '../../../../inc/Api';
import Settings from '../../../../inc/Settings';
import MasterCellEditor from './MasterCellEditor';
import Helper from '../../../../inc/Helper';
class ItemSearchEditor extends MasterCellEditor{
    init(params) {
        this.params = params;
        this.page = 1;
        this.ppp = 20;
        this.value = params.value ? params.value : '';
        this.autoSelectedRow = null;
        this.firstResultRow = null;
        this.totalRecords = 0
        this.itemLoaded = 0
        this.gUi = document.createElement('div');
        this.addClass(this.gUi,'rs_ag_item_search_editor');
        
        this.loader = document.createElement('img');
        this.loader.src='/images/loader2.gif';
        this.addClass(this.loader,'rs_loeader');
        this.gUi.appendChild(this.loader);
        this.input = document.createElement('input');
        this.input.value = this.value;
        this.addClass(this.input,'form-control')
        this.gUi.appendChild(this.input);
        this.popup = document.createElement('div');
        this.gUi.appendChild(this.popup);
        this.addClass(this.popup,'rs_ag_item_search_editor_poup');

        this.sboxDropdwon = document.createElement('div');
        this.addClass(this.sboxDropdwon,'rs_ag_item_searc_list');
        this.popup.appendChild(this.sboxDropdwon);

        
        this.popupContainer = document.createElement('div');
        this.addClass(this.popupContainer,'rs_item_serch_container')
        this.table = document.createElement('table');
        this.addClass(this.table,'table')
        this.tHeader = document.createElement('thead');
        this.tHeaderRow = document.createElement('tr');
        this.tHeaderRow.innerHTML = `<tr><th>Item Name</th><th>Item Code</th></tr>`;
        this.tHeader.appendChild(this.tHeaderRow)
        this.tHeader.scrollIntoView()
        this.tbody = document.createElement('tbody');

        
        this.table.appendChild(this.tHeader)
        this.table.appendChild( this.tbody )
        this.popupContainer.appendChild(this.table)
        this.sboxDropdwon.appendChild( this.popupContainer )


        let that = this;
        this.apiCalledInterval = null;

        this.input.addEventListener('input', (event) => {
            that.value = event.target.value;
            that.skey = that.value;
            that.clearSelectedItem();
            that.hidePopupArea();
            /*
            clearTimeout(that.apiCalledInterval)
            that.apiCalledInterval = setTimeout(function () { 
                that.search(true)
            }, 500);
            */
        });
        this.input.addEventListener('keydown', (event) => {
            if(event.key === "ArrowDown"){
                if(that.firstResultRow){
                    event.preventDefault();
                    event.stopPropagation();
                    that.firstResultRow.focus();
                }
            }
            // if( (that.params.value != that.getValue() ) && Helper.getNullableValue(event.target.value)){
                if(event.key === "Tab"){
                    if(Helper.getNullableValue(event.target.value)){
                        event.preventDefault();
                        event.stopPropagation();
                        that.value = event.target.value;
                        that.skey = that.value;
                        that.search(true)
                    }
                    
                    /*
                    if(that.firstResultRow){
                        const clickEvent = new Event("click");
                        that.firstResultRow.dispatchEvent(clickEvent);
                    }
                    */
                }
            // }
            
        });
        
        this.sboxDropdwon.addEventListener('scroll',() => {
            clearTimeout(that.apiCalledInterval)
            that.apiCalledInterval = setTimeout(function () { 
                that.search()
            }, 500);
            
        })
    }
    updateValue(newValue){
        this.value = newValue;
        this.input.value = newValue;
    }
    onSearchingStart(){
        this.addClass(this.gUi,'is_searching');
    }
    clearOnSearch(){
        this.removeClass(this.gUi,'is_searching');
    }
    clearSelectedItem(){
        let newData = {
            ...this.params.data,
            'item_name': "",
            'item_code': "",
            'item_id': "",
            'barcode': "",
        }
        this.params.node.setData(newData)
    }
    search(isReplace = false){
        if( Helper.getNullableValue(this.skey) === null){
            return;
        }

        let that = this;
        let api = Api;
        api.setUserToken();
        /*
        let filterData = {item_name:null, item_code:null}
        if(this.params.colDef.field && this.skey){
            filterData[this.params.colDef.field] = this.skey
        }
        */
        let filterData = {item_code_name: this.skey}
        if(isReplace){
            that.page =1;
            that.tbody.innerHTML = '';
            that.itemsLoaded=0
        }
        this.onSearchingStart();
        if (that.totalRecords && that.page > Math.ceil(that.totalRecords / that.ppp)) {
            that.clearOnSearch()
            return;
        }
        api.axios().post(Settings.apiItemUrl+`/get_item_dropdown/${this.page}/${this.ppp}`,filterData).then(res => {
            that.clearOnSearch();
            if(res.data.data.length >=1){
                that.showPopupArea();
            }else{
                that.hidePopupArea();
                Helper.alert(Helper.getLabel(null,'item_not_found','Item not found'),'error')
            }
            that.totalRecords = res.data?.total_records
            res.data.data.forEach( (item,key) => {
                let resultRow = document.createElement('tr');
                that.addAttribute(resultRow,'tabindex',key)
                if(key <=0){
                    that.firstResultRow = resultRow;
                }
                resultRow.addEventListener('keydown', (event) => {
                    
                    if(event.key === "ArrowDown"){
                        event.preventDefault();
                        event.stopPropagation();
                        resultRow.nextElementSibling.focus();
                    }
                    if(event.key === "ArrowUp"){
                        event.preventDefault();
                        event.stopPropagation();
                        if(resultRow.previousElementSibling){
                            resultRow.previousElementSibling.focus();
                        }else{
                            that.input.focus()
                        }
                    }
                    if(event.key === "Enter"){
                        event.preventDefault();
                        event.stopPropagation();
                        const clickEvent = new Event("click");
                        resultRow.dispatchEvent(clickEvent);
                        that.hidePopupArea();
                    }
                    if(event.key === "Tab"){
                        const clickEvent = new Event("click");
                        resultRow.dispatchEvent(clickEvent);
                        that.hidePopupArea();
                    }
                });
                resultRow.addEventListener('click',() => {
                    that.firstResultRow = null;
                    let newData = {
                        ...that.params.data,
                        'item_name': item.name,
                        'item_code': item.code,
                        'item_id': item.item_id,
                        "itemgroup_id":item.item_group_id,
                        'barcode': "",
                    }
                    if(item.taxgroupitem_id){
                        newData.taxgroupitem_id = item.taxgroupitem_id;
                    }
                    if(newData.line_type == Settings.lineTypesId.item){
                        item.prices.forEach( priceItem => {
                            if(priceItem.pricing_type ===0){
                                newData.unit_price = that.params.unitPriceDefault?0:priceItem.price;
                            }
                        })
                        newData.unit = item.purchaseUOM;
                    }
                    var cellEditorInstances = that.params.api.getCellEditorInstances();
                    cellEditorInstances.forEach(cellEditorIns => {
                        if(cellEditorIns.params.colDef.field == 'item_code'){
                            cellEditorIns.updateValue(item.code)
                        }
                        if(cellEditorIns.params.colDef.field == 'item_name'){
                            cellEditorIns.updateValue(item.name)
                        }
                        if(cellEditorIns.params.colDef.field == 'barcode'){
                            cellEditorIns.updateValue('');
                            //cellEditorIns.searchBrcode(item.item_id);

                        }
                        if(newData.unit && cellEditorIns.params.colDef.field == 'unit'){
                            cellEditorIns.updateValue(newData.unit);
                        }
                        if(newData.item_group_id && cellEditorIns.params.colDef.field == 'itemgroup_id'){
                            cellEditorIns.updateValue(newData.item_group_id);
                        }
                        if(newData.unit_price && cellEditorIns.params.colDef.field == 'unit_price'){
                            cellEditorIns.updateValue(that.params.rfq_type===1?newData.unit_price:that.params.formType==="rfq"?0:newData.unit_price);
                        }
                    });
                    
                    that.params.node.setData(newData)
                    that.hidePopupArea();
                    that.params.api.tabToNextCell();  
                    setTimeout(() => {
                        let quantityCell = document.getElementById('ordered_qty'); 
                        if (quantityCell) {
                            quantityCell.focus();
                        }
                    }, 100);
                    /*
                    that.params.api.startEditingCell({
                        rowIndex:that.params.rowIndex,
                        colKey:'barcode'
                    })
                    that.params.api.hideOverlay()
                    */
                })
                
                resultRow.innerHTML =  `<td>${item.name ? item.name : ''}</td><td><label>${item.code ? item.code : ''}</label></td>`;
                /*
                itemDropdown.addEventListener('click',function(){
                    that.sboxDropdwon.innerHTML = "";
                    that.value = item.name
                    that.input.value = item.name
                    that.page = 1;

                })
                */
               if(isReplace && key === 0){
                   //that.tbody.replaceChild(resultRow);
                   //that.tbody = document.createElement('tbody');
                  
                   that.tbody.appendChild(resultRow)
                }else{
                   that.tbody.appendChild(resultRow)
               }
               // auto click if have just one item
               if(res.data.data.length == 1){
                    const clickEvent = new Event("click");
                    resultRow.dispatchEvent(clickEvent);
               }
            })
            if (that.itemsLoaded < that.totalRecords) {
                that.page = parseInt(that.page) + 1;
                that.clearOnSearch();
            }
        }).catch(errors => {
            that.clearOnSearch();
        })
        //this.value = event.target.value;
        //that.sboxDropdwon.innerHTML = event.target.value;
        //$(that.sboxDropdwon).html(event.target.value)
    }
    hidePopupArea(){
        this.removeClass(this.popup,'popup_show');
    }
    showPopupArea(){
        this.addClass(this.popup,'popup_show');
    }
    getGui() {
        return this.gUi;
    }
    getValue() {
        return this.value;
    }

    focusIn() {
        this.tbody.innerHTML = '';
        //this.showPopupArea()    
        this.input.focus();
    }
    
    focusOut() {
        this.tbody.innerHTML = '';
        
        this.hidePopupArea();
        //return true;
    }
    afterGuiAttached(){
        this.addClass(this.gUi.parentElement,'rs_ag_grid_overfllow_visible');
        this.input.focus();
    }
    destroy(){
        this.clearOnSearch();
        this.removeClass(this.gUi.parentElement,'rs_ag_grid_overfllow_visible');
        this.hidePopupArea();
    }
    isPopup(){
        return false;
    }
}
export default ItemSearchEditor;