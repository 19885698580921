import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import Helper from '../../../../inc/Helper'
import { getOptions, getStatusNameById, gridLinkValueFormat, gridRFQExpireFormat, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions'

const getRFQColumnsHeadings = ({
  language,
  customComparator,
  rfqStatusList,
  vendorReplyStatusList,
  vendorReply=false,
  customDateComparator,
}) => {
  return [
    {
      field: 'document_number',
      headerName: Helper.getLabel(
        language,
        'document_number',
        'Document number'
      ),
      cellRenderer: (params) => {
        return gridLinkValueFormat(params)
      },
      editable: false,
      minWidth: 220,
    }, 
    {
      field: "target_document_number",
      headerName: Helper.getLabel(
        language,
        "target_document_number",
        "Target document number"
      ),
      valueFormatter: (params) =>
        params.value || "-",
      editable: false,
      minWidth: 240,
    },
    ...(!vendorReply ? 
      [{
        field: 'rfq_status',
        headerName: Helper.getLabel(language, 'rfq_status', 'RFQ status'),
        cellRenderer:(params)=>{return getStatusNameById(params.value,rfqStatusList)},
        editable: false,
        minWidth: 200,
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, rfqStatusList),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name,
        }
      }]
        :
      [{
        field: "vendor_replystatus",
        headerName: Helper.getLabel(language, "status", "Status"),
        cellRenderer: (params) => getStatusNameById(params.value,vendorReplyStatusList),
        editable: false,
        minWidth: 180,
        filter:'agSetColumnFilter',
        filterParams: {
          values: (params) => getOptions(params, vendorReplyStatusList),
          keyCreator: (params) => optionsKeyCreator(params, 'id'),
          valueFormatter: params => params.value.name?.replace(/_/g," ")
        }
      }]
    ),
    {
      field:"onhold",
      headerName: Helper.getLabel(language, "on_hold", "On hold"),
      editable: false,
      minWidth: 150,
      cellRenderer:'agCheckboxCellRenderer',
      cellEditor:'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      filter: 'agSetColumnFilter',
      filterParams: { 
        values: [true, false], 
        keyCreator: (params) => params.value, 
        valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
      valueGetter: (params) => {
        if(!params?.data?.onhold || !params?.data){
            return false
        }
        else {
            return params.data?.onhold
        }
      },
    },
    { 
      field: "purchase_requisition_number",
      headerName: Helper.getLabel(
        language,
        "purchase_requisition_number",
        "Purchase Requisition number"
      ),
      valueFormatter: (params) =>
        params.value || "-",
      editable: false,
      minWidth: 240,
    },
    {
      field: 'createdby',
      headerName: Helper.getLabel(language, 'created_by', 'Created by'),
      editable: false,
      minWidth: 150,
    },
    {
      field: 'title',
      headerName: Helper.getLabel(language, 'title', 'Title'),
      valueFormatter: (params) => {
        return params.value || '-'
      },
      editable: false,
      minWidth: 150,
      comparator: customComparator,
    }, 
    {
      field: 'createddatetime',
      headerName: Helper.getLabel(
        language,
        'created_date_and_time',
        'Created date and time'
      ),
      editable: false, 
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params.value)
          : '-',
    },
    {
      field: 'publish_datetime',
      headerName: Helper.getLabel(
        language,
        'publish_date_time',
        'Publish date and time'
      ),
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        params.value
          ? DatepickerFunctions.convertDateTimeFromDataBase(params.value)
          : '-',
      comparator: customDateComparator,
      minWidth: 220,
    },
    {
        field: 'expiry_datetime',
        headerName: Helper.getLabel(
          language,
          'expiry_datetime',
          'Expiry date and time'
        ),
        filter:'agDateColumnFilter',
        comparator: customDateComparator,
        cellRenderer: (params) => gridRFQExpireFormat(params),
        minWidth: 220,
      },
    {
      field: 'delivery_date',
      headerName: Helper.getLabel(language, 'delivery_date', 'Delivery date'),
      filter:'agDateColumnFilter',
      valueFormatter: (params) =>
        params.value
          ? DatepickerFunctions.convertDateFromDataBase(params.value)
          : '-',
      editable: false,
      minWidth: 150,
      comparator: customDateComparator,
    },
  ]
}

export default getRFQColumnsHeadings
