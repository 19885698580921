import DatepickerFunctions from "../../inc/DatepickerHelper";
import Helper from "../../inc/Helper";
import { getOptions, getStatusNameById, gridLinkValueFormat, optionsKeyCreator } from "../grid/ag/commonFunctions/GridCommonFunctions";

const getPRColumnsHeader = ({language,documentStatusList,purchaseStatusList}) => {
   return [
    {
      field: "document_number",
      headerName: Helper.getLabel(
        language,
        "document_number",
        "Document number"
      ),
      minWidth:200,
      cellRenderer:(params)=> gridLinkValueFormat(params),
      editable: false,
    },
    { field: "rfq_document_number", minWidth: 280, headerName: Helper.getLabel( language, "rfq_document_number", "RFQ document number" ), valueFormatter: (params) => params.value ? params.value : '-' , editable: false },
    { field: "target_document_number", minWidth: 280, headerName: Helper.getLabel( language, "converted_document_number", "Converted document number" ), valueFormatter: (params) => params.value ? params.value : '-' , editable: false },
    {
      field:"is_rfi_pr",
      headerName:Helper.getLabel(language,"requisition_for_RFI","Requisition for RFI"),
      editable:false,
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
      cellRenderer: 'agCheckboxCellRenderer', 
      cellEditor: 'agCheckboxCellEditor', 
      cellRendererParams: { disabled: true },
      minWidth:240,
    },
    {
      field:"is_ro_pr",
      headerName:Helper.getLabel(language,"requisition_for_RFI","Requisition for Release Order"),
      editable:false,
      filter: 'agSetColumnFilter',
      filterParams: { 
          values: [true, false], 
          keyCreator: (params) => params.value, 
          valueFormatter: (params) => params.value ? 'true' : 'false' 
      }, 
      cellRenderer: 'agCheckboxCellRenderer', 
      cellEditor: 'agCheckboxCellEditor', 
      cellRendererParams: { disabled: true },
      minWidth:240,
    },
    {
      field: "description",
      headerName: Helper.getLabel(
        language,
        "description",
        "Description"
      ),
      editable: false,
      valueFormatter:(params) => params.value ? params.value : "-"
    },
    {
      field: "document_status",
      headerName: Helper.getLabel(
        language,
        "document_status",
        "Document status"
      ),
      minWidth:220,
      cellRenderer: (params) => getStatusNameById(params.data.document_status,documentStatusList) ,
      editable: false,
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, documentStatusList),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: "requisition_status",
      headerName: Helper.getLabel(
        language,
        "purchase_requisition_status",
        "Purchase requisition status"
      ),
      minWidth:240,
      cellRenderer: (params) => getStatusNameById(params.data.requisition_status,purchaseStatusList) ,
      editable: false,
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, purchaseStatusList),
        keyCreator: (params) => optionsKeyCreator(params, 'id'),
        valueFormatter: (params) => params.value.name,
      },
    },
    {
      field: "requester_name",
      headerName: Helper.getLabel(
        language,
        "requester",
        "Requester"
      ),
      editable: false,
    },
    {
      field: "createddatetime",
      headerName: Helper.getLabel(
        language,
        "creationdate_time",
        "Created date time"
      ),
      minWidth:200,
      filter:"agDateColumnFilter",
      valueFormatter: (params) => DatepickerFunctions.convertDateTimeFromDataBase(params.value) || "-" ,
      editable: false,
    },
    {
      field: "delivery_date",
      headerName: Helper.getLabel(
        language,
        "delivery_date",
        "Delivery date"
      ),
      filter:"agDateColumnFilter",
      valueFormatter: (params) => DatepickerFunctions.convertDateFromDataBase(params.value) || "-" ,
      editable: false,
    },
  ];

}



export {getPRColumnsHeader}