/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import $ from 'jquery'
import Popup from '../../Popup/Popup'
import Collapse from '../../inc/Collapse'
import Helper from '../../../inc/Helper'
import { connect } from 'react-redux'
import DropDownInput from '../../Forms/Dropdown'
import Input from '../../Forms/Input'
import SimpleLoading from '../../Loading/SimpleLoading'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { ValidateArray, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator'
import { useNavigate } from 'react-router-dom'
import NewInputDatePicker from '../../Forms/NewInputDatePicker'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import Button from '../../inc/Button'

let cancelTokenSource = axios.CancelToken.source()
const CreateItemCataloguePopup = (props) => {
  let auth = props.auth.user
  const navigate = useNavigate()
  const [saveBtnApi, setSaveBtnApi] = useState(false)
  const [allDataLoading, setAllDataLoading] = useState(() => {
    return {
      currency: false,
    }
  })
  const [createItemCatalogue, setCreateItemCatalogue] = useState({
    document_status: null,
    description: null,
    currency_id: auth.default_currency_id ? auth.default_currency_id : null,
    document_number: null,
    agreement_id: null,
    start_date: null,
    end_date: null,
    entity_id: auth.Employee ? auth.Employee.Entity_id : '',
    site_id: auth.Employee ?  auth.Employee.Site_id : '',
    warehouse_id: auth.Employee ? auth.Employee.Warehouse_id : '',
  })

  const [numberSequenceData, setNumberSequenceData] = useState({})
  const [entity, setEntity] = useState([])
  const [sites, setSites] = useState([])
  const [warehouse, setWarehouse] = useState([])

  //Fields Validation
  let validator

  const validationConfigure = () => {
    let fieldConfig = [
      { name: 'currency_id', displayName: Helper.getLabel(props.language, 'currency_id', 'Currency '), types: ['Required'] },
    ]
    if (!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }
  validationConfigure()
  const [documentStatus, setDocumentStatus] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  //End

  //Calls Reduction
  let documentStatusCalled = false
  let currencyCalled = false
  let entityCalled = false
  let warehouseCalled = false
  let sitesCalled = false
  //End

  //variable added for dropdown
  let currentSearchTerm = ''
  let currentSearchTermEntity = ''
  let allEntityData = useRef()
  let allSitesData = useRef()
  let allWarehouseData = useRef()

  //API RENDERS
  const fetchData = () => {
    DocumentStatusAPI()
    CurrencyAPI()
    EntityAPI()
    SitesAPI()
    WareHouseAPI()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    getNumberSequenceData()
  }, [createItemCatalogue?.entity_id])

  const DocumentStatusAPI = () => {
    if (documentStatusCalled) {
      return
    }
    let api = Api
    documentStatusCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/enum/POHeaderDocumentStatus', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let docsStatus = []
          res.data.data.forEach((item) => {
            docsStatus.push({
              value: item.id,
              label: item.name,
            })
          })
          setDocumentStatus(docsStatus)
          setAllDataLoading((oldData) => {
            return {
              ...oldData,
              document_status: true,
            }
          })
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const CurrencyAPI = () => {
    if (currencyCalled) {
      return
    }
    let api = Api
    currencyCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/currency', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let CurrencyARR = []
          res.data.data.forEach((item) => {
            CurrencyARR.push({
              value: item.currency_id,
              label: `${item.currency_code} - ${item.currency_name}`,
            })
          })
          setCurrencyList(CurrencyARR)
          setAllDataLoading((oldData) => {
            return {
              ...oldData,
              currency: true,
            }
          })
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const EntityAPI = () => {
    if (entityCalled) {
      return
    }
    let api = Api
    entityCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/get_entities')
      .then(function (res) {
        if (res.data.status === 'success') {
          let EntityARR = []
          res.data.data.forEach((item) => {
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setEntity(EntityARR)
          setAllDataLoading((oldData) => {
            return {
              ...oldData,
              entity: true,
            }
          })
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const SitesAPI = () => {
    if (sitesCalled) {
      return
    }
    let api = Api
    sitesCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/get_site', {
        code: '',
        name: '',
      })
      .then(function (res) {
        if (res.data.status === 'success') {
          let SitesARR = []
          res.data.data.forEach((item) => {
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setSites(SitesARR)
          setAllDataLoading((oldData) => {
            return {
              ...oldData,
              sites: true,
            }
          })
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const WareHouseAPI = () => {
    if (warehouseCalled) {
      return
    }
    let api = Api
    warehouseCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/get_warehouse', {
        code: '',
        name: '',
      })
      .then(function (res) {
        if (res.data.status === 'success') {
          let Warehouse = []
          res.data.data.forEach((item) => {
            Warehouse.push({
              value: item.warehouse_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setWarehouse(Warehouse)
        }
        setAllDataLoading((oldData) => {
          return {
            ...oldData,
            warehouse: true,
          }
        })
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const getNumberSequenceData = async (entity_id) => {
    try {
      let api = Api
      api.setUserToken()
      const numberSequenceRecord = await api.axios().get(Settings.apiUrl + `/ns_status/${entity_id ?? createItemCatalogue?.entity_id}/${Settings?.source_id?.rate_card}`)
      if (numberSequenceRecord?.status === 200) {
        setNumberSequenceData(numberSequenceRecord?.data)
      }
    } catch (error) {
      setNumberSequenceData({})
    }
  }
  //End

  //Handlers

  const onPopupClose = () => {
    $('html').removeClass('rs_popup_open')
    props.ClosePopup(false)
  }

  const ClosePopupHandler = () => {
    $('html').removeClass('rs_popup_open')
    props.ClosePopup(false)
  }

  const InputChangeHandler = (e) => {
    let itemCatalogueAdditionalParams = {}

    setCreateItemCatalogue((prevState) => {
      return {
        ...prevState,
        ...itemCatalogueAdditionalParams,
        [e.target.name]: e.target.value,
      }
    })
  }

  const DatePickerHandler = (date, name) => {
    setCreateItemCatalogue((prevState) => {
      return {
        ...prevState,
        [name]: date,
      }
    })
  }

  const entityInputChangeHandler = (e) => {
    setCreateItemCatalogue((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        site_id: '',
        warehouse_id: '',
      }
    })
    getNumberSequenceData(e.target.value)
  }
  const siteInputChangeHandler = (e) => {
    setCreateItemCatalogue((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
        warehouse_id: '',
      }
    })
  }

  const additionalParams = {
    entity_id: createItemCatalogue?.entity_id,
  }

  const additionalSiteParams = {
    site_id: createItemCatalogue?.site_id,
  }

  const handlePayload = () => {
    let rsNewItemCatalogueData = {
      document_status: !ValidateArray(documentStatus) ? documentStatus.find((id) => id.label === 'Draft').value : null,
      description: createItemCatalogue.description,
      start_date: DatepickerFunctions.convertDateForDataBase(createItemCatalogue.start_date),
      expiry_date: DatepickerFunctions.convertDateForDataBase(createItemCatalogue.expiry_date),
      currency_id: parseInt(createItemCatalogue.currency_id),
      document_number: numberSequenceData?.ns_type === 'continuous' ? null : createItemCatalogue?.document_number,
      entity_id: parseInt(createItemCatalogue.entity_id),
      site_id: parseInt(createItemCatalogue.site_id),
      warehouse_id: parseInt(createItemCatalogue.warehouse_id),
      header_type:1
    }

    return rsNewItemCatalogueData
  }

  const getApiUrl = () => {
    return Settings.apiPurchaseOrderUrl + '/PA_ratecardheader'
  }

  const getIdByType = (res) => {
    return res.data.ratecardheader_id
  }

  const executePOSTApiforItemCatalogue = async () => {
    try {
      let api = Api
      api.setUserToken()

      let createPayload = handlePayload()
      let url = getApiUrl()

      const res = await api.axios().post(url, createPayload, { cancelToken: cancelTokenSource.token })

      if (res.data.status === 'success') {
        Helper.alert(res.data.message)
        let editUrl = '/item-catalogue/edit'
        navigate(`${editUrl}/${getIdByType(res)}`)
        $('html').removeClass('rs_popup_open')
        props.ClosePopup(false)
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
    }
  }

  const CreateItemCatalogue = () => {
    let validData = {
      ...createItemCatalogue,
    }
    if (!validator.isValid(validData)) {
      validator.displayMessage()
      validator.reset()
      return
    }
    Helper.createDebouncedAPIFunction([executePOSTApiforItemCatalogue], setSaveBtnApi, cancelTokenSource)()
  }
  const isAllDataLoaded = () => {
    let isAllDataLoaded = true
    for (const objectKey in allDataLoading) {
      if (!allDataLoading[objectKey]) {
        isAllDataLoaded = false
      }
    }
    return isAllDataLoaded
  }
  if (!isAllDataLoaded()) {
    return <SimpleLoading />
  }

  const mapEntityData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.entity_id,
    }
  }

  const mapSitesData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.site_id,
    }
  }

  const mapWarehouseData = (item) => {
    return {
      text: `${item.code} - ${item.name}`,
      id: item.warehouse_id,
    }
  }

  return (
    <div className="close-popup_po">
      <Popup onClose={onPopupClose} autoOpen={true} width="752px">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-4">{props.title ? props.title : Helper.getLabel(null, `create_item_catalogue`, `Create Item Catalogue`)}</h2>
            </div>
          </div>
        </div>

        <Collapse className="mb-4 info_po_tabs" open={true} title={Helper.getLabel(props.language, 'etfi', 'Enter the following information')}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label={Helper.getLabel(props.language, 'document_no', 'Document number')}
                  name="document_number"
                  value={createItemCatalogue?.document_number}
                  disable={objectIsEmpty(numberSequenceData) || numberSequenceData?.ns_type === 'continuous'}
                  onChange={InputChangeHandler}
                />
              </div>
              <div className="col-lg-6">
                <Input label={Helper.getLabel(props.language, 'description', 'Description')} value={createItemCatalogue.description} onChange={InputChangeHandler} name="description" maxLength={255} />
              </div>
              <div className="col-lg-6">
                <DropDownInput
                  id="currency_id"
                  required={true}
                  label={Helper.getLabel(props.language, 'currency_id', 'Currency')}
                  placeHolder={Helper.getLabel(props.language, 'select currency', 'Select currency')}
                  options={currencyList}
                  value={createItemCatalogue.currency_id}
                  name="currency_id"
                  onChange={InputChangeHandler}
                  reRenderRequired={true}
                />
              </div>
              <div className="col-lg-6">
                <NewInputDatePicker label={Helper.getLabel(props.language, 'start_date', 'Start date')} name="start_date" id="start_date1" value={createItemCatalogue.start_date} onChange={DatePickerHandler} empty={true}/>
              </div>
              <div className="col-lg-6">
                <NewInputDatePicker label={Helper.getLabel(props.language, 'expiry_date', 'Expiry date')} name="expiry_date" id="expiry_date1" value={createItemCatalogue.expiry_date} onChange={DatePickerHandler} empty={true} />
              </div>
              <div className="col-lg-6">
                <DropDownInput
                  id="entity_id"
                  pagination={true}
                  apiUrl={Settings.apiUrl + `/get_entities`}
                  mapFunction={mapEntityData}
                  allDataRef={allEntityData}
                  value={createItemCatalogue.entity_id}
                  currentSearchTerm={currentSearchTermEntity}
                  label={Helper.getLabel(props.language, 'entity_id', 'Entity')}
                  placeHolder={Helper.getLabel(props.language, 'select entity', 'Select entity')}
                  name="entity_id"
                  required={true}
                  options={entity}
                  onChange={entityInputChangeHandler}
                  reRenderRequired={true}
                />
              </div>
              <div className="col-lg-6">
                <DropDownInput
                  id="site_id"
                  pagination={true}
                  apiUrl={Settings.apiUrl + `/get_site`}
                  additionalParams={additionalParams}
                  currentSearchTerm={currentSearchTerm}
                  mapFunction={mapSitesData}
                  allDataRef={allSitesData}
                  value={createItemCatalogue.site_id}
                  label={Helper.getLabel(props.language, 'site_id', 'Site')}
                  placeHolder={Helper.getLabel(props.language, 'select site', 'Select site')}
                  name="site_id"
                  required={true}
                  options={sites}
                  onChange={siteInputChangeHandler}
                  reRenderRequired={true}
                />
              </div>
              <div className="col-lg-6">
                <DropDownInput
                  id="warehouse_id"
                  pagination={true}
                  apiUrl={Settings.apiUrl + `/get_warehouse`}
                  additionalParams={additionalSiteParams}
                  currentSearchTerm={currentSearchTerm}
                  mapFunction={mapWarehouseData}
                  allDataRef={allWarehouseData}
                  value={createItemCatalogue.warehouse_id}
                  label={Helper.getLabel(props.language, 'warehouse_id', 'Warehouse')}
                  placeHolder={Helper.getLabel(props.language, 'select warehouse', 'Select warehouse')}
                  name="warehouse_id"
                  required={true}
                  options={warehouse}
                  onChange={InputChangeHandler}
                  reRenderRequired={true}
                />
              </div>
            </div>
          </div>
        </Collapse>
        <div className="container-fluid">
          <div className="row mt-3 mb-3">
            <div className="col-12">
              <div className="addDocumentNumber-body-buttons justify-content-end">
                <Button className="black-btn-style" onClick={ClosePopupHandler} title={Helper.getLabel(null, 'close', 'Close')} />
                <Button isDisable={saveBtnApi} isActive={true} onClick={CreateItemCatalogue} title={Helper.getLabel(null, 'create', 'Create')} />
              </div>
            </div>
          </div>
        </div>
      </Popup>
      {saveBtnApi ? <OverlayLoader /> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(CreateItemCataloguePopup)
