import React, { useState, useEffect, useCallback } from 'react'
import Popup from '../../../Popup/Popup'; 
import Helper from '../../../../inc/Helper';
import Api from '../../../../inc/Api'; 
import Settings from '../../../../inc/Settings';
import { connect } from 'react-redux';
import { exportToXlsx, getNextIncrementalNumber, getPopupMessageBasedOnStatus } from '../../../../inc/Validation'; 
import AgGridNew from '../../../grid/ag/ag-grid-new';
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader';
import { generateBaseUrl, getLabelFromDropdown, getOptions, optionsKeyCreator } from '../../../grid/ag/commonFunctions/GridCommonFunctions';
import SimpleDropdown from '../../../grid/ag/cellEditor/SimpleDropdown';
import { transformObjectScoringSections } from '../../../../inc/TransformerFunctions';

/* Validations: Sequence, Weight is required n its a number, Name field is required */

let gridApi = null
let AddBtn = null;
let transaction = null;

const SectionDetails = ({reloadGrid, setSectionDetails, language, scoringmaster_id}) => { 
  const [typeDropdown, setTypeDropdown] = useState([]) 
  const [sectionsExportLoading, setSectionsExportLoading] = useState(false)

  let typeCalled = false 

  useEffect(() => { TypeDropdownAPI() }, [])
 
  const TypeDropdownAPI = () => {
    if(typeCalled){
      return;
    } 

    let api = Api
    typeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/enum/RF_ScoringSection_type`,{}).then(function(res){
      if(res.data.status==="success"){    
        let UPDATED_DATA = []
        res.data.data.forEach(element => {
          UPDATED_DATA.push({
            value: element.id,
            label: element.name
          })
        })
        setTypeDropdown(UPDATED_DATA) 
      }
    }).catch((error) => {  
      console.log(error)
    })
  }
  
  const SequenceDefaultValueSetter = (params) => {
    if(params.data.sequence == undefined) {
      let sequencesArray = []
      params.api.forEachNode((node) => {
        if(node.data.sequence === 0) return;
        sequencesArray.push({sequence: node.data.sequence !== undefined ? parseInt(node.data.sequence) : undefined})
      }) 
      let sequenceVal = getNextIncrementalNumber(sequencesArray) 
      params.node.setDataValue('sequence', sequenceVal) 
      params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
      return; 
    };
    if(params.data.sequence !== 0) return;

    let sequencesArray = []
    params.api.forEachNode((node) => {
      if(node.data.sequence === 0 || params.data.sequence == undefined) return;
      sequencesArray.push({sequence: parseInt(node.data.sequence)})
    }) 
    let sequenceVal = getNextIncrementalNumber(sequencesArray) 
    params.node.setDataValue('sequence', sequenceVal) 
    params.api.refreshCells({ rowNodes: [params.node], columns: ['sequence'] });
  }

  const ScoringDistributionValidations = (params) => {
    let totalTechnicalWeight = 0;
    let totalFinancialWeight = 0;
  
    params.api.forEachNode((node) => {
      const weight = parseFloat(node.data.weight);
      if (!isNaN(weight) && weight >= 0) {
        if (parseInt(node.data.section_type) === 0) {
          totalTechnicalWeight += weight;
        } else if (parseInt(node.data.section_type) === 1) {
          totalFinancialWeight += weight;
        }
      }
    });
    
    if (totalTechnicalWeight > 100) {
      Helper.alert('Invalid Technical Weight Distribution!', 'error');
      return;
    }
  
    if (totalFinancialWeight > 100) {
      Helper.alert('Invalid Financial Weight Distribution!', 'error');
      return;
    }
   
    if(!params?.data?.scoringsectiondetailrfq_id){
      addNewDataSection(params)
    } else {
      updateDataSection(params)
    }   
  };
 
  const updateDataSection = async (event) => { 
    try {
      let payload = {
        name: event?.data?.name,
        sequence: event?.data?.sequence ? parseInt(event?.data?.sequence) : null,
        weight: event?.data?.weight ? parseFloat(event?.data?.weight) : null,
        section_type: event?.data?.section_type ? parseInt(event?.data?.section_type) : null
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().put(Settings.apiPurchaseRequisitionUrl+`/scoringsection/${event?.data?.scoringsectiondetailrfq_id}?for_rfq=True`, payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.api?.refreshServerSide({ purge: true }); 
          gridApi?.api?.deselectAll();
        }, 100);

         
        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    } 
  }

  const addNewDataSection = async (event) => { 
    try {
      let payload = {
        scoringmaster_id: scoringmaster_id,
        sections: [{...event?.data, criteria: []}]
      };

      let api = Api;
      api.setUserToken();
      const res = await api.axios().post(Settings.apiPurchaseRequisitionUrl+'/scoringSection?for_rfq=True', payload);
      if (res.data.status === "success") {
        setTimeout(() => {
          gridApi?.api?.refreshServerSide({ purge: true }); 
          gridApi?.api?.deselectAll();
        }, 100);

        setTimeout(() => {
          if (AddBtn) {
            gridApi?.api?.applyServerSideTransaction(transaction);
          }
        }, 1000);

        Helper.alert(res?.data?.message);
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error);
    }
  }

  const DeleteSection = useCallback(async (element) => { 
    let api = Api;
    api.setUserToken();
    
    let delete_section_id = element?.data?.scoringsectiondetailrfq_id;

    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl+`/scoringsection/${delete_section_id}?for_rfq=True`)
      const rowNode = gridApi?.api?.getRowNode(delete_section_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }

      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.api?.applyServerSideTransaction(transaction)
      }, 200) 

      gridApi?.api?.refreshServerSide({ purge: true }); 
      gridApi?.api?.deselectAll();

      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, []);

  const handleBtn = (value, transactionData) => {
    AddBtn = value
    transaction = transactionData
  }
  
  const CloseHandler = () => {  
    setSectionDetails((prevState) => {
      return {
        ...prevState,
        showPopup: false
      }
    })
    reloadGrid();
  }
 
  const columnDefs = [
    { field: 'name', filter: 'agTextColumnFilter', editable: true, headerName: Helper.getLabel(language, 'name', "Name*") },
    { field: 'sequence', filter: 'agNumberColumnFilter', editable: true, headerName: Helper.getLabel(language, 'sequence', "Sequence*") },
    { field: 'weight', filter: 'agNumberColumnFilter', editable: true, headerName: Helper.getLabel(language, 'weight', "Weight") },
    { 
      field: 'section_type', 
      headerName: Helper.getLabel(language, 'type', "Type"),
      editable: true,
      cellEditor: SimpleDropdown,
      isAllowZero: true,
      minWidth: 180, 
      cellEditorParams: { values: typeDropdown },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, typeDropdown),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label,
      }
    }
  ];

  const getTransformedObjForExport = (data) => transformObjectScoringSections(data, typeDropdown);

  const exportData = async () => {
    if(!scoringmaster_id) return;

    setSectionsExportLoading(true);

    const apiUrl = scoringmaster_id?Settings.apiPurchaseRequisitionUrl+`/scoringsections/${scoringmaster_id}?for_rfq=True`:null;
    const params = '';
    
    let pagination = false;
    let defaultAdvanceFilterObj = false; 
    
    const baseUrl = generateBaseUrl({ gridObj: gridApi, apiUrl, params, pagination, defaultAdvanceFilterObj });
    await exportToXlsx({ url: baseUrl, columns: columnDefs, fileName: 'section_details', additionalFunction: getTransformedObjForExport })
    
    setSectionsExportLoading(false);
  }

  return (
    <div className='common_responsive_class_popup_component'>
      {sectionsExportLoading ? <OverlayLoader isPopupInTab={true} /> : null}
      <Popup className="sections_popup" autoOpen={true} onClose={CloseHandler}>  
        <div className='grid_sections_scoring_criteria grid_save_btn_hide'>
          <AgGridNew
            apiUrl={scoringmaster_id?Settings.apiPurchaseRequisitionUrl+`/scoringsections/${scoringmaster_id}?for_rfq=True`:null}  
            handleDeleteSelectedRows={DeleteSection}
            onRowEditingStopped={SequenceDefaultValueSetter} 
            columnDefs={columnDefs}  
            onRowValueChanged={ScoringDistributionValidations}
            handleAddButton={handleBtn}
            onGridReady={(params) => gridApi = params}
            height={500}
            gridId={'rfq_master_sections_grid'}
            uniqueField={'scoringsectiondetailrfq_id'}
            pagination={false}  
            afterDeleteBtns={
              <div className="import_sample_template ms-auto px-2">
                <button onClick={exportData}>
                  <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                </button>
              </div>
            }
            btnsWrapperStyle="w-100 px-2"  
          />
        </div>  
      </Popup>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

export default connect(mapStateToProps) (SectionDetails); 