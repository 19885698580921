import React from 'react'
import MasterComonent from '../../../Backend/MasterComonent'
import { connect } from 'react-redux'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import Helper from '../../../../inc/Helper'
import BlueCallToAction from "../../../Widgets/BlueCallToAction/BlueCallToAction"
import ProductDetail from "../../../PurchaseRequisition/ProductDetail";
import { useLocation } from "react-router-dom";

const ItemDetail = (props) => {

  const location = useLocation()

  const data = location.state;

  //top header component
  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, 'rfq_reply_id', 'Document number'),
        subtitle:data?.document_number,
      },
      {
        title: Helper.getLabel(props.language, 'title', 'Title'),
        subtitle:(data?.title?.length > 30) ? (
          data?.title?.substring(0, 30) + "..." || "-"
        ) : data?.title || "-",
      },
    ]

  

    return (
      <div className="rfq_detail_form">
        <div className={`${props?.auth?.user?.usertype===0?'':'rfq_details_external'}_action misc-header-blue rfq-internal-blue`}>
          <BlueCallToAction items={items} tags={null} />
        </div>
        <div className="mb-4" />
      </div>
    )
  }

  return (
    <div className={props?.auth?.user?.usertype===0 ? '' :"request_for_quotation_detail_screen"}>
      <MasterComonent>
        <NavigationHeder
        //  backUrl={`/rfq-vendor-reply/rfq-bidding-details?RFQ_vendorreply_id=${data?.RFQ_vendorreply_id}`}
        title={`<span style="color:#313638;">${data?.RFQ_type===1 || data?.rfq_type === 1 ? Helper.getLabel(props.language, 'rfi', "Request for Information") :Helper.getLabel(props.language, 'rfq', "Request for Quotation")} #</span> ${data?.document_number || ''}`}
          hideMoreBtn={true}
        >
         
        </NavigationHeder>

        <div className="tabs_default">
          {addComponentTop()}
          <div className="rfq-vendor-reply-product-detail">
             <ProductDetail isAddToCart={false} hidePrice={true} />
          </div>     
        </div>
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}



export default connect(mapStateToProps)(ItemDetail)
