/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import NavigationHeder from '../../Navigations/NavigationHeder'
import Button from '../../inc/Button'
import MasterComponentWraper from '../../Backend/MasterComponentWraper'
import Helper from '../../../inc/Helper'
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction'
import MasterSidebar from '../../Sidebars/MasterSidebar'
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory'
import { useParams } from 'react-router-dom'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation'
import FormValidator from '../../../inc/FormValidator'
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent'
import TaxTransactionPopup from '../../Purchase Orders Internal/Edit PO/ListView/TaxTransactionPopup'
import RsDataLoader from '../../inc/RsDataLoader'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import FixedNavigationHeder from '../../Navigations/FixedNavigationHeder'
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper'
import axios from 'axios'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { getNewValue } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import HeaderGeneralInformation from './HeaderGeneralInformation'
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader'
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls'
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas'

let cancelTokenSource = axios.CancelToken.source()

const ItemCatalogueEdit = (props) => {
  const { id, instance_id } = useParams();
  const [isShowTransactionPopup, setIsShowTransactionPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saveBtnApi, setSaveBtnApi] = useState(false)

  // const [dataFromGridAndInput, setDataFromGridAndInput] = useState([])
  const [editItemCatalogue, setEditItemCatalogue] = useState({})
  const [taxCode, setTaxCode] = useState([])
  const [taxGroup, setTaxGroup] = useState([])
  const [sites, setSites] = useState([])
  const [entities, setEntities] = useState([])
  const [numberSequence, setNumberSequence] = useState({})

  // Workflow States
  const [workflowActionHistory, setWorkflowActionHistory] = useState([])
  const [workflowEligible, setWorkflowEligible] = useState(null)
  const [workflowExist, setWorkflowExist] = useState({})

  const [disableFields, setDisableFields] = useState(true)
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false)

  const [documentStatusLabel, setDocumentStatusLabel] = useState('')

  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0)
  const myDivRef = useRef(null)
  let sectionName = 'item-catalogue-edit'

  useEffect(() => {
    Helper.resizeWindowHandle(myDivRef, setHeight, window)
  }, [myDivRef.current?.clientHeight, scrollDitection])

  let rate_card_source_id = Settings.source_id.rate_card
  //End

  let validator

  const validationConfigure = () => {
    let fieldConfig = [
      { name: 'currency_id', displayName: Helper.getLabel(props.language, 'currency_id', 'Currency '), types: ['Required'] },
    ]
    if (!validator) {
      validator = new FormValidator(fieldConfig, props.language)
    }
  }

  validationConfigure()

  //End
  let superNav = [{ title: Helper.getLabel(props.language, 'header_view', 'Header View') }, { title: Helper.getLabel(props.language, 'line_view', 'Line View') }]

  let navs = [
    { title: Helper.getLabel(props.language, 'general', 'General'), data_section: 'one', active_status: 'active_tab' },
  ]

  const addComponentTop = () => {
    const editItemCatalogueReferenceNumber = editItemCatalogue.document_number || '-'
  
    let items = [
      { title: Helper.getLabel(props.language, 'document_number', 'Document number'), subtitle: editItemCatalogueReferenceNumber },
    ]

    let tagTitle = documentStatusLabel
    let tags = [{ title: tagTitle || null }]

    return (
      <div className='rfq-internal-blue'>
        <BlueCallToAction scrollDitection={scrollDitection} items={items} tags={props.type===0 ? [] :tags} />
      </div>
    )
  }

  const addComponentRight = () => {
    return (
      <div className="parent-sidebar-cn">
        <MasterSidebar>
          <NoteAttachmentHistory
            scrollDitection={scrollDitection}
            height={height}
            source_id={rate_card_source_id}
            notesource_integrator={id}
            attachmentsource_integrator={id}
            condition={editItemCatalogue?.document_status_name === "Under Review"}
            openCanvas={setShowCanvas}
            openCurrentAssignees={setShowCurrentAssignees}
            hideHistory={props?.user?.usertype === 1 ? true : !workflowActionHistory?.history?.length}
            historyArray={workflowActionHistory}
          />
        </MasterSidebar>
        
        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas}
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={rate_card_source_id}
            source_item_id={id}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees}
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={rate_card_source_id}
            source_item_id={id}
          />
        }
      </div>
    );
  }

  useEffect(() => {
    getWorkFlowCalls()
    TaxCodeAPI()
    TaxGroupAPI()
    SitesAPI()
    EntityAPI()
    getItemCatalogueData()
    // getDocumentStatusLabel()
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //End

  //API Reduction Calls
  let taxCodeCalled = false
  let taxGroupCalled = false
  let entityCalled = false
  let sitesCalled = false

  const getApiUrl = () => {
    return Settings.apiPurchaseOrderUrl + `/PA_ratecardheader/${id}`
  }

  const getWorkFlowCalls = async () => {
    const workflowCalls = new WorkFlowCommonHeaderAPICalls();
    
    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(rate_card_source_id, id);
   
    const trigger = await workflowCalls.getWorkFlowTriggers(); 
    const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name === "Update")?.id

    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props?.user?.tenant_id, rate_card_source_id, id);  
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(rate_card_source_id, props?.user?.tenant_id, mode); 

    if(workflowActionHistory) setWorkflowActionHistory(workflowActionHistory);
    if(workflowExist) setWorkflowExist(workflowExist);
    if(eligibleWorkflow) setWorkflowEligible(eligibleWorkflow);   
  };

  const TaxCodeAPI = () => {
    if (taxCodeCalled) {
      return
    }
    let api = Api
    taxCodeCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/taxgroupitem', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let TaxCode = []
          res.data.data.forEach((item) => {
            TaxCode.push({
              value: item.taxgroupitem_id,
              label: item.name,
            })
          })
          setTaxCode(TaxCode)
        }
      })
      .catch((res) => {
        // getPopupMessageBasedOnStatus(res)
      })
  }

  const TaxGroupAPI = () => {
    if (taxGroupCalled) {
      return
    }
    let api = Api
    taxGroupCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/taxgroup', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          let TaxGroup = []
          res.data.data.forEach((item) => {
            TaxGroup.push({
              value: item.taxgroup_id,
              label: item.name,
            })
          })
          setTaxGroup(TaxGroup)
        }
      })
      .catch((res) => {
        // getPopupMessageBasedOnStatus(res)
      })
  }

  const EntityAPI = () => {
    if (entityCalled) {
      return
    }
    let api = Api
    entityCalled = true
    api.setUserToken()
    api
      .axios()
      .post(Settings.apiUrl + '/search_entity', {
        code: '',
        name: '',
      })
      .then(function (res) {
        if (res.data.status === 'success') {
          let EntityARR = []
          res.data.data.forEach((item) => {
            EntityARR.push({
              value: item.entity_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setEntities(EntityARR)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const SitesAPI = () => {
    if (sitesCalled) {
      return
    }
    let api = Api
    sitesCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/get_site')
      .then(function (res) {
        if (res.data.status === 'success') {
          let SitesARR = []
          res.data.data.forEach((item) => {
            SitesARR.push({
              value: item.site_id,
              label: `${item.code} - ${item.name}`,
            })
          })
          setSites(SitesARR)
        }
      })
      .catch((res) => {
        // getPopupMessageBasedOnStatus(res)
      })
  }
  //End

  const getItemCatalogueData = async () => { 
    let api = Api;
  
    try {
      api.setUserToken();
      const res = await api.axios().get(Settings.apiPurchaseOrderUrl + '/PA_ratecardheader/' + id);
      let numberSequenceData = null;

      try {
        numberSequenceData = await api.axios().get(Settings.apiUrl + `/ns_status/${res?.data?.data?.entity_id}/${Settings.source_id.rate_card}`);
        setNumberSequence(numberSequenceData?.data)
      } catch (error) {
        console.error('Failed to fetch number sequence data:', error);
      }
      if (res.data.status === "success") {
        setNumberSequence(numberSequenceData?.data)
        getDocumentStatusLabel(res?.data?.data?.document_status)
        setDisableFields(res?.data?.data?.document_status_name === "Draft");
        setEditItemCatalogue(res.data.data)
      }
    } catch (error) { 
    }
  };

  const parseInteger = (value) => value ? parseInt(value) : "";
  const convertDate = (date) => DatepickerFunctions.convertDateForDataBase(date);
  
  let payload = {
    description: editItemCatalogue.description || "",
    start_date: convertDate(editItemCatalogue.start_date),
    expiry_date: convertDate(editItemCatalogue.expiry_date),
    currency_id: parseInteger(editItemCatalogue.currency_id),
    document_number: numberSequence?.ns_type === 'manual' ? editItemCatalogue.document_number : null,
    taxgroup_id: parseInteger(editItemCatalogue?.taxgroup_id),
    taxgroupitem_id: parseInteger(editItemCatalogue?.taxgroupitem_id),
  };
 
  //API Executions UPDATE Requests
  const UpdateItemCatalogueHeader = async (payload,isShowResponseMessage = true) => {
    try {
      let api = Api
      api.setUserToken()
      setLoading(true)
      const res = await api.axios().put(getApiUrl(), payload, { cancelToken: cancelTokenSource.token })

      if (res.data.status === 'success') {
        if (isShowResponseMessage) {
          Helper.alert(res.data.message)
        }
        getWorkFlowCalls()
        getItemCatalogueData()
        setLoading(false)
      }
    } catch (res) {
      getPopupMessageBasedOnStatus(res)
      setLoading(false)
    }
  }

  const afterCloseTaxPopup = () => {
    setIsShowTransactionPopup(false)
  }

  const getDocumentStatusLabel = (statusId) => {
    if (editItemCatalogue?.document_status === null) return
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/enum/POHeaderDocumentStatus', {})
      .then(function (res) {
        if (res.data.status === 'success') {
          const documentStatus = res.data.data.find((item) => item.id === statusId)
          setDocumentStatusLabel(documentStatus?.name)
        }
      })
  }

  const SaveHandler = (isShowResponseMessage = true) => {
    let validData = {
      ...editItemCatalogue,
    }

    if (!validator.isValid(validData)) {
      validator.displayMessage()
      validator.reset()
      return
    }

    Helper.createDebouncedAPIFunction([async () => await UpdateItemCatalogueHeader(payload,isShowResponseMessage)], setSaveBtnApi, cancelTokenSource)()
  }

  const SubmitWorkflow = (comment) => {
    let validData = {
      ...editItemCatalogue,
    }

    if (!validator.isValid(validData)) {
      validator.displayMessage()
      validator.reset()
      return
    }

    Helper.createDebouncedAPIFunction([async () => await UpdateItemCatalogueHeader(
      {
        document_status:1, 
        workflow_initiated: true, 
        comment: comment === '' || comment === 'execute_api' ? null : comment
      }, true
    )], setSaveBtnApi, cancelTokenSource)()
  }

  const moreOptions = () => {
    return <ul className='actions_nav'>
      {(editItemCatalogue.document_status_name === "Draft" || editItemCatalogue.document_status_name === "Under Review") ? <li className='disable'>Edit</li> : <li onClick={()=>{UpdateItemCatalogueHeader({document_status:0},true)}}>{Helper.getLabel(props.language, 'edit', "Edit")}</li>}
      {(editItemCatalogue.document_status_name === "Confirmed" || editItemCatalogue.document_status_name === "Under Review") ? <li className='disable'>Confirm</li> : <li onClick={()=>{UpdateItemCatalogueHeader({document_status:1}, true)}}>{Helper.getLabel(props.language, 'confirm', "Confirm")}</li>}
    </ul>
  }

  const handleFetchData = (response) => {
    return {
      rowData: response?.data?.data?.map((item) => ({
        ...item,
        gridId: `${Date.now()}_${getNewValue()}`,
      })),
      rowCount: response?.data?.data?.length,
    }
  }

  return (
    <>
      <div className={'container-fluid vn_edit_screen scrollBarBlinkControl document-main-screen'}>
        <div ref={myDivRef}>
          <NavigationHeder
            backUrl={props.backUrl ?? null}
            moreOptions={moreOptions}
            hideMoreBtn={props.type===0}
            title={`<span style="color:#000;">${props.title ?? Helper.getLabel(props.language, 'edit_item_catalogue', 'Edit Item Catalogue')} #</span> ${
              editItemCatalogue?.document_number ?? ''
            }`}
          >
            {props.type===0 ? <></> : <Button id='btn_master_save' className='rfq_save_btn' isActive={false} title={Helper.getLabel(props.language, 'save', 'Save')} onClick={SaveHandler} isDisable={!disableFields} />}
          </NavigationHeder>

          {isShowTransactionPopup && <TaxTransactionPopup onTaxTransactionPopupClose={afterCloseTaxPopup} line_id={null} source_id={rate_card_source_id} integrator={editItemCatalogue.purchase_order_id} />}

          <div
            style={{
              display: scrollDitection === "down" ? "block" : "none",
            }}
          >
            {props.type===0 ? <></> : editItemCatalogue.document_status_name !== "Confirmed" && !loading && 
              <WorkFlowCommonHeader
                workflowInstanceDetails={workflowExist?.instance_details}
                submitWorkflowAction={
                  !workflowExist?.instance_exist &&
                  workflowEligible &&
                  SubmitWorkflow
                }
                instance_task_id={instance_id}
                module_id={!instance_id ? rate_card_source_id : null}
                source_id={rate_card_source_id}
                source_item_id={id}
                SubmitWorkflowObjDetails={workflowExist?.workflow_name}
                module_item_id={!instance_id ? id : null}
                workflowInstanceExist={workflowExist?.instance_exist}
                workflowEligible={workflowEligible}
                workflowName={"Item Catalogue Workflow"}
                reloadFunction={() => {
                  getWorkFlowCalls();
                  getItemCatalogueData();
                }}
              />
            }
          </div>

          <FixedNavigationHeder
            addComponentTop={addComponentTop}
            sectionName={sectionName}
            navs={navs}
            scrollDitection={scrollDitection}
            selectedSupNav={selectedSupNav}
            setSelectedSupNav={setSelectedSupNav}
            superNav={superNav}
          />
        </div>

        {loading ? (
          <OverlayLoader isLogoCenter={true} />
        ) : (
          <TabContentWraper
            height={height}
            removeMargin={true}
            setScrollDitection={setScrollDitection}
            leftSideContent={
              <>
                {selectedSupNav === 'Header View' && (
                  <div className='lists_contant one' id={`${sectionName}General`} style={{ position: 'relative' }}>
                    <HeaderGeneralInformation
                      isDocumentNumberDisable={props.type===0 ? true : objectIsEmpty(numberSequence) || numberSequence?.ns_type === 'continuous' || (numberSequence?.ns_type === 'manual' && editItemCatalogue?.version === 1)}
                      document_status_label={documentStatusLabel}
                      state={editItemCatalogue}
                      setState={setEditItemCatalogue}
                      taxCode={taxCode}
                      isEditable={props.type===0 ? false : disableFields}
                      taxGroup={taxGroup}
                      type={props.type}
                      entities={entities}
                      sites={sites}
                      isDisable={props.type===0 ? false : disableFields}
                    />
                    <div style={{height:"80px"}}></div>
                  </div>
                )}
                {selectedSupNav === 'Line View' && (
                  <div className='mb-4' style={{ position: 'relative' }}>
                    <LineViewComponent
                      defaultValues={{
                        requester_id: editItemCatalogue.requester_id,
                        warehouse_id: editItemCatalogue.warehouse_id,
                        delivery_date: editItemCatalogue.delivery_date,
                        taxgroupitem_id: editItemCatalogue.taxgroupitem_id,
                        taxgroup_id: editItemCatalogue.taxgroup_id,
                        entity_id: editItemCatalogue.entity_id,
                        site_id: editItemCatalogue.site_id,
                        orderer_id: editItemCatalogue.orderer_id,
                      }}
                      source_id={props.source_id ? props.source_id : Settings.source_id.rate_card}
                      line_source={props.source_id ? props.source_id : Settings.source_id.rate_card}
                      isEditable={props.type===0 ? false : disableFields}
                      fetchData={props?.formType === 'purchase agreement' && handleFetchData}
                      lineGridTitle={props.lineTitle ? props.lineTitle : Helper.getLabel(null, 'item_catalogue_lines', 'Item Catalogue Lines')}
                      header_id={id}
                      purchase_status={editItemCatalogue.purchase_status}
                      document_status={editItemCatalogue.document_status}
                      delivery_date={editItemCatalogue.delivery_date}
                      master_save_button_id='btn_master_save'
                      segmentAccountingDate={editItemCatalogue?.accounting_date ?? new Date()}
                      onbehalf_request={false}
                      includeBudgetColumns={false}
                      hideBillingRule={true}
                      hideDistribution={true}
                      hideSetup={true}
                      hideGeneral={true}
                      importNeeded={props.type !==0}
                      exportNeeded={props.type !==0}
                      exportSampleNeeded={props.type !== 0}
                    />
                    <div style={{height:"60px"}}></div>
                  </div>
                )}
              </>
            }
            rightSideContent={addComponentRight}
          />
        )}
      </div>
      {saveBtnApi ? <OverlayLoader isLogoCenter={true} /> : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    language: state.language,
  }
}

export default connect(mapStateToProps)(MasterComponentWraper(RsDataLoader(ItemCatalogueEdit)))
