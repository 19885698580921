import DatepickerFunctions from "./DatepickerHelper";

export const transformObjectGoodsReceiving = (data, documentStatus) => {
    return data?.map((item) => {
        return {
            ...item,
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            document_status: documentStatus?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectWorkConfirmation = (data, documentStatus) => {
    return data?.map((item) => {
        return {
            ...item,
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            document_status: documentStatus?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectInvoices = (data, documentStatus) => {
    return data?.map((item) => {
        return {
            ...item,
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            invoice_date: DatepickerFunctions.convertDateFromDataBase(item.invoice_date),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            document_status: documentStatus?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectVendors = (data, vendorType) => {
    return data?.map((item) => {
        return {
            ...item,
            vendor_type: vendorType?.find((type) => type.value === item.vendor_type)?.label,
            category: item.category?.map((category) => category.name)?.join(", ")
        }
    })
};

export const transformObjectPR = (data, documentStatusList, purchaseStatusList) => {
    return data?.map((item) => {
        return {
            ...item,
            is_rfi_pr: item.is_rfi_pr ? 'Yes' : 'No',
            createddatetime: DatepickerFunctions.convertDateTimeFromDataBase(item.createddatetime),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date), 
            document_status: documentStatusList?.find((type) => type.id == item.document_status)?.name,
            requisition_status: purchaseStatusList?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectOpenPR = (data, allCurrencies, allUnits) => { 
    const getItemCellValue = (params) => {
        const { type, item_id, category_id } = params;

        switch (type) {
            case 0:
                return `Item ID - ${item_id}`;
            case 1:
                return `Category ID - ${category_id}`;
            default:
                return `Comments`;
        }
    }

    const getFormattedNameValue = (params) => {
        const { type, item_name, category_name, comments } = params;
        switch (type) {
            case 0:
                return item_name
            case 1:
                return category_name;
            default:
                return comments;
        }
    }

    return data?.map((item) => {
        return {
            ...item,
            is_rfi_pr: item.is_rfi_pr ? 'Yes' : 'No',
            unit: allUnits?.filter((val) => val.unit_id === parseInt(item.unit))[0]?.code,
            item_category_id: getItemCellValue(item),
            description: getFormattedNameValue(item),
            createddatetime: DatepickerFunctions.convertDateTimeFromDataBase(item.createddatetime),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            currency_id: allCurrencies?.filter((currency) => parseFloat(currency.currency_id) === parseFloat(item?.currency_id))[0]?.currency_code
        }
    })
};

export const transformObjectScoringTemplates = (data) => {
    return data?.map((item) => {
        return {
            ...item, 
            show_evaluation_criteria_to_bidder: item.show_evaluation_criteria_to_bidder ? "Yes" : "No"
        }
    })
};

export const transformObjectComplianceTemplates = (data, complianceHeaders) => {
    return data?.map((item) => {
        return {
            ...item,  
            complianceheader_id: complianceHeaders?.find((val) => val.value == (item.complianceheader_id))?.label
        }
    })
};

export const transformObjectTermsConditions = (data) => {
    return data?.map(({ all_content, ...rest }) => {
        return {
            ...rest,
            document_type: rest?.document_type?.join(", ")
        };
    });
};

export const transformObjectRFQExternal = (data, rfqQuotationType, rfqStatusList) => {
    return data?.map((item) => {
        return {
            ...item,
            onhold: item.onhold ? 'Yes' : 'No',
            vendor_details: item.vendor_details ? 'Yes' : 'No',
            interested_to_participate: item.interested_to_participate ? 'Yes' : 'No',
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            quotation_type: rfqQuotationType?.find((type) => type.id == item.quotation_type)?.name,
            vendorreplystatus_external: rfqStatusList?.find((type) => type.id == item.vendorreplystatus_external)?.name,
            publish_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.publish_datetime),
            expiry_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.expiry_datetime)
        }
    })
};

export const transformObjectContractExternal = (data, purchaseStatuses, documentStatuses) => {
    return data?.map((item) => {
        return {
            ...item,
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            purchase_status: purchaseStatuses?.find((type) => type.id == item.purchase_status)?.name,
            document_status: documentStatuses?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectPuchaseOrderExternal = (data, purchaseStatuses, documentStatuses, allSites) => {
    return data?.map((item) => {
        return {
            ...item,
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            site_id: allSites?.find((type) => type.site_id == item.site_id)?.name,
            purchase_status: purchaseStatuses?.find((type) => type.id == item.purchase_status)?.name,
            document_status: documentStatuses?.find((type) => type.id == item.document_status)?.name
        }
    })
};
  
export const transformObjectPurchaseAgreementExternal = (data, purchaseStatuses, documentStatuses) => {
    return data?.map((item) => {
        return {
            ...item,
            start_date: DatepickerFunctions.convertDateFromDataBase(item.start_date),
            expiry_date: DatepickerFunctions.convertDateFromDataBase(item.expiry_date),
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            purchase_status: purchaseStatuses?.find((type) => type.id == item.purchase_status)?.name,
            document_status: documentStatuses?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectReleaseOrderExternal = (data, purchaseStatuses, documentStatuses) => {
    return data?.map((item) => {
        return {
            ...item, 
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date),
            delivery_date: DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            purchase_status: purchaseStatuses?.find((type) => type.id == item.purchase_status)?.name,
            document_status: documentStatuses?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectReturnOrder = (data, shDocumentStatus) => {
    return data?.map((item) => {
        return {
            ...item, 
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date), 
            document_status: shDocumentStatus?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectPOGRN = (data, shDocumentStatus) => {
    return data?.map((item) => {
        return {
            ...item, 
            accounting_date: DatepickerFunctions.convertDateFromDataBase(item.accounting_date),
            document_date: DatepickerFunctions.convertDateFromDataBase(item.document_date), 
            document_status: shDocumentStatus?.find((type) => type.id == item.document_status)?.name
        }
    })
};

export const transformObjectAmendmentRequest = (data, allDocumentStatus) => {
    return data?.map((item) => {
        return {
            ...item,  
            documentstatus: allDocumentStatus?.find((type) => type.id == item.documentstatus)?.name
        }
    })
};

export const transformObjectAddress = (data, purposeList) => { 
    return data?.address?.map((item) => {
        return {
            ...item,  
            purpose: purposeList?.find((type) => type.id == item.purpose)?.name,
            is_primary: item.is_primary ? "Yes" : "No"
        }
    })
};

export const transformObjectContact = (data, ctTypesList) => { 
    return data?.contact?.map((item) => {
        return {
            ...item,  
            type: ctTypesList?.find((type) => type.value == item.type)?.label,
            is_primary: item.is_primary ? "Yes" : "No",
            is_active: item.is_active ? "Active" : "In-Active",
            mobile: item.mobile ? "Yes" : "No"
        }
    })
};

export const transformObjectVendorsRFQ = (data, vendorReplyHeaderStatus) => { 
    return data?.map((item) => {
        return {
            ...item,
            vendorsite_id: item.vendorsite_name,
            expiry_datetime: DatepickerFunctions.convertDateTimeFromDataBase(item.expiry_datetime),
            vendor_replystatus: vendorReplyHeaderStatus?.find((type) => type.id == item.vendor_replystatus)?.name?.replace(/_/g," ")
        }
    })
};

export const transformObjectCollaborationTeam = (data, accessLevel) => { 
    return data?.map((item) => {
        return {
            ...item, 
            employee_id: item.employee_name,
            teamheader_id: item.teamheader_name,
            dynamicteamheader_id: Object.values(item.dynamicteamheader_names)?.join(", "),
            access_level: accessLevel?.find((type) => type.id == item.access_level)?.name,
            PR_view_access: item.PR_view_access ? "Yes" : "No",
            RFP_view_access: item.RFP_view_access ? "Yes" : "No",
            perform_RFQ_technical_evaluation: item.perform_RFQ_technical_evaluation ? "Yes" : "No",
            perform_RFQ_financial_evaluation: item.perform_RFQ_financial_evaluation ? "Yes" : "No",
            allowed_to_award: item.allowed_to_award ? "Yes" : "No",
            allowed_to_unsealed_technical_proposal: item.allowed_to_unsealed_technical_proposal ? "Yes" : "No",
            allowed_to_unsealed_commercial_proposal: item.allowed_to_unsealed_commercial_proposal ? "Yes" : "No",
            allow_check_fund: item.allow_check_fund ? "Yes" : "No"
        }
    })
};

export const transformObjectLines = (data, poLineTypesList, commitmentLevelList, prList, agreementLinesList, unitsList, taxGroupItems, taxGroup, reportTypeOptions, itemGroupList, entitiesList, linestatusList) => {
    return data?.map((item) => {
        return {
            ...item, 
            start_date: DatepickerFunctions.convertDateFromDataBase(item.start_date), 
            end_date: DatepickerFunctions.convertDateFromDataBase(item.end_date),
            delivery_date:  DatepickerFunctions.convertDateFromDataBase(item.delivery_date),
            line_type: poLineTypesList?.find(val => val.value == item.line_type)?.label,
            commitment_level: commitmentLevelList?.find(val => val.value == item.commitment_level)?.label,
            purchaserequisition_id: prList?.find(val => val.value == item.purchaserequisition_id)?.label,
            agreementline_id: agreementLinesList?.find(val => val.value == item.agreementline_id)?.label,
            category_id: item.category_name,
            unit: unitsList?.find(val => val.value == item.unit)?.label, 
            requester_id: item.requester_name,
            orderer_id: item.orderer_name,
            item_id: item.item_name,
            taxgroupitem_id: taxGroupItems?.find(val => val.value == item.taxgroupitem_id)?.label,
            taxgroup_id: taxGroup?.find(val => val.value == item.taxgroup_id)?.label,
            report_type: reportTypeOptions?.find(val => val.value == item.report_type)?.label,
            itemgroup_id: itemGroupList?.find(val => val.value == item.itemgroup_id)?.label,
            max_is_enforced: item.max_is_enforced ? "Yes" : "No",
            retainage_applicable: item.retainage_applicable ? "Yes" : "No",
            performance_bond_applicable: item.performance_bond_applicable ? "Yes" : "No",
            entity_id: entitiesList?.find(val => val.value == item.entity_id)?.label,
            requester_id: item.requester_name,
            site_id: item.site_name,
            warehouse_id: item.warehouse_name,
            enforce_same_category_item_release: item.enforce_same_category_item_release ? "Yes" : "No",
            line_status: linestatusList?.find(val => val.value == item.line_status)?.label,
        }
    })
};

export const transformObjectInvoiceLines = (data, lineIdOptions, lineData) => {
    return data?.map((item) => {
        return {
            ...item, 
            item_name: lineIdOptions?.find(val => val.value == item.item_name)?.label, 
            billingrule_id: lineData?.find(val => val.billingrule_id == item.billingrule_id)?.billingrule?.description
        }
    })
};

export const transformObjectScoringSections = (data, typeDropdown) => {
    return data?.map((item) => {
        return {
            ...item, 
            section_type: typeDropdown?.find(val => val.value == item.section_type)?.label 
        }
    })
};

export const transformObjectScoringCriterias = (data) => {
    return data?.map((item) => {
        return {
            ...item, 
            comments_mandatory: item.comments_mandatory ? "Yes" : "No"
        }
    })
};

export const transformObjectComplianceLines = (data) => {
    return data?.map((item) => { 
        return {
            ...item, 
            description: item.compliance_description
        }
    })
};

export const transformObjectTeamLines = (data, accessLevels) => {
    return data?.map((item) => { 
        return {
            ...item, 
            access_level: accessLevels?.find(val => val.value == item.access_level)?.label,
            sme_team_lead: item.sme_team_lead_name,
            employee_id: item.employee_name,
            from_date: DatepickerFunctions.convertDateFromDataBase(item.from_date),
            to_date: DatepickerFunctions.convertDateFromDataBase(item.to_date),
            RFP_view_access: item.RFP_view_access ? "Yes" : "No",
            PR_view_access: item.PR_view_access ? "Yes" : "No",
            allowed_to_unsealed_commercial_proposal: item.allowed_to_unsealed_commercial_proposal ? "Yes" : "No",
            allowed_to_unsealed_technical_proposal: item.allowed_to_unsealed_technical_proposal ? "Yes" : "No",
            perform_RFQ_financial_evaluation: item.perform_RFQ_financial_evaluation ? "Yes" : "No",
            perform_RFQ_technical_evaluation: item.perform_RFQ_technical_evaluation ? "Yes" : "No"
        }
    })
};

export const transformObjectItemEntity = (data, entityReason) => {
    return data?.map((item) => { 
        return {
            ...item, 
            reason_id: entityReason?.find(val => val.value == item.reason_id)?.label,
            is_active: item.is_active ? "Active" : "In-Active",
            entity_id: item.entity_name
        }
    })
};

export const transformObjectItemVendor = (data) => {
    return data?.map((item) => { 
        return {
            ...item, 
            vendor_id: item.vendor_name,
            is_preferred: item.is_preferred ? "Yes" : "No" 
        }
    })
};

export const transformObjectVendorBusinessClass = (data, vendorBusinessList) => {
    return data?.map((item) => { 
        return {
            ...item, 
            businessclassification_id: vendorBusinessList?.find(val => val.value == item.businessclassification_id)?.label,
            license_effective_date: DatepickerFunctions.convertDateFromDataBase(item.license_effective_date),
            license_expiry_date: DatepickerFunctions.convertDateFromDataBase(item.license_expiry_date),
            status: item.status ? "Active" : "In-Active"
        }
    })
};


