import React, { useCallback, useEffect, useRef, useState } from 'react';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import Helper from '../../../inc/Helper';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Button from '../../inc/Button';
import SimpleLoading from '../../Loading/SimpleLoading';
import PoTopBlueShortDetails from '../Widget/PoTopBlueShortDetails';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../inc/Validation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AgGridNew from '../../grid/ag/ag-grid-new';
import { generateBaseUrl, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions';
import ButtonNew from '../../inc/ButtonNew';
import { transformObjectAmendmentRequest } from '../../../inc/TransformerFunctions';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';

let gridObj = null

const AmendmentRequestList = (props) => {
    const [isCreatingAmendment, setIsCreatingAmendment] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false) 
    const totalRecords = useRef(0)
    const [amendmentHeader, setAmendmentHeader] = useState(null);
    const [allDocumentStatus, setAllDocumentStatus] = useState([]);

    let isLoadedHeader = false

    const navigate = useNavigate()
    const location = useLocation()

    const {header_id} = useParams()

    const loadDocumentStatus = () => {
        let api = Api;
        api.setUserToken();
        api.axios().get(Settings.apiUrl + '/enum/POAmendment_DocumentStatus').then(res => {
            setAllDocumentStatus(res.data.data);
        }).catch(error => {
            setAllDocumentStatus([]);
        });
    };

    const loadHeader = () => {
        if (!header_id || isLoadedHeader) {
            return;
        }
        let api = Api;
        api.setUserToken();
        setIsLoading(true);
        api.axios().get(Settings.apiPurchaseOrderUrl + `/PO_header/${header_id}`).then(res => {
            isLoadedHeader = true;
            setIsLoading(false)
            setAmendmentHeader(res.data.data);
        }).catch(error => {
            setAmendmentHeader(null);
            setIsLoading(false);
        });
    };

    const createAmendment = () => {
        if (!amendmentHeader) {
            return;
        }
        let api = Api;
        api.setUserToken();
        let data = {
            purchaseorder_id: amendmentHeader?.purchase_order_id,
            PO_headerversionnumber: amendmentHeader?.version,
            amendment_description: null
        };
        setIsCreatingAmendment(true);
        api.axios().post(Settings.apiPurchaseOrderUrl + '/amendment', data).then(res => {
            Helper.alert(res.data.message);
            navigate((props.editUrl ? props.editUrl + '/' : "/amendment-request/edit/") + res.data.amendment_id);
        }).catch(error => {
            getPopupMessageBasedOnStatus(error);
            setIsCreatingAmendment(false);
        });
    };

    const isNewAmendmentAllowed = () => {
        if (!location?.state) {
          return true; 
        }
    
        const purchaseStatus = location.state.purchase_status?.name?.toLowerCase();
        const documentStatus = location.state.document_status?.toLowerCase();
      
        if (purchaseStatus === 'open' && documentStatus === 'draft' || purchaseStatus === 'reopen' && documentStatus==='draft' || purchaseStatus === 'withdraw freeze' && documentStatus==='draft') {
          Helper.alert(`New Amendment Request creation is not allowed because your ${location.state.document_name} document is in draft state.`, "error");
          return false; 
        } else if (purchaseStatus === 'close' || purchaseStatus === 'hold' || purchaseStatus === 'canceled' || purchaseStatus === 'freeze' || purchaseStatus === 'amendment in process') {
          Helper.alert(`New Amendment Request creation is not allowed because your ${location.state.document_name} document is ${purchaseStatus.replace("/_/g","_")}.`, "error");
          return false;
        }
        return true; 
      };

    const handleNewAmendment = () => {
        if(isNewAmendmentAllowed()){
            createAmendment()
        }
    }

    const onDeleteHandler = useCallback(async (element) => { 
        let api = Api;
        api.setUserToken();
        
        let delete_amendment_id = element?.data?.amendment_id;
    
        try {
            const res = await api
                .axios()
                .delete(Settings.apiPurchaseOrderUrl + `/amendment/${delete_amendment_id}`)
            const rowNode = gridObj?.api?.getRowNode(delete_amendment_id)
            if (rowNode) {
                rowNode.setSelected(false)
            }
            setTimeout(() => {
                const transaction = { remove: [element.data] }
                gridObj?.api?.applyServerSideTransaction(transaction)
            }, 200) 
            gridObj?.deselectAll();
            Helper.alert(res?.data?.message, 'success')
        } catch (err) {
            getPopupMessageBasedOnStatus(err)
        }
    }, []);

    const onGridReady = (params) => {
        gridObj = params
    }

    const onCellClickHandler = (event) => {
        if (event.colDef.field === 'amendment_id') {
            navigate((props.editUrl ? props.editUrl + '/' : "/amendment-request/edit/") + event.data.amendment_id);
        }
    };

    const documentStatusCell = (params) => {
        let statusName = ""
        allDocumentStatus.forEach(docStatus => {
            if (params.value == docStatus.id) {
                statusName = docStatus.name;
            }
        });
        return statusName;
    };

    useEffect(() => {
        loadHeader();
        loadDocumentStatus();
    }, []);

    let lng = null;
    let gridHeaders = [
        { field: 'amendment_id', headerName: Helper.getLabel(lng, 'amendment_request_number', 'Amendment request number'), minWidth: 250, cellRenderer:gridLinkValueFormat},
        { field: 'description', headerName: Helper.getLabel(lng, 'description', 'Description') , minWidth:250},
        { field: 'documentstatus',headerName:Helper.getLabel(lng,'status','Status'),cellRenderer:documentStatusCell}
    ];

    const getTransformedObjForExport = (data) => transformObjectAmendmentRequest(data, allDocumentStatus);

    const exportData = async () => {
      setExportLoading(true)
  
      const apiUrl = Settings.apiPurchaseOrderUrl + `/PO_amendment/${header_id}`;
      const params = '';
  
      let pagination = true
      let defaultAdvanceFilterObj = false
      let pageSize = totalRecords.current
      
      const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
      await exportToXlsx({ url: baseUrl, columns: gridHeaders, fileName: 'amendment_requests', additionalFunction: getTransformedObjForExport })
      
      setExportLoading(false)
    }

    return (
      <div className="good_receiving_notes">
        <div className="container-fluid">
          <NavigationHeder
            backUrl={props.backUrl ? props.backUrl + "/" + header_id : null}
            title={
              props.title
                ? props.title
                : Helper.getLabel(lng, "Amendment_request", "Amendment request")
            }
            hideMoreBtn={true}
          >
            <ButtonNew
              id="create_note"
              isDisable={isCreatingAmendment}
              onClick={handleNewAmendment}
              isActive={true}
              title={Helper.getLabel(lng, "new", "New")}
            />
          </NavigationHeder>
          {exportLoading ? <OverlayLoader /> : null}
          {isLoading || isCreatingAmendment ? <SimpleLoading /> : ""}
          <PoTopBlueShortDetails poid={header_id} />
          {/* <AgGrid id="good_receiving_list" onDelete={deleteHandler} header={gridHeaders} data={dataList} viewOnly={true} hideSave={true} addBtnHide={true} onCellClicked={onCellClickHandler} /> */}
          <AgGridNew
            gridId={"amendment-request-list-grid"}
            columnDefs={gridHeaders}
            hideAddBtn={true}
            apiUrl={Settings.apiPurchaseOrderUrl + `/PO_amendment/${header_id}`}
            returnApiCondition={!header_id}
            handleDeleteSelectedRows={onDeleteHandler}
            uniqueField={"amendment_id"}
            onGridReady={onGridReady}
            onCellClicked={onCellClickHandler} 
            afterDeleteBtns={
                <div className="import_sample_template ms-auto px-2">
                    <button onClick={exportData}>
                        <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                    </button>
                </div>
            } 
            btnsWrapperStyle={`w-100 px-2`}
            fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
          />
        </div>
      </div>
    );
};

export default MasterComponentWraper(AmendmentRequestList);
