import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Helper from '../../inc/Helper';
import $ from 'jquery';
import Form_gui_ids from '../../inc/Form_gui_ids';
import ApplySecurityRoles from '../SecurityRoles/ApplySecurityRoles';
import LabelsGUI_ID from '../../inc/LabelsGUI_ID';
import Api from '../../inc/Api';
class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            menu:[]
        }
    }
    componentDidMount(){
        //Helper.makeSticky('.sidebar_section_inner')
    } 

    // {security.canView( frontendIds.appMasterSidebar.budget ) ? <li><Link to="/budget" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/budget.svg" alt="Budget"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.budget,'Budget')}</span></Link></li> : ""}
    // place the above menu when done
    
    componentDidUpdate(prevProps) {
        $('.sidebar_menu').on('a','click',function(e){
         
           
        })
    }
    menuItemClickHandler(e){
        $('.sidebar_menu li').removeClass('active');
        $(e.target).parents('li').addClass('active')
    }
    render() {
        let security = this.props.security;
        let frontendIds= Form_gui_ids;
        if(!security.canView(Form_gui_ids.appMasterSidebar.main_component_id) && !this.props.auth.user.admin_user){
            return <></>
        }
        let sidebarStyle = {};
        if( this.props.appOptions.options.isSidebarOpen){
            sidebarStyle.width = '250px';
        }else{
            sidebarStyle.width = '0';
            sidebarStyle.overflow = "hidden";
            sidebarStyle.visibility = "hidden";
        }

        const location = window.location.href;
        const match = location.match(/\/\/([a-zA-Z0-9-]+)\./);
        const subdomain = match ? match[1] : null;

        if(this.props.auth.user.usertype == 0) { sidebarStyle.background = '#29304C' } else { sidebarStyle.background = '#535E86' }

        return (
            <div className='sidebar_section' style={sidebarStyle}>
                <div className='sidebar_section_inner'>
                    <Link className='main_logo rs_sticky_tag' onClick={ e=> this.menuItemClickHandler(e) } to={!this.props.auth.user.admin_user?'/dashboard':'/lynkaz-administration'}> {this.props.auth.user.usertype !== 0 && <img src="/images/bars_logo.svg" className="me-3" alt="Logo Bars"/>} <img src="/images/logo_white.svg" alt="Logo"/> </Link>
                    <ul className='sidebar_menu'>
                        {security.canView( frontendIds.appMasterSidebar.command_center ) && !this.props.auth.user.admin_user ? <li><Link to="/dashboard" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/command.svg" alt="Command Center"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.command_center,'Command Center')}</span></Link></li> : '' }
                        {security.canView( frontendIds.appMasterSidebar.request ) && !this.props.auth.user.admin_user ? <li><Link to="/request" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/request.svg" alt="Request"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.request,'Request')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.procure ) && !this.props.auth.user.admin_user ? <li><Link to="/procure" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/procure.svg" alt="Source"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.source,'Sourcing')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.receive ) && !this.props.auth.user.admin_user ? <li><Link to="/receive" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/receive.svg" alt="Receive"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.receive,'Receiving')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.master_data ) && !this.props.auth.user.admin_user ? <li><Link to="/master-data" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/master-data.svg" alt="Master Data"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.master_data,'Master Data')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.finance ) && !this.props.auth.user.admin_user ? <li><Link to="/finance" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/finance.svg" alt="Finance"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.finance,'Finance')}</span></Link></li> : ""}
                        
                        {security.canView( frontendIds.appMasterSidebar.vendor_profile ) && !this.props.auth.user.admin_user ? <li><Link to={`/edit-vendor/${this.props.vendor.vendor?.vendor_id}`} onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/vendor_profile.svg" alt="Vendor Profile"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.vendor_profile,'Vendor Profile')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.request_for_quotation ) && !this.props.auth.user.admin_user ? <li><Link to="/rfq-vendor-reply/rfq-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/rfq.svg" alt="Request for Quotation"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.request_for_quotation,'Request for Quotation')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.request_for_information ) && !this.props.auth.user.admin_user ? <li><Link to="/rfi-vendor-reply/rfi-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/rfi.svg" alt="Request for Information"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.request_for_information,'Request for Information')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.contract ) && !this.props.auth.user.admin_user ? <li><Link to="/contract-order-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/contract_order.svg" alt="Contract"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.contract,'Contract Order')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.purchase_order ) && !this.props.auth.user.admin_user ? <li><Link to="/purchase-order-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/purchase_order.svg" alt="Purchase Order"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.purchase_order,'Purchase Order')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.purchase_agreement ) && !this.props.auth.user.admin_user ? <li><Link to="/purchase-agreement-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/blanket_agreement.svg" alt="Purchase Agreement"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.purchase_agreement,'Purchase Agreement')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.release_order ) && !this.props.auth.user.admin_user ? <li><Link to="/release-order-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/release_order.svg" alt="Release Order"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.release_order,'Release Order')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.goods_receiving ) && !this.props.auth.user.admin_user ? <li><Link to="/goods-receiving-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/goods_receiving.svg" alt="Goods Receiving"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.goods_receiving,'Goods Receiving')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.work_confirmation ) && !this.props.auth.user.admin_user ? <li><Link to="/work-confirmation-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/work_confirmation.svg" alt="Work Confirmation"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.work_confirmation,'Work Confirmation')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.invoice ) && !this.props.auth.user.admin_user ? <li><Link to="/invoice-list" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/invoice.svg" alt="Invoice"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.invoice,'Invoice')}</span></Link></li> : ""}
 
                        {security.canView( frontendIds.appMasterSidebar.workflow ) && !this.props.auth.user.admin_user && this.props.auth.user.usertype == 0 ? <li><a href={`https://workflow.lynkaz.com/?t=${Api.getUserToken()}&subdomain=${subdomain}`} target="_blank" rel="noopener noreferrer"><img src="/images/icons/workflow-icon.svg" alt="Workflow"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.workflow,'Workflow')}</span></a></li> : ""}

                        {security.canView( frontendIds.appMasterSidebar.budget ) && !this.props.auth.user.admin_user ? <li><Link to="/budget" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/budget.svg" alt="Budget"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.budget,'Budget')}</span></Link></li> : ""}
 
                        {security.canView( frontendIds.appMasterSidebar.reports ) && !this.props.auth.user.admin_user ? <li><Link to="/reports" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/reports.svg" alt="Reports"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.reports,'Reports')}</span></Link></li> : ""}
                        {this.props.auth.user?.usertype == 1 && security.canView( frontendIds.appMasterSidebar.auction ) && !this.props.auth.user.admin_user ? <li><Link to="/auction" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/administrator.svg" alt="Auction"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.auction,'Auction')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.setup ) && !this.props.auth.user.admin_user ? <li><Link to="/setup" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/setup.svg" alt="Setup"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.setup,'Setup')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.administration ) && !this.props.auth.user.admin_user ? <li><Link to="/administration" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/administrator.svg" alt="Administration"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.administration,'Administration')}</span></Link></li> : ""}
                        {security.canView( frontendIds.appMasterSidebar.questionnaire ) && this.props.auth.user.usertype == 0 && !this.props.auth.user.admin_user && subdomain === 'gts-dev' ? <li><Link to="/questionnaire" onClick={ e=> this.menuItemClickHandler(e) }><img src="/images/icons/sidebar/finance.svg" alt="Questionnaire"/><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.questionnaire,'Questionnaire')}</span></Link></li> : ""}
                        {this.props.auth.user.admin_user ? <li><Link to="/lynkaz-administration"><img src="/images/icons/sidebar/administrator.svg" alt="Administration" /><span>{Helper.getLabel(this.props.language,LabelsGUI_ID.sidepanel.administration,'Administration')}</span></Link></li> : ""}
                    </ul>
                </div>
                
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth:state.auth,
        appOptions:state.options,
        language:state.language,
        vendor:state.vendor,
    }
}
const SecurityOptions = {
    gui_id:Form_gui_ids.appMasterSidebar.main_component_id
}
export default connect(mapStateToProps) ( ApplySecurityRoles( Sidebar ,SecurityOptions) );
