import React, { Component } from "react";
import { Alert } from "react-bootstrap";

import Helper from "../../../inc/Helper";
import DeleteGrey from "../../../assets/delete-grey.svg";
import WishlistIconActive from "../../../assets/wishlist-icon-active.svg";
import ShoppingItemCard from "../common/ShoppingItemCard";
import CheckDeleteActionBar from "../common/CheckDeleteActionBar";
import Settings from "../../../inc/Settings";
import Api from "../../../inc/Api";
import SimpleLoading from "../../Loading/SimpleLoading";
import { connect } from "react-redux";
import ShopSearchbar, { searchType } from "../common/ShopSearchbar";
import ModernPagination from "../common/ModernPagination";
import DropSelect from "../ShopResults/DropSelect";
import { pageSizeOptions, SearchStringType } from "../ShopResults";
import Dropdown from "../../Forms/Dropdown";

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      isWishlistLoading: false,
      wishlistItems: [],
      selectedItems: [],
      lineTypesList: [],
      resultCount: 0,
      pageSize: 10,
      pageNumber: 1,
      searchStringType: SearchStringType.name.key_name,
      wishlistSearchString: "",
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.deleteWishlistItems = this.deleteWishlistItems.bind(this);
    this.incrementCartCount = this.incrementCartCount.bind(this);
    this.decrementWishlistCount = this.decrementWishlistCount.bind(this);
    this.handleDeleteFromWishlist = this.handleDeleteFromWishlist.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.setSearchStringType = this.setSearchStringType.bind(this);
    this.updateWishlistSearchString =
      this.updateWishlistSearchString.bind(this);
  }

  componentDidMount() {
    this.getSearchResults();
    this.getLineTypesList(); 
    if (this.props.onChildRender) {
      this.props.onChildRender();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // search if page size value changes
    if (prevState.pageSize && prevState.pageSize !== this.state.pageSize) {
      this.getSearchResults();
      return;
    }

    //search if pagenumber changes
    if (
      prevState.pageNumber &&
      prevState.pageNumber !== this.state.pageNumber
    ) {
      this.getSearchResults();
    }
  }

  getLineTypesList() {
    let api = Api;
    api.setUserToken();
    let that = this;
    api
      .axios()
      .get(Settings.loginUrl + `/enum/po_lineType`)
      .then(function (res) {
        if (res.data?.status === "success") {
          that.setState({
            lineTypesList: [...res.data?.data],
          });
        }
      })
      .catch((error) => {
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  getSearchResults(pageNumber,search,filters) {
    let searchString = search ?? this.state.wishlistSearchString;
    let data = {
      filters: {
        name:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.name.key_name
            ? searchString
            : null,
        item_code:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.itemCode.key_name
            ? searchString
            : null,
        bar_code:
          searchString?.length > 0 &&
          this.state.searchStringType === SearchStringType.barCode.key_name
            ? searchString
            : null,
      },
    };
    let api = Api;
    api.setUserToken();
    let that = this;
    this.setState({ isWishlistLoading: true });
    api
      .axios()
      .post(
        Settings.apiPurchaseRequisitionUrl +
          `/get_wishlist_items/${this.state.pageNumber}/${this.state.pageSize}`,
        data
      )
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState({
            isWishlistLoading: false,
            wishlistItems: [...res.data?.data],
            resultCount: res.data?.data?.length,
          });
        }
      })
      .catch((error) => {
        that.setState({ isWishlistLoading: true });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  handleDeleteFromWishlist(id) {
    let api = Api;
    let that = this;
    api.setUserToken();
    api
      .axios()
      .delete(Settings.apiPurchaseRequisitionUrl + "/wishlist/" + id)
      .then(function (res) {
        if (res.data.status === "success") {
          that.setState((prevState) => {
            const selectedItems = prevState.selectedItems;
            const index = selectedItems.findIndex((item_id) => item_id === id);
            if (index !== -1) {
              selectedItems.splice(index, 1);
            }
            const wishlistItems = prevState.wishlistItems.filter(
              (item) => item.item_id !== id
            );
            return {
              selectedItems,
              wishlistItems,
            };
          });
        }
      })
      .catch((error) => {
        that.setState({ isWishlistLoading: false });
        Helper.alert(error?.response?.data?.message, "error");
      });
  }

  deleteWishlistItems() {
    if (this.state.selectedItems?.length === 0) return;
    let api = Api;
    let that = this;
    api.setUserToken();
    this.setState({ isWishlistLoading: true });
    const newSelectedItems = [...this.state.selectedItems];
    this.state.selectedItems.forEach((id, index) => {
      api
        .axios()
        .delete(Settings.apiPurchaseRequisitionUrl + "/wishlist/" + id)
        .then(function (res) {
          if (res.data.status === "success") {
            const index = newSelectedItems.indexOf(id);
            newSelectedItems.splice(index, 1);
          }
          that.decrementWishlistCount();
          if (that.state.selectedItems.length - 1 === index) {
            that.setState({
              isWishlistLoading: false,
              selectedItems: newSelectedItems,
            });
            that.getSearchResults();
          }
        })
        .catch((error) => {
          that.setState({ isWishlistLoading: false });
          Helper.alert(error?.response?.data?.message, "error");
        });
    });
  }

  checkAllWishItemsSelected(selectedIds) {
    return this.state.wishlistItems?.every((cartItem) => {
      return selectedIds.includes(cartItem.item_id);
    });
  }

  handleSelect(id) {
    const selectedItems = [...this.state.selectedItems];
    const existingIndex = selectedItems.indexOf(id);
    if (existingIndex === -1) {
      selectedItems.push(id);
    } else {
      selectedItems.splice(existingIndex, 1);
    }
    const allWishItemsSelected = this.checkAllWishItemsSelected(selectedItems);

    this.setState({ selectedItems, selectAll: allWishItemsSelected });
  }

  toggleSelectAll() {
    this.setState((prevState) => {
      const selectedItems = [...prevState.selectedItems];
      const wishlistItems = [...prevState.wishlistItems];

      const selectAll = !prevState.selectAll;

      if (selectAll) {
        wishlistItems.forEach((item) => {
          if (!selectedItems.includes(item.item_id)) {
            selectedItems.push(item.item_id);
          }
        });
      } else {
        selectedItems.length = 0;
      }

      this.setState({ selectedItems, selectAll });
    });
  }

  incrementCartCount() {
    this.props.changeCartCount(this.props.cartCount + 1);
  }

  decrementWishlistCount() {
    this.props.changeWishlistCount(
      this.props.wishlistCount >= 1
        ? this.props.wishlistCount - 1
        : this.props.wishlistCount
    );
  }

  handlePageChange(pageNumber) {
    this.setState({ pageNumber });
  }

  handlePageSizeChange(newPageSize) {
    this.setState({ pageSize: parseInt(newPageSize) });
    this.setState({ pageNumber: 1 });
  }

  setSearchStringType(val) {
    this.setState({ searchStringType: val.type });
  }

  updateWishlistSearchString(val) {
    this.setState({ wishlistSearchString: val });
  }

  render() {
    return (
      <>
        <ShopSearchbar
          handleSearch={this.getSearchResults}
          searchString={this.state.wishlistSearchString}
          searchType={searchType.wishlist}
          updateWishlistSearchString={this.updateWishlistSearchString}
          searchStringType={this.state.searchStringType}
          setSearchStringType={this.setSearchStringType}
        />
        <div className="pr-shop">
          {this.state.isWishlistLoading ? (
            <div style={{ margin: "20px auto" }}>
              <SimpleLoading />
            </div>
          ) : (
            <div className="pr-shop-main">
              <CheckDeleteActionBar
                icon={WishlistIconActive}
                title="Wishlist"
                itemCount={this.state.resultCount}
                selectAll={this.state.selectAll}
                toggleSelectAll={this.toggleSelectAll}
                deleteAll={this.deleteWishlistItems}
              />
              <div className="pr-shop-main-additional">
                <div className="pr-shop-main-additional-count">
                  {(this.state.pageNumber - 1) * this.state.pageSize + 1}-
                  {this.state.pageNumber * this.state.pageSize <
                  this.state.resultCount
                    ? this.state.pageNumber * this.state.pageSize
                    : this.state.resultCount}{" "}
                  of over {this.state.resultCount} results
                </div>
                <div className="d-flex align-items-center gap-3">
             <p className="fw-bold mb-0">Page size</p>
             <div className='rs_ppp_select pagination-select'>
                <Dropdown 
                  disableClear={true} 
                  id={`pagination-dropdown-${this.props.id}`} 
                  value={this.state.pageSize}
                  onChange={this.handlePageSizeChange} 
                  options={pageSizeOptions}
                />
             </div>
          </div>
              </div>
              <div className="pr-shop-main-itemgrid">
                {this.state.wishlistItems.map((item) => {
                  return (
                    <ShoppingItemCard
                      key={item.item_id}
                      showCheckbox={true}
                      item={item}
                      handleSelect={this.handleSelect}
                      incrementCartCount={this.incrementCartCount}
                      selectedItems={this.state.selectedItems}
                      lineTypes={this.state.lineTypesList}
                      decrementWishlistCount={this.decrementWishlistCount}
                      handleDeleteFromWishlist={this.handleDeleteFromWishlist}
                    />
                  );
                })}
              </div>
              <div className="modern-pagination-wrapper">
                {this.props.wishlistCount > this.state.pageSize ? (
                  <ModernPagination
                    currentPage={this.state.pageNumber}
                    totalPages={this.props.wishlistCount}
                    pageSize={this.state.pageSize}
                    onPageChange={this.handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    wishlistCount: state.prShop.wishlistCount,
    cartCount: state.prShop.cartCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCartCount: (payload) =>
      dispatch({ type: "CHANGE_CART_COUNT", payload }),
    changeWishlistCount: (payload) =>
      dispatch({ type: "CHANGE_WISHLIST_COUNT", payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
