import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import '../../Purchase Order/ExtPO.css'
import { useLocation, useNavigate } from 'react-router-dom'
import Helper from '../../../../inc/Helper'
import SimpleLoading from '../../../Loading/SimpleLoading'
import NavigationHeder from '../../../Navigations/NavigationHeder'
import WorkConfirmationApiCalls from '../../Work Confirmation/CreateWorkConfirmation/NewWorkConfirmation/WorkConfirmationApiCalls'
import MasterComonent from '../../../Backend/MasterComonent'
import ExtHeaderLine from '../../../Header/ExtHeaderLine'
import Settings from '../../../../inc/Settings'
import ButtonNew from '../../../inc/ButtonNew'
import Api from '../../../../inc/Api'
import { exportToXlsx, getPopupMessageBasedOnStatus, handleFetchDataForListing } from '../../../../inc/Validation'
import AgGridNew from '../../../grid/ag/ag-grid-new'
import getGRNColumns from './GrnColumnsHeadings'
import DatepickerFunctions from '../../../../inc/DatepickerHelper'
import axios from 'axios'
import OverlayLoader from '../../../PurchaseRequisition/common/OverlayLoader'
import ApplySecurityRoles from '../../../SecurityRoles/ApplySecurityRoles'
import Gui_id_list from '../../../../inc/Gui_id_list'
import Alert from '../../../inc/Alert'
import { generateBaseUrl } from '../../../grid/ag/commonFunctions/GridCommonFunctions'
import { transformObjectGoodsReceiving } from '../../../../inc/TransformerFunctions'


let gridObj = null
let cancelTokenSource = axios.CancelToken.source();

const GoodsReceivingList = (props) => {
  const workConfirmationApiCalls = new WorkConfirmationApiCalls()
  const navigate = useNavigate()

  //states
  const [loading, setLoading] = useState(false)
  const totalRecords = useRef()
  const [exportLoading,setExportLoading] = useState(false)
  const [shTypes, setShTypes] = useState([])
  const [documentStatus, setDocumentStatus] = useState([])
  const [allPOHeaders, setAllPOHeaders] = useState([])
  const [defaultSettings, setDefaultSettings] = useState({});
  const [saveBtnApi,setSaveBtnApi]=useState(false)

  const location = useLocation()

  let defaultProcurementSettingsCalled = false;

  //for fetch params from route
  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search)
    return params.get(name)
  }

  const purchaseorder_id = getSearchParam('purchaseorder_id')
  const status = getSearchParam('status')
  const createdBy = getSearchParam('createby')

  const getAsyncData = async () => {
    setLoading(true)
    const shtypes = await workConfirmationApiCalls.getAllShType()
    const documentStatus = await workConfirmationApiCalls.getAllShDocumentStatus()
    const poHeaders = await workConfirmationApiCalls.getAllPOHeaderType()

    if (documentStatus) setDocumentStatus(documentStatus)
    if (poHeaders) setAllPOHeaders(poHeaders)
    if (shtypes) setShTypes(shtypes)
    if (documentStatus && poHeaders) setLoading(false)
  }

  const getDefaultProcurementSettings = () => {
    if(defaultProcurementSettingsCalled || props.auth?.user?.usertype === 0) return;
    defaultProcurementSettingsCalled = true;

    let api = Api 
    api.setUserToken()
    api.axios().get(Settings.apiProcurementSettingsUrl+`/get_default_procurmentsetting`,{}).then(function(res){
      if(res.data.status==="success"){  
        setDefaultSettings(res?.data?.data);
      }
    })
  }

  const getAPIUrl = () => { 
    const shtype = shTypes?.find((item) => item.name === 'Goods Receiving'); 
    let url = Settings.apiPurchaseOrderUrl + `/sh_headers/${shtype?.id}`;
    return url
  };

  const getAdditionalParams = () => {
    let addParams 
    if(status){
      addParams = `&status=${status}`
    }
    if (purchaseorder_id) {
      addParams = `&purchaseorder_id=${purchaseorder_id}`
    }
    if(createdBy){
      addParams = `&createdby=${createdBy}`
    } 
    return addParams
  }

  //for fetch data
  useEffect(() => {
    getAsyncData() 
    getDefaultProcurementSettings()
  }, [location?.search])

  //function for on cell click
  const cellHandler = (event) => { 
    if (event.colDef.field === 'receiving_reference_number') {
      purchaseorder_id
        ? navigate(
            `/goods-receiving-details?shipment_id=${event.data?.shipment_id}&purchaseorder_id=${purchaseorder_id}`,
            {state:{document_number:location?.state?.document_number, type:location?.state?.type}}
          )
        : navigate(
            `/goods-receiving-details?shipment_id=${event.data?.shipment_id}`,
          )
    }
    if (event.colDef.field === 'document_number' && !purchaseorder_id) {
      let backUrlState;

      if(status){
        backUrlState = `/goods-receiving-list?status=${status}`;
      } else if(createdBy){
        backUrlState = `/goods-receiving-list?createby=${createdBy}`;
      } else {
        backUrlState = `/goods-receiving-list`;
      }

      const rowType = allPOHeaders?.find(
        (option) => option.id === event.data?.purchaseorder_type,
      ) 
      if(props?.auth?.user?.usertype===0){
        if(rowType?.name === 'Purchase Order'){
          navigate(`/purchase-order/edit/${event.data?.purchaseorder_id}`,{state:{isPrevPath:true,prevRoutePath:backUrlState}})
        } 
        if(rowType?.name === 'Release Order'){
          navigate(`/release-order/edit/${event.data?.purchaseorder_id}`,{state:{isPrevPath:true,prevRoutePath:backUrlState}})
        }
        if(rowType?.name === 'Contract Order'){
          navigate(`/contract-order/edit/${event.data?.purchaseorder_id}`,{state:{isPrevPath:true,prevRoutePath:backUrlState}})
        }
        return;
      }
      rowType?.name === 'Purchase Order'
        ? navigate(
            `/purchase-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&goods_receiving_no=${event.data?.receiving_reference_number}`,
          )
        : rowType?.name === 'Release Order' ? navigate(
            `/release-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&goods_receiving_no=${event.data?.receiving_reference_number}`,
          )
        : rowType?.name === 'Contract Order' ? navigate(
            `/contract-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&goods_receiving_no=${event.data?.receiving_reference_number}`
          )
        : navigate(
          `/purchase-order-details?&purchase_order_id=${event.data?.purchaseorder_id}&goods_receiving_no=${event.data?.receiving_reference_number}`
        )
    }    
    if (event.colDef.field === 'vendor_code') {
      navigate(
        `/edit-vendor/${event?.data?.vendor_id}`
      )
    }
    if(event.colDef.field === 'vendor_code') { 
      navigate(`/edit-vendor/${event.data.vendor_id}`)
    }
  }

  const onShipmentHeaderDelete  = useCallback(async (element,index,lastIndex) => { 
    let api = Api;
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiPurchaseOrderUrl +
          `/sh_header/${element?.data?.shipment_id}`)
      const rowNode = gridObj?.api?.getRowNode(element.data?.shipment_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridObj?.api?.applyServerSideTransaction(transaction)
      }, 200)
      if(index === lastIndex) {
        gridObj?.api?.refreshServerSide({purge:true})
      }
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])

  const createShFromPoId = async () => {
    if (!purchaseorder_id) return;
    const api = Api;
    api.setUserToken();
    try {
        const res = await api.axios().get(`${Settings.apiPurchaseOrderUrl}/PO_header/${purchaseorder_id}`, {});
        let newData = {
            ...res.data.data,
            accounting_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
            document_date: DatepickerFunctions.convertDateForDataBase(DatepickerFunctions.convertDateFromDataBase(new Date())),
        };   
        const resSh = await api.axios().post(`${Settings.apiPurchaseOrderUrl}/sh_headers/${Settings.shType.po}`, newData,{ cancelToken: cancelTokenSource.token });
        Helper.alert(resSh.data.message);
        navigate((props.editUrl ? props.editUrl + '/' : "/good-receiving/edit/") + resSh.data.shipment_id);
    }catch(error) {
        getPopupMessageBasedOnStatus(error);
    } 
};

const postGoodReceiving = () => {
    Helper.createDebouncedAPIFunction([
        async () => await createShFromPoId()
    ], setSaveBtnApi, cancelTokenSource,false,500000)(); 
}

const onGRNNewClick = () => {
  if(purchaseorder_id){
    postGoodReceiving()
  }else{
      navigate('/goods-receiving/new')
  }
}

  const handleGridReady= (params) => {
    gridObj = params
 }
  
  const Columns_Headings = getGRNColumns({
    language:props.language,
    documentStatus:documentStatus,
    applyLinkToDocumentNumberCol: !purchaseorder_id
  })

  const typeTitle = () => {
    if(parseInt(status)===0){
      return "Draft"
    }else if(parseInt(status)===1){
      return "Posted"
    }
    else if(createdBy){
      return "Created by Me"
    } else {
      return "All"
    }
  }

  let url = getAPIUrl()
  let additionalParams = getAdditionalParams()
  let isGRNAllowed = props.auth?.user?.usertype === 0 || (props?.auth?.user?.usertype === 1 && defaultSettings?.vendor_allowed_to_create_goods_receiving)

  let documentName = location?.state?.type==="release order" ? "Release" : "Purchase"

  const renderGuiList = () => { 
    if(props?.auth?.user?.usertype === 0){
      return {
        screen: Gui_id_list.receiving.goods_receiving.goods_receiving_main,
        addBtn: Gui_id_list.receiving.goods_receiving.goods_receiving_main_create_button,
        deleteBtn: Gui_id_list.receiving.goods_receiving.goods_receiving_main_delete_button,
      }
    } else {
      return ''
    }
  };

  let security = props?.security;

  if (renderGuiList() !== "" && !security.canView(renderGuiList()?.screen)) {
    return (
      <Fragment>
        <MasterComonent>
          <Alert 
            message="You do not have any access to the following screen, please contact your administrator!" 
            type="danger" 
          />
        </MasterComonent>
      </Fragment>
    );
  }

  let GRNBackUrlRoute = location.search === '' ? props.auth?.user?.usertype === 0 ? `/receive` : `/dashboard` : (status || createdBy) ? '/receive' : -1

  const getTransformedObjForExport = (data) => transformObjectGoodsReceiving(data, documentStatus);

  const exportData = async () => {
    setExportLoading(true);

    const apiUrl = url;
    const params = additionalParams;

    let pagination = true;
    let defaultAdvanceFilterObj = false;
    let pageSize = totalRecords.current;

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
    await exportToXlsx({ url: baseUrl, columns: Columns_Headings, fileName: "goods_receiving_details", additionalFunction: getTransformedObjForExport });
    
    setExportLoading(false);
  };

  return (
    <div
      className={
        props.auth?.user?.usertype === 0
          ? 'work-confirmation-internal-screen'
          : 'purchase_orders_listing_archive external-theme'
      }
    >
      <MasterComonent>
        <div className='container-fluid'>
          <NavigationHeder
            backUrl={
              GRNBackUrlRoute
            }
            title={
              purchaseorder_id
                ? `<span style="color:#757575;">${Helper.getLabel(
                    props.language,
                    'po_listing',
                    `${documentName} Orders List > </span><span style="color:#313638"> ${documentName} Order Details : ${location?.state?.document_number || purchaseorder_id}`
                  )} > </span>  Goods Receiving List`
                : `Goods Receiving - ${typeTitle()}`
            }
            hideMoreBtn={true}
          >
            { isGRNAllowed ? (
              <ButtonNew
                onClick={onGRNNewClick}
                isDisable={renderGuiList() !== "" && !security.canCreate(renderGuiList()?.addBtn)}
                title={Helper.getLabel(props.language, 'new', 'New')}
              />
            ):null}
          </NavigationHeder>
          {loading ? (
            <SimpleLoading />
          ) : (
            <div>
              {props.auth?.user?.usertype === 1 && (
                <ExtHeaderLine
                  title={Helper.getLabel(
                    props.language,
                    'goods_receiving',
                    `Goods Receiving - ${typeTitle()}`
                  )}
                />
              )}
              <AgGridNew
                apiUrl={url}
                additionalParams={additionalParams}
                filterAdditionalParams={`&sh_type=0${additionalParams||''}`}
                pagination={true}
                columnDefs={Columns_Headings}
                onGridReady={handleGridReady}
                handleDeleteSelectedRows={isGRNAllowed && onShipmentHeaderDelete}
                onCellClicked={cellHandler}
                afterDeleteBtns={
                  <div className="import_sample_template px-2">
                    <button onClick={exportData}>
                      <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                    </button>
                  </div>
                }
                btnsWrapperStyle={`w-100 justify-content-between px-2`}
                isDisabledCheckbox={!isGRNAllowed}
                hideDeleteBtn={!isGRNAllowed || (renderGuiList() !== "" && !security.canDelete(renderGuiList()?.deleteBtn))}
                hideAddBtn={true} 
                height={500}
                uniqueField={'shipment_id'}
                fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
                gridId={`grn-listing-page`}
              />
            </div>
          )}
        </div>
      </MasterComonent>
      {exportLoading ? <OverlayLoader /> : null}
      {saveBtnApi ? <OverlayLoader isLogoCenter={true}  /> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}
 
const SecurityOptions = {
  gui_id:Gui_id_list.receiving.goods_receiving.goods_receiving_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(GoodsReceivingList, SecurityOptions))));