import React from 'react';
import RFQList from '../../RFQ Vendor Reply/RFQList/RFQList';

const RFIList = () => {
  return (
    <RFQList type={1} />
  );
}

export default RFIList;
