import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import Tabs from '../../Widgets/Tabs/Tabs'
import GeneralBasic from './GeneralBasic'
import GeneralSetup from './GeneralSetup'

const HeaderGeneralInformation = (props) => {
    let generalTabs = [
        {title:Helper.getLabel(props.language,'basic','Basic'), isActive: true}, 
        {title:Helper.getLabel(props.language,'setup','Setup')}  
    ]
  return (
    <Collapse className='mb-5 general_info' open={true} title={Helper.getLabel(props.language,'general_information',"General Information")}>
      <Tabs disableSticky={true} navs={generalTabs} showAll={false} scrollOffset="300">
        <div>
          <GeneralBasic document_status_label={props.document_status_label} isEditable={props.isEditable} taxGroups={props.taxGroup} taxCodes={props.taxCode} disableField={props.isDisable} setState={props.setState} state={props.state} isDocumentNumberDisable={props.isDocumentNumberDisable} type={props.type} />
        </div>

        <div>
          <GeneralSetup isEditable={props.isEditable} sites={props.sites} entities={props.entities}  setState={props.setState} state={props.state} />
        </div>
      </Tabs> 
    </Collapse> 
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (HeaderGeneralInformation) 