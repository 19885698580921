import React from 'react';
import POArchive from '../../components/Purchase Orders Internal/Archive PO/POArchive';
import Helper from '../../inc/Helper';
import Gui_id_list from '../../inc/Gui_id_list';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';

const PoArchivePage = (props) => {
    return (
        <POArchive 
            title={Helper.getLabel(null, 'purchase_orders_all', 'Purchase Orders - ')}
            createFormTitle={Helper.getLabel(null, 'create_purchase_order', 'Create Purchase Order')}
            editUrl="/purchase-order/edit" 
            type={"0"}
            security={props?.security}
        />
    );
};
 
const SecurityOptions = {
  gui_id: Gui_id_list.procure.purchase_orders.purchase_orders
};

export default (((ApplySecurityRoles(PoArchivePage, SecurityOptions)))); 