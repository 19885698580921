import React, {useState, useEffect, useCallback, Fragment, useRef} from 'react'
import { connect } from 'react-redux'
import Gui_id_list from '../../../inc/Gui_id_list'
import MasterComponentWraper from '../../Backend/MasterComponentWraper' 
import Input from '../../Forms/Input' 
import DropDownInput from '../../../components/Forms/Dropdown';
import '../Parameters.css'; 
import Button from '../../inc/Button'
import Collapse from '../../inc/Collapse'
import LinkBtn from '../../inc/LinkBtn'
import SimpleLoading from '../../Loading/SimpleLoading'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles'
import Tabs from '../../Widgets/Tabs/Tabs'
import { useParams } from 'react-router-dom'
import Alert from '../../inc/Alert'
import Helper from '../../../inc/Helper' 
import DateEditor from "../../grid/ag/cellEditor/CustomDatePicker";
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings' 
import { areObjectsDifferent, customDateComparator, exportToXlsx, getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import Checkbox from '../../Forms/Checkbox'
import AgGridNew from '../../grid/ag/ag-grid-new'
import SimpleDropdown from '../../grid/ag/cellEditor/SimpleDropdown'
import { generateBaseUrl, getLabelFromDropdown, getOptions, gridRFQExpireFormat, optionsKeyCreator } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'
import { transformObjectTeamLines } from '../../../inc/TransformerFunctions'

/* Employee_id, from date ( not greater than to date), access level */

let defaultTeamHeaderObj = null 
let gridApi = null
let totalDropdownPage = 1;
let totalDropdownPageEmployeeItem = 1;
let AddBtn;
let transaction;

const EditTeamHeader = (props) => {
  const {id} = useParams()
  const [loading, setLoading] = useState(true) 
  const [teamsExportLoading, setTeamsExportLoading] = useState(false)
  const [editTeamHeader, setEditTeamHeader] = useState({}) 

  const [employeeList, setEmployeeList] = useState([]) 
  const [headerTypeList, setHeaderTypeList] = useState([])
  const [accessLevels, setAccessLevels] = useState([]) 

  const [height, setHeight] = useState(0);
  const wrapperDiv = useRef();

  useEffect(() => { 
    Helper.resizeWindowHandle(wrapperDiv,setHeight,window);
  }, [wrapperDiv?.current?.clientHeight, loading]);

  useEffect(() => {
    getEmployees()
    getAccessLevelsData()
    getTeamHeaderName()
    getTeamHeaderTypeList()
  }, [])

  let employeeCalled = false 
  let teamHeaderCalled = false  
  let HeaderTypeCalled = false
  let accessLevelsCalled = false 
  
  const getEmployees = () => {
    if(employeeCalled) {
      return;
    }
    let api = Api
    employeeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiOrgUrl+'/employee_dropdown?status=true',{}).then(function(res){
      if(res.data.status==="success"){
        let employeeARR = []
        res.data.data.forEach(item => {  
        employeeARR.push({
          value: item.employee_id,
          label: item.displayname
        })
       }) 
       setEmployeeList(employeeARR)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }

  const getTeamHeaderName = () => {
    if(teamHeaderCalled) {
      return;
    }
    let api = Api
    teamHeaderCalled = true
    
    api.setUserToken()
    api.axios().get(Settings.apiUrl+`/teamheader/${id}`,{}).then(function(res){
      if(res.data.status==="success"){
        defaultTeamHeaderObj = res.data.data
        setEditTeamHeader(res.data.data)
        setLoading(false)
      } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
      setLoading(false)
    })
  }

  const getAccessLevelsData = () => {
    if(accessLevelsCalled){
      return;
    }
    let api = Api
    accessLevelsCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/lineaccess_level',{}).then(function(res){
    if(res.data.status==='success'){   
      let Updated_State = []
      res.data.data.forEach(element => {
        Updated_State.push({
          label: element.name,
          value: element.id
        })
      });
      setAccessLevels(Updated_State) 
    } 
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }
  
  const getTeamHeaderTypeList = () => {
    if(HeaderTypeCalled) {
      return;
    }
    let api = Api
    HeaderTypeCalled = true
    api.setUserToken()
    api.axios().get(Settings.apiUrl+'/enum/teamheader_type',{}).then(function(res){
      if(res.data.status==="success"){
        let HeaderType = []
        res.data.data.forEach(item => {  
          HeaderType.push({
            value: item.id,
            label: item.name
          })
        })
        setHeaderTypeList(HeaderType)
      }
    }).catch((res) => { 
      getPopupMessageBasedOnStatus(res) 
    })
  }
 
  const onInputChangeHandler = (e) => { 
    setEditTeamHeader((prevState) => {
      return {
        ...prevState,
        [e.target.name]:e.target.value
      }
    })
  }

  const dynamicTeamChangeHandler = (name, value) => { 
    if(value == "on"){
      setEditTeamHeader((prevState) => {
        return {...prevState, [name]: true }
      })
    }else {
      setEditTeamHeader((prevState) => {
        return {...prevState, [name]: false }
      })
    }
  }
 
  const handleBtn = (value,transactionData) => {
    AddBtn = value
    transaction = transactionData
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.teamLine_id) {
      updateDataTeamLines(event)
    } else {
      addNewDataTeamLines(event)
    }
  }
  
  const getPayload = (element) => {
    const payload = {
      "employee_id": parseInt(element.employee_id),
      "teamheader_id": parseInt(id),
      "from_date": DatepickerFunctions.gridDateForDatabase(element.from_date),
      "to_date": DatepickerFunctions.gridDateForDatabase(element.to_date),
      "description": element.description,
      "PR_view_access": element.PR_view_access,
      "RFP_view_access": element.RFP_view_access,
      "allowed_to_unsealed_commercial_proposal": element.allowed_to_unsealed_commercial_proposal,
      "allowed_to_unsealed_technical_proposal": element.allowed_to_unsealed_technical_proposal,
      "perform_RFQ_financial_evaluation": element.perform_RFQ_financial_evaluation,
      "perform_RFQ_technical_evaluation": element.perform_RFQ_technical_evaluation,
      "sme_team_lead": parseInt(element.sme_team_lead),
      "access_level": parseInt(element.access_level)
    }
    return payload
  }

  const addNewDataTeamLines = async (event) => {  
    try {
      let api = Api
      api.setUserToken()
      const teamHeaderResponse = await api
        .axios()
        .post(Settings.apiUrl+'/teamlines', getPayload(event?.data))
      setTimeout(() => {
        gridApi?.api?.refreshServerSide({ purge: true });
        gridApi?.api?.deselectAll();
      }, 100)
      setTimeout(()=>{
        if(AddBtn){
          gridApi?.api?.applyServerSideTransaction(transaction)
        }
      }, 1000)
      Helper.alert('Team header lines added successfully')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }

  const updateDataTeamLines = async (event) => {
    try {
      let api = Api
      api.setUserToken()
      const teamHeaderResponse = await api
        .axios()
        .put(Settings.apiUrl+`/teamlines/${event?.data?.teamLine_id}`, getPayload(event?.data))
      setTimeout(() => {
        gridApi?.api?.refreshServerSide({ purge: true })
        gridApi?.api?.deselectAll();
      }, 100)
      Helper.alert(teamHeaderResponse?.data?.message)
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }
 
  const onDeleteTeamHeaderLines = useCallback(async (element) => {
    let api = Api
    api.setUserToken();
    try {
      const res = await api
        .axios()
        .delete(Settings.apiUrl+`/teamlines/${element?.data?.teamLine_id}`)
      const rowNode = gridApi?.api?.getRowNode(element?.data?.teamLine_id)
      if (rowNode) {
        rowNode.setSelected(false)
      }
      setTimeout(() => {
        const transaction = { remove: [element.data] }
        gridApi?.api?.applyServerSideTransaction(transaction)
      }, 200)
      gridApi?.api?.refreshServerSide({ purge: true })
      gridApi?.api?.deselectAll();
      Helper.alert(res?.data?.message, 'success')
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
    }
  }, [])
 
  const SaveHandler = () => {
    if(!areObjectsDifferent(defaultTeamHeaderObj, editTeamHeader)){
      return;
    }
    if(!editTeamHeader.name){
      Helper.alert('Name field missing!', 'error')
      return;
    }
    if(editTeamHeader.type===null || editTeamHeader.type===undefined){
      Helper.alert('Type field missing!', 'error')
      return;
    };

    let payload = {
      "name": editTeamHeader.name !== "" ? editTeamHeader.name : null,
      "type": parseInt(editTeamHeader.type),
      "is_dynamic_team": editTeamHeader.is_dynamic_team,
      "vendor_notification": editTeamHeader.vendor_notification,
      "sme_team": editTeamHeader.sme_team,
    }

    if(editTeamHeader?.is_dynamic_team){
      payload = {
        ...payload,
        perform_RFQ_technical_evaluation: editTeamHeader?.perform_RFQ_technical_evaluation,
        perform_RFQ_financial_evaluation:editTeamHeader?.perform_RFQ_financial_evaluation,
        // allowed_to_award:editTeamHeader?.allowed_to_award,
        allowed_to_unsealed_technical_proposal:editTeamHeader?.allowed_to_unsealed_technical_proposal,
        allowed_to_unsealed_commercial_proposal:editTeamHeader?.allowed_to_unsealed_commercial_proposal,
        add_buyer:editTeamHeader?.add_buyer,
        add_requester:editTeamHeader?.add_requester,
        add_sme:editTeamHeader?.add_sme
      }
    }

    setLoading(true);
    let api = Api
    api.setUserToken()  
    api.axios().put(Settings.apiUrl+`/teamheader/${id}`,payload).then(function(res){
      if(res.data.status==="success"){ 
        Helper.alert(res?.data?.message)
        getTeamHeaderName()
      }
    }).catch((res) => {  
      getPopupMessageBasedOnStatus(res)  
      setLoading(false);
    })
  }

  let security = props.security;
  let frontendIds= Gui_id_list;
  if(!security.canView(Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_edit_screen)){
    return <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
  }

  /* Grid Column Headers */

  let select2SettingsEmployee = {
    ajax: {
      url: (params) =>{
        totalDropdownPageEmployeeItem = params.page || 1;
        return `${Settings.apiOrgUrl}/employee_dropdown?status=true`;
      },
      processResults: function (data) {
        let isMorePageExist = ( data.total_records - ( totalDropdownPageEmployeeItem * Settings.dropdownPageSize )) > 0 ? true : false ; 
        let result = data?.data?.map( item => {
          return {
            id: item.employee_id,
            text: item.displayname
          }
        })
        return {
          results: result,
          pagination: {
            more: isMorePageExist
          }
        };
      }
    }
  };

  const teamHeaderLinesCols = [
    {
      field: 'employee_id',
      headerName: 'Employee*',
      editable: (event) => !event?.data?.teamLine_id,
      cellEditor: SimpleDropdown,
      minWidth: 230,
      isAllowZero:true, 
      valueGetter: (params) => {
        if(!params?.data?.employee_name || !params?.data){
          return '-'
        }
        else {
          return params?.data?.employee_name
        }
      },
      select2Settings: select2SettingsEmployee,
      cellEditorParams: {
        values: employeeList
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, employeeList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    }, 
    {
      field: 'from_date',
      headerName: 'From date',
      filter:'agDateColumnFilter', 
      editable: true,
      valueFormatter: (params) => { 
       return params.value
          ? DatepickerFunctions.convertDateFromDataBase(params.value)
          : '-'
      }, 
      cellEditor: DateEditor,
      minWidth: 230,
      comparator: customDateComparator,
    },
    {
      field: 'to_date',
      headerName: 'To date',
      filter:'agDateColumnFilter', 
      editable: true,
      cellRenderer: (params) => gridRFQExpireFormat(params, false),
      cellEditor: DateEditor,
      minWidth: 230,
      comparator: customDateComparator,
    },
    {
      field: 'access_level',
      headerName: 'Access level*',
      editable: true, 
      cellEditor: SimpleDropdown,
      minWidth: 270,
      isAllowZero:true, 
      cellEditorParams: {
        values: accessLevels
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, accessLevels),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    },
    {   
      field:'RFP_view_access', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'RFP view access', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      editable: true,
      minWidth: 230,
      valueGetter: (params) => {
        if(!params?.data?.RFP_view_access || !params?.data){
          return false
        }
        else {
          return params.data?.RFP_view_access
        }
      }
    },
    {   
      field:'PR_view_access', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'PR view access', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      minWidth: 230,
      editable: true,
      valueGetter: (params) => {
        if(!params?.data?.PR_view_access || !params?.data){
          return false
        }
        else {
          return params.data?.PR_view_access
        }
      }
    },
    {   
      field:'allowed_to_unsealed_commercial_proposal', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'Allowed to unsealed commercial proposal', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      editable: true,
      minWidth: 230,
      valueGetter: (params) => {
        if(!params?.data?.allowed_to_unsealed_commercial_proposal || !params?.data){
          return false
        }
        else {
          return params.data?.allowed_to_unsealed_commercial_proposal
        }
      }
    },
    {   
      field:'allowed_to_unsealed_technical_proposal', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'Allowed to unsealed technical proposal', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      editable: true,
      minWidth: 230,
      valueGetter: (params) => {
        if(!params?.data?.allowed_to_unsealed_technical_proposal || !params?.data){
          return false
        }
        else {
          return params.data?.allowed_to_unsealed_technical_proposal
        }
      }
    },
    {   
      field:'perform_RFQ_financial_evaluation', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'Perform RFQ commercial evaluation', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
      cellRendererParams: { disabled: true },
      editable: true,
      minWidth: 230,
      valueGetter: (params) => {
        if(!params?.data?.perform_RFQ_financial_evaluation || !params?.data){
          return false
        }
        else {
          return params.data?.perform_RFQ_financial_evaluation
        }
      }
    },
    {   
      field:'perform_RFQ_technical_evaluation', filter: 'agSetColumnFilter', filterParams: { values: [true, false] }, 
      headerName: 'Perform RFQ technical evaluation', 
      cellRenderer: 'agCheckboxCellRenderer',
      cellRendererParams: { disabled: true },
      cellEditor: 'agCheckboxCellEditor',
      editable: true,
      minWidth: 230,
      valueGetter: (params) => {
        if(!params?.data?.perform_RFQ_technical_evaluation || !params?.data){
          return false
        }
        else {
          return params.data?.perform_RFQ_technical_evaluation
        }
      }
    }, 
    {
      field: 'sme_team_lead',
      headerName: 'SME team lead',
      editable: true,
      cellEditor: SimpleDropdown,
      minWidth: 230,
      isAllowZero:true, 
      cellEditorParams: {
        values: employeeList
      },
      cellDataType:false,
      valueFormatter: (params)=> getLabelFromDropdown(params),
      filter:'agSetColumnFilter',
      filterParams: {
        values: (params) => getOptions(params, employeeList),
        keyCreator: (params) => optionsKeyCreator(params, 'value'),
        valueFormatter: params => params.value.label
      }
    },
    { field:'description', editable: true, filter: 'agTextColumnFilter', minWidth: 420, headerName: 'Description' }, 
  ];

  const getTransformedObjForExport = (data) => transformObjectTeamLines(data, accessLevels);

  const exportData = async () => {
    if(!id) return;

    setTeamsExportLoading(true);

    const apiUrl = id ? Settings.apiUrl+`/teamlines_header/${id}` : null;
    const params = '';
    
    let pagination = false;
    let defaultAdvanceFilterObj = false; 
    
    const baseUrl = generateBaseUrl({ gridObj: gridApi, apiUrl, params, pagination, defaultAdvanceFilterObj });
    await exportToXlsx({ url: baseUrl, columns: teamHeaderLinesCols, fileName: 'teams_details', additionalFunction: getTransformedObjForExport })
    
    setTeamsExportLoading(false);
  }

  return (    
    <Fragment> 
      {loading ? <OverlayLoader isPopupInTab={true} /> : null}
      {teamsExportLoading ? <OverlayLoader isPopupInTab={true} /> : null}

      <div className='container-fluid'> 
        <div ref={wrapperDiv}> 
          <NavigationHeder hideMoreBtn={true} title={Helper.getLabel(props.language,'edit_team',"Edit Team")}>
            <LinkBtn to={-1} isActive= {false} className='black-btn-style' title={Helper.getLabel(props.language,'close',"Close")} /> 
            <Button className="rfq_save_btn" onClick={SaveHandler} isActive={false} title={Helper.getLabel(props.language,'save',"Save")} isDisable={!security.canCreate(frontendIds.procure.purchase_orders.control_forms.team_header.team_header_edit_screen_save_button)} /> 
          </NavigationHeder>  
        </div>
 
        <div className='common_scrollbar_styles' style={{ height: `${height - 30}px`, overflowY: 'auto'}}>
          <Collapse open={true} title={Helper.getLabel(props?.language,'team_header',"Team Header")}>
            <div className='container-fluid team_header'>
              <div className='row align-items-center'> 
                <div className='col-lg-3'>
                  <Input required={true} onChange={onInputChangeHandler} value={editTeamHeader.name} label={Helper.getLabel(props.language,'name',"Name")} name='name' />
                </div> 
                <div className='col-lg-3'>
                  <DropDownInput required={true} onChange={onInputChangeHandler} value={editTeamHeader.type} options={headerTypeList} label={Helper.getLabel(props.language,'type',"Type")} id='type_id' name='type' reRenderRequired={true} />
                </div>
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language,'sme_team',"SME team")} name="sme_team" isChecked={editTeamHeader?.sme_team} type='checkbox' onChange={dynamicTeamChangeHandler} />
                </div>
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language,'dynamic',"Dynamic")} name="is_dynamic_team" isChecked={editTeamHeader?.is_dynamic_team} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={true}/>
                </div>
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language,'vendor_notification',"Vendor notification")} name="vendor_notification" isChecked={editTeamHeader?.vendor_notification} type='checkbox' onChange={dynamicTeamChangeHandler} />
                </div>
                {editTeamHeader?.is_dynamic_team && <> 
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language, 'perform_RFQ_technical_evaluation', 'Perform RFQ technical evaluation')} name="perform_RFQ_technical_evaluation" isChecked={editTeamHeader?.perform_RFQ_technical_evaluation} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language, 'perform_RFQ_financial_evaluation', 'Perform RFQ commercial evaluation')} name="perform_RFQ_financial_evaluation" isChecked={editTeamHeader?.perform_RFQ_financial_evaluation} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                {/* <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language, 'allowed_to_award', 'Allowed to award')} name="allowed_to_award" isChecked={editTeamHeader?.allowed_to_award} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div> */}
                <div className='col-lg-3 new-team-header'>
                  <Checkbox label={Helper.getLabel(props.language, 'allowed_to_unsealed_technical_proposal', 'Allowed to unsealed technical proposal')} name="allowed_to_unsealed_technical_proposal" isChecked={editTeamHeader?.allowed_to_unsealed_technical_proposal} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                <div className='col-lg-3 new-team-header mt-3'>
                  <Checkbox label={Helper.getLabel(props.language, 'allowed_to_unsealed_commercial_proposal', 'Allowed to unsealed commercial proposal')} name="allowed_to_unsealed_commercial_proposal" isChecked={editTeamHeader?.allowed_to_unsealed_commercial_proposal} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                <div className='col-lg-3 new-team-header mt-3'>
                  <Checkbox label={Helper.getLabel(props.language, 'add_buyer', 'Add buyer')} name="add_buyer" isChecked={editTeamHeader?.add_buyer} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                <div className='col-lg-3 new-team-header mt-3'>
                  <Checkbox label={Helper.getLabel(props.language, 'add_requester', 'Add requester')} name="add_requester" isChecked={editTeamHeader?.add_requester} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                <div className='col-lg-3 new-team-header mt-3'>
                  <Checkbox label={Helper.getLabel(props.language, 'add_sme', 'Add SME')} name="add_sme" isChecked={editTeamHeader?.add_sme} type='checkbox' onChange={dynamicTeamChangeHandler} disableWithoutIcon={false} />
                </div>
                </>}
              </div>
            </div>
          </Collapse>
            
          {editTeamHeader?.is_dynamic_team ? <></> : <Collapse open={true} title={Helper.getLabel(props?.language,'team_header_lines',"Team Header Lines")}>
            <AgGridNew
              apiUrl={id ? Settings.apiUrl+`/teamlines_header/${id}` : null}
              pagination={false}
              isDeleteBtnDisable={editTeamHeader?.is_dynamic_team}
              isAddBtnDisable={editTeamHeader?.is_dynamic_team}
              columnDefs={teamHeaderLinesCols}
              onRowValueChanged={onRowValueChanged} 
              handleDeleteSelectedRows={onDeleteTeamHeaderLines} 
              onGridReady={(params) => gridApi = params}
              handleAddButton={handleBtn}
              uniqueField={`teamLine_id`}
              gridId={'teams-line-master-data'} 
              afterDeleteBtns={
                <div className="import_sample_template ms-auto px-2">
                  <button onClick={exportData}> 
                    <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                  </button>
                </div>
              }
              btnsWrapperStyle={`w-100 px-2`}
            />
          </Collapse>}
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions:state.options,
    auth:state.auth,
    language:state.language
  }
}

const SecurityOptions = {
  gui_id:Gui_id_list.procure.purchase_orders.control_forms.team_header.team_header_edit_screen
}

export default connect(mapStateToProps) (MasterComponentWraper((ApplySecurityRoles(EditTeamHeader, SecurityOptions))))