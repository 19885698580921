import DatepickerFunctions from "../../../../inc/DatepickerHelper";
import Helper from "../../../../inc/Helper";
import Settings from "../../../../inc/Settings";
import { checkIfArrayIsEmpty, isEmptyObject, isRFQExpired, objectIsEmpty } from "../../../../inc/Validation";
import { faTimes, faCheck, faExclamationTriangle, faHourglassEnd } from '@fortawesome/free-solid-svg-icons'

const getLabelFromDropdown = (params) => {
    let output = params.value;
    if( Array.isArray(params.colDef.cellEditorParams?.values)){
      params.colDef?.cellEditorParams?.values?.forEach(item => {
            if(parseInt(params.value) === parseInt(item.value)){
                output = item.label;
            }
        })
    }
    if(!params.colDef.isAllowZero){
        if(output == 0 ){
            return '';
        }
    }
    return output;
}

const gridValueFormatChip = (params, objNotation=true, objProp='') => {  
  if(checkIfArrayIsEmpty(params.value)){
    return '-';
  };
  
  let chips = params?.value?.map(item => <div className="chips-list-page">{objNotation ? item[objProp] : item}</div>)
  return <div className='d-flex gap-2 mt-1'>{chips}</div>;
};

const getOptions = (params, optionsArray) => {
  setTimeout(() => {
    params.success(optionsArray);
  }, 500);
};

const optionsKeyCreator = (params, propToTarget) => {
  return params.value[propToTarget]
};

const gridLinkValueFormat = (params, harcodeVal=null) => {
  return <div className="grid_simple_link">{ harcodeVal == null || isEmptyObject(harcodeVal) ? (params?.value ? params?.value : '-') : harcodeVal }</div>
};

const gridRFQExpireFormat = (params, dateTimeReq=true) => {
  let { value } = params;

  const isRfqExpired = isRFQExpired(value);
  return <div className={isRfqExpired ? "grid_rfq_expire_cell" : ""}>{dateTimeReq ? (DatepickerFunctions.convertDateTimeFromDataBase(value) || "-") : (DatepickerFunctions.convertDateFromDataBase(value) || "-")}</div>;
};
 
const statusLabelValueFormat = (params,label1,label2) => {
  if(params.value === true){
    return <span class="rs_active_label">{label1 ? label1 : 'Active'}</span>;
  }
  return <span class="rs_inactive_label">{label2 ? label2 : 'Inactive'}</span>;
};

const getPoColorCode = (statusId) => {
    let output = {
      bgColor:'#fff',
      textColor:'#000'
    }
    let poStatusWithColor = Settings.po_purchase_status;
    for (const key in poStatusWithColor) {
      if (Object.hasOwn(poStatusWithColor, key)) {
        const poSt = poStatusWithColor[key];
        if(statusId == poSt.id){
          output = poSt;
        }
      }
    }
    return output;
  }  

  const getDocumentStatus = (params,documentStatus) => {
    const statusName = documentStatus?.find(
      (option) => option.id === params.value,
    )
    return statusName
      ? <div><div className={`rs_ag_status_label ${Helper.getClassFromString(
          statusName?.name,
        )}`}>{statusName?.name}</div></div>
      : null
  }
  
  const purchaseStatusValueFormat = (params,statuses) => {
    let currentpurchaseStatus = statuses?.find((item) => {
      return item.id == params.data.purchase_status ? item : false
    })
    if (currentpurchaseStatus) {
      let color = getPoColorCode(currentpurchaseStatus.id)
      return <span className="rs_active_label" style={{backgroundColor:color.bgColor,color:color.textColor}}>{currentpurchaseStatus.name}</span>
    }
    return '-'
  }

  const getTextColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
      case 'price accepted':
        return '#455A64'
      case 'closed':
        return '#FC5E24'
      case 'invoiced':
        return '#2D74E0'
      case 'action required':
        return '#FDAB3D'
      case 'expired':
        return '#E94335'
      case 'draft':
        return '#607D8B'
      case 'no response':
        return '#2D74E0'
      case 'bid received':
        return '#3CBA54'
      case 'partial bid received':
        return '#3CBA54'
      case 'declined':
        return '#D32F2F'
      case 'partial declined':
        return '#B71C1C'
      case 'awarded':
        return '#1B5E20'
      case 'partial awarded':
        return '#9E9D24'
      case 'sealed':
        return '#FF6D00'
      case 'submitted':
        return '#2E7D32'
      case 'reopen':
        return '#FC5E24'
      case 'freeze':
        return '#2D74E0'
      case 'hold':
        return '#FDAB3D'
      case 'withdraw freeze':
        return '#E94335'
      case 'close':
        return '#FC5E24'
      case 'posted':
        return '#2E7D32'
      case 'approved':
        return '#2E7D32'
      case 'confirmed':
        return '#2E7D32'
      case 'under review':
        return '#1976D2'
      case 'published':
        return 'rgb(251, 192, 45)'
      case 'cancelled':
      case 'canceled':
        return '#C62828'
      case 'onhold':
        return '#F57F17'
      case 'under technical evaluation':
        return '#1976D2'
      case 'under commercial evaluation':
        return '#1976D2'
      case 'under awarding':
        return '#1565C0'
      case 'under negotiation':
        return '#EF6C00'
      case 're tender draft':
        return '#455A64'
      case 'new':
        return '#1B5E20'
      case 'partial submitted':
        return '#558B2F' 
      case 'disqualified':
        return '#3E2723'
      case 'partial disqualified':
        return '#4E342E' 
      case 'not awarded':
        return '#5D4037'
      case 'not interested':
        return '#FFA000'
      case 'interested to bid':
        return '#00ACC1' 
      case 'requested extension':
        return '#FBC02D' 
      case 'under bafo':
        return '#29B6F6'
      case 'bid return':
        return '#673AB7'
      case "send":
        return "#2D74E0";
      case "received":
        return "#3CBA54";
      case "rejected":
        return "#E94335";    
      default:
        return '#303030'
    }
  }

  const getBackgroundColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'open':
      case 'draft':
      case 'price accepted':
        return '#ECEFF1'
      case 'closed':
        return '#FC5E24'
      case 'invoiced':
        return '#2D74E0'
      case 'action required':
        return '#FDAB3D'
      case 'expired':
        return '#E94335'
      case 'no response':
        return '#2D74E010'
      case 'bid received':
        return '#3CBA5410'
      case 'partial bid received':
        return '#3CBA54'
      case 'declined':
        return '#FFCDD2'
      case 'partial declined':
        return '#EF9A9A'
      case 'awarded':
        return '#A5D6A7'
      case 'partial awarded':
        return '#E6EE9C'
      case 'sealed':
        return '#FFE0B2'
      case 'submitted':
        return '#C8E6C9'
      case 'reopen':
        return '#FC5E24'
      case 'freeze':
        return '#2D74E0'
      case 'hold':
        return '#FDAB3D'
      case 'withdraw freeze':
        return '#E94335'
      case 'close':
        return '#FC5E24'
      case 'posted':
        return '#C8E6C9'
      case 'approved':
        return '#C8E6C9'
      case 'confirmed':
        return '#C8E6C9'
      case 'under review':
        return '#E3F2FD'
      case 'published':
        return 'rgb(255, 249, 196)'
      case 'cancelled':
      case 'canceled':
        return '#FFCDD2'
      case 'onhold':
        return '#FFF9C4'
      case 'under technical evaluation':
        return '#E3F2FD'
      case 'under commercial evaluation':
        return '#E3F2FD'
      case 'under awarding':
        return '#B3E5FC'
      case 'under negotiation':
        return '#FFE0B2'
      case 're tender draft':
        return '#CFD8DC'
      case 'new':
        return '#E8F5E9' 
      case 'partial submitted':
        return '#DCEDC8' 
      case 'disqualified':
        return '#BCAAA4' 
      case 'partial disqualified':
        return '#D7CCC8' 
      case 'not awarded':
        return '#BCAAA4'
      case 'not interested':
        return '#FFECB3'
      case 'interested to bid':
        return '#E0F7FA' 
      case 'requested extension':
        return '#FFF9C4' 
      case 'under bafo':
        return '#E1F5FE'
      case 'bid return':
        return '#D1C4E9'
      case "send":
          return "#2D74E014";
      case "received":
          return "#3CBA5414";
      case "rejected":
          return "rgba(233, 67, 53, 0.06)"
      default:
        return 'rgba(48, 48, 48,0.5)'
    }
  }

  const getStatusNameById = (id, type, isNameNeeded, name,isCustomFont) => {
    const status = isNameNeeded ? name?.replace(/_/g, ' ') : type?.find((option) => option.id === id)?.name?.replace(/_/g, ' ')
    return status
      ? <div className="ag-grid-status-cell" style={{color: getTextColor(
            status,
          ),backgroundColor: `${getBackgroundColor(status)}`,fontSize:isCustomFont}} >
            {status}        
        </div>
      : null
  }
 
  const getBidStatus = (bidStatus) => {
    switch (bidStatus) {
      case null:
        return <div className="logo_inside_grid text-center"><img src="/images/icons/legal-hammer-blue.svg" alt="blue hammer" style={{height:"24px"}}/></div>
      case true:
        return <div className="logo_inside_grid text-center"><img src="/images/icons/legal-hammer-green.svg" alt="green hammer"  style={{height:"24px"}} /></div>
      case false:
        return <div className="logo_inside_grid text-center"><img src="/images/icons/legal-hammer-black.svg" alt="black hammer"  style={{height:"24px"}} /></div>
      default:
        return <div className="logo_inside_grid text-center"><img src="/images/icons/legal-hammer-blue.svg" alt="blue hammer"  style={{height:"24px"}} /></div>
    }
  }

  const getRecallBidStatus = (expiryDate) => {
    if (expiryDate && new Date(expiryDate) < new Date()) {
      return <div className="logo_inside_grid text-center"><img src="/images/icons/refresh-grey.svg" alt="refresh grey icon" style={{height:"24px"}} /></div>
    }

    return <div className="logo_inside_grid text-center"><img src="/images/icons/refresh-blue.svg" alt="" style={{height:"24px"}} /></div>
  }

  const displayViewAttachedLogo = () => {
    return <div style={{textAlign:"center", marginLeft:"-17px"}} ><img src="/images/icons/attach.svg" alt="attach image" style={{height:"24px"}} /></div>
  }

  const getSiteNameById = (params,allSites) => {
    const siteName = allSites?.find((option) => option.site_id === params.value)
    return siteName ? <div>{siteName.name}</div> : null
  }

  const getDisplayNamesFromArray = (params, array, propToTargetVal, propToTargetLabel) => {
    const targetLabel = array?.find((option) => option[propToTargetVal] === params.value) 
    return targetLabel ? <div>{targetLabel[propToTargetLabel]}</div> : '-'
  }

  let newValueCounter = 0;
  const getNewValue = () => {
    newValueCounter += 1;
    return `${newValueCounter}_${Math.floor(Math.random() * 100000) + 100}`;
  }

  const getBillingRuleDescription = (params,values) => {
    const billingRuleName = values?.find(
      (option) => option.billingrule_id === params.value,
    )
    return billingRuleName
      ? <div>{billingRuleName?.billingrule?.description || '-'}</div>
      : null
  }

  const handleAction = (params) => {
    if(params?.data?.completed || params?.data?.submitted){
        return "Submitted"
    }else if(params?.data?.recalled){
        return "Recalled"
    }else if(params?.data?.retendered){
        return "Retendered"
    }else if(params?.data?.requested_retender){
      return "Requested Retender"
    }else{
        return '-'
    }
  }
  
  const getActionStatusName = (params,actions) => {
    const action = actions.find((item)=>item.id===params.value)
    return action?.name || "-"
  }
 
  function calculateDateDifference(oldExpiry, newExpiry) {
    const oldDate = new Date(oldExpiry);
    const newDate = new Date(newExpiry);
  
    const timeDifference = newDate - oldDate;
  
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
    return {
      days,
      hours,
      minutes
    };
  }

  const createRow = (newAddItems,gridApi,source_id) => {
    let output = 1
    gridApi?.forEachNode(nodeItem => {
      let lineNumber = parseInt(source_id) === 10 ? nodeItem?.data?.linenumber : nodeItem?.data?.line_number
      if( Helper.getOnlyNumber(lineNumber) >= output ){
        output = Helper.getOnlyNumber(lineNumber)  + 1
      }
    })
    let lineNumber = parseInt(source_id) === 10 ? {linenumber:output} : {line_number:output}
    return {
      gridId: `${Date.now()}_${getNewValue()}`,
      isNew: true,
      ...lineNumber,
      ...newAddItems
    }
  }

  const getBudgetIcon = (params) => {
    if(params?.value?.toLowerCase()==="passed"){
      return faCheck
    }else if(params?.value?.toLowerCase()==="passed_with_warning"){
      return faExclamationTriangle
    }else if(params?.value?.toLowerCase()==="not_performed"){
      return faHourglassEnd
    }else{
      return faTimes
    }
  }
  
  const getBudgetColor = (params) => {
    if(params?.value?.toLowerCase()==="passed"){
      return "#3CBA54"
    }else if(params?.value?.toLowerCase()==="passed_with_warning"){
      return "#d83b01"
    }else{
      return "#D73535"
    }
  }

  

  function convertObjectsToFilters(objects, params) {
    const filters = []

    for (const key in objects) {
        const { operator, conditions, filterType, values } = objects[key];
        const { customColId } = params?.api?.getColumn(key)?.colDef;
        const objFilters = [];
        
        if (filterType && filterType === 'set') {
            objFilters.push({
                left: customColId ? customColId : key,
                right: values,
                operation: filterType
            })
            filters.push({
                conditions: 'AND', 
                filters: objFilters
            })
        } else if (filterType && filterType === 'date') {
          if (conditions && conditions.length > 0) {
              const conditionsFilters = conditions.map((condition) => {
                return {
                  left: customColId ? customColId : key,
                  right: condition.type==="inRange"?[condition.dateFrom,condition.dateTo]:condition.dateFrom,
                  operation: condition.type,
              }
              })
              objFilters.push(...conditionsFilters)
              filters.push({
                  conditions: operator,
                  filters: objFilters,
              })
          } else {
            const { type, dateFrom, dateTo } = objects[key]
            objFilters.push({
                left: customColId ? customColId : key,
                right: type==="inRange"?[dateFrom,dateTo]:dateFrom,
                operation: type,
            })
            filters.push({
                conditions: operator || 'AND',
                filters: objFilters,
            })
          }
        } else {
            if (conditions && conditions.length > 0) {
                const conditionsFilters = conditions.map((condition) => ({
                    left: customColId ? customColId : key,
                    right: condition.filter,
                    operation: condition.type,
                }))
                objFilters.push(...conditionsFilters)
                filters.push({
                    conditions: operator,
                    filters: objFilters,
                })
            } else {
                const { type, filter } = objects[key]
                objFilters.push({
                    left: customColId ? customColId : key,
                    right: filter,
                    operation: type,
                })
                filters.push({
                    conditions: operator || 'AND',
                    filters: objFilters,
                })
            }
        }
    }

    return filters
}


  const getUrl = (params, paginationUrl, queryString, sortedBy, defaultAdvanceFilterObj, customFilterModel = null, customSortModel = null) => {
    let apiBaseUrl = paginationUrl

    const filterModel = customFilterModel ?? params?.request?.filterModel
    const sortModel = customSortModel ?? params?.request?.sortModel
    if (!objectIsEmpty(filterModel) && !checkIfArrayIsEmpty(customSortModel)) {
      apiBaseUrl += `${apiBaseUrl?.includes('?') ? '&' : '?'}${queryString}&${sortedBy}`
      return apiBaseUrl
    }
    if (!checkIfArrayIsEmpty(sortModel)) {
      apiBaseUrl += `${apiBaseUrl?.includes('?') ? '&' : '?'}${sortedBy}`
    }
    if (!objectIsEmpty(filterModel)) {
      apiBaseUrl += `${apiBaseUrl?.includes('?') ? '&' : '?'}${queryString}`
    }
    if (objectIsEmpty(filterModel) && defaultAdvanceFilterObj) {
      apiBaseUrl += `${apiBaseUrl?.includes('?') ? '&' : '?'}${queryString}`
    }

    return apiBaseUrl
  }

  const generateBaseUrl = ({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj,pageSize }) => {
    const customFilterModel = gridObj?.api?.getFilterModel()
    const filterObject = convertObjectsToFilters(customFilterModel, gridObj)
    const pageNumber = 1
    const additionalParamUrl = params ? `${apiUrl}?page_number=${pageNumber}&page_size=${pageSize}${params}` : `${apiUrl}?page_number=${pageNumber}&page_size=${pageSize}`
    const paginationUrl = pagination ? additionalParamUrl : apiUrl + `${params || ''}`
    const combinedFilterObject = defaultAdvanceFilterObj ? [...filterObject, defaultAdvanceFilterObj] : filterObject
    const queryString = `adv_search=${encodeURIComponent(JSON.stringify(combinedFilterObject))}`

    const sortingArray = gridObj.api
      .getColumns()
      .filter((item) => item.sort)
      .map((item) => {
        const { customColId } = gridObj?.api?.getColumn(item?.colId)?.colDef
        return {
          colId: customColId ? customColId : item?.colId,
          sort: item.sort,
        }
      })

    const sortingQuery = `sorted_by=${JSON.stringify(sortingArray)}`
    return getUrl(params, paginationUrl, queryString, sortingQuery, defaultAdvanceFilterObj, customFilterModel, sortingArray)
  }


  
  
export { getLabelFromDropdown, gridRFQExpireFormat, statusLabelValueFormat, getOptions, gridLinkValueFormat, optionsKeyCreator, getPoColorCode, getDocumentStatus, getStatusNameById, getTextColor, getBackgroundColor, getBidStatus, getRecallBidStatus, getDisplayNamesFromArray, displayViewAttachedLogo, getSiteNameById, purchaseStatusValueFormat , getNewValue, getBillingRuleDescription , handleAction , getActionStatusName, calculateDateDifference, createRow, gridValueFormatChip, getBudgetColor, getBudgetIcon, convertObjectsToFilters, getUrl, generateBaseUrl }