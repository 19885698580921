import React from 'react';
import ApplySecurityRoles from '../../components/SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../inc/Gui_id_list';
import ItemCatalogueList from "../../components/RateCard/RateCardList/RateCardList";
import MasterComonent from '../../components/Backend/MasterComonent';
import Alert from '../../components/inc/Alert';
import { connect } from 'react-redux';

const ItemCatalogueListingScreen = (props) => {
    let security = props?.security; 

    if(!security.canView(Gui_id_list.procure.item_catalogue.item_catalogue_main) || props?.user?.is_buyer !== true){
        return <MasterComonent>
            <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
        </MasterComonent>
    };
    return (
        <ItemCatalogueList 
            disableCreateBtnFromSecurity={!security.canCreate(Gui_id_list.procure.item_catalogue.item_catalogue_main_create_button) || props?.user?.is_buyer !== true}
            disableDeleteBtnFromSecurity={!security.canDelete(Gui_id_list.procure.item_catalogue.item_catalogue_main_delete_button) || props?.user?.is_buyer !== true} 
        />
    )   
}

const mapStateToProps = (state) => {
    return { 
        user:state.auth.user
    }
}

const SecurityOptions = {
  gui_id: Gui_id_list.procure.item_catalogue.item_catalogue_main
};

export default connect(mapStateToProps) (((ApplySecurityRoles(ItemCatalogueListingScreen, SecurityOptions))));