import React, { useRef, useState } from 'react'
import MasterComonent from '../../Backend/MasterComonent'
import NavigationHeder from '../../Navigations/NavigationHeder'
import ButtonNew from '../../inc/ButtonNew'
import Helper from '../../../inc/Helper'
import AgGridNew from '../../grid/ag/ag-grid-new'
import BudgetEntryPopup from './BudgetEntryPopup'
import { generateBaseUrl, getNewValue, gridLinkValueFormat } from '../../grid/ag/commonFunctions/GridCommonFunctions'
import DatepickerFunctions from '../../../inc/DatepickerHelper'
import { useNavigate } from 'react-router-dom'
import { Budget_Data } from './BudgetData'
import { exportToXlsx, handleFetchDataForListing } from '../../../inc/Validation'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

let gridObj = null;
const BudgeEntryList = () => {
  const [budgetEntryPopup, setBudgetEntryPopup] = useState(false)

  const navigate = useNavigate()
  const totalRecords = useRef()
  const [exportLoading, setExportLoading] = useState(false)

  const onNewBtnClick = () => {
    setBudgetEntryPopup(true)
  }

  const onPopupClose = () => {
    setBudgetEntryPopup(false)
  }

  const onCellClicked = (event) => {
     if(event?.colDef.field==='title'){
        navigate(`/edit-budget-entry/${event?.data?.id}`)
     }
  }

  let dataSource = {
    getRows: async function (params) {
      params?.success({
        rowData: Budget_Data?.map((item) => ({
          ...item,
          gridId: `${Date.now()}_${getNewValue()}`,
        })),
        rowCount: Budget_Data?.length,
      })
    },
  }

  const onGridReady = (params) => {
    if (params) {
      gridObj = params;
      setTimeout(() => {
        params?.api?.setGridOption('serverSideDatasource', dataSource)
      }, 200);
    }
  }

  const Columns_Headings = [
    {
      field: 'title',
      headerName: Helper.getLabel(null, 'title', 'Title'),
      editable: false,
      minWidth:180,
      cellRenderer:(params)=>{return gridLinkValueFormat(params)}
    },
    {
      field: 'year',
      headerName: Helper.getLabel(null, 'year', 'Year'),
      editable: false,
      minWidth:160,
    },
    {
      field: 'initiate_date',
      headerName: Helper.getLabel(null, 'initiate_date', 'Initiate date'),
      editable: false,
      minWidth:180,
      cellRenderer:(params)=>{return DatepickerFunctions.convertDateFromDataBase(params.value)}
    },
    {
      field: 'department',
      headerName: Helper.getLabel(null, 'department', 'Department'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'project',
      headerName: Helper.getLabel(null, 'project', 'Project'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'owner',
      headerName: Helper.getLabel(null, 'owner', 'Owner'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'description',
      headerName: Helper.getLabel(null, 'description', 'Description'),
      editable: false,
      minWidth: 200,
    },
    {
      field: 'total_budget_estimated',
      headerName: Helper.getLabel(null, 'total_budget_estimated', 'Total budget estimated'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'key_objectives',
      headerName: Helper.getLabel(null, 'key_objectives', 'Key objectives'),
      editable: false,
      minWidth:180,
    },
    {
      field: 'status',
      headerName: Helper.getLabel(null, 'status', 'Status'),
      editable: false,
      minWidth:180,
    },
  ]
  
  const exportData = async () => {
    setExportLoading(true);

    const apiUrl = '';
    const params = '';

    let pagination = true;
    let defaultAdvanceFilterObj = false;
    let pageSize = totalRecords.current;

    const baseUrl = generateBaseUrl({ gridObj, apiUrl, params, pagination, defaultAdvanceFilterObj, pageSize });
    await exportToXlsx({ url: baseUrl, columns: ( Columns_Headings ), fileName:  ("budget_entry_details"), additionalFunction: () => {} });
    
    setExportLoading(false);
  };

  return (
    <div>
      <MasterComonent>
        <NavigationHeder title="Budget Entry" hideMoreBtn={true}>
          <ButtonNew
            title={Helper.getLabel(null, "new", "New")}
            onClick={onNewBtnClick}
          ></ButtonNew>
        </NavigationHeder>
        {exportLoading ? <OverlayLoader /> : null}
        <div>
          <AgGridNew
            gridId={"budget-entry-grid"}
            isDataSourceRequire={true}
            columnDefs={Columns_Headings}
            height={500}
            hideAddBtn={true}
            isDeleteBtnDisable={true}
            onGridReady={onGridReady}
            onCellClicked={onCellClicked}
            afterDeleteBtns={
              <div className="import_sample_template px-2"> {/* enable this export button once the api is implemented into this form */}
                <button disabled={true} onClick={exportData}> 
                  <img className="img-fluid" src="/images/icons/excel-logo.svg" alt="excel-logo" /> Export
                </button>
              </div>
            }
            btnsWrapperStyle={`w-100 justify-content-between px-2`}
            fetchData={(response) => handleFetchDataForListing(response, totalRecords)}
          />
        </div>
      </MasterComonent>
      {budgetEntryPopup && <BudgetEntryPopup ClosePopup={onPopupClose} />}
    </div>
  );
}

export default BudgeEntryList
