import { useState, useCallback } from "react";

import Api from "../Api";
import Settings from "../Settings";
import Helper from "../Helper";
import { getSearchParam } from "../../components/RequestForQuotation/RFQDetailForm/RfqFunctions";
import { getPopupMessageBasedOnStatus } from "../Validation";
import { useLocation } from "react-router-dom";

const useAwardingBudgetCheck = (awardingTotalRecords, selectedSupNav) => {
    const [isAwardingLinesLoading, setIsAwardingLinesLoading] = useState(false);
    const location = useLocation();

    const hasBudgetCheckIssues = (data) => {
        return data?.some((line) => line.budget_check === null || line.budget_check?.toLowerCase()==="failed" || line.budget_check?.toLowerCase()==="not_performed");
    };

    const checkAwardingForBudget = useCallback((rfqHeader) => {
        return new Promise((resolve) => {
            if (!rfqHeader?.RFQ_id) return resolve(false);
            if (!getSearchParam("rfq_view_id",location) || selectedSupNav !== "Awarding") {
                return resolve(false);
            }

            const api = Api
            api.setUserToken();
            setIsAwardingLinesLoading(true);

            api.axios()
                .get(
                    `${Settings.apiPurchaseRequisitionUrl}/get_awarded_lines/${rfqHeader.RFQ_id}?page_number=1&page_size=${awardingTotalRecords?.current}`
                )
                .then((res) => {
                    setIsAwardingLinesLoading(false);
                    if (res.data.status !== "success") return resolve(false);
                    console.log(hasBudgetCheckIssues(res.data.data))
                    if (hasBudgetCheckIssues(res.data.data)) {
                        Helper.alert("Please check fund first.", "error");
                        return resolve(true);
                    }
                        
                    resolve(false);
                })
                .catch((err) => {
                    setIsAwardingLinesLoading(false);
                    getPopupMessageBasedOnStatus(err);
                    resolve(true);
                });
        });
    }, [awardingTotalRecords, selectedSupNav, location]);

    return { checkAwardingForBudget, isAwardingLinesLoading };
};


export default useAwardingBudgetCheck;
